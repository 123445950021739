import { call, fork, takeLatest, put, select } from 'redux-saga/effects';

// api
import { serviceModuleRpc, staffModuleRpc } from 'services/api';
import { fieldIsEmpty, formErrorIsEmpty, getErrorEntry, logger } from 'utils';
import { Service, Staff, StaffByServiceParams } from 'model';
import { RootState } from 'store/config';
import {
  ServiceActionTypes,
  setService,
  setServiceStaff,
  selectServiceEdit,
  clearServiceEdit,
  staffByServiceRequest,
  serviceEditLoading,
  setServiceEditError,
  clearServiceEditError,
  selectServiceEditError,
  selectServiceDelete,
  clearServiceDelete,
  setServiceDeleteSuccess,
  selectServiceDetail,
  selectServiceFormUpdatePartial,
  clearServiceFormPartial,
  updateServiceEdit,
} from '.';
import { PayloadAction } from '@reduxjs/toolkit';

function* getService(action: PayloadAction<number>) {
  if (!action.payload) return;

  try {
    logger('Service Detail Request');

    const response: Service = yield call(
      [serviceModuleRpc, serviceModuleRpc.getService],
      action.payload,
    );

    yield put(setService(response));

    logger('Service Detail Success');
  } catch (error: any) {
    logger.error('Service Detail Failure');
  }
}

function* getStaffByService() {
  const state: RootState = yield select();
  const service = selectServiceDetail(state);

  if (!service.id) return;

  try {
    const params: StaffByServiceParams = {
      id: service.id,
    };
    logger('Staff By Service Request');

    const response: Array<Staff> = yield call(
      staffModuleRpc.getStaffByService.bind(staffModuleRpc, params),
    );

    yield put(setServiceStaff(response));

    logger('Staff By Service Success');
  } catch (error: any) {
    logger.error('Staff By Service Failure');
  }
}

function* updateService() {
  yield call(validateServiceUpdate);

  const state: RootState = yield select();
  const serviceEdit = selectServiceEdit(state);
  const serviceEditError = selectServiceEditError(state);
  const service = selectServiceDetail(state);

  if (formErrorIsEmpty(serviceEditError)) return;

  try {
    yield put(serviceEditLoading(true));
    logger('Service Update Request');

    const response: Service = yield call(
      [serviceModuleRpc, serviceModuleRpc.updateService],
      service,
      serviceEdit,
    );

    yield put(clearServiceEdit());

    yield put(setService(response));
    yield put(staffByServiceRequest());

    yield put(serviceEditLoading(false));
    logger('Service Update Success');
  } catch (error: any) {
    const { key, value } = getErrorEntry(error);

    yield put(
      setServiceEditError({
        key: key,
        value: value,
      }),
    );

    yield put(serviceEditLoading(false));
    logger.error('Service Update Failure');
  }
}

function* validateServiceUpdate() {
  yield put(clearServiceEditError());

  const state: RootState = yield select();
  const serviceEdit = selectServiceEdit(state);

  if (fieldIsEmpty(serviceEdit.name)) {
    yield put(
      setServiceEditError({
        key: 'name',
        value: 'This field is required.',
      }),
    );
  }
}

function* updateServicePartial() {
  const state: RootState = yield select();
  const serviceFormUpdatePartial = selectServiceFormUpdatePartial(state);
  const service = selectServiceDetail(state);

  try {
    yield put(serviceEditLoading(true));

    logger('Service update partial request');

    const response: Service = yield call(
      [serviceModuleRpc, serviceModuleRpc.updateServicePartial],
      service,
      serviceFormUpdatePartial,
    );

    yield put(setService(response));
    yield put(clearServiceFormPartial());
    yield put(
      updateServiceEdit({
        key: 'image',
        value: null,
      }),
    );
    yield put(serviceEditLoading(false));

    logger('Service update partial Success');
  } catch (error: any) {
    const { key, value } = getErrorEntry(error);

    yield put(
      setServiceEditError({
        key: key,
        value: value,
      }),
    );

    yield put(serviceEditLoading(false));
    logger.error('Service update partial Failure');
  }
}

function* deleteService() {
  const state: RootState = yield select();
  const serviceDelete = selectServiceDelete(state);

  try {
    yield put(serviceEditLoading(true));
    logger('Service Delete Request');

    yield call(serviceModuleRpc.deleteService.bind(serviceModuleRpc, serviceDelete.id));

    yield put(clearServiceDelete());
    yield put(serviceEditLoading(false));
    yield put(setServiceDeleteSuccess(true));

    logger('Service Delete Success');
  } catch (error: any) {
    yield put(serviceEditLoading(false));

    logger.error('Service Delete Failure');
  }
}

function* serviceDetailRequestWatcher() {
  yield takeLatest(ServiceActionTypes.SERVICE_REQUEST, getService);
}

function* staffByServiceRequestWatcher() {
  yield takeLatest(ServiceActionTypes.STAFF_BY_SERVICE_REQUEST, getStaffByService);
}

function* serviceUpdateRequestWatcher() {
  yield takeLatest(ServiceActionTypes.UPDATE_REQUEST, updateService);
}

function* serviceUpdatePartialRequestWatcher() {
  yield takeLatest(ServiceActionTypes.UPDATE_PARTIAL_REQUEST, updateServicePartial);
}

function* serviceDeleteWatcher() {
  yield takeLatest(ServiceActionTypes.SERVICE_DELETE_REQUEST, deleteService);
}

export const serviceDetailSagas = [
  fork(serviceDetailRequestWatcher),
  fork(staffByServiceRequestWatcher),
  fork(serviceUpdateRequestWatcher),
  fork(serviceUpdatePartialRequestWatcher),
  fork(serviceDeleteWatcher),
];
