import styled from 'styled-components';
import {
  TableContainer,
  Table as TableUi,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';

const Container = styled.div`
  flex: 1;
  flex-wrap: nowrap;
  overflow-x: auto;
  display: flex;
`;

interface Props {
  headers: Array<string>;
  data: Array<any>;
}

const Table = ({ headers = [], data }: Props) => {
  return (
    <Container>
      <TableContainer>
        <TableUi>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                {row.map((cell: any, cellIndex: number) => (
                  <TableCell key={cellIndex}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </TableUi>
      </TableContainer>
    </Container>
  );
};

export default Table;
