import { useAuthEffect, useDocumentTitle, usePermission } from 'hooks';
import { PrivateLayout } from 'layout';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/config';

// components
import ClientsComposition from './ClientsComposition';

const ClientsScreen = () => {
  const { userPermissionFetched } = useSelector((state: RootState) => state.auth);
  const history = useHistory();
  const isAllowed = usePermission('company_receptionist');

  useAuthEffect(() => {
    if (userPermissionFetched && !isAllowed) {
      history.push('/dashboard');
    }
  }, [userPermissionFetched, isAllowed]);

  useDocumentTitle('Clients - Gotei');

  return (
    <PrivateLayout>
      <ClientsComposition />
    </PrivateLayout>
  );
};

export default ClientsScreen;
