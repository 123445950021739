import { CSSProperties } from 'react';

export const MuiTableCellRoot: CSSProperties = {
  padding: '10px 12px',
};

export const MuiTableCellHead: CSSProperties = {
  color: '#5E6C84',
  fontSize: 12,
  fontWeight: 600,
  padding: '8px 12px',
};
