import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { FieldsErrors, FormFieldPayload, Service, ServiceFormUpdate, Staff } from 'model';
import { RootState } from 'store/config';

interface State {
  service: Service;
  serviceStaff: Array<Staff>;
  serviceEditLoading: boolean;
  serviceEdit: ServiceFormUpdate;
  serviceEditError: FieldsErrors;
  serviceFormUpdatePartial: Partial<ServiceFormUpdate>;
  serviceDelete: Service;
  serviceDeleteSuccess: boolean;
}

const initialState: State = {
  service: {
    company: '',
    created: '',
    description: '',
    duration: '',
    id: 0,
    name: '',
    staff: [],
    updated: '',
    image: '',
    is_meeting: false,
    is_active: true,
  },
  serviceStaff: [],
  serviceEditLoading: false,
  serviceEditError: {},
  serviceEdit: {
    company: '',
    created: '',
    description: '',
    duration: '',
    id: 0,
    name: '',
    staff: [],
    updated: '',
    image: '',
    is_meeting: false,
    is_active: true,
  },
  serviceFormUpdatePartial: {
    company: '',
    created: '',
    description: '',
    duration: '',
    id: 0,
    name: '',
    staff: [],
    updated: '',
    image: '',
  },
  serviceDelete: {
    company: '',
    created: '',
    description: '',
    duration: '',
    id: 0,
    name: '',
    staff: [],
    updated: '',
    image: '',
    is_meeting: false,
    is_active: true,
  },
  serviceDeleteSuccess: false,
};

const ServiceDetailSlice = createSlice({
  name: 'serviceDetail',
  initialState,
  reducers: {
    setService: (state, action: PayloadAction<Service>) => {
      return {
        ...state,
        service: action.payload,
      };
    },
    setServiceStaff: (state, action: PayloadAction<Array<Staff>>) => {
      return {
        ...state,
        serviceStaff: action.payload,
      };
    },
    setServiceEdit: (state, action: PayloadAction<ServiceFormUpdate>) => {
      return {
        ...state,
        serviceEdit: action.payload,
      };
    },
    clearServiceEdit: (state) => {
      return {
        ...state,
        serviceEdit: {
          company: '',
          created: '',
          description: '',
          duration: '',
          id: 0,
          name: '',
          staff: [],
          updated: '',
          image: '',
          is_meeting: false,
          is_active: true,
        },
      };
    },
    updateServiceEdit: (state, action: PayloadAction<FormFieldPayload<keyof Service>>) => {
      return {
        ...state,
        serviceEdit: {
          ...state.serviceEdit,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    setServiceFormPartial: (state, action: PayloadAction<Partial<ServiceFormUpdate>>) => {
      return {
        ...state,
        serviceFormUpdatePartial: action.payload,
      };
    },
    clearServiceFormPartial: (state) => {
      return {
        ...state,
        serviceFormUpdatePartial: {
          company: '',
          created: '',
          description: '',
          duration: '',
          id: 0,
          name: '',
          staff: [],
          updated: '',
          image: '',
        },
      };
    },
    serviceEditLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        serviceEditLoading: action.payload,
      };
    },
    setServiceEditError: (
      state,
      action: PayloadAction<FormFieldPayload<keyof Service | 'server'>>,
    ) => {
      return {
        ...state,
        serviceEditError: {
          ...state.serviceEditError,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearServiceEditError: (state) => {
      return {
        ...state,
        serviceEditError: {},
      };
    },
    setServiceDelete: (state, action: PayloadAction<Service>) => {
      return {
        ...state,
        serviceDelete: action.payload,
      };
    },
    clearServiceDelete: (state) => {
      return {
        ...state,
        serviceDelete: {
          company: '',
          created: '',
          description: '',
          duration: '',
          id: 0,
          name: '',
          staff: [],
          updated: '',
          image: '',
          is_meeting: false,
          is_active: true,
        },
      };
    },
    setServiceDeleteSuccess: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        serviceDeleteSuccess: action.payload,
      };
    },
  },
});

export const {
  setService,
  setServiceStaff,
  setServiceEdit,
  clearServiceEdit,
  updateServiceEdit,
  serviceEditLoading,
  setServiceFormPartial,
  clearServiceFormPartial,
  setServiceEditError,
  clearServiceEditError,
  setServiceDelete,
  clearServiceDelete,
  setServiceDeleteSuccess,
} = ServiceDetailSlice.actions;

export enum ServiceActionTypes {
  SERVICE_REQUEST = 'service/SERVICE_REQUEST',
  STAFF_BY_SERVICE_REQUEST = 'service/STAFF_BY_SERVICE_REQUEST',
  UPDATE_REQUEST = 'service/UPDATE_REQUEST',
  UPDATE_PARTIAL_REQUEST = 'service/UPDATE_PARTIAL_REQUEST',
  SERVICE_DELETE_REQUEST = 'service/SERVICE_DELETE_REQUEST',
}

export const serviceRequest = (id: number) => {
  return {
    type: ServiceActionTypes.SERVICE_REQUEST,
    payload: id,
  };
};

export const staffByServiceRequest = () => {
  return {
    type: ServiceActionTypes.STAFF_BY_SERVICE_REQUEST,
  };
};

export const serviceUpdateRequest = () => {
  return {
    type: ServiceActionTypes.UPDATE_REQUEST,
  };
};

export const serviceUpdatePartialRequest = () => {
  return {
    type: ServiceActionTypes.UPDATE_PARTIAL_REQUEST,
  };
};

export const serviceDeleteRequest = () => {
  return {
    type: ServiceActionTypes.SERVICE_DELETE_REQUEST,
  };
};

export const selectService = (state: RootState) => {
  return state.serviceDetail;
};

export const selectServiceDetail = createSelector([selectService], (value) => value.service);

export const selectServiceEdit = createSelector([selectService], (value) => value.serviceEdit);

export const selectServiceFormUpdatePartial = createSelector(
  [selectService],
  (value) => value.serviceFormUpdatePartial,
);

export const selectServiceEditError = createSelector(
  [selectService],
  (value) => value.serviceEditError,
);

export const selectServiceDelete = createSelector([selectService], (value) => value.serviceDelete);

export const serviceDetailReducer = ServiceDetailSlice.reducer;
