import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BookingType,
  BookingUpdateType,
  BusinessHour,
  Company,
  FieldsErrors,
  FormFieldPayload,
  ManageSteps,
  WorkingHour,
} from 'model';
import moment from 'moment-timezone';
import { RootState } from 'store/config';

interface Slice {
  step: ManageSteps;
  company: Company;
  businessHours: Array<BusinessHour>;
  staffWorkingHours: Array<WorkingHour>;
  loading: boolean;
  booking: BookingType;
  bookingError: string;
  bookingFormUpdate: BookingUpdateType;
  bookingFormError: FieldsErrors;
  bookingFormUpdateLoading: boolean;
  date: string;
  availability: Array<string>;
  availabilityError: string;
  availabilityLoading: boolean;
  availabilityAreFetched: boolean;
  deleteLoading: boolean;
}

const initialState: Slice = {
  step: ManageSteps.overview,
  company: {
    uuid: '',
    created: '',
    name: '',
    updated: '',
    url_component: '',
    timezone: '',
    image: '',
    is_verified: false,
    verified_at: '',
  },
  businessHours: [],
  loading: false,
  staffWorkingHours: [],
  booking: {
    id: 0,
    uuid: '',
    service: {
      id: 0,
      company: '',
      created: '',
      description: '',
      duration: '',
      name: '',
      staff: [],
      updated: '',
      image: '',
      is_meeting: false,
      is_active: true,
    },
    client: {
      company: '',
      id: 0,
      name: '',
      email: '',
      phone: null,
    },
    start_time: '',
    end_time: '',
    created: '',
    updated: '',
    staff: {
      id: 0,
      company: '',
      created: '',
      description: '',
      name: '',
      updated: '',
      user: null,
      image: '',
    },
    company: '',
    is_canceled: false,
  },
  bookingError: '',
  bookingFormUpdate: {
    id: 0,
    uuid: '',
    service: 0,
    client: 0,
    start_time: '',
    end_time: '',
    created: '',
    updated: '',
    staff: 0,
    company: '',
    is_canceled: false,
  },
  bookingFormError: {},
  bookingFormUpdateLoading: false,
  date: moment().format(),
  availabilityLoading: false,
  availabilityAreFetched: false,
  availability: [],
  availabilityError: '',
  deleteLoading: false,
};

const ManageSlice = createSlice({
  name: 'manage',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<ManageSteps>) => {
      return {
        ...state,
        step: action.payload,
      };
    },
    setCompany: (state, action: PayloadAction<Company>) => {
      return {
        ...state,
        company: action.payload,
      };
    },
    setBusinessHours: (state, action: PayloadAction<Array<BusinessHour>>) => {
      return {
        ...state,
        businessHours: action.payload,
      };
    },
    setStaffWorkingHours: (state, action: PayloadAction<Array<WorkingHour>>) => {
      state.staffWorkingHours = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setBooking: (state, action: PayloadAction<BookingType>) => {
      return {
        ...state,
        booking: action.payload,
      };
    },
    setBookingError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        bookingError: action.payload,
      };
    },
    setBookingFormUpdate: (state, action: PayloadAction<BookingUpdateType>) => {
      return {
        ...state,
        bookingFormUpdate: action.payload,
      };
    },
    updateBookingFormUpdate: (
      state,
      action: PayloadAction<FormFieldPayload<keyof BookingUpdateType>>,
    ) => {
      return {
        ...state,
        bookingFormUpdate: {
          ...state.bookingFormUpdate,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearBookingFormUpdate: (state) => {
      return {
        ...state,
        bookingFormUpdate: {
          id: 0,
          uuid: '',
          service: 0,
          client: 0,
          start_time: '',
          end_time: '',
          created: '',
          updated: '',
          staff: 0,
          company: '',
          is_canceled: false,
        },
      };
    },
    bookingFormUpdateLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        bookingFormUpdateLoading: action.payload,
      };
    },
    setBookingFormError: (state, action: PayloadAction<FieldsErrors>) => {
      state.bookingFormError = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        date: action.payload,
      };
    },
    setAvailability: (state, action: PayloadAction<Array<string>>) => {
      return {
        ...state,
        availability: action.payload,
      };
    },
    setAvailabilityLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        availabilityLoading: action.payload,
      };
    },
    setAvailabilityAreFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        availabilityAreFetched: action.payload,
      };
    },
    setAvailabilityError: (state, action: PayloadAction<string>) => {
      state.availabilityError = action.payload;
    },
    setDeleteLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        deleteLoading: action.payload,
      };
    },
  },
});

export const {
  setStep,
  setCompany,
  setBusinessHours,
  setStaffWorkingHours,
  setLoading,
  setBooking,
  setBookingError,
  setBookingFormUpdate,
  updateBookingFormUpdate,
  clearBookingFormUpdate,
  bookingFormUpdateLoading,
  setBookingFormError,
  setDate,
  setAvailability,
  setAvailabilityLoading,
  setAvailabilityAreFetched,
  setAvailabilityError,
  setDeleteLoading,
} = ManageSlice.actions;

export enum ManageActionTypes {
  COMPANY_REQUEST = 'manage/COMPANY_REQUEST',
  BOOKING_REQUEST = 'manage/BOOKING_REQUEST',
  AVAILABILITY_REQUEST = 'manage/AVAILABILITY_REQUEST',
  BOOKING_UPDATE_SUBMIT = 'manage/BOOKING_UPDATE_SUBMIT',
  DELETE_BOOKING_REQUEST = 'manage/DELETE_BOOKING_REQUEST',
  BUSINESS_HOURS_REQUEST = 'manage/BUSINESS_HOURS_REQUEST',
  STAFF_WORKING_HOURS_REQUEST = 'manage/STAFF_WORKING_HOURS_REQUEST',
}

export const companyRequest = (uuid: string) => {
  return {
    type: ManageActionTypes.COMPANY_REQUEST,
    payload: uuid,
  };
};

export const businessHoursRequest = (companyUrl: string) => {
  return {
    type: ManageActionTypes.BUSINESS_HOURS_REQUEST,
    payload: companyUrl,
  };
};

export const staffWorkingHoursRequest = () => ({
  type: ManageActionTypes.STAFF_WORKING_HOURS_REQUEST,
});

export const bookingRequest = (uuid: string) => {
  return {
    type: ManageActionTypes.BOOKING_REQUEST,
    payload: uuid,
  };
};

export const deleteRequest = (uuid: string) => {
  return {
    type: ManageActionTypes.BOOKING_REQUEST,
    payload: uuid,
  };
};

export const availabilityRequest = () => {
  return {
    type: ManageActionTypes.AVAILABILITY_REQUEST,
  };
};

export const bookingUpdateSubmit = () => {
  return {
    type: ManageActionTypes.BOOKING_UPDATE_SUBMIT,
  };
};

export const bookingDeleteRequest = () => {
  return {
    type: ManageActionTypes.DELETE_BOOKING_REQUEST,
  };
};

export const selectManage = (state: RootState) => {
  return state.manage;
};

export const selectDate = createSelector([selectManage], (value) => value.date);

export const selectBooking = createSelector([selectManage], (value) => value.booking);

export const selectBookingFormUpdate = createSelector(
  [selectManage],
  (value) => value.bookingFormUpdate,
);

export const manageReducer = ManageSlice.reducer;
