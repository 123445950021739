// dependencies
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, Card, Chip, Stack } from '@mui/material';
import { Font } from 'elements';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store/config';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 0 0 1rem;
  height: 200px;
`;

const Title = styled(Font)`
  font-size: 32px;
  font-weight: 700;
`;

const calculatePercentage = (newBookings: number, oldBookings: number) => {
  /**
   * @INCREASE_FORMULA
   * Difference = New Number - Old Number
   * Increase = Difference ÷ Old Number × 100.
   *
   * @DECREASE_FORMULA
   * Difference = Old Number - New Number AND
   * Decrease = Difference ÷ Old Number × 100
   *
   */
  const isIncrease = newBookings > oldBookings;

  if (isIncrease) {
    let increase = 0;
    // get the difference between today and previous day bookings
    increase = newBookings - oldBookings;

    // if previous day has more than 0 then divide else don't because it break the division
    if (oldBookings > 0) increase /= oldBookings;

    return increase * 100;
  } else {
    let decrease = 0;
    // get the difference between today and previous day bookings
    decrease = oldBookings - newBookings;

    // if previous day has more than 0 then divide else don't because it break the division
    if (oldBookings > 0) decrease /= oldBookings;

    return -decrease * 100;
  }
};

function StatisticWidgets() {
  const { dailyBookings, lastDailyBookings, weeklyAppointments, lastWeeklyAppointments } =
    useSelector((state: RootState) => state.dashboard);
  const dailyPercentage = calculatePercentage(dailyBookings.length, lastDailyBookings.length);
  const weeklyPercentage = calculatePercentage(
    weeklyAppointments.length,
    lastWeeklyAppointments.length,
  );

  return (
    <Container>
      <Card sx={{ p: 2, mr: 1, justifyContent: 'center' }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Title>{dailyBookings.length}</Title>

          <Box>
            {dailyPercentage >= 0 ? (
              <Chip
                sx={{
                  background: '#CEEAD6',
                  color: '#B31412',
                  fontWeight: 600,
                }}
                label={
                  <Stack direction='row' alignItems='center'>
                    <ArrowDropUp color='success' /> &nbsp;
                    <Font style={{ color: '#137333', fontWeight: 600 }}>
                      +{dailyPercentage?.toFixed(0)}%
                    </Font>
                  </Stack>
                }
              />
            ) : (
              <Chip
                sx={{ background: '#FAD2CF' }}
                label={
                  <Stack direction='row' alignItems='center'>
                    <ArrowDropDown color='error' /> &nbsp;
                    <Font style={{ color: '#B31412', fontWeight: 600 }}>
                      {dailyPercentage?.toFixed(0)}%
                    </Font>
                  </Stack>
                }
              />
            )}
          </Box>
        </Stack>

        <Font type='h5' style={{ fontSize: 16 }}>
          Today appointments
        </Font>

        <Font type='secondary' style={{ marginTop: 10 }}>
          Appointments in comparison to previous day
        </Font>
      </Card>

      <Card sx={{ p: 2, ml: 1, justifyContent: 'center' }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Title>{weeklyAppointments.length}</Title>

          <Box>
            {weeklyPercentage >= 0 ? (
              <Chip
                sx={{
                  background: '#CEEAD6',
                  color: '#B31412',
                  fontWeight: 600,
                }}
                label={
                  <Stack direction='row' alignItems='center'>
                    <ArrowDropUp color='success' /> &nbsp;
                    <Font style={{ color: '#137333', fontWeight: 600 }}>
                      +{weeklyPercentage?.toFixed(0)}%
                    </Font>
                  </Stack>
                }
              />
            ) : (
              <Chip
                sx={{ background: '#FAD2CF' }}
                label={
                  <Stack direction='row' alignItems='center'>
                    <ArrowDropDown color='error' /> &nbsp;
                    <Font style={{ color: '#B31412', fontWeight: 600 }}>
                      {weeklyPercentage?.toFixed(0)}%
                    </Font>
                  </Stack>
                }
              />
            )}
          </Box>
        </Stack>

        <Font type='h5' style={{ fontSize: 16 }}>
          Weekly appointments
        </Font>

        <Font type='secondary' style={{ marginTop: 10 }}>
          Bookings in comparison to previous week
        </Font>
      </Card>
    </Container>
  );
}

export default StatisticWidgets;
