import { CSSProperties, useState } from 'react';

// dependencies
import styled from 'styled-components';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { Font, Popper } from 'elements';

const ButtonText = styled(Font)`
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Circle = styled.span`
  font-size: 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  padding: 2px 6px;
  margin-left: 5px;
`;

interface Props {
  options: Array<string>;
  value?: string | Array<string>;
  style?: CSSProperties;
  multiple?: boolean;
  placeholder?: string;
  tooltip?: string;
  onChange?: (option: string) => void;
}

function Select({
  value,
  options = [],
  style = {},
  multiple = false,
  placeholder = 'None',
  tooltip = '',
  onChange,
}: Props) {
  const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div>
      <Tooltip
        title={tooltip}
        open={!!tooltip && tooltipOpen && !popperEl}
        onOpen={() => {
          setTooltipOpen(true);
        }}
        onClose={() => {
          setTooltipOpen(false);
        }}
      >
        <Button
          color='inherit'
          variant='contained'
          endIcon={<KeyboardArrowDown />}
          style={{ ...style }}
          onClick={(event) => {
            setPopperEl(event.currentTarget);
            setTooltipOpen(false);
          }}
        >
          <ButtonText>
            {Array.isArray(value) ? (
              <>
                {placeholder}

                {value.length > 0 && <Circle>{value.length}</Circle>}
              </>
            ) : value ? (
              value
            ) : (
              placeholder
            )}
          </ButtonText>
        </Button>
      </Tooltip>

      <Popper open={!!popperEl} anchorEl={popperEl} onClose={() => setPopperEl(null)}>
        <List component='div' sx={{ p: 0, maxHeight: 400, overflow: 'auto' }}>
          {options.map((option, index) => {
            if (multiple) {
              return (
                <ListItem
                  button
                  key={index}
                  sx={{ p: 0 }}
                  onClick={() => {
                    if (onChange) onChange(option);
                  }}
                >
                  <ListItemIcon>
                    <Checkbox checked={value?.includes(option)} />
                  </ListItemIcon>

                  <ListItemText primary={<Font>{option}</Font>} />
                </ListItem>
              );
            } else {
              return (
                <ListItem
                  button
                  key={index}
                  sx={{ padding: '5px 12px' }}
                  selected={option === value}
                  onClick={() => {
                    if (onChange) onChange(option);

                    setPopperEl(null);
                  }}
                >
                  <ListItemText primary={<Font>{option}</Font>} />
                </ListItem>
              );
            }
          })}
        </List>
      </Popper>
    </div>
  );
}

export default Select;
