import { ReactText } from 'react';

// dependencies
import styled from 'styled-components';
import { Link as RouteLink } from 'react-router-dom';
import { Theme } from '@mui/material';

const StyledLink = styled(RouteLink)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
  text-decoration: none;
  font-size: 0.875rem;
  &:hover {
    text-decoration: underline;
  }
`;

interface Props {
  to: string;
  params?: string;
  children: ReactText;
}

const Link = ({ to, children, params }: Props) => {
  return (
    <StyledLink to={{ pathname: to, search: params }}>{children}</StyledLink>
  );
};

export default Link;
