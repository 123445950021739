import {
  AccessTime,
  ChevronLeft,
  InsertInvitation,
  Person,
  RoomService,
} from '@mui/icons-material';
import { Box, IconButton, LinearProgress, linearProgressClasses, Stack } from '@mui/material';
import { Font } from 'elements';
import styled from 'styled-components';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { setStep } from '..';
import moment from 'moment-timezone';
import { useMemo } from 'react';

const stepsTitle = [
  'Select your service',
  'Select your provider',
  'Select your date & time',
  'Confirm',
];

const Container = styled.div``;

const StyledIconButton = styled(IconButton)`
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  margin-right: 1.5rem;
`;

const Img = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;
`;

const StackItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const MobileStepper = () => {
  const { step, company, bookingForm, services, staff } = useSelector(
    (state: RootState) => state.bookingPage,
  );
  const dispatch = useDispatch();
  const maxStep = 3;
  const currentStep = step + 1;
  const stepProgression = (step / maxStep) * 100;
  const selectedService = useMemo(
    () => services.find((service) => service.id === bookingForm.service),
    [bookingForm.service, services],
  );
  const selectedProvider = useMemo(
    () => staff.find((staffDetail) => staffDetail.id === bookingForm.staff),
    [bookingForm.staff, staff],
  );

  return (
    <Container>
      <Stack mb={4}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Box flex={1}>
            <StyledIconButton
              disabled={step === 0}
              sx={{ opacity: step === 0 ? 0 : 1 }}
              onClick={() => {
                dispatch(setStep(Math.max(step - 1, 0)));
              }}
            >
              <ChevronLeft color='primary' fontSize='large' />
            </StyledIconButton>
          </Box>

          <Box textAlign='center' flex={4}>
            {company.image ? (
              <Img src={company.image} />
            ) : (
              <Font style={{ fontSize: 30 }} type='h1'>
                {company.name}
              </Font>
            )}
          </Box>

          <Box flex={1} />
        </Stack>
      </Stack>

      <Stack direction='row' mb={4}>
        <Stack justifyContent='space-between'>
          <Font type='h3'>{stepsTitle[step]}</Font>

          <Font type='secondary'>Step {currentStep} of 4</Font>
        </Stack>
      </Stack>

      <Stack>
        {!!selectedService && (
          <StackItem>
            <RoomService sx={{ mr: 2 }} />

            <Font>{selectedService?.name}</Font>
          </StackItem>
        )}

        {!!selectedService && (
          <StackItem>
            <AccessTime sx={{ mr: 2 }} />

            <Font>{selectedService?.duration} mins</Font>
          </StackItem>
        )}

        {!!selectedProvider && (
          <StackItem>
            <Person sx={{ mr: 2 }} />

            <Font>{selectedProvider.name}</Font>
          </StackItem>
        )}

        {!!bookingForm.start_time && (
          <StackItem>
            <InsertInvitation sx={{ mr: 2 }} />

            <Font>{moment(bookingForm.start_time).format('ddd D MMMM YYYY, HH:mm')}</Font>
          </StackItem>
        )}
      </Stack>

      <Box m='2rem -1.5rem 2rem -1.5rem'>
        <LinearProgress
          sx={{
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
          variant='determinate'
          value={stepProgression}
        />
      </Box>
    </Container>
  );
};

export default MobileStepper;
