// dependencies
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  colors,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from '@mui/material';
import { Check, Edit, PhotoCamera } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FileUpload, Font, TimePicker } from 'elements';
import styled from 'styled-components';
import { fileToBase64, weekdays } from 'utils';
import moment from 'moment-timezone';
import { BusinessHour, BusinessHourFormCreate } from 'model';

// redux
import { RootState } from 'store/config';
import { useDispatch, useSelector } from 'react-redux';
import {
  businessHoursUpdateRequest,
  businessHoursCreateRequest,
  businessHoursDeleteRequest,
  updateCompanyFormEdit,
  setCompanyFormEDit,
  clearCompanyFormEdit,
  companyUpdateRequest,
  companyUpdatePartialRequest,
  setCompanyFormEditPartial,
} from '..';

import image from 'assets/images/placeholder.png';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionTitle = styled.div`
  flex: 1;
  padding: 0 1.5rem 0 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const SectionContent = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
`;

const SectionActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const CompanyImage = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 24px 0 0 24px;
  margin: 0 -29px;

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    height: 60%;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.grey[200]};
    border-radius: 4px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0;
  }
`;

const CompanyName = styled.div`
  margin-top: 1.5rem;
`;

const DaySection = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

const DayNameWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex: 0.7;
  }
`;

const AvatarWrapper = styled.div`
  position: relative;

  &:hover {
    div:nth-child(2) {
      display: flex;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
`;

const LabelWrapper = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

const LoadingWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

const Label = styled.label`
  &:hover {
    cursor: pointer;
  }
`;

const MobileHidden = styled.div`
  flex: 1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

function Company() {
  const { company, businessHours } = useSelector((state: RootState) => state.organization);
  const { companyFormEdit, companyFormEditError, companyFormEditLoading, businessHoursError } =
    useSelector((state: RootState) => state.company);
  const dispatch = useDispatch();

  return (
    <Container>
      <Section>
        <SectionTitle>
          <Font type='h5'>Overview</Font>
          <Font>Company Information</Font>
        </SectionTitle>

        <SectionContent>
          <CompanyImage>
            <FileUpload
              style={{ display: 'none' }}
              accept={['image/*']}
              htmlFor='upload-file'
              onFileUpload={async (event) => {
                const file = event.target.files?.[0];

                if (file) {
                  const image = await fileToBase64(file);

                  dispatch(
                    setCompanyFormEditPartial({
                      uuid: company.uuid,
                      image,
                    }),
                  );

                  dispatch(companyUpdatePartialRequest());
                }
              }}
            />

            <AvatarWrapper>
              <Avatar
                src={company.image || ''}
                sx={{
                  height: 110,
                  width: 110,
                  border: `5px solid ${colors.common.white}`,
                  backgroundColor: colors.common.white,
                }}
              >
                <Avatar src={image} sx={{ height: 105, width: 105 }} />
              </Avatar>

              {companyFormEditLoading ? (
                <LoadingWrapper>
                  <CircularProgress sx={{ color: colors.common.white }} />
                </LoadingWrapper>
              ) : (
                <LabelWrapper>
                  <Label htmlFor='upload-file'>
                    <PhotoCamera fontSize='large' sx={{ color: colors.common.white }} />
                  </Label>
                </LabelWrapper>
              )}
            </AvatarWrapper>

            <Font type='h2' style={{ marginLeft: 24 }}>
              {company.name}
            </Font>
          </CompanyImage>

          {companyFormEditError.image && (
            <FormHelperText error>{companyFormEditError.image}</FormHelperText>
          )}

          <CompanyName>
            {companyFormEdit.uuid ? (
              <TextField
                fullWidth
                type='text'
                label='Company name'
                value={companyFormEdit.name}
                error={!!companyFormEditError.name}
                helperText={companyFormEditError.name}
                onChange={(event) => {
                  dispatch(
                    updateCompanyFormEdit({
                      key: 'name',
                      value: event.currentTarget.value,
                    }),
                  );
                }}
              />
            ) : (
              <>
                <Font type='h5'>Company name</Font>
                <Font>{company.name}</Font>
              </>
            )}
          </CompanyName>

          <CompanyName>
            {companyFormEdit.uuid ? (
              <Autocomplete
                value={companyFormEdit.timezone}
                options={moment.tz.names()}
                renderInput={(params) => <TextField {...params} label='Timezone' />}
                onChange={(_, value) => {
                  dispatch(
                    updateCompanyFormEdit({
                      key: 'timezone',
                      value: value,
                    }),
                  );
                }}
              />
            ) : (
              <>
                <Font type='h5'>Timezone</Font>
                <Font>{company.timezone}</Font>
              </>
            )}
          </CompanyName>
        </SectionContent>

        <MobileHidden>
          <SectionActions>
            <div>
              {companyFormEdit.uuid ? (
                <>
                  <Button
                    color='inherit'
                    sx={{
                      mr: 1,
                    }}
                    onClick={() => {
                      dispatch(clearCompanyFormEdit());
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    startIcon={<Check />}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      dispatch(companyUpdateRequest());
                    }}
                  >
                    Save
                  </LoadingButton>
                </>
              ) : (
                <Button
                  color='inherit'
                  startIcon={<Edit />}
                  onClick={async () => {
                    dispatch(
                      setCompanyFormEDit({
                        ...company,
                      }),
                    );
                  }}
                >
                  Edit
                </Button>
              )}
            </div>
          </SectionActions>
        </MobileHidden>
      </Section>

      <Divider sx={{ m: '24px 0' }} />

      <Section>
        <SectionTitle>
          <Font type='h5'>Website</Font>
          <Font>Your company URL</Font>
        </SectionTitle>

        <SectionContent>
          {companyFormEdit.uuid ? (
            <TextField
              fullWidth
              type='url'
              label='Company URL'
              value={companyFormEdit.url_component}
              error={!!companyFormEditError.url_component}
              helperText={
                companyFormEditError.url_component
                  ? companyFormEditError.url_component
                  : 'The above field is your company url which your website will be published.'
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>{window.origin + '/'}</InputAdornment>
                ),
              }}
              onChange={(event) => {
                dispatch(
                  updateCompanyFormEdit({
                    key: 'url_component',
                    value: event.currentTarget.value.trim(),
                  }),
                );
              }}
            />
          ) : (
            <>
              <Font type='h5'>Company URL</Font>
              <Link href={`${window.origin}/booking/${company.url_component}`} target='_blank'>
                {`${window.origin}/booking/${company.url_component}`}
              </Link>
            </>
          )}
        </SectionContent>

        <SectionActions></SectionActions>
      </Section>

      <Divider sx={{ m: '24px 0' }} />

      <Section>
        <SectionTitle>
          <Font type='h5'>Business hours</Font>
          <Font>
            The company's opening and closing hours will allow the clients to book appointments only
            between the company's business hours
          </Font>
        </SectionTitle>

        <SectionContent>
          {weekdays().map((day: number, index) => {
            const businessHourForm = businessHours.find((time) => time.weekday === day);
            const checked = !!businessHourForm;
            const error = businessHoursError[day];

            let start = moment().hours(8).minutes(0).seconds(0);
            let end = moment().hours(17).minutes(0).seconds(0);

            if (businessHourForm) {
              const [startHours, startMinutes] = businessHourForm.start_time.split(':');
              const [endHours, endMinutes] = businessHourForm.end_time.split(':');

              start = moment().hours(+startHours).minutes(+startMinutes).seconds(0);
              end = moment().hours(+endHours).minutes(+endMinutes).seconds(0);
            }

            return (
              <DaySection key={index}>
                <Stack direction={'row'} flex={1} justifyContent={'space-between'}>
                  <DayNameWrapper>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={checked} />}
                        label={moment().set('weekday', day).format('dddd')}
                        onChange={() => {
                          if (checked && businessHourForm) {
                            dispatch(businessHoursDeleteRequest(businessHourForm));
                          } else {
                            const businessHoursFormCreate: BusinessHourFormCreate = {
                              weekday: day,
                              company: company.uuid,
                              start_time: start.seconds(0).format('HH:mm:ss'),
                              end_time: end.seconds(0).format('HH:mm:ss'),
                            };

                            dispatch(businessHoursCreateRequest(businessHoursFormCreate));
                          }
                        }}
                      />
                    </FormGroup>
                  </DayNameWrapper>

                  <Stack
                    flex={1}
                    sx={{
                      alignItems: {
                        xs: 'flex-end',
                        sm: 'flex-end',
                        lg: 'flex-start',
                      },
                    }}
                  >
                    <Box>
                      <TimePicker
                        error={!!error}
                        disabled={!checked}
                        value={start}
                        onChange={(value) => {
                          if (businessHourForm) {
                            const businessHourFormCopy: BusinessHour = {
                              ...businessHourForm,
                              start_time: value.format('HH:mm'),
                            };

                            dispatch(businessHoursUpdateRequest(businessHourFormCopy));
                          }
                        }}
                      />

                      <Font style={{ margin: '0 1rem' }}>to</Font>

                      <TimePicker
                        error={!!error}
                        disabled={!checked}
                        value={end}
                        onChange={(value) => {
                          if (businessHourForm) {
                            const businessHourFormCopy: BusinessHour = {
                              ...businessHourForm,
                              end_time: value.format('HH:mm'),
                            };

                            dispatch(businessHoursUpdateRequest(businessHourFormCopy));
                          }
                        }}
                      />
                    </Box>

                    <Box>{error && <FormHelperText error>{error}</FormHelperText>}</Box>
                  </Stack>
                </Stack>
              </DaySection>
            );
          })}
        </SectionContent>

        <SectionActions></SectionActions>
      </Section>
    </Container>
  );
}

export default Company;
