// dependencies
import { EventAvailable } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { NotificationTypeAction } from 'model';

const backgroundColorMap = {
  BOOKING_CREATE: '#CEEAD6',
  BOOKING_DELETE: '#FAD2CF',
  BOOKING_UPDATE: '#FFE0A7',
};

const colorMap = {
  BOOKING_CREATE: '#137333',
  BOOKING_DELETE: '#B31412',
  BOOKING_UPDATE: '#FFA500',
};

interface Props {
  action: NotificationTypeAction;
}
const EventAvatar = ({ action }: Props) => {
  return (
    <Avatar
      sx={{
        backgroundColor: backgroundColorMap[action],
      }}
    >
      <EventAvailable sx={{ color: colorMap[action] }} />
    </Avatar>
  );
};

export default EventAvatar;
