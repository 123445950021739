// components
import { useDocumentTitle } from 'hooks';
import SignUpComposition from './SignUpComposition';

const LoginScreen = () => {
  useDocumentTitle('Sign up - Gotei');

  return <SignUpComposition />;
};

export default LoginScreen;
