// dependencies

import styled from 'styled-components';

const Container = styled.div``;

const BorderLeft = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.grey[200]};
  position: absolute;
  top: 0;
  left: 15%;
  height: 100%;
  width: 1px;
`;

const BorderRight = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.grey[200]};
  position: absolute;
  top: 0;
  right: 15%;
  height: 100%;
  width: 1px;
`;

const RectWrapper = styled.div`
  position: fixed;
  background-color: ${({ theme }) => theme.palette.common.white};
  z-index: 1;
  top: 450px;
  right: 0;
  left: 0;
  bottom: -450px;
  transform: skewY(-12deg);
`;

const RectLongLeft = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  z-index: 3;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 75%;
`;

const RectLongRight = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 40px;
  position: absolute;
  top: -40px;
  right: 0;
  left: 75%;
`;

const RectShort = styled.div`
  background-color: #50d9ff;
  height: 30px;
  position: absolute;
  top: -30px;
  left: 3%;
  right: 85%;
`;

function Background() {
  return (
    <Container>
      <BorderLeft />
      <BorderRight />

      <RectWrapper>
        <BorderLeft />
        <BorderRight />
        <RectLongLeft />
        <RectShort />
        <RectLongRight />
      </RectWrapper>
    </Container>
  );
}

export default Background;
