import { all } from 'redux-saga/effects';

import { authSagas } from '../auth';
import { uiSagas } from '../ui';
import { loginSagas } from 'screens/login';
import { calendarSagas } from 'screens/calendar';
import { organizationSagas } from '../organization';
import { staffSagas } from 'screens/staff';
import { StaffDetailSagas } from 'screens/staffDetail';
import { serviceDetailSagas } from 'screens/serviceDetail';
import { servicesSagas } from 'screens/services';
import { companySagas } from 'screens/company';
import { clientsSagas } from 'screens/clients';
import { clientSagas } from 'screens/client';
import { dashboardSagas } from 'screens/dashboard';
import { bookingPageSagas } from 'screens/publicPages/bookingPage';
import { manageSagas } from 'screens/publicPages/manage';
import { signupSagas } from 'screens/signUp';
import { resetPasswordSagas } from 'screens/resetPassword';

export default function* rootSagas() {
  yield all([
    ...loginSagas,
    ...authSagas,
    ...calendarSagas,
    ...organizationSagas,
    ...staffSagas,
    ...StaffDetailSagas,
    ...serviceDetailSagas,
    ...servicesSagas,
    ...companySagas,
    ...clientsSagas,
    ...clientSagas,
    ...uiSagas,
    ...bookingPageSagas,
    ...dashboardSagas,
    ...manageSagas,
    ...signupSagas,
    ...resetPasswordSagas,
  ]);
}
