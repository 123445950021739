import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { RootState } from 'store/config';
import { userModuleRpc } from 'services/api';
import { emailIsValid, fieldIsEmpty, formErrorIsEmpty, logger } from 'utils';

import { openSnackbarRequest } from 'store/ui';

import {
  SignUpActionTypes,
  setLoading,
  selectSignUpFormCreate,
  setUserFormCreateError,
  cleanUserFormCreateError,
  selectSignUpFormCreateError,
  clearUserFormCreate,
  setRegisterComplete,
} from '.';
import { FieldsErrors } from 'model';

function* signUpSubmit() {
  yield call(validateSignup);

  const state: RootState = yield select();
  const signUpFormCreate = selectSignUpFormCreate(state);
  const signUpFormCreateError = selectSignUpFormCreateError(state);

  if (formErrorIsEmpty(signUpFormCreateError)) {
    return;
  }

  try {
    logger('Sign Up Submit Request');

    yield put(setLoading(true));

    yield call([userModuleRpc, userModuleRpc.signupWithCompany], signUpFormCreate);

    logger('Sign Up Success');

    yield put(openSnackbarRequest(`Your account has been successfully created.`));

    yield put(clearUserFormCreate());
    yield put(setRegisterComplete(true));
    yield put(setLoading(false));
  } catch (error: any) {
    logger.error('Sign Up Failure');

    yield put(setUserFormCreateError(error));
    yield put(setLoading(false));
  }
}

function* validateSignup() {
  yield put(cleanUserFormCreateError());

  const state: RootState = yield select();
  const signUpFormCreate = selectSignUpFormCreate(state);

  const errors: FieldsErrors = {};

  if (fieldIsEmpty(signUpFormCreate.name)) {
    errors['name'] = 'This field is required';
  }
  if (fieldIsEmpty(signUpFormCreate.email)) {
    errors['email'] = 'This field is required';
  }
  if (!emailIsValid(signUpFormCreate.email)) {
    errors['email'] = 'This has not valid format';
  }
  if (fieldIsEmpty(signUpFormCreate.password)) {
    errors['password'] = 'This field is required';
  }

  yield put(setUserFormCreateError(errors));
}

export function* singUpSubmitWatcher() {
  yield takeLatest(SignUpActionTypes.SIGN_UP_FORM_CREATE_SUBMIT, signUpSubmit);
}

export const signupSagas = [fork(singUpSubmitWatcher)];
