import { useEffect } from 'react';

// dependencies
import styled from 'styled-components';
import { Font } from 'elements';
import { Button } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { ManageSteps } from 'model';
import { SlotPicker } from 'shared';
import { useDocumentTitle } from 'hooks';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import {
  setStep,
  setDate,
  availabilityRequest,
  updateBookingFormUpdate,
  staffWorkingHoursRequest,
} from '..';

const Container = styled.div`
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  align-items: stretch;
`;

function Reschedule() {
  const {
    company,
    date,
    businessHours,
    staffWorkingHours,
    availability,
    availabilityLoading,
    availabilityAreFetched,
    availabilityError,
  } = useSelector((state: RootState) => state.manage);
  const dispatch = useDispatch();

  useDocumentTitle('Select a Date & Time - Gotei');

  useEffect(() => {
    if (date) dispatch(availabilityRequest());
  }, [dispatch, date]);

  useEffect(() => {
    dispatch(staffWorkingHoursRequest());
  }, [dispatch]);

  return (
    <Container>
      <Font
        style={{
          color: 'rgba(26, 26, 26, 0.6)',
          display: 'block',
          marginBottom: 8,
        }}
      >
        Reschedule Appointment
      </Font>

      <Font
        type='h2'
        style={{
          display: 'block',
          marginBottom: 24,
        }}
      >
        Select a Date & Time
      </Font>

      <Button
        sx={{
          mb: 2,
        }}
        startIcon={<ChevronLeft />}
        color='inherit'
        onClick={() => {
          dispatch(setStep(ManageSteps.overview));
        }}
      >
        Overview
      </Button>

      <Content>
        <SlotPicker
          date={date}
          company={company}
          businessHours={businessHours}
          staffWorkingHours={staffWorkingHours}
          availability={availability}
          availabilityError={availabilityError}
          availabilityLoading={availabilityLoading}
          availabilityAreFetched={availabilityAreFetched}
          setDate={(date) => dispatch(setDate(date))}
          onSlotSelect={(stateTime) => {
            dispatch(
              updateBookingFormUpdate({
                key: 'start_time',
                value: stateTime.format(),
              }),
            );

            dispatch(setStep(ManageSteps.confirm));
          }}
        />
      </Content>
    </Container>
  );
}

export default Reschedule;
