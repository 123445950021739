import styled from 'styled-components';
import { Stack, Box } from '@mui/material';
import { Font } from 'elements';
import { Check, Event, RoomService } from '@mui/icons-material';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1rem;
  }
`;

const Content = styled.div`
  flex: 1;

  max-width: 1200px;
  display: flex;
  margin: 50px 0 100px 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    margin: 2rem 0 4rem 0;
  }
`;

const QuickStartSection = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin: -1rem 0 0 -1rem;
`;

const QuickStartWrapper = styled.div`
  flex: 1;
  margin: 1rem 0 0 1rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 2rem;
  }
`;

const QuickStartIcon = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 38px;
  height: 38px;
`;

const QuickStartTitle = styled(Font)``;

const QuickStartDescription = styled(Font)`
  font-size: 18px;
  width: 240px;
  margin-top: 1.5rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 1rem;
  }
`;

function QuickStart() {
  return (
    <Container>
      <Content>
        <QuickStartSection>
          <QuickStartWrapper>
            <Stack direction='row' spacing={2}>
              <Box>
                <QuickStartIcon>
                  <RoomService sx={{ color: (theme) => theme.palette.common.white }} />
                </QuickStartIcon>
              </Box>

              <Stack>
                <QuickStartTitle type='h1'>Add your services</QuickStartTitle>

                <QuickStartDescription>
                  Whatever your business does, we got you. Set up your business services very easily
                  with just a few clicks.
                </QuickStartDescription>
              </Stack>
            </Stack>
          </QuickStartWrapper>

          <QuickStartWrapper>
            <Stack direction='row' spacing={2}>
              <Box>
                <QuickStartIcon>
                  <Event sx={{ color: (theme) => theme.palette.common.white }} />
                </QuickStartIcon>
              </Box>

              <Stack>
                <QuickStartTitle type='h1'>Set up your availability</QuickStartTitle>

                <QuickStartDescription>
                  Configure your business and staff working hours and let your customers know when
                  you are available.
                </QuickStartDescription>
              </Stack>
            </Stack>
          </QuickStartWrapper>

          <QuickStartWrapper>
            <Stack direction='row' spacing={2}>
              <Box>
                <QuickStartIcon>
                  <Check sx={{ color: (theme) => theme.palette.common.white }} />
                </QuickStartIcon>
              </Box>

              <Stack>
                <QuickStartTitle type='h1'>Get booked</QuickStartTitle>

                <QuickStartDescription>
                  Gotei will generate a booking page for your business and allow you to get booked
                  24/7.
                </QuickStartDescription>
              </Stack>
            </Stack>
          </QuickStartWrapper>
        </QuickStartSection>
      </Content>
    </Container>
  );
}

export default QuickStart;
