import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FieldsErrors, FormFieldPayload } from 'model';
import { RootState } from 'store/config';

interface ConfirmResetPassword {
  password: string;
  confirmPassword: string;
}

interface SliceState {
  email: string;
  resetPasswordLoading: boolean;
  resetPasswordSuccess: boolean;
  resetPasswordError: FieldsErrors;
  token: string;
  confirmResetPassword: ConfirmResetPassword;
  confirmResetPasswordLoading: boolean;
  confirmResetPasswordSuccess: boolean;
  confirmResetPasswordError: FieldsErrors;
}

const initialState: SliceState = {
  email: '',
  resetPasswordLoading: false,
  resetPasswordSuccess: false,
  resetPasswordError: {},
  token: '',
  confirmResetPassword: {
    password: '',
    confirmPassword: '',
  },
  confirmResetPasswordLoading: false,
  confirmResetPasswordSuccess: false,
  confirmResetPasswordError: {},
};

const ResetPasswordSlice = createSlice({
  initialState: initialState,
  name: 'resetPassword',
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        email: action.payload,
      };
    },
    setToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        token: action.payload,
      };
    },
    setResetPasswordLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        resetPasswordLoading: action.payload,
      };
    },
    setResetPasswordSuccess: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        resetPasswordSuccess: action.payload,
      };
    },
    setResetPasswordError: (state, action: PayloadAction<FieldsErrors>) => {
      return {
        ...state,
        resetPasswordError: {
          ...action.payload,
        },
      };
    },
    clearResetPasswordError: (state) => {
      return {
        ...state,
        resetPasswordError: {},
      };
    },
    updateConfirmResetPassword: (
      state,
      action: PayloadAction<FormFieldPayload<keyof ConfirmResetPassword>>,
    ) => {
      return {
        ...state,
        confirmResetPassword: {
          ...state.confirmResetPassword,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearConfirmResetPassword: (state) => {
      return {
        ...state,
        confirmResetPassword: {
          password: '',
          confirmPassword: '',
        },
      };
    },
    setConfirmResetPasswordLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        confirmResetPasswordLoading: action.payload,
      };
    },
    setConfirmResetPasswordSuccess: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        confirmResetPasswordSuccess: action.payload,
      };
    },
    setConfirmResetPasswordError: (state, action: PayloadAction<FieldsErrors>) => {
      return {
        ...state,
        confirmResetPasswordError: {
          ...action.payload,
        },
      };
    },
    clearConfirmResetPasswordError: (state) => {
      return {
        ...state,
        confirmResetPasswordError: {},
      };
    },
  },
});

export const {
  setEmail,
  setToken,
  setResetPasswordLoading,
  setResetPasswordSuccess,
  setResetPasswordError,
  clearResetPasswordError,
  updateConfirmResetPassword,
  clearConfirmResetPassword,
  setConfirmResetPasswordLoading,
  setConfirmResetPasswordSuccess,
  setConfirmResetPasswordError,
  clearConfirmResetPasswordError,
} = ResetPasswordSlice.actions;

export enum ResetPasswordActionTypes {
  RESET_PASSWORD = 'resetPassword/RESET_PASSWORD',
  CONFIRM_RESET_PASSWORD = 'resetPassword/CONFIRM_RESET_PASSWORD',
}

export const resetPasswordRequest = () => {
  return {
    type: ResetPasswordActionTypes.RESET_PASSWORD,
  };
};

export const confirmResetPasswordRequest = () => {
  return {
    type: ResetPasswordActionTypes.CONFIRM_RESET_PASSWORD,
  };
};

export const selectResetPassword = (state: RootState) => {
  return state.resetPassword;
};

export const selectEmail = createSelector([selectResetPassword], (value) => value.email);

export const selectToken = createSelector([selectResetPassword], (value) => value.token);

export const selectConfirmResetPassword = createSelector(
  [selectResetPassword],
  (value) => value.confirmResetPassword,
);

export const selectConfirmResetPasswordError = createSelector(
  [selectResetPassword],
  (value) => value.confirmResetPasswordError,
);

export const resetPasswordReducer = ResetPasswordSlice.reducer;
