import BaseRpc from './BaseRpc';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  AppointmentByClientParams,
  BookingCreateType,
  BookingType,
  BookingUpdateType,
  GetBookingParams,
} from 'model';

axios.defaults.withCredentials = true; // this is required to be able receive the cookies tokens
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

export class CalendarModuleRpc extends BaseRpc {
  public async getDailyBookings(
    params: GetBookingParams,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/events/daily`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    if (params) {
      config.params = params;
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getWeeklyBookings(
    params: GetBookingParams,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/events/weekly`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    if (params) {
      config.params = params;
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getMonthlyBookings(
    params: GetBookingParams,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/events/monthly`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    if (params) {
      config.params = params;
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getAppointmentByClient(
    params: AppointmentByClientParams,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/events/client`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    if (params) {
      config.params = params;
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async deleteBooking(
    bookingId: number,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/events/${bookingId}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.delete<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async createBooking(
    booking: BookingCreateType,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/events/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.post<any>(url, booking, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateBooking(
    booking: BookingUpdateType,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/events/${booking.id}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.put<any>(url, booking, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }
}

export default CalendarModuleRpc;
