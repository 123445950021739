import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { NavBar, Drawer, Popper, Font, Modal, Background } from 'elements';
import { TimeHandler, CheckLogoutSuccessFlow } from 'hoc';
import { useAuthEffect, useWillUnmount } from 'hooks';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import {
  openSnackbarRequest,
  clearEventFormCreate,
  eventCreateSubmit,
  clearEventFormUpdate,
  eventUpdateSubmit,
  setEventFormUpdate,
  eventDeleteRequest,
  setEventDeleteModal,
  clearEventFormDropUpdate,
  wsNotificationConnect,
} from 'store/ui';
import { clientAppointmentsRequest } from 'screens/client';
import { authLogoutRequest } from 'store/auth';
import { setCompanyIsFetched } from 'store/organization';
import {
  clientsRequest,
  companyRequest,
  servicesRequest,
  staffRequest,
  permissionsRequest,
  businessHoursRequest,
} from 'store/organization';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import {
  Close,
  DeleteOutlined,
  EditOutlined,
  Event,
  People,
  PersonOutlined,
  RoomServiceOutlined,
  WatchLaterOutlined,
} from '@mui/icons-material';
import { BookingUpdateType } from 'model';
import { PrivateLayoutProvider, usePrivateLayout } from 'contexts';

// components
import { EventFormCreate, EventFormUpdate, Notifications } from 'shared';

import { ReactComponent as SVG } from 'assets/svg/mailbox.svg';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  min-height: 0;
`;

const Main = styled.main`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 0;

  ${({ theme }) => theme.breakpoints.up('lg')} {
  }
`;

const EventPreview = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

interface LoaderProps {
  screenLoading: boolean;
}

const Loader = styled.div<LoaderProps>`
  position: fixed;
  background-color: white;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  display: ${({ screenLoading }) => (screenLoading ? 'block' : 'none')};
`;

const MailboxSvg = styled(SVG)`
  height: auto;
  width: 200px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 90%;
  }
`;

interface Props {
  children: ReactNode | ReactNode[];
}

const PrivateLayout = ({ children }: Props) => {
  const {
    eventPreview,
    eventFormLoading,
    eventFormCreate,
    eventFormUpdate,
    eventDeleteModal,
    eventFormDropUpdate,
    notificationWsOpen,
  } = useSelector((state: RootState) => state.ui);
  const { user, userPermissionFetched, userPermissionError, logoutFailed } = useSelector(
    (state: RootState) => state.auth,
  );
  const { company, companyIsFetched } = useSelector((state: RootState) => state.organization);
  const { appointmentAnchor, notificationAnchor, setAppointmentAnchor, setNotificationAnchor } =
    usePrivateLayout();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  useWillUnmount(() => {
    dispatch(setCompanyIsFetched(false));
  });

  useAuthEffect(() => {
    // fetch Organizations data
    dispatch(clientsRequest());
    dispatch(companyRequest());
    dispatch(servicesRequest());
    dispatch(staffRequest());
    dispatch(permissionsRequest());
  }, [dispatch]);

  useAuthEffect(() => {
    if (company.url_component) dispatch(businessHoursRequest());
  }, [company]);

  useAuthEffect(() => {
    if (!company.uuid && companyIsFetched && user.is_company_admin) {
      history.push('/company-create');
    }
  }, [history, company, companyIsFetched]);

  useAuthEffect(() => {
    if (!notificationWsOpen) dispatch(wsNotificationConnect());
  }, [dispatch, notificationWsOpen]);

  useAuthEffect(() => {
    if (userPermissionError && !user.is_company_admin) {
      dispatch(authLogoutRequest());

      setTimeout(() => {
        dispatch(openSnackbarRequest(`Something went wrong with your account permissions.`));
      }, 500);
    }
  }, [userPermissionError]);

  useEffect(() => {
    if (logoutFailed) history.push('/');
  }, [history, logoutFailed]);

  if (company.uuid && !company.is_verified) {
    return (
      <Stack direction='column' justifyContent='center' alignItems='center' height='100vh'>
        <Background />

        <Stack zIndex={100} maxWidth={550} justifyContent='center' alignItems='center'>
          <MailboxSvg />

          <Font
            style={{ marginTop: '4rem', fontSize: '2rem', marginBottom: 16, textAlign: 'center' }}
            type='h4'
          >
            You need to verify your email address!
          </Font>

          <Font style={{ fontSize: 16, textAlign: 'center' }}>
            When you signed up we sent you an email with a link that will verify your email. If you
            don't see it, check the spam folder.
          </Font>
        </Stack>
      </Stack>
    );
  }

  return (
    <Container>
      <Loader screenLoading={!userPermissionFetched}>Loading...</Loader>

      <CheckLogoutSuccessFlow>
        <TimeHandler>
          <NavBar />

          <Content>
            <Drawer />

            <Main>{children}</Main>
          </Content>
        </TimeHandler>
      </CheckLogoutSuccessFlow>

      <Popper
        open={!!notificationAnchor}
        anchorEl={notificationAnchor}
        onClose={() => setNotificationAnchor(null)}
      >
        <Notifications />
      </Popper>

      <Popper
        open={!!appointmentAnchor}
        anchorEl={appointmentAnchor}
        onClose={() => setAppointmentAnchor(null)}
      >
        <EventPreview>
          <Stack direction='row' justifyContent='flex-end' m={'-1rem -1rem 0.5rem 0'}>
            <Box>
              <Tooltip title='Delete'>
                <IconButton
                  onClick={() => {
                    const booking: BookingUpdateType = {
                      ...eventPreview,
                      client: eventPreview.client.id,
                      staff: eventPreview.staff.id,
                      service: eventPreview.service.id,
                    };

                    dispatch(setEventFormUpdate(booking));
                    dispatch(setEventDeleteModal(true));
                    setAppointmentAnchor(null);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title='Edit'>
                <IconButton
                  onClick={() => {
                    const booking: BookingUpdateType = {
                      ...eventPreview,
                      client: eventPreview.client.id,
                      staff: eventPreview.staff.id,
                      service: eventPreview.service.id,
                    };

                    dispatch(setEventFormUpdate(booking));
                    setAppointmentAnchor(null);
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title='Close'>
                <IconButton onClick={() => setAppointmentAnchor(null)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>

          <Stack direction='row' alignItems='center'>
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: '#eaeffe',
                borderRadius: 1,
              }}
            ></Box>

            <Stack ml={3}>
              <Font type='h3'>
                {eventPreview.staff.name} and {eventPreview.client.name}
              </Font>
              <Font>
                {moment(eventPreview.start_time).format('dddd, DD MMMM HH:mm')} -
                {moment(eventPreview.end_time).format('HH:mm')}
              </Font>
            </Stack>
          </Stack>

          <Stack direction='row' alignItems='center' mt={3}>
            <Box>
              <Tooltip title='Service'>
                <RoomServiceOutlined />
              </Tooltip>
            </Box>

            <Stack ml={3}>
              <Font>{eventPreview.service.name}</Font>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' mt={1}>
            <Box>
              <Tooltip title='Provider'>
                <PersonOutlined />
              </Tooltip>
            </Box>

            <Stack ml={3}>
              <Font>{eventPreview.staff.name}</Font>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' mt={1}>
            <Box>
              <Tooltip title='Client'>
                <People />
              </Tooltip>
            </Box>

            <Stack ml={3}>
              <Font>{eventPreview.client.name}</Font>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' mt={1}>
            <Box>
              <Tooltip title='Date'>
                <Event />
              </Tooltip>
            </Box>

            <Stack ml={3}>
              <Font>{moment(eventPreview.start_time).format('DD MMM YYYY, HH:mm')}</Font>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' mt={1}>
            <Box>
              <Tooltip title='Duration'>
                <WatchLaterOutlined />
              </Tooltip>
            </Box>

            <Stack ml={3}>
              <Font>{eventPreview.service.duration} min</Font>
            </Stack>
          </Stack>
        </EventPreview>
      </Popper>

      <Modal
        submitText='Create'
        open={!!eventFormCreate.company}
        loading={eventFormLoading}
        onClose={() => dispatch(clearEventFormCreate())}
        onSubmit={() => dispatch(eventCreateSubmit())}
      >
        <EventFormCreate />
      </Modal>

      <Modal
        cancelText='Cancel'
        submitText='Update'
        open={!!eventFormUpdate.company && !eventDeleteModal && !eventFormDropUpdate.id}
        loading={eventFormLoading}
        onClose={() => dispatch(clearEventFormUpdate())}
        onSubmit={() => {
          dispatch(eventUpdateSubmit());

          if (location.pathname.includes('/clients/')) {
            dispatch(clientAppointmentsRequest());
          }
        }}
      >
        <EventFormUpdate />
      </Modal>

      <Modal
        danger
        open={eventDeleteModal}
        loading={eventFormLoading}
        title='Are you sure to delete this booking?'
        onClose={() => {
          dispatch(clearEventFormUpdate());
          dispatch(setEventDeleteModal(false));
        }}
        onSubmit={() => {
          dispatch(eventDeleteRequest());

          if (location.pathname.includes('/clients/')) {
            dispatch(clientAppointmentsRequest());
          }
        }}
      >
        <div>
          You will delete this booking permanently. After you press yes there is no coming back!
        </div>
      </Modal>

      <Modal
        open={!!eventFormDropUpdate.id}
        title='Reschedule event'
        onSubmit={() => {
          dispatch(setEventFormUpdate(eventFormDropUpdate));
          dispatch(eventUpdateSubmit());
        }}
        submitText='Yes'
        onClose={() => {
          dispatch(clearEventFormDropUpdate());
        }}
      >
        <div>
          You will change the time of the booking permanently. <br /> Do you want to continue?
        </div>
      </Modal>
    </Container>
  );
};

const PrivateLayoutWithProvider = ({ children }: Props) => (
  <PrivateLayoutProvider>
    <PrivateLayout>{children}</PrivateLayout>
  </PrivateLayoutProvider>
);

export default PrivateLayoutWithProvider;
