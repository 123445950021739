import { combineReducers } from '@reduxjs/toolkit';

// reducers
import { authReducer } from '../auth';
import { uiReducer } from '../ui';
import { calendarReducer } from 'screens/calendar';
import { staffReducer } from 'screens/staff';
import { staffDetailReducer } from 'screens/staffDetail';
import { clientsReducer } from 'screens/clients';
import { clientReducer } from 'screens/client';
import { loginReducer } from 'screens/login';
import { organizationReducer } from 'store/organization';
import { companyReducer } from 'screens/company';
import { serviceDetailReducer } from 'screens/serviceDetail';
import { servicesReducer } from 'screens/services';
import { dashboardReducer } from 'screens/dashboard/store';
import { bookingPageReducer } from 'screens/publicPages/bookingPage';
import { manageReducer } from 'screens/publicPages/manage';
import { signupReducer } from 'screens/signUp';
import { resetPasswordReducer } from 'screens/resetPassword';

// api
import { authenticationApi, integrationsApi, companyApi } from 'services/query';

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  calendar: calendarReducer,
  staff: staffReducer,
  staffDetail: staffDetailReducer,
  clients: clientsReducer,
  client: clientReducer,
  login: loginReducer,
  organization: organizationReducer,
  company: companyReducer,
  serviceDetail: serviceDetailReducer,
  services: servicesReducer,
  bookingPage: bookingPageReducer,
  dashboard: dashboardReducer,
  manage: manageReducer,
  signup: signupReducer,
  resetPassword: resetPasswordReducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [integrationsApi.reducerPath]: integrationsApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
