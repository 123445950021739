import { Font } from 'elements';
import styled from 'styled-components';

import { ReactComponent as Notifications } from 'assets/svg/feature-notifications.svg';
import { ReactComponent as CustomAvailability } from 'assets/svg/feature-custom-availability.svg';
import { ReactComponent as Timezone } from 'assets/svg/feature-timezone.svg';
import { ReactComponent as GrowBusiness } from 'assets/svg/feature-grow-business.svg';
import { ReactComponent as WorkPlan } from 'assets/svg/feature-work-plan.svg';
import { ReactComponent as Reschedule } from 'assets/svg/feature-reschedule.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  margin-bottom: 140px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1rem;
    margin-bottom: 2rem;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Font)`
  text-align: center;
  font-size: 52px;
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 38px;
  }
`;

const FeatureSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 45px;
`;

const Feature = styled.div`
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 2rem;
  }
`;

const FeatureIcon = styled.div`
  margin-bottom: 14px;
`;

const FeatureTitle = styled(Font)`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const FeatureDescription = styled(Font)`
  font-size: 18px;
  min-width: 250px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-width: auto;
  }
`;

function Features() {
  return (
    <Container>
      <Content>
        <TitleWrapper>
          <Title type='h1'>Features</Title>
        </TitleWrapper>

        <FeatureSection>
          <Feature>
            <FeatureIcon>
              <Notifications />
            </FeatureIcon>

            <FeatureTitle>Notifications and reminders</FeatureTitle>

            <FeatureDescription>
              You get real time notifications to manage your appointments and your customers get
              emails to confirm and remind about appointments.
            </FeatureDescription>
          </Feature>

          <Feature>
            <FeatureIcon>
              <CustomAvailability />
            </FeatureIcon>

            <FeatureTitle>Custom availability and control</FeatureTitle>

            <FeatureDescription>
              Set up custom availability, padding between appointments and appointment duration.
            </FeatureDescription>
          </Feature>

          <Feature>
            <FeatureIcon>
              <Timezone />
            </FeatureIcon>

            <FeatureTitle>Timezone display</FeatureTitle>

            <FeatureDescription>
              Get booked from anywhere in world and gotei will show the correct time depend on your
              costumer timezone.
            </FeatureDescription>
          </Feature>

          <Feature>
            <FeatureIcon>
              <GrowBusiness />
            </FeatureIcon>

            <FeatureTitle>Grow your business</FeatureTitle>

            <FeatureDescription>
              Hassle-free scheduling means more appointments and more leads closed.
            </FeatureDescription>
          </Feature>

          <Feature>
            <FeatureIcon>
              <WorkPlan />
            </FeatureIcon>

            <FeatureTitle>Plan work and break times</FeatureTitle>

            <FeatureDescription>
              Define your work hours and break times. Prevent people from booking you outside of
              office hours or during your breaks.
            </FeatureDescription>
          </Feature>

          <Feature>
            <FeatureIcon>
              <Reschedule />
            </FeatureIcon>

            <FeatureTitle>Client rescheduling</FeatureTitle>

            <FeatureDescription>
              Allow your clients an easy way to reschedule their appointments
            </FeatureDescription>
          </Feature>
        </FeatureSection>
      </Content>
    </Container>
  );
}

export default Features;
