import { Font } from 'elements';
import styled from 'styled-components';
import { Box, Button, Divider, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { updateStaffDetailCreate } from '.';

// components
import Staff from './components/Staff';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem;
  }
`;

const Content = styled.div`
  margin-top: 1rem;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

function StaffComposition() {
  const { company } = useSelector((state: RootState) => state.organization);
  const { filteredStaff, staffAreFetched } = useSelector((state: RootState) => state.staff);
  const dispatch = useDispatch();

  return (
    <Container>
      <PageHeader>
        <Stack mr={1}>
          <Font type='h1'>Staff</Font>

          <Font>Staff list page, search by name or delete a staff member.</Font>
        </Stack>

        <Box>
          {staffAreFetched && !!filteredStaff.length && (
            <Button
              sx={{ whiteSpace: 'nowrap' }}
              startIcon={<Add />}
              onClick={() => {
                dispatch(
                  updateStaffDetailCreate({
                    key: 'company',
                    value: company.uuid,
                  }),
                );
              }}
              variant='contained'
              color='primary'
            >
              Add staff
            </Button>
          )}
        </Box>
      </PageHeader>

      <Divider />

      <Content>
        <Staff />
      </Content>
    </Container>
  );
}

export default StaffComposition;
