const prod = {
  ORIGIN: 'https://www.gotei.app',
  API: 'https://api.gotei.app',
  WEBSOCKET: 'wss://api.gotei.app',
};

const dev = {
  ORIGIN: 'http://localhost:3000',
  API: 'http://localhost:8000',
  WEBSOCKET: 'ws://127.0.0.1:8000',
};

const mode = process.env.NODE_ENV;

export const DOMAIN = mode === 'development' ? dev : prod;
