import { Background } from 'elements';
import styled from 'styled-components';

// components
import Login from './components/Login';

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.palette.grey[50]};
`;

const LoginComposition = () => {
  return (
    <Container>
      <Background />

      <Login />
    </Container>
  );
};

export default LoginComposition;
