import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
  BusinessHour,
  BusinessHourFormCreate,
  Company,
  CompanyFormUpdate,
  FieldsErrors,
  FormFieldPayload,
} from 'model';
import { RootState } from 'store/config';

interface State {
  date?: string;
  companyFormEdit: Company;
  companyFormEditPartial: Partial<Company>;
  companyFormEditError: FieldsErrors;
  companyFormEditLoading: boolean;
  businessHoursError: FieldsErrors;
}

const initialState: State = {
  date: dayjs().format('DD MMM YYYY'),
  companyFormEdit: {
    uuid: '',
    name: '',
    created: '',
    updated: '',
    url_component: '',
    timezone: '',
    image: '',
    is_verified: false,
    verified_at: '',
  },
  companyFormEditPartial: {},
  companyFormEditError: {},
  companyFormEditLoading: false,
  businessHoursError: {},
};

const CompanySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setDate: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        date: action.payload,
      };
    },
    setCompanyFormEDit: (state, action: PayloadAction<Company>) => {
      return {
        ...state,
        companyFormEdit: action.payload,
      };
    },
    updateCompanyFormEdit: (state, action: PayloadAction<FormFieldPayload<keyof Company>>) => {
      return {
        ...state,
        companyFormEdit: {
          ...state.companyFormEdit,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearCompanyFormEdit: (state) => {
      return {
        ...state,
        companyFormEdit: {
          uuid: '',
          name: '',
          created: '',
          updated: '',
          url_component: '',
          timezone: '',
          image: '',
          is_verified: false,
          verified_at: '',
        },
      };
    },
    setCompanyFormEditPartial: (state, action: PayloadAction<Partial<CompanyFormUpdate>>) => {
      return {
        ...state,
        companyFormEditPartial: action.payload,
      };
    },
    clearCompanyFormEditPartial: (state) => {
      return {
        ...state,
        companyFormEditPartial: {},
      };
    },
    setCompanyFormEditError: (state, action: PayloadAction<FormFieldPayload<keyof Company>>) => {
      return {
        ...state,
        companyFormEditError: {
          ...state.companyFormEditError,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearCompanyFormEditError: (state) => {
      return {
        ...state,
        companyFormEditError: {},
      };
    },
    setCompanyFormEditLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        companyFormEditLoading: action.payload,
      };
    },
    setBusinessHoursError: (state, action: PayloadAction<FormFieldPayload<number>>) => {
      return {
        ...state,
        businessHoursError: {
          ...state.businessHoursError,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearBusinessHoursError: (state) => {
      return {
        ...state,
        businessHoursError: {},
      };
    },
  },
});

export const {
  setDate,
  setCompanyFormEDit,
  updateCompanyFormEdit,
  clearCompanyFormEdit,
  setCompanyFormEditPartial,
  clearCompanyFormEditPartial,
  setCompanyFormEditError,
  clearCompanyFormEditError,
  setCompanyFormEditLoading,
  setBusinessHoursError,
  clearBusinessHoursError,
} = CompanySlice.actions;

export enum CompanyActionTypes {
  COMPANY_UPDATE_REQUEST = 'company/COMPANY_UPDATE_REQUEST',
  COMPANY_UPDATE_PARTIAL_REQUEST = 'company/COMPANY_UPDATE_PARTIAL_REQUEST',
  BUSINESS_HOUR_UPDATE_REQUEST = 'company/BUSINESS_HOUR_UPDATE_REQUEST',
  BUSINESS_HOUR_CREATE_REQUEST = 'company/BUSINESS_HOUR_CREATE_REQUEST',
  BUSINESS_HOUR_DELETE_REQUEST = 'company/BUSINESS_HOUR_DELETE_REQUEST',
}

export const companyUpdateRequest = () => {
  return {
    type: CompanyActionTypes.COMPANY_UPDATE_REQUEST,
  };
};

export const companyUpdatePartialRequest = () => {
  return {
    type: CompanyActionTypes.COMPANY_UPDATE_PARTIAL_REQUEST,
  };
};

export const businessHoursUpdateRequest = (businessHour: BusinessHour) => {
  return {
    type: CompanyActionTypes.BUSINESS_HOUR_UPDATE_REQUEST,
    payload: businessHour,
  };
};

export const businessHoursCreateRequest = (businessHour: BusinessHourFormCreate) => {
  return {
    type: CompanyActionTypes.BUSINESS_HOUR_CREATE_REQUEST,
    payload: businessHour,
  };
};

export const businessHoursDeleteRequest = (businessHour: BusinessHour) => {
  return {
    type: CompanyActionTypes.BUSINESS_HOUR_DELETE_REQUEST,
    payload: businessHour,
  };
};

export const selectCompany = (state: RootState) => {
  return state.company;
};

export const selectCompanyFormEdit = createSelector(
  [selectCompany],
  (value) => value.companyFormEdit,
);

export const selectCompanyFormEditPartial = createSelector(
  [selectCompany],
  (value) => value.companyFormEditPartial,
);

export const selectCompanyFormEditError = createSelector(
  [selectCompany],
  (value) => value.companyFormEditError,
);

export const companyReducer = CompanySlice.reducer;
