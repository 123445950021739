import { useDocumentTitle } from 'hooks';
import LandingPageComposition from './LandingPageComposition';

const LandingPage = () => {
  useDocumentTitle('Gotei');

  return <LandingPageComposition />;
};

export default LandingPage;
