import { call, fork, takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// api
import { clientsModuleRpc } from 'services/api';
import { fieldIsEmpty, getErrorEntry, logger, formErrorIsEmpty } from 'utils';
import { Client, ClientFormCreate } from 'model';
import { RootState } from 'store/config';

import { selectCompanyDetail } from 'store/organization';
import {
  ClientsActionTypes,
  setClients,
  selectClientFormAdd,
  setClientFormAddError,
  clearClientFormAddError,
  selectClientFormAddError,
  selectClientList,
  setClientFormAddModalShow,
  clearClientFormAdd,
  setClientFormLoading,
  clientsRequest,
  setClientsDelete,
  selectSearchText,
  setFilteredClients,
  setClientsAreFetched,
  setClientsLoading,
} from '.';

function* getClients() {
  try {
    logger('Clients Request');

    yield put(setClientsLoading(true));

    const response: Array<Client> = yield call(clientsModuleRpc.getClients.bind(clientsModuleRpc));

    logger('Clients Success');
    yield put(setClients(response));
    yield put(setFilteredClients(response));
    yield put(setClientsAreFetched(true));
    yield put(setClientsLoading(false));
  } catch (error: any) {
    logger('Clients Failure');

    yield put(setClientsLoading(false));
  }
}

function* createClient() {
  yield call(validateClientFormAdd);

  const state: RootState = yield select();
  const clientFormAdd = selectClientFormAdd(state);
  const clientFormAddError = selectClientFormAddError(state);
  const company = selectCompanyDetail(state);

  if (formErrorIsEmpty(clientFormAddError)) return;

  try {
    yield put(setClientFormLoading(true));
    logger('Client Create Request');

    const clientForm: ClientFormCreate = {
      ...clientFormAdd,
      company: company.uuid,
    };

    const response: Client = yield call(
      clientsModuleRpc.createClient.bind(clientsModuleRpc, clientForm),
    );

    const clients = [...selectClientList(state)];
    clients.push(response);

    logger('Client Create Success');
    yield put(setClients(clients));
    yield put(clearClientFormAdd());
    yield put(clientsRequest());
    yield put(setClientFormLoading(false));
    yield put(setClientFormAddModalShow(false));
  } catch (error: any) {
    const { key, value } = getErrorEntry(error);

    yield put(
      setClientFormAddError({
        key,
        value,
      }),
    );

    logger('Client Create Failure');
    yield put(setClientFormLoading(false));
  }
}

function* validateClientFormAdd() {
  yield put(clearClientFormAddError());

  const state: RootState = yield select();
  const clientFormAdd = selectClientFormAdd(state);

  if (fieldIsEmpty(clientFormAdd.name)) {
    yield put(
      setClientFormAddError({
        key: 'name',
        value: 'First name is required.',
      }),
    );
  } else if (fieldIsEmpty(clientFormAdd.email)) {
    yield put(
      setClientFormAddError({
        key: 'email',
        value: 'Email is required.',
      }),
    );
  }
}

interface DeleteClientPayload {
  client: Client;
  index: number;
  total: number;
}

function* deleteClient(action: PayloadAction<DeleteClientPayload>) {
  const { client, index, total } = action.payload;

  try {
    yield put(setClientFormLoading(true));
    logger('Client Delete Request');

    yield call(clientsModuleRpc.deleteClient.bind(clientsModuleRpc, client.id));

    logger('Client Delete Success');

    if (index === total) {
      yield put(clientsRequest());
    }
    yield put(setClientsDelete([]));
    yield put(setClientFormLoading(false));
  } catch (error: any) {
    const { key, value } = getErrorEntry(error);

    yield put(
      setClientFormAddError({
        key,
        value,
      }),
    );

    logger('Client Delete Failure');
    yield put(setClientFormLoading(false));
  }
}

function* filterClients() {
  const state: RootState = yield select();
  const searchText = selectSearchText(state).trim().toLowerCase();
  const clients = selectClientList(state);

  const filteredClients: Client[] = [];

  if (searchText) {
    clients.forEach((client) => {
      const check = Object.values(client).some((value: string | number) =>
        (value || '').toString().toLowerCase().includes(searchText),
      );

      if (check) filteredClients.push(client);
    });
  } else {
    filteredClients.push(...clients);
  }

  yield put(setFilteredClients(filteredClients));
}

function* clientsWatcher() {
  yield takeLatest(ClientsActionTypes.CLIENTS_REQUEST, getClients);
}

function* clientCreateWatcher() {
  yield takeLatest(ClientsActionTypes.CLIENTS_CREATE_REQUEST, createClient);
}

function* clientDeleteWatcher() {
  yield takeEvery(ClientsActionTypes.CLIENTS_DELETE_REQUEST, deleteClient);
}

function* filterClientsWatcher() {
  yield takeLatest(ClientsActionTypes.FILTER_CLIENTS, filterClients);
}

export const clientsSagas = [
  fork(clientsWatcher),
  fork(clientCreateWatcher),
  fork(clientDeleteWatcher),
  fork(filterClientsWatcher),
];
