import { Box, Card, Chip, Skeleton, Stack, Typography } from '@mui/material';
import {
  useConnectGoogleCalendarMutation,
  useDisconnectGoogleCalendarMutation,
  useGoogleCalendarQuery,
} from 'services/query';
import styled from 'styled-components';
import { DOMAIN } from 'config';
import { GoogleButton } from 'shared';
import { useAuthEffect, useQuery } from 'hooks';
import { useHistory } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';

// images
import { ReactComponent as GoogleCalendarIcon } from 'assets/svg/google-calendar.svg';
import { ReactComponent as GoogleCalendarOldIcon } from 'assets/svg/google-calendar-old.svg';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { openSnackbarRequest } from 'store/ui';
import { getError } from 'utils';

const GOOGLE_REDIRECT_URI = DOMAIN.ORIGIN + '/integrations/calendar-connections';

const Container = styled.div`
  flex: 1;
  max-width: 800px;
`;

export const GoogleCalendarConnection = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const {
    data: calendarData,
    isLoading: calendarIsLoading,
    isSuccess: calendarIsSuccess,
    isError: calendarIsError,
  } = useGoogleCalendarQuery(user.id);
  const [
    connectGoogleCalendar,
    { isLoading: connectCalendarIsLoading, error: connectCalendarError },
  ] = useConnectGoogleCalendarMutation();
  const [
    disconnectGoogleCalendar,
    { isLoading: disconnectCalendarIsLoading, error: disconnectCalendarError },
  ] = useDisconnectGoogleCalendarMutation();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const code = query.get('code');

  useAuthEffect(() => {
    if (code) handleConnectGoogleCalendar(code);
  }, [code]);

  const handleConnectGoogleCalendar = (authorizationCode: string) => {
    const codeCopy = authorizationCode;
    history.push('/integrations/calendar-connections');

    connectGoogleCalendar(codeCopy);
  };

  const handleDisconnectGoogleCalendar = (calendarId: string) => {
    disconnectGoogleCalendar(calendarId);
  };

  useEffect(() => {
    if (connectCalendarError && 'data' in connectCalendarError) {
      const errorMessage = getError(connectCalendarError.data);
      dispatch(openSnackbarRequest(errorMessage));
    } else if (disconnectCalendarError && 'data' in disconnectCalendarError) {
      const errorMessage = getError(disconnectCalendarError.data);
      dispatch(openSnackbarRequest(errorMessage));
    }
  }, [dispatch, connectCalendarError, disconnectCalendarError]);

  return (
    <Container>
      <Card sx={{ p: { sm: '2rem', xs: '1.5rem' } }}>
        {calendarIsLoading && (
          <Stack
            direction={{
              sm: 'row',
              xs: 'column',
            }}
            alignItems={{
              xs: 'normal',
              sm: 'center',
            }}
          >
            <Stack
              flex={3}
              direction={{
                sm: 'row',
                xs: 'column',
              }}
              alignItems={{
                xs: 'normal',
                sm: 'center',
              }}
            >
              <Stack flex={2} direction='row' alignItems='center' mr={2}>
                <Skeleton variant='rectangular' height={50} width={50} sx={{ borderRadius: 1 }} />

                <Skeleton height={20} width={150} sx={{ ml: 2 }} />
              </Stack>

              <Stack flex={1} spacing={1} direction='row' alignItems='center'>
                <Skeleton width={150} sx={{ ml: { sm: 2, xs: 0 }, mt: { xs: 2, sm: 0 } }} />
              </Stack>
            </Stack>

            <Stack
              flex={1}
              direction='row'
              justifyContent='flex-end'
              ml={{ sm: 2, xs: 0 }}
              mt={{ xs: 3, sm: 0 }}
            >
              <Skeleton variant='rectangular' height={42} width='100%' sx={{ borderRadius: 1 }} />
            </Stack>
          </Stack>
        )}

        {calendarIsSuccess && !calendarIsLoading && (
          <Stack
            direction={{
              sm: 'row',
              xs: 'column',
            }}
            alignItems={{
              xs: 'normal',
              sm: 'center',
            }}
          >
            <Stack
              flex={3}
              direction={{
                sm: 'row',
                xs: 'column',
              }}
              m={'0.5rem 0'}
            >
              <Stack flex={2} direction='row' alignItems='center' mr={2}>
                <GoogleCalendarIcon />

                <Typography variant='h5' ml={2}>
                  Google Calendar
                </Typography>

                <Chip label='connected' size='small' color='success' />
              </Stack>

              <Stack flex={1} spacing={1} direction='row' alignItems='center' mt={{ xs: 1 }}>
                <Typography variant='body2' color='text.secondary'>
                  {calendarData?.user_info?.email}
                </Typography>
              </Stack>
            </Stack>

            <Box flex={1} textAlign='end' m={'0.5rem 0'}>
              <LoadingButton
                fullWidth
                loading={disconnectCalendarIsLoading}
                variant='contained'
                size='large'
                color='inherit'
                onClick={() => handleDisconnectGoogleCalendar(calendarData.id)}
              >
                Disconnect
              </LoadingButton>
            </Box>
          </Stack>
        )}

        {calendarIsError && !calendarIsLoading && (
          <Stack
            direction={{ sm: 'row', xs: 'column' }}
            alignItems={{ sm: 'center', xs: 'normal' }}
          >
            <Stack
              flex={3}
              direction={{ sm: 'row', xs: 'column' }}
              alignItems={{ sm: 'center', xs: 'normal' }}
            >
              <Stack flex={2} direction='row' alignItems='center' mr={2}>
                <GoogleCalendarOldIcon />

                <Typography variant='h5' ml={2} mt={{ xs: 1 }}>
                  Google Calendar
                </Typography>
              </Stack>

              <Stack flex={1} spacing={1}>
                <Typography variant='body2' color='text.secondary'>
                  Gmail, G Suite
                </Typography>
              </Stack>
            </Stack>

            <Box flex={1} mt={{ xs: 2, sm: 0 }}>
              {connectCalendarIsLoading ? (
                <LoadingButton loading fullWidth variant='contained' size='large' color='inherit'>
                  Connect
                </LoadingButton>
              ) : (
                <GoogleButton fullWidth redirectUri={GOOGLE_REDIRECT_URI} variant='calendar' />
              )}
            </Box>
          </Stack>
        )}
      </Card>
    </Container>
  );
};
