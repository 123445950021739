import { useEffect } from 'react';

// dependencies
import { CircularProgress, Theme } from '@mui/material';
import { ManageSteps } from 'model';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import {
  companyRequest,
  businessHoursRequest,
  bookingRequest,
  setBookingFormUpdate,
  setStep,
} from '.';

// components
import BookingOverview from './components/BookingOverview';
import ConfirmReschedule from './components/ConfirmReschedule';
import Reschedule from './components/Reschedule';
import RescheduleSuccess from './components/RescheduleSuccess';
import BookingNotFound from './components/BookingNotFound';
import BookingCancelled from './components/BookingCancelled';
import { Background } from 'elements';
import moment from 'moment-timezone';

const mobileMediaQuery = '(max-width:770px)';

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: ${({ theme }: { theme: Theme }) => theme.palette.grey[50]};
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Content = styled.div`
  z-index: 1;
  margin-top: 60px;
  display: flex;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 56px 48px;
  box-shadow: 0 14px 32px 0 rgb(22 45 61 / 8%), 0 1px 4px 0 rgb(22 45 61 / 10%);
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 650px;

  @media ${mobileMediaQuery} {
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 2rem 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 90%;
    min-width: unset;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 56px 1.5rem;
  }
`;

const Loading = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Params {
  uuid: string;
  company_uuid: string;
}

function ManageComposition() {
  const { loading, step, company, booking, bookingError } = useSelector(
    (state: RootState) => state.manage,
  );
  const { company_uuid, uuid } = useParams<Params>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (company.uuid) {
      moment.tz.setDefault(company.timezone);

      moment.locale('en', {
        week: {
          dow: 1,
          doy: 1,
        },
      });
    }
  }, [company]);

  useEffect(() => {
    if (company_uuid) {
      dispatch(companyRequest(company_uuid));
    }

    if (uuid) dispatch(bookingRequest(uuid));
  }, [dispatch, company_uuid, uuid]);

  useEffect(() => {
    if (company.uuid) {
      dispatch(businessHoursRequest(company.url_component));
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (booking.is_canceled) {
      dispatch(setStep(ManageSteps.cancelled));
    } else if (booking.id)
      dispatch(
        setBookingFormUpdate({
          ...booking,
          client: booking.client.id,
          staff: booking.staff.id,
          service: booking.service.id,
        }),
      );
  }, [dispatch, booking]);

  return (
    <Container>
      <Background />

      <Content>
        {loading ? (
          <Loading>
            <CircularProgress />
          </Loading>
        ) : bookingError ? (
          <BookingNotFound companyUrl={company_uuid} />
        ) : step === ManageSteps.overview ? (
          <BookingOverview />
        ) : step === ManageSteps.reschedule ? (
          <Reschedule />
        ) : step === ManageSteps.confirm ? (
          <ConfirmReschedule />
        ) : step === ManageSteps.cancelled ? (
          <BookingCancelled />
        ) : (
          <RescheduleSuccess />
        )}
      </Content>
    </Container>
  );
}

export default ManageComposition;
