// dependencies
import { TextField } from '@mui/material';
import styled from 'styled-components';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { updateStaffDetailCreate } from '..';
import { RootState } from 'store/config';

const Container = styled.div``;

const TextFieldWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

function StaffFormCreate() {
  const { staffDetailCreate, staffDetailCreateError } = useSelector(
    (state: RootState) => state.staff,
  );
  const dispatch = useDispatch();

  return (
    <Container>
      <TextFieldWrapper>
        <TextField
          label='Name'
          variant='outlined'
          type='text'
          fullWidth
          error={!!staffDetailCreateError.name}
          helperText={staffDetailCreateError.name}
          value={staffDetailCreate.name}
          onChange={(event) => {
            dispatch(
              updateStaffDetailCreate({
                key: 'name',
                value: event.currentTarget.value,
              }),
            );
          }}
        />
      </TextFieldWrapper>

      <TextFieldWrapper>
        <TextField
          label='Description'
          variant='outlined'
          type='text'
          fullWidth
          multiline
          minRows={4}
          maxRows={10}
          error={!!staffDetailCreateError.description}
          helperText={staffDetailCreateError.description ?? 'Optional'}
          value={staffDetailCreate.description}
          onChange={(event) => {
            dispatch(
              updateStaffDetailCreate({
                key: 'description',
                value: event.currentTarget.value,
              }),
            );
          }}
        />
      </TextFieldWrapper>
    </Container>
  );
}

export default StaffFormCreate;
