import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { Client, ClientFormCreate, FieldsErrors, FormFieldPayload } from 'model';
import { RootState } from 'store/config';

interface State {
  clients: Array<Client>;
  filteredClients: Client[];
  clientsLoading: boolean;
  clientsAreFetched: boolean;
  searchText: string;
  clientFormAddModalShow: boolean;
  clientFormLoading: boolean;
  clientFormAdd: ClientFormCreate;
  clientFormAddError: FieldsErrors;
  clientsDelete: Client[];
  selected: Client[];
}

const initialState: State = {
  clients: [],
  clientsLoading: false,
  clientsAreFetched: false,
  filteredClients: [],
  searchText: '',
  clientFormAddModalShow: false,
  clientFormLoading: false,
  clientFormAdd: {
    company: '',
    name: '',
    phone: null,
    email: '',
  },
  clientFormAddError: {},
  clientsDelete: [],
  selected: [],
};

const ClientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<Array<Client>>) => {
      return {
        ...state,
        clients: action.payload,
      };
    },
    setClientsLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        clientsLoading: action.payload,
      };
    },
    setClientsAreFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        clientsAreFetched: action.payload,
      };
    },
    setFilteredClients: (state, action: PayloadAction<Client[]>) => {
      return {
        ...state,
        filteredClients: action.payload,
      };
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        searchText: action.payload,
      };
    },
    setClientFormAddModalShow: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        clientFormAddModalShow: action.payload,
      };
    },
    setClientFormLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        clientFormLoading: action.payload,
      };
    },
    updateClientFormAdd: (
      state,
      action: PayloadAction<FormFieldPayload<keyof ClientFormCreate>>,
    ) => {
      return {
        ...state,
        clientFormAdd: {
          ...state.clientFormAdd,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearClientFormAdd: (state) => {
      return {
        ...state,
        clientFormAdd: {
          company: '',
          name: '',
          phone: null,
          email: '',
        },
      };
    },
    setClientFormAddError: (
      state,
      action: PayloadAction<FormFieldPayload<keyof ClientFormCreate>>,
    ) => {
      return {
        ...state,
        clientFormAddError: {
          ...state.clientFormAddError,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearClientFormAddError: (state) => {
      return {
        ...state,
        clientFormAddError: {},
      };
    },
    setClientsDelete: (state, action: PayloadAction<Client[]>) => {
      return {
        ...state,
        clientsDelete: action.payload,
      };
    },
    setSelected: (state, action: PayloadAction<Client[]>) => {
      return {
        ...state,
        selected: action.payload,
      };
    },
  },
});

export const {
  setClients,
  setClientsLoading,
  setClientsAreFetched,
  setFilteredClients,
  setSearchText,
  setClientFormAddModalShow,
  setClientFormLoading,
  updateClientFormAdd,
  clearClientFormAdd,
  setClientFormAddError,
  clearClientFormAddError,
  setClientsDelete,
  setSelected,
} = ClientsSlice.actions;

export enum ClientsActionTypes {
  CLIENTS_REQUEST = 'clients/CLIENTS_REQUEST',
  CLIENTS_CREATE_REQUEST = 'clients/CLIENTS_CREATE_REQUEST',
  CLIENTS_DELETE_REQUEST = 'clients/CLIENTS_DELETE_REQUEST',
  FILTER_CLIENTS = 'clients/FILTER_CLIENTS',
}

export const clientsRequest = () => {
  return {
    type: ClientsActionTypes.CLIENTS_REQUEST,
  };
};

export const clientCreateRequest = () => {
  return {
    type: ClientsActionTypes.CLIENTS_CREATE_REQUEST,
  };
};

export const clientDeleteRequest = (client: Client, index: number, total: number) => {
  return {
    type: ClientsActionTypes.CLIENTS_DELETE_REQUEST,
    payload: {
      client,
      index,
      total,
    },
  };
};

export const filterClients = () => {
  return {
    type: ClientsActionTypes.FILTER_CLIENTS,
  };
};

export const selectClients = (state: RootState) => {
  return state.clients;
};

export const selectClientList = createSelector([selectClients], (value) => value.clients);

export const selectClientFormAdd = createSelector([selectClients], (value) => value.clientFormAdd);

export const selectClientFormAddError = createSelector(
  [selectClients],
  (value) => value.clientFormAddError,
);

export const selectClientsDelete = createSelector([selectClients], (value) => value.clientsDelete);

export const selectSearchText = createSelector([selectClients], (value) => value.searchText);

export const clientsReducer = ClientsSlice.reducer;
