import dayjs, { Dayjs } from 'dayjs';

export const getMonthDays = (date: Dayjs): Array<Array<Dayjs>> => {
  const monthIndex = dayjs(date).month();
  let days: Array<Dayjs> = [];
  let monthStartDate = dayjs(date).startOf('month');

  while (monthStartDate.month() === monthIndex) {
    days.push(monthStartDate);

    monthStartDate = dayjs(monthStartDate).add(1, 'day');
  }

  let prevMonthEndDate = monthStartDate.month(monthIndex - 1).endOf('month');
  let nextMonthStartDate = monthStartDate.month(monthIndex + 1).startOf('month');
  const indexInWeek = dayjs(date).startOf('month').day();
  let onStartDaysDiff = indexInWeek === 0 ? 7 - 1 : indexInWeek - 1;

  const onStartDaysToAdd: any[] = [];

  while (onStartDaysDiff > 0) {
    onStartDaysToAdd.push(prevMonthEndDate);
    prevMonthEndDate = dayjs(prevMonthEndDate).subtract(1, 'day');

    onStartDaysDiff -= 1;
  }

  days.unshift(...onStartDaysToAdd.reverse());

  const monthDays: any[] = [];
  let weekDays: any = [];
  let counter = 1;

  days.forEach((day, index) => {
    if (counter < 7) {
      weekDays.push(day);
      counter += 1;
    } else {
      weekDays.push(day);
      monthDays.push(weekDays);
      counter = 1;
      weekDays = [];
    }
  });

  monthDays.push(weekDays);

  const monthDaysLastIndex = monthDays.length - 1;

  let onEndDaysDiff = 7 - monthDays[monthDaysLastIndex].length;

  const endDaysToAdd: any[] = [];

  while (onEndDaysDiff > 0) {
    endDaysToAdd.push(nextMonthStartDate);
    nextMonthStartDate = dayjs(nextMonthStartDate).add(1, 'day');

    onEndDaysDiff -= 1;
  }

  monthDays[monthDaysLastIndex].push(...endDaysToAdd);

  return monthDays;
};

export function timeArrayGenerator(
  interval = 10,
  startTime = 0 * 60,
  endTime = 24 * 60,
  hour: 12 | 24 = 24,
): Array<string> {
  var times = [];
  var ap = ['AM', 'PM'];

  for (var i = 0; startTime < endTime; i++) {
    var hh = Math.floor(startTime / 60); // getting hours of day in 0-24 format
    var mm = startTime % 60; // getting minutes of the hour in 0-55 format

    times[i] =
      ('0' + (hh % hour)).slice(-2) +
      ':' +
      ('0' + mm).slice(-2) +
      (hour === 12 ? ap[Math.floor(hh / hour)] : ''); // pushing data in array in [00:00 - 12:00 AM/PM format]
    startTime = startTime + interval;
  }

  return times;
}
