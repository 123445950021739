import { useEffect } from 'react';

import { Background, Font } from 'elements';
import styled from 'styled-components';
import { ReactComponent as SVG } from 'assets/svg/server-error.svg';
import { ReactComponent as PersonSvg } from 'assets/svg/person-well-done.svg';
import { useCompanyVerifyMutation } from 'services/query';
import { Button, CircularProgress } from '@mui/material';
import { useQuery } from 'hooks';
import { getError } from 'utils';
import { Link } from 'react-router-dom';

const ErrorSvg = styled(SVG)`
  height: auto;
  width: 350px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 90%;
  }
`;

const PersonImage = styled(PersonSvg)`
  height: auto;
  width: 300px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 90%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Content = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CompanyVerifyScreen = () => {
  const query = useQuery();
  const token = query.get('token');
  const [companyVerify, { isUninitialized, isLoading, isError, isSuccess, error }] =
    useCompanyVerifyMutation();

  useEffect(() => {
    if (token) companyVerify(token);
  }, [token, companyVerify]);

  return (
    <Container>
      <Background />

      <Content>
        {(isLoading || isUninitialized) && <CircularProgress />}

        {!isLoading && isError && (
          <>
            <ErrorSvg />

            <Font style={{ marginTop: '4rem', fontSize: '2rem', marginBottom: 16 }} type='h4'>
              Something went wrong!
            </Font>

            <Font style={{ fontSize: 16 }}>
              {error && 'data' in error
                ? getError(error?.data)
                : 'Unable to verify your company email.'}
            </Font>
          </>
        )}

        {!isLoading && isSuccess && (
          <>
            <PersonImage />

            <Font style={{ marginTop: '4rem', fontSize: '2rem', marginBottom: 16 }} type='h4'>
              Email successfully verified!
            </Font>

            <Font style={{ fontSize: 16 }}>You can now login to Gotei and start scheduling.</Font>

            <Link to='/login'>
              <Button size='large' variant='contained' sx={{ mt: 2 }}>
                Log in
              </Button>
            </Link>
          </>
        )}
      </Content>
    </Container>
  );
};
