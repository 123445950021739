import moment from 'moment-timezone';

export function weekdays() {
  const weekStart = moment().startOf('week');

  const days = [];

  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days').weekday());
  }

  return days;
}
