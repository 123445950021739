import { createTheme, ThemeProvider } from '@mui/material';

import {
  MuiButtonRoot,
  MuiButtonContained,
  MuiButtonOutlined,
  MuiButtonContainedInherit,
  MuiButtonTextInherit,
  MuiTableCellRoot,
  MuiTableCellHead,
  MuiCardRoot,
  MuiCardHeaderRoot,
  MuiCardHeaderTitle,
  MuiCardContentRoot,
  MuiCardActionsRoot,
  MuiSliderThumb,
  MuiSliderTrack,
  MuiSliderRail,
  MuiSliderValueLabel,
  MuiCircularProgressSecondary,
  MuiChipRoot,
} from './overrides';

const muiTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          ...MuiButtonRoot,
        },
        contained: {
          ...MuiButtonContained,
        },
        outlined: {
          ...MuiButtonOutlined,
        },
        outlinedInherit: {
          borderColor: '#c0c0c0',
        },
        containedInherit: {
          ...MuiButtonContainedInherit,
        },
        textInherit: {
          ...MuiButtonTextInherit,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          ...MuiCardRoot,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          ...MuiCardHeaderRoot,
        },
        title: {
          ...MuiCardHeaderTitle,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ...MuiCardContentRoot,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          ...MuiCardActionsRoot,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...MuiTableCellRoot,
        },
        head: {
          ...MuiTableCellHead,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {},
        thumb: {
          ...MuiSliderThumb,
        },
        track: {
          ...MuiSliderTrack,
        },
        rail: {
          ...MuiSliderRail,
        },
        valueLabel: {
          ...MuiSliderValueLabel,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorSecondary: {
          ...MuiCircularProgressSecondary,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ...MuiChipRoot,
        },
      },
    },
  },
  palette: {
    secondary: {
      main: '#EC7211',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

interface Props {
  children: React.ReactNode;
}

const MuiThemeProvider = ({ children }: Props) => (
  <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
);

export default MuiThemeProvider;
