import { useDocumentTitle } from 'hooks';
import { PrivateLayout } from 'layout';

// components
import CalendarComposition from './CalendarComposition';

const CalendarScreen = () => {
  useDocumentTitle('Calendar - Gotei');

  return (
    <PrivateLayout>
      <CalendarComposition />
    </PrivateLayout>
  );
};

export default CalendarScreen;
