import * as d3 from 'd3';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';

const Container = styled.div`
  flex: 1;
`;
interface Props {
  data: Array<any>;
  color: any;
}

function SparkLine({ data, color }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    draw();
    // eslint-disable-next-line
  }, [data]);

  const draw = () => {
    // Remove the existing svg
    d3.select(ref.current).select('svg').remove();

    const svg = d3.select(ref.current).append('svg').attr('width', '100%').attr('height', '100%');

    // Get width and height from svg
    const svgDimensions = svg.node()?.getBoundingClientRect();

    // Calculate width and height
    const height = svgDimensions ? svgDimensions.height : 50;
    const width = svgDimensions ? svgDimensions.width : 100;

    const yMinValue = d3.min(data, (d) => d);
    const yMaxValue = d3.max(data, (d) => d);

    //setting the scaling
    const xScale = d3
      .scaleLinear()
      .domain([0, data.length - 1])
      .range([0, width]);

    const yScale: any = d3
      .scaleLinear()
      .domain([yMinValue - 1, yMaxValue + 2])
      .range([height, 0]);

    const generateScaledLine = d3
      .line()
      .x((d, i) => xScale(i))
      .y(yScale);

    svg
      .selectAll('.line')
      .data([data])
      .join('path')
      .attr('d', (d) => generateScaledLine(d))
      .attr('fill', 'none')
      .attr('stroke', color);
  };
  return <Container ref={ref} />;
}

export default SparkLine;
