import styled from 'styled-components';
import { TextField, Button, Link, CircularProgress, Alert } from '@mui/material';
import { LoginFormKeys } from 'model';
import { Link as RouterLink } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { updateLoginForm, loginSubmit } from '../store';

const Container = styled.div``;

const Title = styled.h2`
  font-size: 1.5rem;
  margin: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const ForgotPasswordWrapper = styled.div``;

const LoginForm = () => {
  const { loading, loginFormError, loginServerError, loginForm } = useSelector(
    (state: RootState) => state.login,
  );
  const dispatch = useDispatch();

  return (
    <Container>
      <Title>Welcome</Title>

      <Form>
        <TextField
          color='primary'
          variant='outlined'
          label='Email'
          sx={{ mb: 4 }}
          error={!!loginFormError[LoginFormKeys.username]}
          value={loginForm[LoginFormKeys.username]}
          onChange={(event) =>
            dispatch(
              updateLoginForm({
                key: LoginFormKeys.username,
                value: event.target.value,
              }),
            )
          }
        />

        <TextField
          color='primary'
          variant='outlined'
          label='Password'
          type='password'
          sx={{ mb: 1 }}
          error={!!loginFormError[LoginFormKeys.password]}
          value={loginForm[LoginFormKeys.password]}
          onChange={(event) =>
            dispatch(
              updateLoginForm({
                key: LoginFormKeys.password,
                value: event.target.value,
              }),
            )
          }
        />

        <ForgotPasswordWrapper>
          <Link component={RouterLink} to='/reset-password' variant='body2'>
            Forgot Password
          </Link>
        </ForgotPasswordWrapper>

        {loginServerError && (
          <Alert severity='error' sx={{ mt: 2 }}>
            {loginServerError}
          </Alert>
        )}

        <Button
          type='submit'
          variant='contained'
          size='large'
          disabled={loading}
          sx={{ mt: 4 }}
          endIcon={loading && <CircularProgress color='secondary' size={20} />}
          onClick={(event) => {
            event.preventDefault();

            dispatch(loginSubmit());
          }}
        >
          Log in
        </Button>
      </Form>
    </Container>
  );
};

export default LoginForm;
