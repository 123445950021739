import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useQuery } from 'hooks';
import { useEffect } from 'react';
import { useGoogleLoginMutation } from 'services/query/authenticationApi';
import styled from 'styled-components';

// images
import { ReactComponent as ErrorIcon } from 'assets/svg/server-error.svg';
import { useHistory } from 'react-router-dom';
import { getError } from 'utils';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GoogleLoginComposition = () => {
  const [googleLogin, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useGoogleLoginMutation();
  const history = useHistory();
  const query = useQuery();
  const code = query.get('code');

  useEffect(() => {
    if (code) googleLogin(code);
  }, [googleLogin, code]);

  useEffect(() => {
    if (isSuccess) history.push('dashboard');
  }, [history, isSuccess]);

  return (
    <Container>
      {(isLoading || isUninitialized) && !isError && <CircularProgress />}

      {isError && (
        <Stack alignItems='center'>
          <ErrorIcon />

          <Typography sx={{ mt: 4 }} color='error' variant='h6'>
            Google login failed
          </Typography>

          <Typography color='error'>
            {error && 'data' in error ? getError(error.data) : 'Something went wrong!'}
          </Typography>

          <Button
            sx={{ mt: 3 }}
            onClick={() => history.push('/')}
            variant='contained'
            color='primary'
          >
            Return home
          </Button>
        </Stack>
      )}
    </Container>
  );
};
