import { useState } from 'react';

import { Tab, Tabs as TabsUi } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
`;

const TabsCustom = styled(TabsUi)`
  border-bottom: 1px solid lightgrey;

  .MuiTab-root {
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    color: #bdbdbd;
  }
  .MuiTab-root {
    text-transform: unset !important;
  }
  .MuiTab-textColorPrimary.Mui-selected {
    color: #1f4b81;
  }
`;

const TabContent = styled.div`
  margin-top: 1.5rem;
`;

interface Props {
  tabs: Array<string>;
  tabPanels: Array<any>;
  activeIndex?: number;
}

const Tabs = ({ tabs, tabPanels = [], activeIndex = 0 }: Props) => {
  const [value, setValue] = useState(activeIndex);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <TabsCustom
        TabIndicatorProps={{ style: { background: '#f6c21c' } }}
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable auto tabs example'
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab} />
        ))}
      </TabsCustom>
      <TabContent>{tabPanels[value]}</TabContent>
    </Container>
  );
};

export default Tabs;
