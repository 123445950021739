// dependencies
import { Button } from '@mui/material';
import styled from 'styled-components';
import { Logo } from 'elements';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface ContainerProps {
  isScrolling: boolean;
}

const Container = styled.div<ContainerProps>`
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: ${({ isScrolling }) => (isScrolling ? 'rgb(0 0 0 / 4%) 0px 4px 4px' : 'unset')};
  z-index: 10;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1rem;
    height: 65px;
  }
`;

const Content = styled.div`
  max-width: 1200px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonSection = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  margin-left: 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 1rem;
  }
`;

function NavBar() {
  const [isScrolling, setIsScrolling] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
    //eslint-disable-next-line
  }, []);

  const scrollHandler = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > 0 && !isScrolling) {
      setIsScrolling(true);
    }

    if (winScroll === 0) {
      setIsScrolling(false);
    }
  };

  return (
    <Container isScrolling={isScrolling}>
      <Content>
        <Logo size='large' />

        <ButtonSection>
          <Button color='inherit' onClick={() => history.push('/login')}>
            Log in
          </Button>

          <ButtonWrapper>
            <Button variant='contained' onClick={() => history.push('/signup')}>
              Sign up
            </Button>
          </ButtonWrapper>
        </ButtonSection>
      </Content>
    </Container>
  );
}
export default NavBar;
