import { useEffect } from 'react';

import { Box, Button, Divider, Stack } from '@mui/material';
import { Font } from 'elements';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';
import { useHistory } from 'react-router';

// redux
import { RootState } from 'store/config';
import { setServiceDeleteSuccess, updateServiceFormAdd, setServiceFormAddModalShow } from '.';

// components
import Services from './components/Services';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem;
  }
`;

const Content = styled.div`
  margin-top: 1rem;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

function ServicesComposition() {
  const { company } = useSelector((state: RootState) => state.organization);
  const { services, servicesAreFetched, serviceDeleteSuccess } = useSelector(
    (state: RootState) => state.services,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (serviceDeleteSuccess) {
      history.push('/services');

      setTimeout(() => {
        dispatch(setServiceDeleteSuccess(false));
      }, 1000);
    }
  }, [dispatch, history, serviceDeleteSuccess]);

  return (
    <Container>
      <PageHeader>
        <Stack mr={1}>
          <Font type='h1'>Services </Font>

          <Font>This page shows all services which your company provide to your clients.</Font>
        </Stack>

        <Box>
          {servicesAreFetched && !!services.length && (
            <Button
              sx={{ whiteSpace: 'nowrap' }}
              startIcon={<Add />}
              onClick={() => {
                dispatch(
                  updateServiceFormAdd({
                    key: 'company',
                    value: company.uuid,
                  }),
                );
                dispatch(setServiceFormAddModalShow(true));
              }}
              variant='contained'
              color='primary'
            >
              Add Service
            </Button>
          )}
        </Box>
      </PageHeader>

      <Divider />

      <Content>
        <Services />
      </Content>
    </Container>
  );
}

export default ServicesComposition;
