import { CSSProperties } from 'react';

export const MuiSliderThumb: CSSProperties = {
  backgroundColor: '#fff',
  height: 25,
  width: 25,
  marginTop: -12,
  marginLeft: -13,
  boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.1)',
  border: '1px solid #b5b5b5',
};

export const MuiSliderTrack: CSSProperties = {
  height: '2.9px !important',
};

export const MuiSliderRail: CSSProperties = {
  height: 2,
  backgroundColor: '#b5b5b5',
};

export const MuiSliderValueLabel = {
  '&>span': {
    width: 38,
    height: 38,
    transform: 'rotate(-45deg) translateX(10px)',
  },
};
