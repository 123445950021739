import React, { useEffect } from 'react';

// redux
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authTokenVerifyRequest } from 'store/auth';
import { useAuthEffect } from 'hooks';

interface Props {
  children: React.ReactNode;
}

function CheckLoginSuccessFlow({ children }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  useAuthEffect(() => {
    history.push('/dashboard');
  }, []);

  useEffect(() => {
    dispatch(authTokenVerifyRequest());
    // eslint-disable-next-line
  }, []);

  return <>{children}</>;
}

export default CheckLoginSuccessFlow;
