import { useEffect, useState } from 'react';

// dependencies
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  colors,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { FileUpload, Font, Modal, Popper, TimePicker } from 'elements';
import styled from 'styled-components';
import {
  Check,
  Close,
  Delete,
  Edit,
  MoreVertTwoTone,
  PersonAdd,
  PhotoCamera,
} from '@mui/icons-material';
import { useAuthEffect, useWillUnmount } from 'hooks';
import { fileToBase64, weekdays } from 'utils';
import { WorkingHour, WorkingHourFormCreate } from 'model';
import { LoadingButton } from '@mui/lab';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment-timezone';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import {
  staffDetailRequest,
  updateServiceStaffRequest,
  workingHoursDeleteRequest,
  workingHoursCreateRequest,
  updateStaffBreakFormCreate,
  updateStaffBreakFormUpdate,
  clearStaffBreakFormUpdate,
  updateBreakRequest,
  deleteBreakRequest,
  setStaffBreakFormUpdate,
  clearStaffBreakFormCreate,
  setStaffBreakFormCreateError,
  createBreakRequest,
  breaksRequest,
  workingHoursRequest,
  workingHoursUpdateRequest,
  setUserInviteModalShow,
  setUserInvite,
  userInviteRequest,
  updateStaffUserPermissionRequest,
  userDeleteSnackbarRequest,
  setStaffDetailEdit,
  clearStaffDetailEdit,
  staffDetailUpdateRequest,
  updateStaffDetailEdit,
  setStaffDetailDelete,
  clearStaffDetailDelete,
  staffDetailDeleteRequest,
  setStaffDetailDeleteSuccess,
  staffUserPermissionRequest,
  staffDetailUpdatePartialRequest,
  setStaffDetailEditPartial,
} from '..';

const Container = styled.div``;

const Form = styled.form``;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionTitle = styled.div`
  flex: 1;
  padding: 0 1.5rem 0 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const SectionContent = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
`;

const SectionActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const StaffName = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
`;

const DaySection = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const DayNameWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex: 0.7;
  }
`;

const BreakTime = styled.div`
  display: flex;
  align-items: center;
`;

const DeleteSection = styled.div``;

const BreakStartTimeFont = styled(Font)`
  margin-right: 1rem;
`;

const BreakEndTimeFont = styled(Font)`
  margin-left: 1rem;
`;

const AddBreakModalSection = styled.div`
  display: flex;
  align-items: center;
`;

const TimerPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BreaksSection = styled.div`
  flex: 3;
  text-align: end;
`;

const BreaksWrapper = styled.div`
  margin-top: 0.5rem;
`;

const Break = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
`;

const InviteStaffIcon = styled.div`
  text-align: center;
`;

const AvatarWrapper = styled.div`
  position: relative;

  &:hover {
    div:nth-child(2) {
      display: flex;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
`;

const LabelWrapper = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

const LoadingWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

const MobileHiddenDummyElement = styled.div`
  flex: 1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const Label = styled.label`
  &:hover {
    cursor: pointer;
  }
`;

const MobileHidden = styled.div`
  flex: 1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

interface Params {
  id?: string;
}

function StaffDetail() {
  const {
    staffLoading,
    staffDetail,
    updateServiceStaffError,
    workingHoursForm,
    workingHoursError,
    staffBreaks,
    staffBreakFormUpdate,
    staffBreakFormUpdateError,
    staffBreakFormCreate,
    staffBreakFormCreateError,
    staffBreakFormLoading,
    userInviteModalShow,
    userInviteError,
    staffUserPermission,
    staffDetailEditLoading,
    staffDetailEdit,
    staffDetailEditError,
    staffDetailDelete,
    staffDetailDeleteLoading,
    staffDetailDeleteSuccess,
  } = useSelector((state: RootState) => state.staffDetail);
  const { permissions, services, businessHours } = useSelector(
    (state: RootState) => state.organization,
  );
  const { user } = useSelector((state: RootState) => state.auth);
  const [popperEl, setPopperEl] = useState<any>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<Params>();
  const businessHourCreate = businessHours.find(
    (hour) => hour.weekday === staffBreakFormCreate.weekday,
  );
  const businessHourUpdate = businessHours.find(
    (hour) => hour.weekday === staffBreakFormUpdate.weekday,
  );

  useEffect(() => {
    if (staffDetailDeleteSuccess) history.push('/staff');
  }, [history, staffDetailDeleteSuccess]);

  useWillUnmount(() => {
    dispatch(setStaffDetailDeleteSuccess(false));
  });

  useAuthEffect(() => {
    if (id && staffDetail.id !== +id) dispatch(staffDetailRequest(+id));
  }, [dispatch, id]);

  useAuthEffect(() => {
    if (staffDetail.id) {
      dispatch(breaksRequest());
      dispatch(workingHoursRequest());
      dispatch(staffUserPermissionRequest());
    }
  }, [staffDetail]);

  return (
    <Container>
      <Form
        onSubmit={(event) => {
          event.preventDefault();

          dispatch(staffDetailUpdateRequest());
        }}
      >
        <Section>
          <SectionTitle>
            <Font type='h5'>Overview</Font>
            <Font>Staff information</Font>
          </SectionTitle>

          <SectionContent>
            <StaffName>
              <FileUpload
                style={{ display: 'none' }}
                accept={['image/*']}
                htmlFor='upload-file'
                onFileUpload={async (event) => {
                  const file = event.target.files?.[0];

                  if (file) {
                    const image = await fileToBase64(file);

                    dispatch(
                      setStaffDetailEditPartial({
                        image,
                      }),
                    );

                    dispatch(staffDetailUpdatePartialRequest());
                  }
                }}
              />

              <AvatarWrapper>
                <Avatar
                  src={staffDetail.image}
                  sx={{
                    height: 110,
                    width: 110,
                  }}
                />

                {staffDetailEditLoading ? (
                  <LoadingWrapper>
                    <CircularProgress sx={{ color: colors.common.white }} />
                  </LoadingWrapper>
                ) : (
                  <LabelWrapper>
                    <Label htmlFor='upload-file'>
                      <PhotoCamera fontSize='large' sx={{ color: colors.common.white }} />
                    </Label>
                  </LabelWrapper>
                )}
              </AvatarWrapper>

              <Stack direction='row' alignItems='center'>
                <Font type='h2' style={{ marginLeft: 24 }}>
                  {staffDetail.name}
                </Font>

                {staffDetail.user?.email === user.email && (
                  <Typography variant='body2' sx={{ ml: 1, mb: 1, opacity: 0.5 }}>
                    (You)
                  </Typography>
                )}
              </Stack>
            </StaffName>

            {staffDetailEditError.image && (
              <FormHelperText error>{staffDetailEditError.image}</FormHelperText>
            )}

            {staffDetailEdit.id ? (
              <>
                <TextField
                  required
                  type='text'
                  label='Name'
                  margin='normal'
                  sx={{ mt: 3 }}
                  value={staffDetailEdit.name}
                  error={!!staffDetailEditError.name}
                  helperText={staffDetailEditError.name}
                  onChange={(event) => {
                    dispatch(
                      updateStaffDetailEdit({
                        key: 'name',
                        value: event.currentTarget.value,
                      }),
                    );
                  }}
                />

                <TextField
                  type='text'
                  multiline
                  rows={4}
                  margin='normal'
                  label='Description'
                  error={!!staffDetailEditError.description}
                  helperText={staffDetailEditError.description}
                  value={staffDetailEdit.description}
                  onChange={(event) => {
                    dispatch(
                      updateStaffDetailEdit({
                        key: 'description',
                        value: event.currentTarget.value,
                      }),
                    );
                  }}
                />
              </>
            ) : (
              <>
                <Stack mt={3}>
                  {staffLoading ? (
                    <>
                      <Skeleton variant='text' width={150} />
                      <Skeleton variant='text' width={80} />
                    </>
                  ) : (
                    <>
                      <Font type='h5'>Name</Font>
                      <Font>{staffDetail.name}</Font>
                    </>
                  )}
                </Stack>

                <Stack mt={2}>
                  {staffLoading ? (
                    <>
                      <Skeleton variant='text' width={150} />
                      <Skeleton variant='text' width={80} />
                    </>
                  ) : (
                    <>
                      <Font type='h5'>Description</Font>
                      <Font>{staffDetail.description} </Font>
                    </>
                  )}
                </Stack>
              </>
            )}
          </SectionContent>

          <MobileHidden>
            <SectionActions>
              <div>
                {staffDetailEdit.id ? (
                  <>
                    <Button
                      color='inherit'
                      sx={{
                        mr: 1,
                      }}
                      onClick={() => dispatch(clearStaffDetailEdit())}
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      type='submit'
                      color='primary'
                      variant='contained'
                      startIcon={<Check />}
                      loading={staffDetailEditLoading}
                    >
                      Save
                    </LoadingButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      onClick={(event) => {
                        setPopperEl(event.currentTarget);
                      }}
                    >
                      <MoreVertTwoTone />
                    </IconButton>

                    <Popper open={!!popperEl} anchorEl={popperEl} onClose={() => setPopperEl(null)}>
                      <Stack sx={{ width: 150, p: 1 }}>
                        <Box>
                          <Button
                            fullWidth
                            color='inherit'
                            startIcon={<Edit />}
                            sx={{ justifyContent: 'flex-start' }}
                            onClick={() => {
                              dispatch(setStaffDetailEdit(staffDetail));

                              setPopperEl(null);
                            }}
                          >
                            Edit
                          </Button>
                        </Box>

                        <Button
                          fullWidth
                          color='error'
                          startIcon={<Delete />}
                          sx={{ justifyContent: 'flex-start' }}
                          disabled={staffDetail.user?.is_company_admin}
                          onClick={() => {
                            dispatch(setStaffDetailDelete(staffDetail));

                            setPopperEl(null);
                          }}
                        >
                          Delete
                        </Button>
                      </Stack>
                    </Popper>
                  </>
                )}
              </div>
            </SectionActions>
          </MobileHidden>
        </Section>
      </Form>

      <Divider sx={{ m: '24px 0' }} />

      <Section>
        <SectionTitle>
          <Font type='h5'>User invitation</Font>
          <Font>
            Invite this staff, assign them permission role and give them the power to use the
            platform
          </Font>
        </SectionTitle>

        <SectionContent>
          {staffDetail.user ? (
            <>
              <Stack mt={2}>
                <Font type='h5'>Status</Font>
                <Font>Invited</Font>
              </Stack>

              <Stack mt={2}>
                <Font type='h5'>Email</Font>
                <Font>{staffDetail.user.email} </Font>
              </Stack>

              <Stack direction='row' mt={2} justifyContent='space-between'>
                <Stack flex={1}>
                  <Font type='h5'>Name</Font>
                  <Font>{staffDetail.user.name} </Font>
                </Stack>

                <Stack flex={1}>
                  <Font type='h5'>Phone</Font>
                  <Font>{staffDetail.user.phone ? staffDetail.user.phone : '-'}</Font>
                </Stack>
              </Stack>

              <Stack mt={2}>
                <Font type='h5'>Permission role</Font>

                <FormControl fullWidth>
                  <Select
                    size='small'
                    disabled={staffDetail.user?.is_company_admin}
                    sx={{ maxWidth: 250, mt: 0.5 }}
                    value={staffUserPermission[0]}
                    onChange={(event) => {
                      const value = event.target.value;
                      const permission = permissions.find((perm) => perm[0] === value);

                      if (permission?.length) {
                        dispatch(updateStaffUserPermissionRequest(permission));
                      }
                    }}
                  >
                    {permissions.map((permission) => (
                      <MenuItem key={permission[0]} value={permission[0]}>
                        {permission[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </>
          ) : (
            <Stack>
              {staffLoading ? (
                <>
                  <Skeleton variant='text' width={150} />
                  <Skeleton variant='text' width={80} />
                </>
              ) : (
                <>
                  <Font type='h5'>Status</Font>
                  <Font>Not invited yet</Font>
                </>
              )}
            </Stack>
          )}
        </SectionContent>

        <SectionActions>
          <div>
            {staffDetail.user ? (
              <Button
                variant='outlined'
                color='error'
                disabled={staffDetail.user?.is_company_admin}
                onClick={() => {
                  if (staffDetail.user) {
                    dispatch(userDeleteSnackbarRequest(staffDetail.user.id));
                  }
                }}
              >
                Delete User
              </Button>
            ) : (
              <Button
                variant='outlined'
                onClick={() => {
                  dispatch(setUserInviteModalShow(true));
                }}
              >
                Invite User
              </Button>
            )}
          </div>
        </SectionActions>
      </Section>

      <Divider sx={{ m: '24px 0' }} />

      <Section>
        <SectionTitle>
          <Font type='h5'>Services</Font>
          <Font>
            Services are provided by the staff. Assign or unassign services from this stuff with one
            click
          </Font>
        </SectionTitle>

        <SectionContent>
          <Font type='h5'>&nbsp;</Font>

          <Box>
            {!services.length
              ? 'There re no services to assign'
              : services.map((service) => {
                  if (service.staff.some((staff) => staff.id === staffDetail.id)) {
                    return (
                      <Chip
                        color='primary'
                        label={service.name}
                        key={service.id}
                        onDelete={() => {
                          const staffCopy = [...service.staff];
                          const staff = staffCopy.filter((staff) => staff.id !== staffDetail.id);

                          dispatch(
                            updateServiceStaffRequest(service, {
                              staff: staff.map((staff) => staff.id),
                            }),
                          );
                        }}
                      />
                    );
                  } else {
                    return (
                      <Chip
                        label={service.name}
                        key={service.id}
                        onClick={() => {
                          const staff = [...service.staff];
                          staff.push(staffDetail);

                          dispatch(
                            updateServiceStaffRequest(service, {
                              staff: staff.map((staff) => staff.id),
                            }),
                          );
                        }}
                      />
                    );
                  }
                })}

            {updateServiceStaffError && (
              <FormHelperText error={!!updateServiceStaffError}>
                {updateServiceStaffError}
              </FormHelperText>
            )}
          </Box>
        </SectionContent>

        <SectionActions>
          <div></div>
        </SectionActions>
      </Section>

      <Divider sx={{ m: '24px 0' }} />

      <Section>
        <SectionTitle>
          <Font type='h5'>Working hours</Font>
          <Font>
            Staff will be able to accept appointments only between these working hours range
          </Font>
        </SectionTitle>

        <SectionContent>
          {weekdays().map((day, index) => {
            const dayByName = moment().set('weekday', day);
            const weekday: any = dayByName.weekday();
            const workingHourForm = workingHoursForm.find((time) => time.weekday === weekday);
            const businessHour = businessHours.find((businessHour) => businessHour.weekday === day);
            const checked = !!workingHourForm;
            const error = workingHoursError[weekday];

            let start = moment().hours(8).minutes(0).seconds(0);
            let end = moment().hours(17).minutes(0).seconds(0);

            if (workingHourForm) {
              const [startHours, startMinutes] = workingHourForm.start_time.split(':');
              const [endHours, endMinutes] = workingHourForm.end_time.split(':');

              start = moment().hours(+startHours).minutes(+startMinutes).seconds(0);
              end = moment().hours(+endHours).minutes(+endMinutes).seconds(0);
            }

            return (
              <DaySection key={index}>
                <Stack direction={'row'} flex={1} justifyContent={'space-between'}>
                  <DayNameWrapper>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={checked} />}
                        label={dayByName.format('dddd')}
                        onChange={() => {
                          if (checked && workingHourForm) {
                            dispatch(workingHoursDeleteRequest(workingHourForm));
                          } else {
                            const workingHoursFormCreate: WorkingHourFormCreate = {
                              weekday,
                              staff: staffDetail.id,
                              start_time: start.format('HH:mm:ss'),
                              end_time: end.format('HH:mm:ss'),
                            };

                            dispatch(workingHoursCreateRequest(workingHoursFormCreate));
                          }
                        }}
                      />
                    </FormGroup>
                  </DayNameWrapper>

                  <Stack
                    flex={1}
                    sx={{
                      alignItems: {
                        xs: 'flex-end',
                        sm: 'flex-end',
                        lg: 'flex-start',
                      },
                    }}
                  >
                    <Box>
                      <TimePicker
                        includeLastStep
                        error={!!error}
                        disabled={!checked}
                        businessHour={businessHour}
                        value={start}
                        onChange={(value) => {
                          if (workingHourForm) {
                            const workingHourFormCopy: WorkingHour = {
                              ...workingHourForm,
                              start_time: value.format('HH:mm'),
                            };

                            dispatch(workingHoursUpdateRequest(workingHourFormCopy));
                          }
                        }}
                      />

                      <Font style={{ margin: '0 1rem' }}>to</Font>

                      <TimePicker
                        includeLastStep
                        error={!!error}
                        disabled={!checked}
                        businessHour={businessHour}
                        value={end}
                        onChange={(value) => {
                          if (workingHourForm) {
                            const workingHourFormCopy: WorkingHour = {
                              ...workingHourForm,
                              end_time: value.format('HH:mm'),
                            };

                            dispatch(workingHoursUpdateRequest(workingHourFormCopy));
                          }
                        }}
                      />
                    </Box>

                    <Box>{error && <FormHelperText error>{error}</FormHelperText>}</Box>
                  </Stack>
                </Stack>
              </DaySection>
            );
          })}
        </SectionContent>

        <MobileHiddenDummyElement />
      </Section>

      <Divider sx={{ m: '24px 0' }} />

      <Section>
        <SectionTitle>
          <Font type='h5'>Breaks</Font>
          <Font>
            Break time during the day will prevent appointments booked when the staff is resting
          </Font>
        </SectionTitle>

        <SectionContent>
          {weekdays().map((day: number, index) => {
            const breaks = staffBreaks.filter((staffBreak) => staffBreak.weekday === day);

            return (
              <DaySection key={index}>
                <DayNameWrapper>
                  <Font type='h5'>{moment().set('weekday', day).format('dddd')}</Font>
                </DayNameWrapper>

                <BreaksSection>
                  <Button
                    variant='contained'
                    color='inherit'
                    size='small'
                    onClick={() => {
                      if (!staffDetail.id) return;

                      dispatch(
                        updateStaffBreakFormCreate({
                          key: 'weekday',
                          value: day,
                        }),
                      );
                      dispatch(
                        updateStaffBreakFormCreate({
                          key: 'staff',
                          value: staffDetail.id,
                        }),
                      );
                    }}
                  >
                    Add Break
                  </Button>

                  <BreaksWrapper>
                    {breaks.map((staffBreak, index) => {
                      return staffBreak.id === staffBreakFormUpdate.id ? (
                        <Break key={index}>
                          <div style={{ flex: 1 }}>
                            <BreakTime>
                              <TimePicker
                                includeLastStep
                                style={{ marginRight: 8 }}
                                businessHour={businessHourUpdate}
                                value={moment(staffBreakFormUpdate.start_time, 'HH:mm')}
                                onChange={(value) => {
                                  dispatch(
                                    updateStaffBreakFormUpdate({
                                      key: 'start_time',
                                      value: value.format('HH:mm'),
                                    }),
                                  );
                                }}
                              />
                              -
                              <TimePicker
                                includeLastStep
                                style={{ marginLeft: 8 }}
                                businessHour={businessHourUpdate}
                                value={moment(staffBreakFormUpdate.end_time, 'HH:mm')}
                                onChange={(value) => {
                                  dispatch(
                                    updateStaffBreakFormUpdate({
                                      key: 'end_time',
                                      value: value.format('HH:mm'),
                                    }),
                                  );
                                }}
                              />
                            </BreakTime>

                            {staffBreakFormUpdateError && (
                              <FormHelperText error>{staffBreakFormUpdateError}</FormHelperText>
                            )}
                          </div>

                          <div style={{ flex: 1 }}>
                            <IconButton
                              size='small'
                              sx={{ ml: 1.5 }}
                              onClick={() => {
                                dispatch(updateBreakRequest());
                              }}
                            >
                              <Check />
                            </IconButton>

                            <IconButton
                              size='small'
                              onClick={() => {
                                dispatch(clearStaffBreakFormUpdate());
                              }}
                            >
                              <Close />
                            </IconButton>
                          </div>
                        </Break>
                      ) : (
                        <Break key={index}>
                          <BreakTime>
                            <BreakStartTimeFont
                              onDoubleClick={() => {
                                dispatch(setStaffBreakFormUpdate(staffBreak));
                              }}
                            >
                              {moment(staffBreak.start_time, 'HH:mm').format('HH:mm')}
                            </BreakStartTimeFont>
                            -
                            <BreakEndTimeFont
                              onDoubleClick={() => {
                                dispatch(setStaffBreakFormUpdate(staffBreak));
                              }}
                            >
                              {moment(staffBreak.end_time, 'HH:mm').format('HH:mm')}
                            </BreakEndTimeFont>
                          </BreakTime>

                          <IconButton
                            size='small'
                            color='error'
                            sx={{ ml: 1.5 }}
                            onClick={() => {
                              dispatch(deleteBreakRequest(staffBreak.id));
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Break>
                      );
                    })}
                  </BreaksWrapper>
                </BreaksSection>
              </DaySection>
            );
          })}
        </SectionContent>

        <MobileHiddenDummyElement />
      </Section>

      <Modal
        title='Add break'
        open={!!staffBreakFormCreate.staff}
        onClose={() => {
          dispatch(clearStaffBreakFormCreate());
          dispatch(setStaffBreakFormCreateError(''));
        }}
        onSubmit={() => {
          dispatch(createBreakRequest());
        }}
        submitText='Save'
        loading={staffBreakFormLoading}
      >
        <AddBreakModalSection>
          <TimerPickerWrapper>
            <Font type='h5'>Start time</Font>

            <TimePicker
              includeLastStep
              style={{
                marginRight: 16,
                width: 100,
              }}
              value={
                staffBreakFormCreate.start_time
                  ? moment(staffBreakFormCreate.start_time, 'HH:mm')
                  : undefined
              }
              businessHour={businessHourCreate}
              onChange={(value) => {
                dispatch(
                  updateStaffBreakFormCreate({
                    key: 'start_time',
                    value: value.format('HH:mm'),
                  }),
                );
              }}
            />
          </TimerPickerWrapper>

          <TimerPickerWrapper>
            <div style={{ opacity: 0 }}>-</div>
            <div>-</div>
          </TimerPickerWrapper>

          <TimerPickerWrapper
            style={{
              marginLeft: 16,
            }}
          >
            <Font type='h5'>End time</Font>

            <TimePicker
              style={{ width: 100 }}
              includeLastStep
              value={
                staffBreakFormCreate.end_time
                  ? moment(staffBreakFormCreate.end_time, 'HH:mm')
                  : undefined
              }
              businessHour={businessHourCreate}
              onChange={(value) => {
                dispatch(
                  updateStaffBreakFormCreate({
                    key: 'end_time',
                    value: value.format('HH:mm'),
                  }),
                );
              }}
            />
          </TimerPickerWrapper>
        </AddBreakModalSection>

        {staffBreakFormCreateError && (
          <Alert
            severity='error'
            sx={{
              mt: 2,
            }}
          >
            {staffBreakFormCreateError}
          </Alert>
        )}
      </Modal>

      <Modal
        title='Invite User'
        open={userInviteModalShow}
        onClose={() => dispatch(setUserInviteModalShow(false))}
      >
        <div>
          <InviteStaffIcon>
            <PersonAdd
              sx={{
                mb: 1,
              }}
              fontSize='large'
            />
          </InviteStaffIcon>

          <Font
            type='h3'
            style={{
              textAlign: 'center',
              display: 'block',
              marginBottom: 16,
            }}
          >
            Invite a user and allow them to use the platform
          </Font>
        </div>

        <TextField
          type='email'
          fullWidth
          sx={{ mb: 4 }}
          size='small'
          autoComplete='on'
          error={!!userInviteError}
          helperText={userInviteError}
          onChange={(event) => {
            dispatch(setUserInvite(event.currentTarget.value));
          }}
        />

        <LoadingButton
          variant='contained'
          fullWidth
          loading={staffDetailEditLoading}
          onClick={() => {
            dispatch(userInviteRequest(staffDetail?.id));
          }}
        >
          Invite User
        </LoadingButton>
      </Modal>

      <Modal
        danger
        open={!!staffDetailDelete.id}
        title={'Delete staff'}
        submitText='Delete'
        loading={staffDetailDeleteLoading}
        onClose={() => dispatch(clearStaffDetailDelete())}
        onSubmit={() => {
          dispatch(staffDetailDeleteRequest());
        }}
      >
        <DeleteSection>
          <Alert sx={{ mb: 3 }} severity='warning'>
            <AlertTitle>Warning</AlertTitle>
            Deleting {staffDetailDelete.name} will also <strong>delete all the appointments</strong>
            &nbsp; which are assign to this staff!
          </Alert>

          <Font>
            Are you sure you want to delete <strong> {staffDetailDelete.name}</strong> staff?
          </Font>
        </DeleteSection>
      </Modal>
    </Container>
  );
}

export default StaffDetail;
