import {
  Avatar,
  Badge,
  Box,
  Button,
  buttonClasses,
  Divider,
  IconButton,
  paperClasses,
  Stack,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  EventOutlined,
  EmojiPeople,
  AutoAwesomeMosaicOutlined,
  WorkOutline,
  GroupOutlined,
  Business,
  Close,
  LogoutOutlined,
  Notifications,
  HubOutlined,
} from '@mui/icons-material';
import Font from 'elements/Font/Font';
import { usePrivateLayout } from 'contexts';
import WithPermission from 'hoc/WithPermission';

// redux
import { RootState } from 'store/config';
import { setIsOpenDrawer } from 'store/ui';
import { authLogoutRequest } from 'store/auth';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Svg } from 'assets/svg/organization.svg';
import Logo from 'elements/Logo/Logo';
import { useRef } from 'react';

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  .MuiPaper-root {
    > div {
      border-right: none;
    }
  }

  .${paperClasses.root} {
    position: relative;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .${paperClasses.root} {
      width: 100% !important;
    }
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    .${paperClasses.root} {
      width: 340px;
    }
  }
`;

const Container = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-right: 1px solid ${({ theme }) => theme.palette.grey[300]};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
    height: 100%;
  }
`;

const CompanyWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
  }
`;

const CompanyDetails = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 8px 10px;
`;

const CompanyIcon = styled.div``;

const CompanyName = styled.div``;

const MenuWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0 1rem;
  }
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 0;
`;

const MenuListTitle = styled(Font)`
  padding-left: 1rem;
  margin: 6px 0;
  color: #42526e;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
`;

const OrganizationSvg = styled(Svg)`
  height: 28px;
  width: 28px;
  margin-right: 16px;
`;

const StyledButton = styled(Button)`
  padding: 6px 16px !important;
  justify-content: flex-start !important;
  align-items: center;
  position: relative;
  padding: 8px 10px 8px 17px !important;

  &.${buttonClasses.colorInherit} {
    &:hover {
      background: #e6effc;
    }
  }

  &.${buttonClasses.containedPrimary} {
    background: #e6effc;
    color: ${({ theme }) => theme.palette.primary.main};

    &:before {
      content: ' ';
      position: absolute;
      height: 16px;
      width: 4px;
      background-color: ${({ theme }) => theme.palette.primary.main};
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      border-radius: 0px 1.998px 1.998px 0px;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ShowOnlyLargeScreen = styled.div`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: none;
  }
`;

const NotificationsPlaceholder = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
`;

function Drawer() {
  const ref = useRef(null);
  const { company } = useSelector((state: RootState) => state.organization);
  const user = useSelector((state: RootState) => state.auth.user);
  const notifications = useSelector((state: RootState) => state.ui.notifications);
  const isOpenDrawer = useSelector((state: RootState) => state.ui.isOpenDrawer);
  const { setNotificationAnchor } = usePrivateLayout();
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = history.location.pathname;

  const navigateHandler = (path: string) => {
    history.push(path);
    dispatch(setIsOpenDrawer(false));
  };

  return (
    <>
      <NotificationsPlaceholder ref={ref} />

      <StyledSwipeableDrawer
        variant={lgScreen ? 'temporary' : 'permanent'}
        open={isOpenDrawer}
        onClose={() => dispatch(setIsOpenDrawer(false))}
        onOpen={() => dispatch(setIsOpenDrawer(true))}
      >
        <Container>
          <ShowOnlyLargeScreen>
            <Stack
              direction='row'
              justifyContent='space-between'
              height={65}
              p={'0 1rem'}
              alignItems='center'
            >
              <Logo
                link='/dashboard'
                size='large'
                onClick={() => dispatch(setIsOpenDrawer(false))}
              />

              <IconButton onClick={() => dispatch(setIsOpenDrawer(false))}>
                <Close />
              </IconButton>
            </Stack>

            <Divider />
          </ShowOnlyLargeScreen>

          <ShowOnlyLargeScreen>
            <Stack p={2} direction='row' justifyContent='space-between'>
              <Stack direction='row' alignItems='center' spacing={2} p={1}>
                <Avatar sx={{ width: 45, height: 45 }} src={user.image || ''} />

                <Stack p={1}>
                  <Font type='h4'>{user.name}</Font>
                  <Font>{user.email}</Font>
                </Stack>
              </Stack>

              <Box>
                <IconButton
                  onClick={() => {
                    // we set currentTarget the ref of the placeholder div
                    // because if we set this event for notifications when
                    // we close the drawer notifications popper will move
                    dispatch(setIsOpenDrawer(false));

                    const customEvent: any = { currentTarget: ref.current };
                    setNotificationAnchor(customEvent);
                  }}
                >
                  <Badge
                    badgeContent={
                      notifications.filter((notification) => !notification.is_read).length
                    }
                    color='error'
                  >
                    <Notifications color='inherit' />
                  </Badge>
                </IconButton>
              </Box>
            </Stack>

            <Divider sx={{ m: '0 1rem 1rem 1rem' }} />
          </ShowOnlyLargeScreen>

          <CompanyWrapper>
            <CompanyDetails>
              <CompanyIcon>
                <OrganizationSvg />
              </CompanyIcon>

              <CompanyName>
                <Font type='h5' style={{ fontWeight: 600, lineHeight: 'unset', margin: 0 }}>
                  {company.name}
                </Font>

                <Font style={{ color: '#6B778C', fontSize: 12, marginTop: -8 }}>
                  Company's name
                </Font>
              </CompanyName>
            </CompanyDetails>
          </CompanyWrapper>

          <MenuWrapper>
            <MenuList>
              <MenuListTitle>Schedule</MenuListTitle>

              <StyledButton
                variant={pathname.includes('/dashboard') ? 'contained' : 'text'}
                color={pathname.includes('/dashboard') ? 'primary' : 'inherit'}
                startIcon={
                  <IconWrapper>
                    <AutoAwesomeMosaicOutlined sx={{ fontSize: 25 }} />
                  </IconWrapper>
                }
                onClick={() => {
                  navigateHandler('/dashboard');
                }}
              >
                Dashboard
              </StyledButton>

              <StyledButton
                variant={pathname === '/calendar' ? 'contained' : 'text'}
                color={pathname === '/calendar' ? 'primary' : 'inherit'}
                startIcon={
                  <IconWrapper>
                    <EventOutlined sx={{ fontSize: 25 }} />
                  </IconWrapper>
                }
                onClick={() => {
                  navigateHandler('/calendar');
                }}
              >
                Calendar
              </StyledButton>

              <WithPermission permission='company_receptionist'>
                <StyledButton
                  variant={pathname.includes('/clients') ? 'contained' : 'text'}
                  color={pathname.includes('/clients') ? 'primary' : 'inherit'}
                  startIcon={
                    <IconWrapper>
                      <EmojiPeople sx={{ fontSize: 25 }} />
                    </IconWrapper>
                  }
                  onClick={() => {
                    navigateHandler('/clients');
                  }}
                >
                  Clients
                </StyledButton>
              </WithPermission>
            </MenuList>

            <MenuList>
              <MenuListTitle>Workspaces</MenuListTitle>

              <StyledButton
                variant={pathname.includes('/integrations') ? 'contained' : 'text'}
                color={pathname.includes('/integrations') ? 'primary' : 'inherit'}
                startIcon={
                  <IconWrapper>
                    <HubOutlined sx={{ fontSize: 25 }} />
                  </IconWrapper>
                }
                onClick={() => {
                  navigateHandler('/integrations');
                }}
              >
                Integrations
              </StyledButton>
            </MenuList>

            <WithPermission permission='company_admin'>
              <MenuList>
                <MenuListTitle>Settings</MenuListTitle>

                <StyledButton
                  variant={pathname.includes('/company') ? 'contained' : 'text'}
                  color={pathname.includes('/company') ? 'primary' : 'inherit'}
                  startIcon={
                    <IconWrapper>
                      <Business sx={{ fontSize: 25 }} />
                    </IconWrapper>
                  }
                  onClick={() => {
                    navigateHandler('/company');
                  }}
                >
                  Company
                </StyledButton>

                <StyledButton
                  variant={pathname.includes('/services') ? 'contained' : 'text'}
                  color={pathname.includes('/services') ? 'primary' : 'inherit'}
                  startIcon={
                    <IconWrapper>
                      <WorkOutline sx={{ fontSize: 25 }} />
                    </IconWrapper>
                  }
                  onClick={() => {
                    navigateHandler('/services');
                  }}
                >
                  Services
                </StyledButton>

                <StyledButton
                  variant={pathname.includes('/staff') ? 'contained' : 'text'}
                  color={pathname.includes('/staff') ? 'primary' : 'inherit'}
                  startIcon={
                    <IconWrapper>
                      <GroupOutlined sx={{ fontSize: 25 }} />
                    </IconWrapper>
                  }
                  onClick={() => {
                    navigateHandler('/staff');
                  }}
                >
                  Staff
                </StyledButton>
              </MenuList>
            </WithPermission>
          </MenuWrapper>

          <ShowOnlyLargeScreen>
            <Divider sx={{ m: 2 }} />

            <Box m={'0 1rem 1rem 1rem'}>
              <StyledButton
                fullWidth
                color='inherit'
                startIcon={
                  <IconWrapper>
                    <LogoutOutlined sx={{ fontSize: 25 }} />
                  </IconWrapper>
                }
                onClick={() => {
                  dispatch(authLogoutRequest());
                }}
              >
                Logout
              </StyledButton>
            </Box>
          </ShowOnlyLargeScreen>
        </Container>
      </StyledSwipeableDrawer>
    </>
  );
}

export default Drawer;
