import { CSSProperties } from 'react';

export const MuiCardRoot: CSSProperties = {
  borderColor: '#EEEEEE',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'none',
  border: '1px solid #DEE3EA',
};

export const MuiCardHeaderRoot: CSSProperties = {
  borderBottom: `1px solid #DEE3EA`,
};

export const MuiCardHeaderTitle: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
};

export const MuiCardContentRoot: CSSProperties = {
  fontSize: 14,
  flex: 1,
};

export const MuiCardActionsRoot: CSSProperties = {};
