import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN } from 'config';

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: `${DOMAIN.API}/company`,
  }),
  endpoints: (builder) => ({
    companyVerify: builder.mutation({
      query: (token) => {
        return {
          method: 'post',
          url: 'verify/',
          body: { token },
        };
      },
    }),
  }),
});

export const { useCompanyVerifyMutation } = companyApi;
