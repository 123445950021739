import BaseRpc from './BaseRpc';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  BookingType,
  StaffByServiceParams,
  StaffBreaksParams,
  StaffBreakFormCreate,
  StaffBreak,
  Permission,
  StaffFormUpdate,
  WorkingHoursByStaffParams,
  WorkingHour,
  WorkingHourFormCreate,
  Staff,
  StaffFormCreate,
} from 'model';
import { base64ToFile, formDataGenerator, generateString } from 'utils';

axios.defaults.withCredentials = true; // this is required to be able receive the cookies tokens
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

export class CompanyModuleRpc extends BaseRpc {
  private getImageName() {
    return `${generateString()}.jpg`;
  }

  public async getStaff(config: AxiosRequestConfig = {}): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/staff/detail`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getStaffDetail(
    id: number,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/staff/detail/${id}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateStaffDetail(
    staff: Staff,
    staffFormUpdate: StaffFormUpdate,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/staff/detail/${staff.id}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const form: Partial<StaffFormUpdate> = { ...staffFormUpdate };

      if (form.image) delete form.image;

      const formData = formDataGenerator(form);

      const response = await axios.put<any>(url, formData, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateStaffDetailPartial(
    staff: Staff,
    staffFormUpdatePartial: Partial<StaffFormUpdate>,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/staff/detail/${staff.id}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const image = staffFormUpdatePartial.image
        ? await base64ToFile(staffFormUpdatePartial.image, this.getImageName())
        : null;
      const formData = formDataGenerator({ ...staffFormUpdatePartial, image });

      const response = await axios.patch<any>(url, formData, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async deleteStaffDetail(
    id: number,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/staff/detail/${id}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.delete<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async createStaffDetail(
    staffCreateForm: StaffFormCreate,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/staff/detail/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const image = staffCreateForm.image
        ? await base64ToFile(staffCreateForm.image, `staff-${new Date().getTime()}.jpg`)
        : null;
      const formData = formDataGenerator({ ...staffCreateForm, image });

      const response = await axios.post(url, formData, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getStaffByService(
    params: StaffByServiceParams,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/staff/detail/service`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    if (params) {
      config.params = params;
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getStaffUserPermissions(
    staffId: number,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/staff/permissions/${staffId}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async addStaffUserPermission(
    staffUserId: number,
    permission: Permission,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/staff/permissions/${staffUserId}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.put<any>(url, permission, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async removeStaffUserPermission(
    staffId: number,
    permission: Array<string>,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/staff/permissions/${staffId}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };

      config.data = { ...permission };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.delete<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getStaffBreaks(params: StaffBreaksParams, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/staff/break/staff`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    if (params) {
      config.params = params;
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async createStaffBreak(staffBreak: StaffBreakFormCreate, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/staff/break/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.post<any>(url, { ...staffBreak }, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async deleteStaffBreak(staffBreakId: number, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/staff/break/${staffBreakId}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.delete<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateStaffBreak(staffBreak: StaffBreak, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/staff/break/${staffBreak.id}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.put<any>(url, { ...staffBreak }, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async geWorkingHoursByStaff(
    params: WorkingHoursByStaffParams,
    config: AxiosRequestConfig = {},
  ) {
    const url = `${this.baseUrl}/staff/working-hour/staff`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    if (params) {
      config.params = params;
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateWorkingHour(workingHour: WorkingHour, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/staff/working-hour/${workingHour.id}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.put<any>(url, workingHour, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async createWorkingHour(
    workingHour: WorkingHourFormCreate,
    config: AxiosRequestConfig = {},
  ) {
    const url = `${this.baseUrl}/staff/working-hour/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.post<any>(url, workingHour, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async deleteWorkingHour(workingHour: WorkingHour, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/staff/working-hour/${workingHour.id}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.delete<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }
}

export default CompanyModuleRpc;
