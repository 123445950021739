export const fieldIsEmpty = (value: string | number | undefined) => {
  if (!value) {
    return true;
  }

  if (typeof value === 'number') {
    return !!value;
  }

  return (value || '').length < 1;
};

interface FormData {
  [key: string]: string;
}

export const formErrorIsEmpty = (obj: FormData) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return true;
    }
  }
  return false;
};

export const confirmPasswordIsValid = (
  confirmPassword: string | number,
  Password: string | number,
) => {
  if (Password === confirmPassword) {
    return true;
  }

  return false;
};

export function emailIsValid(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

interface Form {
  [key: string]: any;
}

export const formDataGenerator = (form: Form = {}) => {
  const formData = new FormData();

  Object.entries(form).forEach(([key, value]: [string, any]) => {
    if (Array.isArray(value)) {
      // Check if value is array and loop to assign each element
      for (const val of value) formData.append(key, val);
    } else if (typeof value === 'boolean') {
      // Validate if value is boolean
      formData.append(key, JSON.stringify(value));
    } else if (typeof value === 'object' && !!value && !(value instanceof File)) {
      // Check if value is object and stringify it
      formData.append(key, JSON.stringify(value));
    } else if (value) {
      // all the rest type data are assigned here
      formData.append(key, value);
    } else {
      formData.append(key, '');
    }
  });

  return formData;
};
