import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import {
  BookingCreateType,
  BookingType,
  BusinessHour,
  Client,
  ClientFormCreate,
  Company,
  FieldsErrors,
  FormFieldPayload,
  Service,
  Staff,
  WorkingHour,
} from 'model';
import { RootState } from 'store/config';
import moment from 'moment-timezone';

interface StepsLabelOptional {
  [key: string]: string;
}

interface SliceState {
  isLoading: boolean;
  bookingForm: BookingCreateType;
  company: Company;
  companyLoading: boolean;
  companyError: string;
  businessHours: Array<BusinessHour>;
  staffWorkingHours: Array<WorkingHour>;
  step: number;
  stepsLabelOptional: StepsLabelOptional;
  services: Array<Service>;
  servicesAreFetched: boolean;
  staff: Array<Staff>;
  staffAreFetched: boolean;
  availability: Array<string>;
  availabilityError: string;
  availabilityAreFetched: boolean;
  date: string;
  client: Client;
  clientForm: ClientFormCreate;
  clientFormError: FieldsErrors;
  confirmBookingIsLoading: boolean;
  confirmBookingIsDone: boolean;
  error: string;
  booking: BookingType;
}

const initialState: SliceState = {
  step: 0,
  stepsLabelOptional: {},
  isLoading: true,
  bookingForm: {
    client: 0,
    service: 0,
    staff: 0,
    company: '',
    start_time: '',
  },
  company: {
    uuid: '',
    created: '',
    name: '',
    updated: '',
    url_component: '',
    timezone: '',
    image: '',
    is_verified: false,
    verified_at: '',
  },
  companyLoading: false,
  companyError: '',
  businessHours: [],
  staffWorkingHours: [],
  services: [],
  servicesAreFetched: false,
  staff: [],
  staffAreFetched: false,
  date: moment().format(),
  availability: [],
  availabilityError: '',
  availabilityAreFetched: false,
  client: {
    company: '',
    id: 0,
    name: '',
    email: '',
    phone: null,
  },
  clientForm: {
    company: '',
    name: '',
    email: '',
    phone: null,
  },
  clientFormError: {},
  confirmBookingIsLoading: false,
  confirmBookingIsDone: false,
  error: '',
  booking: {
    id: 0,
    uuid: '',
    service: {
      company: '',
      created: '',
      description: '',
      duration: '',
      id: 0,
      name: '',
      staff: [],
      updated: '',
      image: '',
      is_meeting: false,
      is_active: true,
    },
    client: {
      company: '',
      id: 0,
      name: '',
      email: '',
      phone: null,
    },
    start_time: '',
    end_time: '',
    created: '',
    updated: '',
    staff: {
      company: '',
      created: '',
      description: '',
      id: 0,
      name: '',
      updated: '',
      user: null,
      image: '',
    },
    company: '',
    is_canceled: false,
  },
};

const BookingPageSlice = createSlice({
  initialState,
  name: 'bookingPage',
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        step: action.payload,
      };
    },
    setStepsLabelOptional: (
      state,
      action: PayloadAction<FormFieldPayload<'Service' | 'Provider' | 'Date' | 'Confirm'>>,
    ) => {
      return {
        ...state,
        stepsLabelOptional: {
          ...state.stepsLabelOptional,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    updateBookingForm: (
      state,
      action: PayloadAction<FormFieldPayload<keyof BookingCreateType>>,
    ) => {
      return {
        ...state,
        bookingForm: {
          ...state.bookingForm,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearBookingForm: (state) => {
      return {
        ...state,
        bookingForm: {
          client: 0,
          service: 0,
          staff: 0,
          company: '',
          start_time: moment().format(),
        },
      };
    },
    setCompany: (state, action: PayloadAction<Company>) => {
      return {
        ...state,
        company: action.payload,
      };
    },
    setCompanyLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        companyLoading: action.payload,
      };
    },
    setCompanyError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        companyError: action.payload,
      };
    },
    setBusinessHours: (state, action: PayloadAction<Array<BusinessHour>>) => {
      return {
        ...state,
        businessHours: action.payload,
      };
    },
    setStaffWorkingHours: (state, action: PayloadAction<Array<WorkingHour>>) => {
      state.staffWorkingHours = action.payload;
    },
    setServices: (state, action: PayloadAction<Array<Service>>) => {
      return {
        ...state,
        services: action.payload,
      };
    },
    setServicesAreFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        servicesAreFetched: action.payload,
      };
    },
    setStaff: (state, action: PayloadAction<Array<Staff>>) => {
      return {
        ...state,
        staff: action.payload,
      };
    },
    setStaffAreFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        staffAreFetched: action.payload,
      };
    },
    setDate: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        date: action.payload,
      };
    },
    setAvailability: (state, action: PayloadAction<Array<string>>) => {
      return {
        ...state,
        availability: action.payload,
      };
    },
    setAvailabilityError: (state, action: PayloadAction<string>) => {
      state.availabilityError = action.payload;
    },
    setAvailabilityAreFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        availabilityAreFetched: action.payload,
      };
    },
    setClient: (state, action: PayloadAction<Client>) => {
      return {
        ...state,
        client: action.payload,
      };
    },
    updateClientForm: (state, action: PayloadAction<FormFieldPayload<keyof ClientFormCreate>>) => {
      return {
        ...state,
        clientForm: {
          ...state.clientForm,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearClientForm: (state) => {
      return {
        ...state,
        clientForm: {
          company: '',
          name: '',
          email: '',
          phone: null,
        },
      };
    },
    setClientFormError: (state, action: PayloadAction<FieldsErrors>) => {
      state.clientFormError = action.payload;
    },
    updateClientFormError: (state, action: PayloadAction<FieldsErrors>) => {
      state.clientFormError = { ...state.clientFormError, ...action.payload };
    },
    clearClientFormError: (state) => {
      state.clientFormError = {};
    },
    setConfirmBookingIsDone: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        confirmBookingIsDone: action.payload,
      };
    },
    setError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    setBooking: (state, action: PayloadAction<BookingType>) => {
      return {
        ...state,
        booking: action.payload,
      };
    },
  },
});

export const {
  setStep,
  setStepsLabelOptional,
  setIsLoading,
  setCompany,
  setCompanyLoading,
  setCompanyError,
  setBusinessHours,
  setStaffWorkingHours,
  updateBookingForm,
  clearBookingForm,
  setServices,
  setServicesAreFetched,
  setStaff,
  setStaffAreFetched,
  setDate,
  setAvailability,
  setAvailabilityError,
  setAvailabilityAreFetched,
  setClient,
  updateClientForm,
  clearClientForm,
  setClientFormError,
  updateClientFormError,
  clearClientFormError,
  setConfirmBookingIsDone,
  setError,
  setBooking,
} = BookingPageSlice.actions;

export enum BookingPageActionTypes {
  COMPANY_DETAIL_REQUEST = 'bookingPage/COMPANY_DETAIL_REQUEST',
  SERVICES_REQUEST = 'bookingPage/SERVICES_REQUEST',
  STAFF_REQUEST = 'bookingPage/STAFF_REQUEST',
  AVAILABILITY_REQUEST = 'bookingPage/AVAILABILITY_REQUEST',
  CONFIRM_BOOKING_REQUEST = 'bookingPage/CONFIRM_BOOKING_REQUEST',
  CLIENT_REGISTER_REQUEST = 'bookingPage/CLIENT_REGISTER_REQUEST',
  BUSINESS_HOURS_REQUEST = 'bookingPage/BUSINESS_HOURS_REQUEST',
  STAFF_WORKING_HOURS_REQUEST = 'bookingPage/STAFF_WORKING_HOURS_REQUEST',
}

export const companyDetailRequest = (companyUrl: string) => {
  return {
    type: BookingPageActionTypes.COMPANY_DETAIL_REQUEST,
    payload: companyUrl,
  };
};

export const businessHoursRequest = (companyUrl: string) => {
  return {
    type: BookingPageActionTypes.BUSINESS_HOURS_REQUEST,
    payload: companyUrl,
  };
};

export const staffWorkingHoursRequest = () => ({
  type: BookingPageActionTypes.STAFF_WORKING_HOURS_REQUEST,
});

export const servicesRequest = () => {
  return {
    type: BookingPageActionTypes.SERVICES_REQUEST,
  };
};

export const staffRequest = () => {
  return {
    type: BookingPageActionTypes.STAFF_REQUEST,
  };
};

export const availabilityRequest = () => {
  return {
    type: BookingPageActionTypes.AVAILABILITY_REQUEST,
  };
};

export const confirmBookingRequest = () => {
  return {
    type: BookingPageActionTypes.CONFIRM_BOOKING_REQUEST,
  };
};

export const clientCreateRequest = () => {
  return {
    type: BookingPageActionTypes.CLIENT_REGISTER_REQUEST,
  };
};

export const selectBookingPage = (state: RootState) => {
  return state.bookingPage;
};

export const selectCompany = createSelector([selectBookingPage], (value) => value.company);

export const selectDate = createSelector([selectBookingPage], (value) => value.date);

export const selectBookingForm = createSelector([selectBookingPage], (value) => value.bookingForm);

export const selectClientForm = createSelector([selectBookingPage], (value) => value.clientForm);

export const selectClientFormError = createSelector(
  [selectBookingPage],
  (value) => value.clientFormError,
);

export const selectClient = createSelector([selectBookingPage], (value) => value.client);

export const bookingPageReducer = BookingPageSlice.reducer;
