import { useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Divider, TextField, Link, Alert } from '@mui/material';
import { Logo } from 'elements';
import styled from 'styled-components';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDocumentTitle, useWillUnmount } from 'hooks';

// redux
import { RootState } from 'store/config';
import { useDispatch, useSelector } from 'react-redux';
import {
  confirmResetPasswordRequest,
  updateConfirmResetPassword,
  clearConfirmResetPassword,
  clearConfirmResetPasswordError,
  setConfirmResetPasswordSuccess,
  setToken,
} from '..';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
`;

const LogoWrapper = styled.div`
  padding: 0 0 40px 20px;
`;

const Content = styled.div`
  margin-top: 56px;
  width: 540px;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 90%;
    min-width: unset;
  }
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 56px 48px;
  box-shadow: 0 14px 32px 0 rgb(22 45 61 / 8%), 0 1px 4px 0 rgb(22 45 61 / 10%);
  border-radius: 3px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-width: unset;
    padding: 40px 8px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 0 20px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin: 0 0 0 20px;
`;

const FooterText = styled.div`
  text-align: center;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.grey[600]};
`;

function ConfirmResetPassword() {
  const {
    confirmResetPassword,
    confirmResetPasswordLoading,
    confirmResetPasswordSuccess,
    confirmResetPasswordError,
  } = useSelector((state: RootState) => state.resetPassword);
  const dispatch = useDispatch();
  const history = useHistory();

  useDocumentTitle('Reset Password - Gotei');

  useEffect(() => {
    if (confirmResetPasswordSuccess) history.push('/');
  }, [history, confirmResetPasswordSuccess]);

  useWillUnmount(() => {
    dispatch(clearConfirmResetPassword());
    dispatch(clearConfirmResetPasswordError());
    dispatch(setConfirmResetPasswordSuccess(false));
    dispatch(setToken(''));
  });

  return (
    <Container>
      <Content>
        <LogoWrapper>
          <Logo link='/' />
        </LogoWrapper>

        <FormSection>
          <Title>Reset password</Title>

          <Form
            onSubmit={(event) => {
              event.preventDefault();

              dispatch(confirmResetPasswordRequest());
            }}
          >
            <TextField
              color='primary'
              variant='outlined'
              label='Password'
              type='password'
              sx={{ mb: 2 }}
              error={!!confirmResetPasswordError.password}
              helperText={confirmResetPasswordError.password}
              value={confirmResetPassword.password}
              onChange={(event) =>
                dispatch(
                  updateConfirmResetPassword({
                    key: 'password',
                    value: event.target.value,
                  }),
                )
              }
            />

            <TextField
              color='primary'
              variant='outlined'
              label='Confirm password'
              type='password'
              error={!!confirmResetPasswordError.confirmPassword}
              helperText={confirmResetPasswordError.confirmPassword}
              value={confirmResetPassword.confirmPassword}
              onChange={(event) =>
                dispatch(
                  updateConfirmResetPassword({
                    key: 'confirmPassword',
                    value: event.target.value,
                  }),
                )
              }
            />

            {confirmResetPasswordError.detail && (
              <Alert severity='error' sx={{ mt: 2 }}>
                {confirmResetPasswordError.detail}
              </Alert>
            )}

            <LoadingButton
              type='submit'
              variant='contained'
              size='large'
              sx={{ mt: 4 }}
              loading={confirmResetPasswordLoading}
            >
              Submit
            </LoadingButton>

            <Divider sx={{ m: '1rem 0' }} />

            <FooterText>
              Found the password?{' '}
              <Link component={RouterLink} to='/login' variant='body2'>
                Login
              </Link>
            </FooterText>
          </Form>
        </FormSection>
      </Content>
    </Container>
  );
}

export default ConfirmResetPassword;
