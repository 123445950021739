export const debounce = (callback: (...args: any[]) => void, delay = 250) => {
  let timeoutId: any;

  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      timeoutId = null;

      callback(...args);
    }, delay);
  };
};
