import { BrowserRouter, Route, Switch } from 'react-router-dom';

import {
  NotFound,
  LandingPageScreen,
  LoginScreen,
  GoogleLoginScreen,
  SignUpScreen,
  ResetPasswordScreen,
  DashboardScreen,
  CalendarScreen,
  StaffScreen,
  StaffDetailScreen,
  ClientsScreen,
  ClientScreen,
  CompanyScreen,
  ServiceScreen,
  ServicesScreen,
  BookingPageScreen,
  ManageScreen,
  IntegrationsScreen,
  CalendarConnectionScreen,
  GoogleMeetIntegrationScreen,
  CompanyVerifyScreen,
} from 'screens';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* PUBLIC URLS  */}
        <Route exact path='/' component={LandingPageScreen} />
        <Route exact path='/login' component={LoginScreen} />
        <Route exact path='/google-login' component={GoogleLoginScreen} />
        <Route exact path='/signup' component={SignUpScreen} />
        <Route exact path='/verify-company' component={CompanyVerifyScreen} />
        <Route exact path='/reset-password' component={ResetPasswordScreen} />
        <Route exact path='/booking/:companyUrl' component={BookingPageScreen} />
        <Route exact path='/booking/:company_uuid/manage/:uuid' component={ManageScreen} />

        {/* PRIVATE URLS  */}
        <Route exact path='/dashboard' component={DashboardScreen} />
        <Route exact path='/calendar' component={CalendarScreen} />
        <Route exact path='/clients' component={ClientsScreen} />
        <Route exact path='/clients/:id' component={ClientScreen} />
        <Route exact path='/integrations' component={IntegrationsScreen} />
        <Route
          exact
          path='/integrations/calendar-connections'
          component={CalendarConnectionScreen}
        />
        <Route exact path='/integrations/google-meet' component={GoogleMeetIntegrationScreen} />
        <Route exact path='/company' component={CompanyScreen} />
        <Route exact path='/services/:id' component={ServiceScreen} />
        <Route exact path='/services' component={ServicesScreen} />
        <Route exact path='/staff' component={StaffScreen} />
        <Route exact path='/staff/:id' component={StaffDetailScreen} />

        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
