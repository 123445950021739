import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { OrganizationActionTypes } from '.';
import {
  calendarModuleRpc,
  companyModuleRpc,
  clientsModuleRpc,
  staffModuleRpc,
} from 'services/api';
import { logger } from 'utils';
import {
  BusinessHour,
  BusinessHoursByCompanyParams,
  Client,
  Company,
  Permission,
  Service,
  Staff,
} from 'model';
import {
  setClients,
  setCompany,
  setServices,
  setStaff,
  setPermissions,
  setCompanyIsFetched,
  selectCompanyDetail,
  setBusinessHours,
} from '.';
import { RootState } from 'store/config';

function* getCompanyDetails() {
  try {
    logger('Organization Detail Request');

    const response: Company = yield call(companyModuleRpc.getCompany.bind(calendarModuleRpc));

    logger('Organization Detail Success');

    yield put(setCompany(response));
    yield put(setCompanyIsFetched(true));
  } catch (error) {
    logger.error('Organization Detail Failure');

    yield put(setCompanyIsFetched(true));
  }
}

function* getClients() {
  try {
    logger('Organization Clients Request');

    const response: Array<Client> = yield call(clientsModuleRpc.getClients.bind(clientsModuleRpc));

    yield put(setClients(response));

    logger('Organization Clients Success');
  } catch (error: any) {
    logger.error('Organization Clients Failure');
  }
}

function* getServices() {
  try {
    logger('Organization Services Request');

    const response: Array<Service> = yield call(
      companyModuleRpc.getServices.bind(calendarModuleRpc),
    );

    yield put(setServices(response));

    logger('Organization Services Success');
  } catch (error: any) {
    logger.error('Organization Services Failure');
  }
}

function* getStaff() {
  try {
    logger('Organization Staff Request');

    const response: Array<Staff> = yield call(staffModuleRpc.getStaff.bind(staffModuleRpc));

    yield put(setStaff(response));

    logger('Organization Staff Success');
  } catch (error: any) {
    logger.error('Organization Staff Failure');
  }
}

function* getPermissions() {
  try {
    logger('Organization Permission Request');

    const response: Array<Permission> = yield call(
      companyModuleRpc.getPermissions.bind(companyModuleRpc),
    );

    yield put(setPermissions(response));

    logger('Organization Permission Success');
  } catch (error: any) {
    logger.error('Organization Permission Failure');
  }
}

function* getBusinessHours() {
  const state: RootState = yield select();
  const companyDetail = selectCompanyDetail(state);

  if (!companyDetail.url_component) return;

  try {
    logger('Organization Business Hours Request');

    const params: BusinessHoursByCompanyParams = {
      url_component: companyDetail.url_component,
    };

    const response: Array<BusinessHour> = yield call(
      [companyModuleRpc, companyModuleRpc.getBusinessHoursByCompany],
      params,
    );

    logger('Organization Business Hours Success');

    yield put(setBusinessHours(response));
  } catch (error) {
    logger.error('Organization Business Hours Failure');
  }
}

function* getCompanyDetailsWatcher() {
  yield takeLatest(OrganizationActionTypes.COMPANY_REQUEST, getCompanyDetails);
}

function* getClientsWatcher() {
  yield takeLatest(OrganizationActionTypes.CLIENTS_REQUEST, getClients);
}

function* getServicesWatcher() {
  yield takeLatest(OrganizationActionTypes.SERVICES_REQUEST, getServices);
}

function* getStaffWatcher() {
  yield takeLatest(OrganizationActionTypes.STAFF_REQUEST, getStaff);
}

function* egtPermissionsWatcher() {
  yield takeLatest(OrganizationActionTypes.PERMISSIONS_REQUEST, getPermissions);
}

function* businessHoursWatcher() {
  yield takeLatest(OrganizationActionTypes.BUSINESS_HOURS_REQUEST, getBusinessHours);
}

export const organizationSagas = [
  fork(getCompanyDetailsWatcher),
  fork(getClientsWatcher),
  fork(getServicesWatcher),
  fork(getStaffWatcher),
  fork(egtPermissionsWatcher),
  fork(businessHoursWatcher),
];
