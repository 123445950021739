import BaseRpc from './BaseRpc';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  BookingType,
  Company,
  BusinessHoursByCompanyParams,
  BusinessHour,
  BusinessHourFormCreate,
  CompanyFormCreate,
  CompanyFormUpdate,
} from 'model';
import { base64ToFile, formDataGenerator, generateString } from 'utils';

axios.defaults.withCredentials = true; // this is required to be able receive the cookies tokens
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

export class CompanyModuleRpc extends BaseRpc {
  private getImageName() {
    return `${generateString()}.jpg`;
  }

  public async getCompany(config: AxiosRequestConfig = {}): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/company/detail/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async createCompany(
    companyFormCreate: CompanyFormCreate,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/company/create/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.post<any>(url, companyFormCreate, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateCompany(
    company: Company,
    companyFormUpdate: CompanyFormUpdate,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/company/detail/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const form: Partial<CompanyFormUpdate> = { ...companyFormUpdate };

      if (form.image) {
        delete form.image;
      }

      const formData = formDataGenerator(form);

      const response = await axios.put<any>(url, formData, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateCompanyPartial(
    company: Company,
    companyFormUpdatePartial: Partial<CompanyFormUpdate>,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/company/detail/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const image = companyFormUpdatePartial.image
        ? await base64ToFile(companyFormUpdatePartial.image, this.getImageName())
        : null;
      const formData = formDataGenerator({ ...companyFormUpdatePartial, image });

      const response = await axios.patch<any>(url, formData, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getServices(config: AxiosRequestConfig = {}): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/services/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async inviteUser(
    email: string,
    staffId: number,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/accounts/invite-user/${staffId}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.post<any>(url, { email }, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getPermissions(config: AxiosRequestConfig = {}): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/company/permissions/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getBusinessHoursByCompany(
    params: BusinessHoursByCompanyParams,
    config: AxiosRequestConfig = {},
  ) {
    const url = `${this.baseUrl}/company/business-hours/company`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    if (params) {
      config.params = params;
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateBusinessHour(businessHour: BusinessHour, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/company/business-hours/${businessHour.id}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.put<any>(url, businessHour, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async createBusinessHour(
    businessHour: BusinessHourFormCreate,
    config: AxiosRequestConfig = {},
  ) {
    const url = `${this.baseUrl}/company/business-hours/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.post<any>(url, businessHour, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async deleteBusinessHour(businessHour: BusinessHour, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/company/business-hours/${businessHour.id}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.delete<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getNotifications(config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/notification/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }
}

export default CompanyModuleRpc;
