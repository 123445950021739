import { useEffect } from 'react';

// dependencies
import { useQuery, useWillUnmount } from 'hooks';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Background } from 'elements';

// redux
import { RootState } from 'store/config';
import { useDispatch, useSelector } from 'react-redux';
import { setRegisterComplete, updateUserFormCreate } from '.';

// components
import SignUp from './components/SignUp';

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[50]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 3rem 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 3rem 0;
  }
`;

const SignUpComposition = () => {
  const { registerComplete } = useSelector((state: RootState) => state.signup);
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const email = query.get('email');

  useEffect(() => {
    if (registerComplete) history.push('/login');
  }, [history, registerComplete]);

  useEffect(() => {
    if (email) {
      dispatch(
        updateUserFormCreate({
          key: 'email',
          value: email,
        }),
      );

      history.push('/signup');
    }
    // eslint-disable-next-line
  }, []);

  useWillUnmount(() => dispatch(setRegisterComplete(false)));

  return (
    <Container>
      <Background />

      <SignUp />
    </Container>
  );
};

export default SignUpComposition;
