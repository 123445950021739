import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { FieldsErrors, FormFieldPayload, Staff, StaffFormCreate, WorkingHour } from 'model';
import { RootState } from 'store/config';

interface State {
  staff: Array<Staff>;
  filteredStaff: Staff[];
  staffLoading: boolean;
  staffAreFetched: boolean;
  searchText: string;
  staffDetailCreate: StaffFormCreate;
  staffDetailCreateLoading: boolean;
  staffDetailCreateError: FieldsErrors;
  staffDetailDelete: Staff;
  staffDetailDeleteLoading: boolean;
}

const initialState: State = {
  staff: [],
  filteredStaff: [],
  staffLoading: true,
  staffAreFetched: false,
  searchText: '',
  staffDetailCreate: {
    name: '',
    description: '',
    company: '',
    user: null,
    image: '',
  },
  staffDetailCreateError: {},
  staffDetailCreateLoading: false,
  staffDetailDelete: {
    id: 0,
    company: '',
    created: '',
    description: '',
    name: '',
    updated: '',
    user: null,
    image: '',
  },
  staffDetailDeleteLoading: false,
};

const StaffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    setStaff: (state, action: PayloadAction<Array<Staff>>) => {
      return {
        ...state,
        staff: action.payload,
      };
    },
    setFilteredStaff: (state, action: PayloadAction<Staff[]>) => {
      return {
        ...state,
        filteredStaff: action.payload,
      };
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        searchText: action.payload,
      };
    },
    setStaffLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        staffLoading: action.payload,
      };
    },
    setStaffAreFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        staffAreFetched: action.payload,
      };
    },
    updateStaffDetailCreate: (
      state,
      action: PayloadAction<FormFieldPayload<keyof StaffFormCreate>>,
    ) => {
      return {
        ...state,
        staffDetailCreate: {
          ...state.staffDetailCreate,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearStaffDetailCreate: (state) => {
      return {
        ...state,
        staffDetailCreate: {
          name: '',
          description: '',
          company: '',
          user: null,
          image: '',
        },
      };
    },
    setStaffDetailCreateError: (
      state,
      action: PayloadAction<FormFieldPayload<keyof Staff | 'server'>>,
    ) => {
      return {
        ...state,
        staffDetailCreateError: {
          ...state.staffDetailCreateError,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearStaffDetailCreateError: (state) => {
      return {
        ...state,
        staffDetailCreateError: {},
      };
    },
    setStaffDetailCreateLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        staffDetailCreateLoading: action.payload,
      };
    },
    setStaffDetailDelete: (state, action: PayloadAction<Staff>) => {
      return {
        ...state,
        staffDetailDelete: action.payload,
      };
    },
    clearStaffDetailDelete: (state) => {
      return {
        ...state,
        staffDetailDelete: {
          id: 0,
          company: '',
          created: '',
          description: '',
          name: '',
          updated: '',
          user: null,
          image: '',
        },
      };
    },
    setStaffDetailDeleteLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        staffDetailDeleteLoading: action.payload,
      };
    },
  },
});

export const {
  setStaff,
  setFilteredStaff,
  setStaffLoading,
  setStaffAreFetched,
  setSearchText,
  clearStaffDetailCreate,
  updateStaffDetailCreate,
  setStaffDetailCreateError,
  clearStaffDetailCreateError,
  setStaffDetailCreateLoading,
  setStaffDetailDelete,
  clearStaffDetailDelete,
  setStaffDetailDeleteLoading,
} = StaffSlice.actions;

export enum StaffActionTypes {
  STAFF_REQUEST = 'staff/STAFF_REQUEST',
  STAFF_DETAIL_DELETE_REQUEST = 'staff/STAFF_DETAIL_DELETE_REQUEST',
  STAFF_DETAIL_CREATE_REQUEST = 'staff/STAFF_DETAIL_CREATE_REQUEST',
  STAFF_WORKING_HOUR_CREATE_REQUEST = 'staffDetail/STAFF_WORKING_HOUR_CREATE_REQUEST',
  FILTER_STAFF = 'staff/FILTER_STAFF',
}

export const staffRequest = () => {
  return {
    type: StaffActionTypes.STAFF_REQUEST,
  };
};

export const staffDetailDeleteRequest = () => {
  return {
    type: StaffActionTypes.STAFF_DETAIL_DELETE_REQUEST,
  };
};

export const staffDetailCreateRequest = () => {
  return {
    type: StaffActionTypes.STAFF_DETAIL_CREATE_REQUEST,
  };
};

export const filterStaff = () => {
  return {
    type: StaffActionTypes.FILTER_STAFF,
  };
};

export const workingHoursCreateRequest = (staffDetail: Staff, workingHour: WorkingHour) => {
  return {
    type: StaffActionTypes.STAFF_WORKING_HOUR_CREATE_REQUEST,
    payload: { staffDetail, workingHour },
  };
};

export const selectStaff = (state: RootState) => {
  return state.staff;
};

export const selectStaffList = createSelector([selectStaff], (value) => value.staff);

export const selectStaffDetailDelete = createSelector(
  [selectStaff],
  (value) => value.staffDetailDelete,
);

export const selectStaffDetailCreate = createSelector(
  [selectStaff],
  (value) => value.staffDetailCreate,
);

export const selectSearchText = createSelector([selectStaff], (value) => value.searchText);

export const staffReducer = StaffSlice.reducer;
