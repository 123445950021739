export interface AvailabilityParams {
  date: string;
  service: number;
}

export enum ManageSteps {
  overview = 'overview',
  reschedule = 'reschedule',
  confirm = 'confirm',
  success = 'success',
  cancelled = 'cancelled',
}
