import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { FieldsErrors, FormFieldPayload, UserFormCreate } from 'model';
import { RootState } from 'store/config';

interface SliceState {
  loading: boolean;
  userFormCreate: UserFormCreate;
  userFormCreateError: FieldsErrors;
  registerComplete: boolean;
}

const initialState: SliceState = {
  loading: false,
  userFormCreate: {
    name: '',
    email: '',
    phone: null,
    password: '',
  },
  userFormCreateError: {},
  registerComplete: false,
};

const SignUpSlice = createSlice({
  initialState,
  name: 'signup',
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    updateUserFormCreate: (
      state,
      action: PayloadAction<FormFieldPayload<keyof UserFormCreate>>,
    ) => {
      return {
        ...state,
        userFormCreate: {
          ...state.userFormCreate,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearUserFormCreate: (state) => {
      return {
        ...state,
        userFormCreate: {
          name: '',
          email: '',
          phone: null,
          password: '',
        },
      };
    },
    setUserFormCreateError: (state, action: PayloadAction<FieldsErrors>) => {
      return {
        ...state,
        userFormCreateError: {
          ...action.payload,
        },
      };
    },
    cleanUserFormCreateError: (state) => {
      return {
        ...state,
        userFormCreateError: {},
      };
    },
    setRegisterComplete: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        registerComplete: action.payload,
      };
    },
  },
});

export const {
  setLoading,
  updateUserFormCreate,
  clearUserFormCreate,
  setUserFormCreateError,
  cleanUserFormCreateError,
  setRegisterComplete,
} = SignUpSlice.actions;

export enum SignUpActionTypes {
  SIGN_UP_FORM_CREATE_SUBMIT = 'signup/SIGN_UP_FORM_CREATE_SUBMIT',
}

export const signUpSubmit = () => {
  return {
    type: SignUpActionTypes.SIGN_UP_FORM_CREATE_SUBMIT,
  };
};

export const selectSignUp = (state: RootState) => {
  return state.signup;
};

export const selectSignUpFormCreate = createSelector(
  [selectSignUp],
  (value) => value.userFormCreate,
);

export const selectSignUpFormCreateError = createSelector(
  [selectSignUp],
  (value) => value.userFormCreateError,
);

export const signupReducer = SignUpSlice.reducer;
