import BaseRpc from './BaseRpc';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  AvailabilityParams,
  BookingCreateType,
  BookingType,
  BookingUpdateType,
  ClientFormCreate,
} from 'model';

export class PublicModuleRpc extends BaseRpc {
  public async getCompanyDetail(
    uuid: string,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/public/company/detail/${uuid}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getCompanyByURL(
    companyURL: string,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/public/company/${companyURL}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getBusinessHours(
    companyUrl: string,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/public/company/${companyUrl}/business-hours`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getStaffWorkingHours(staffId: number, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/public/staff/${staffId}/working-hours`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getServices(
    companyId: string,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/public/services/${companyId}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getStaffByService(
    serviceId: number,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/public/staff/${serviceId}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getAvailability(
    staffId: number,
    params: AvailabilityParams,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/public/availability/${staffId}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    if (params) {
      config.params = params;
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async createClient(
    clientForm: ClientFormCreate,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/public/client/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.post<any>(url, { ...clientForm }, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getBooking(
    uuid: string,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/public/events/${uuid}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateBooking(
    booking: BookingUpdateType,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/public/events/${booking.uuid}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.put<any>(url, booking, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async createBooking(
    booking: BookingCreateType,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/public/events/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.post<any>(url, booking, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async deleteBooking(
    uuid: string,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/public/events/${uuid}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.delete<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }
}

export default PublicModuleRpc;
