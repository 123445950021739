import { useEffect } from 'react';

import {
  IconButton,
  TextField,
  Avatar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Box,
  CircularProgress,
  Button,
  Tooltip,
  Alert,
  AlertTitle,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { Add, Delete } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { debounce, useAuthEffect } from 'hooks';
import { Font, Modal } from 'elements';

// redux
import { RootState } from 'store/config';
import { useDispatch, useSelector } from 'react-redux';
import {
  staffRequest,
  staffDetailDeleteRequest,
  staffDetailCreateRequest,
  setStaffDetailDelete,
  clearStaffDetailDelete,
  clearStaffDetailCreate,
  setSearchText,
  filterStaff,
  updateStaffDetailCreate,
} from '..';

// components
import StaffFormCreate from './StaffFormCreate';

import { ReactComponent as SVG } from 'assets/svg/staff.svg';

const Container = styled.div``;

const Form = styled.form``;

const TableToolbar = styled.div`
  margin: 0.5rem 0 0.5rem 0;
`;

const DeleteSection = styled.div``;

const SvgContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 2rem 0;
`;

const AddStaffSvg = styled(SVG)`
  width: 30%;
  height: auto;
  max-width: 220px;
`;

function Staff() {
  const {
    filteredStaff,
    staff,
    staffLoading,
    staffAreFetched,
    staffDetailCreate,
    staffDetailCreateLoading,
    staffDetailDelete,
    staffDetailDeleteLoading,
    searchText,
  } = useSelector((state: RootState) => state.staff);
  const { company } = useSelector((state: RootState) => state.organization);
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(filterStaff());
  }, [dispatch, searchText]);

  useAuthEffect(() => {
    if (!staffAreFetched) dispatch(staffRequest());
  }, [dispatch, filteredStaff]);

  const searchTextHandler = debounce((event) => {
    dispatch(setSearchText(event.target.value));
  }, 350);

  return (
    <Container>
      {staffLoading && !staffAreFetched ? (
        <CircularProgress sx={{ mt: 1 }} size={24} />
      ) : staffAreFetched && !staff.length ? (
        <SvgContainer>
          <AddStaffSvg />

          <Font style={{ marginTop: '2rem' }} type='h4'>
            You have not created any staff yet
          </Font>

          <Font>Create a new staff now.</Font>

          <Button
            sx={{ mt: 3 }}
            startIcon={<Add />}
            onClick={() => {
              dispatch(
                updateStaffDetailCreate({
                  key: 'company',
                  value: company.uuid,
                }),
              );
            }}
            variant='contained'
            color='primary'
          >
            Add staff
          </Button>
        </SvgContainer>
      ) : (
        <>
          <TableToolbar>
            <TextField
              sx={{ width: 250, backgroundColor: (theme) => theme.palette.grey[50] }}
              placeholder='Search...'
              variant='outlined'
              size='small'
              onChange={searchTextHandler}
            />

            {!!searchText && (
              <Button
                color='inherit'
                sx={{ ml: 1 }}
                onClick={() => {
                  dispatch(setSearchText(''));
                }}
              >
                Clear filters
              </Button>
            )}
          </TableToolbar>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredStaff.map((staffDetail) => (
                <TableRow
                  hover
                  key={staffDetail.id}
                  sx={{
                    '&:hover': {
                      ' & > td:last-child button': {
                        opacity: 1,
                        pointerEvents: 'all',
                      },
                    },
                  }}
                  onClick={() => history.push(`/staff/${staffDetail.id}`)}
                >
                  <TableCell>
                    <Stack direction='row' alignItems='center'>
                      <Avatar src={staffDetail.image} sx={{ width: 32, height: 32, mr: 1 }} />

                      {staffDetail.name}

                      {staffDetail.user?.email === user.email && (
                        <Typography variant='body2' sx={{ ml: 1, opacity: 0.5, fontSize: 12 }}>
                          (You)
                        </Typography>
                      )}
                    </Stack>
                  </TableCell>

                  <TableCell>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                      <Box>{staffDetail.description}</Box>

                      <Box>
                        {staffDetail.user?.is_company_admin ? null : (
                          <Tooltip title='Delete'>
                            <IconButton
                              sx={{ opacity: 0, pointerEvents: 'none' }}
                              onClick={(event) => {
                                event.stopPropagation();

                                dispatch(setStaffDetailDelete(staffDetail));
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}

      <Modal
        open={!!staffDetailCreate.company}
        title={'Create staff'}
        onClose={() => dispatch(clearStaffDetailCreate())}
        loading={staffDetailCreateLoading}
        onSubmit={() => {
          dispatch(staffDetailCreateRequest());
        }}
      >
        <Form
          onSubmit={(event) => {
            event.preventDefault();

            dispatch(staffDetailCreateRequest());
          }}
        >
          <StaffFormCreate />
        </Form>
      </Modal>

      <Modal
        danger
        open={!!staffDetailDelete.id}
        title={'Delete staff'}
        submitText='Delete'
        loading={staffDetailDeleteLoading}
        onClose={() => dispatch(clearStaffDetailDelete())}
        onSubmit={() => {
          dispatch(staffDetailDeleteRequest());
        }}
      >
        <DeleteSection>
          <Alert sx={{ mb: 3 }} severity='warning'>
            <AlertTitle>Warning</AlertTitle>
            Deleting {staffDetailDelete.name} will also <strong>delete all the appointments</strong>
            &nbsp; which are assign to this staff!
          </Alert>

          <Font>
            Are you sure you want to delete <strong> {staffDetailDelete.name}</strong> staff?
          </Font>
        </DeleteSection>
      </Modal>
    </Container>
  );
}

export default Staff;
