import {
  ClickAwayListener,
  Popper as MuiPopper,
  PopperPlacementType,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Modal from 'elements/Modal/Modal';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .modal-container {
      width: 94vw;
      left: 3vw;
      bottom: 3vw;
      border-radius: 13px;
    }

    .MuiCardContent-root {
      padding: 8px;
    }
  }
`;

const Content = styled.div`
  box-shadow: rgba(170, 180, 190, 0.3) 0px 4px 20px;
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.palette.grey[300]};
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const StyledPopper = styled(MuiPopper)`
  z-index: 10;

  & > div {
    max-height: calc(100vh - 100px);
    display: flex;
    border-radius: 4px;
  }
`;

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  placement?: PopperPlacementType;
  children: React.ReactNode;
  onClose: () => void;
}

function Popper({ children, open, anchorEl, placement = 'bottom-start', onClose }: Props) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (mobileScreen) {
    return (
      <StyledModal open={open} onClose={onClose}>
        {children}
      </StyledModal>
    );
  }

  return (
    <StyledPopper open={open} anchorEl={anchorEl} placement={placement}>
      <ClickAwayListener
        onClickAway={() => {
          if (onClose) onClose();
        }}
      >
        <Content>{children}</Content>
      </ClickAwayListener>
    </StyledPopper>
  );
}

export default Popper;
