import { useState } from 'react';

import styled from 'styled-components';
import { Button, TextField, Box } from '@mui/material';
import { Font } from 'elements';
import { emailIsValid } from 'utils';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';

import { ReactComponent as HeroCalendar } from 'assets/svg/hero-calendar.svg';

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 4rem 2rem 0 2rem;
  margin-bottom: 80px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 4rem 1rem 0 1rem;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  max-width: 1200px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const RightSection = styled.div`
  flex: 1.5;
  display: flex;
  align-items: center;
`;

const HeroTitle = styled(Font)`
  font-size: 67px;
  font-weight: 700;
  line-height: 1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 38px;
  }
`;

const HeroTitleHighlight = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Form = styled.form`
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: 1.5rem;
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  height: 56px;
  padding: 8px 32px;
  border-radius: 0 4px 4px 0;
`;

const BannerDescription = styled(Font)`
  font-size: 18px;
  margin-top: 45px;
`;

const HeroCalendarSvg = styled(HeroCalendar)`
  max-height: 550px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: auto;
    margin-top: 3rem;
  }
`;

function Hero() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  return (
    <Container>
      <Content>
        <LeftSection>
          <HeroTitle>
            Free scheduling <HeroTitleHighlight>software</HeroTitleHighlight>
          </HeroTitle>

          <BannerDescription>
            Gotei is free scheduling software that automates your business appointments and helps
            you grow.
          </BannerDescription>

          <Box mt={4} position='relative' maxWidth={550}>
            <Form
              onSubmit={(event) => {
                event.preventDefault();

                if (!emailIsValid(email)) {
                  setError(true);
                } else {
                  setError(false);

                  history.push({
                    pathname: '/signup',
                    search: qs.stringify({ email }),
                  });
                }
              }}
            >
              <TextField
                fullWidth
                value={email}
                type='email'
                placeholder='Enter your email'
                onChange={(event) => setEmail(event.currentTarget.value)}
                error={error}
                helperText={error ? 'Please enter a valid email address.' : ''}
              />

              <StyledButton
                size='large'
                variant='contained'
                type='submit'
                sx={{ height: 56, p: '8px 32px' }}
              >
                Get stared
              </StyledButton>
            </Form>
          </Box>
        </LeftSection>

        <RightSection>
          <HeroCalendarSvg />
        </RightSection>
      </Content>
    </Container>
  );
}

export default Hero;
