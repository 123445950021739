import { call, fork, select, put, takeLatest } from 'redux-saga/effects';

import { calendarModuleRpc, staffModuleRpc } from 'services/api';
import moment from 'moment-timezone';
import { logger } from 'utils';
import { RootState } from 'store/config';
import { BookingType, GetBookingParams, Staff } from 'model';
import {
  CalendarActionTypes,
  setBookings,
  selectCalendarDate,
  selectCalendarView,
  setStaff,
  selectStaffSelected,
  setBookingLoading,
  setBookingsAreFetched,
} from '.';

function* getBookings() {
  const state: RootState = yield select();
  const date = selectCalendarDate(state);
  const calendarView = selectCalendarView(state);
  const staffSelected = selectStaffSelected(state);

  try {
    logger('Bookings Request');

    const params: GetBookingParams = {
      date: moment(date).format('DD/MM/YYYY'),
      staff: staffSelected.map((staffDetail) => staffDetail.id).join(' '),
    };

    let response: Array<BookingType> = [];

    yield put(setBookingLoading(true));

    if (calendarView === 'day') {
      response = yield call(calendarModuleRpc.getDailyBookings.bind(calendarModuleRpc, params));
    } else if (calendarView === 'week') {
      response = yield call(calendarModuleRpc.getWeeklyBookings.bind(calendarModuleRpc, params));
    } else {
      response = yield call(calendarModuleRpc.getMonthlyBookings.bind(calendarModuleRpc, params));
    }
    logger('Bookings Request Success');

    yield put(setBookings(response));
    yield put(setBookingsAreFetched(true));
    yield put(setBookingLoading(false));
  } catch (error: any) {
    logger('Bookings Request Failure');
    yield put(setBookingLoading(false));
  }
}

function* getStaff() {
  try {
    logger('Calendar Staff Request');

    const response: Array<Staff> = yield call(staffModuleRpc.getStaff.bind(staffModuleRpc));

    yield put(setStaff(response));

    logger('Calendar Staff Success');
  } catch (error: any) {
    logger.error('Calendar Staff Failure');
  }
}

function* getBookingsWatcher() {
  yield takeLatest(CalendarActionTypes.BOOKINGS_REQUEST, getBookings);
}

function* staffWatcher() {
  yield takeLatest(CalendarActionTypes.STAFF_REQUEST, getStaff);
}

export const calendarSagas = [fork(getBookingsWatcher), fork(staffWatcher)];
