// dependencies
import styled from 'styled-components';
import { Font } from 'elements';
import { AccessTime, InsertInvitation, Person } from '@mui/icons-material';
import moment from 'moment-timezone';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { Divider } from '@mui/material';

import calendarSuccess from 'assets/images/calendar-success.png';
import { useDocumentTitle } from 'hooks';

const Container = styled.div`
  flex: 1;
  display: flex;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  text-align: center;
`;

const Service = styled.div`
  flex: 1;
  margin-left: 24px;
`;

const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const Img = styled.img``;

function RescheduleSuccess() {
  const { booking } = useSelector((state: RootState) => state.manage);

  useDocumentTitle('Reschedule Confirmed - Gotei');

  return (
    <Container>
      <Content>
        <Image>
          <Img src={calendarSuccess} />
        </Image>

        <Header>
          <Font
            type='h2'
            style={{
              display: 'block',
              marginBottom: 8,
            }}
          >
            Reschedule Confirmed
          </Font>

          <Font
            style={{
              color: 'rgba(26, 26, 26, 0.6)',
              display: 'block',
            }}
          >
            You are rescheduled with {booking.staff.name}.
          </Font>
        </Header>

        <Divider sx={{ m: 3 }} />

        <Service>
          <Font type='h4' style={{ marginBottom: 16, color: 'rgba(26, 26, 26, 0.6)' }}>
            Appointment Overview
          </Font>

          <ServiceItem>
            <Person sx={{ mr: 2 }} />

            <Font>{`${booking.client.name}`}</Font>
          </ServiceItem>

          <ServiceItem>
            <InsertInvitation sx={{ mr: 2 }} />

            <Font>{moment(booking.start_time).format('ddd D MMMM YYYY, HH:mm')}</Font>
          </ServiceItem>

          <ServiceItem>
            <AccessTime sx={{ mr: 2 }} />

            <Font>{moment(booking.end_time).diff(booking.start_time, 'minutes')} minutes</Font>
          </ServiceItem>
        </Service>

        <Divider sx={{ margin: '8px 24px 0 24px' }} />
      </Content>
    </Container>
  );
}

export default RescheduleSuccess;
