import { fireGtmEvent } from './fireGtmEvent';

enum GTM_SCHEDULE_EVENTS {
  CLIENT_CREATE_EVENT = 'CLIENT_CREATE_EVENT',
  USER_CREATE_EVENT = 'USER_CREATE_EVENT',
}

interface CreateEventProps {
  clientName: string;
  providerName: string;
  companyName: string;
}

export const clientCreateEvent = ({ clientName, providerName, companyName }: CreateEventProps) => {
  fireGtmEvent({
    event: GTM_SCHEDULE_EVENTS.CLIENT_CREATE_EVENT,
    clientName,
    providerName,
    companyName,
  });
};

export const userCreateEvent = ({ clientName, providerName, companyName }: CreateEventProps) => {
  fireGtmEvent({
    event: GTM_SCHEDULE_EVENTS.USER_CREATE_EVENT,
    clientName,
    providerName,
    companyName,
  });
};
