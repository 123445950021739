import { useState } from 'react';

import styled from 'styled-components';
import {
  AddOutlined,
  Close,
  DeleteOutlined,
  Event,
  People,
  PersonOutlined,
  RoomServiceOutlined,
} from '@mui/icons-material';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Box,
  Stack,
  Tooltip,
  IconButton,
  Alert,
} from '@mui/material';
import { Font, Modal, TimePicker } from 'elements';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { BookingFormKeys } from 'model';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { setClientFormAddModalShow, clientCreateRequest } from 'screens/clients';
import {
  updateEventFormUpdate,
  clearEventFormUpdate,
  setEventDeleteModal,
  // setDeleteBookingModal,
  //   setBookingFormEditModal,
} from 'store/ui';

// components
import ClientFormAdd from 'screens/clients/components/ClientFormAdd';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

function EventFormUpdate() {
  const { eventFormUpdate, eventFormError } = useSelector((state: RootState) => state.ui);
  const { businessHours } = useSelector((state: RootState) => state.organization);
  const { clients, staff, services } = useSelector((state: RootState) => state.organization);
  const { clientFormAddModalShow, clientFormLoading } = useSelector(
    (state: RootState) => state.clients,
  );
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const selectedService = services.find((service) => service.id === +eventFormUpdate.service);
  const weekday = moment(eventFormUpdate.start_time).weekday();
  const businessHour = businessHours.find((hour) => hour.weekday === weekday);
  const staffBookingFormEdit = staff.find(
    (staffDetail) => staffDetail.id === +eventFormUpdate.staff,
  );
  const clientBookingFormEdit = clients.find((client) => client.id === +eventFormUpdate.client);

  return (
    <Form>
      <Stack direction='row' justifyContent='flex-end' m={'-1rem -1rem 0.5rem 0'}>
        <Box>
          <Tooltip title='Delete'>
            <IconButton
              onClick={() => {
                dispatch(setEventDeleteModal(true));
              }}
            >
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        </Box>

        <Box>
          <Tooltip title='Close'>
            <IconButton
              onClick={() => {
                dispatch(clearEventFormUpdate());
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>

      <Stack direction='row' alignItems='center'>
        <Box
          sx={{
            width: 20,
            height: 20,
            backgroundColor: '#eaeffe',
            borderRadius: 1,
            m: '3px',
          }}
        ></Box>

        <Stack ml={3}>
          <Font type='h3'>
            {staffBookingFormEdit?.name}{' '}
            {clientBookingFormEdit && `and ${clientBookingFormEdit?.name}`}
          </Font>
          <Font>
            {moment(eventFormUpdate.start_time).format('dddd, DD MMMM HH:mm')} -
            {moment(eventFormUpdate.end_time).format('HH:mm')}
          </Font>
        </Stack>
      </Stack>

      {eventFormError.non_field_errors && (
        <Alert sx={{ mt: 3 }} severity='error'>
          {eventFormError.non_field_errors}
        </Alert>
      )}

      <Stack direction='row' alignItems='center' mt={3} mb={1.5}>
        <Box>
          <RoomServiceOutlined />
        </Box>

        <Stack ml={3} flex={1}>
          <FormControl variant='outlined' fullWidth size='small' error={!!eventFormError.service}>
            <InputLabel htmlFor='outlined-age-native-simple'>Service</InputLabel>

            <Select
              native
              value={eventFormUpdate.service}
              onChange={(event: any) => {
                dispatch(
                  updateEventFormUpdate({
                    key: BookingFormKeys.service,
                    value: event.currentTarget.value,
                  }),
                );
              }}
              label='Service'
            >
              <option value='' />

              {services.map((service, index) => (
                <option value={service.id} key={index}>
                  {service.name}
                </option>
              ))}
            </Select>

            <FormHelperText>{eventFormError.service}</FormHelperText>
          </FormControl>
        </Stack>
      </Stack>

      <Stack direction='row' alignItems='center' mb={1.5}>
        <Box>
          <PersonOutlined />
        </Box>

        <Stack ml={3} flex={1}>
          <FormControl variant='outlined' fullWidth size='small' error={!!eventFormError.staff}>
            <InputLabel htmlFor='outlined-age-native-simple'>Provider</InputLabel>

            <Select
              native
              value={eventFormUpdate.staff}
              onChange={(event: any) => {
                dispatch(
                  updateEventFormUpdate({
                    key: BookingFormKeys.staff,
                    value: event.currentTarget.value,
                  }),
                );
              }}
              label='Provider'
            >
              <option value='' />

              {staff.map((employee, index) => (
                <option value={employee.id} key={index}>
                  {employee.name}
                </option>
              ))}
            </Select>

            <FormHelperText>{eventFormError.staff}</FormHelperText>
          </FormControl>
        </Stack>
      </Stack>

      <Stack direction='row' alignItems='center' mb={1.5}>
        <Box>
          <People />
        </Box>

        <Stack direction='row' flex={1} ml={3}>
          <FormControl
            variant='outlined'
            fullWidth
            size='small'
            error={!!eventFormError.client}
            sx={{ mr: 1.5 }}
          >
            <InputLabel htmlFor='outlined-age-native-simple'>Client</InputLabel>

            <Select
              native
              value={eventFormUpdate.client}
              onChange={(event: any) => {
                const value = event.currentTarget.value;
                const client = clients.find((client) => client.id === +value);

                dispatch(
                  updateEventFormUpdate({
                    key: BookingFormKeys.client,
                    value: client ? client.id : '',
                  }),
                );
              }}
              label='Client'
            >
              <option value='' />

              {clients.map((client, index) => (
                <option value={client.id} key={index}>
                  {`${client.name}`}
                </option>
              ))}
            </Select>

            <FormHelperText>{eventFormError.client}</FormHelperText>
          </FormControl>

          <Button
            color='primary'
            variant='outlined'
            startIcon={<AddOutlined />}
            sx={{ height: 40, borderRadius: '4px !important' }}
            onClick={() => {
              dispatch(setClientFormAddModalShow(true));
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>

      <Stack direction='row' alignItems='center' mb={1.5}>
        <Box>
          <Event />
        </Box>

        <Stack direction='row' flex={1} ml={3}>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <DatePicker
              open={isOpen}
              label='Date'
              value={moment(eventFormUpdate.start_time)}
              disabled={!eventFormUpdate.service}
              onChange={(value) => {
                if (value) {
                  const dateTime = moment(eventFormUpdate.start_time);
                  const date = value.format('YYYY/MM/DD');
                  const time = dateTime.format('HH:mm');

                  dispatch(
                    updateEventFormUpdate({
                      key: BookingFormKeys.start_time,
                      value: moment(date + ' ' + time).format(),
                    }),
                  );
                }
              }}
              onClose={() => {
                setIsOpen(false);
              }}
              OpenPickerButtonProps={{
                sx: { display: 'none' },
              }}
              renderInput={(params) => (
                <TextField
                  size='small'
                  sx={{
                    mr: 1.5,
                  }}
                  {...params}
                  autoComplete='off'
                  onClick={() => {
                    setIsOpen(true);
                  }}
                />
              )}
            />
          </LocalizationProvider>

          <TimePicker
            style={{
              width: 120,
            }}
            disabled={!eventFormUpdate.service}
            steps={selectedService?.duration ? +selectedService?.duration : undefined}
            businessHour={businessHour}
            value={moment(eventFormUpdate.start_time)}
            onChange={(value) => {
              const dateTime = moment(eventFormUpdate.start_time);
              const date = dateTime.format('YYYY/MM/DD');
              const time = value.format('HH:mm');

              dispatch(
                updateEventFormUpdate({
                  key: BookingFormKeys.start_time,
                  value: moment(date + ' ' + time).format(),
                }),
              );
            }}
          />
        </Stack>
      </Stack>

      <Modal
        open={!!clientFormAddModalShow}
        title={'Add Client'}
        loading={clientFormLoading}
        onClose={() => dispatch(setClientFormAddModalShow(false))}
        onSubmit={() => {
          dispatch(clientCreateRequest());
        }}
      >
        <ClientFormAdd />
      </Modal>
    </Form>
  );
}
export default EventFormUpdate;
