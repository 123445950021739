// dependencies
import { Divider, IconButton, Stack, Theme, Link, Box, Tooltip } from '@mui/material';
import { EventAvatar, Font } from 'elements';
import styled from 'styled-components';
import moment from 'moment-timezone';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { websocket } from 'store/ui';

import { ReactComponent as SleepSvg } from 'assets/svg/sleep.svg';
import { usePrivateLayout } from 'contexts';
import { Close } from '@mui/icons-material';

const Container = styled.div``;

const NotificationsWrapper = styled.div`
  padding: 10px;
  width: 500px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: unset;
  }
`;

const SvgWrapper = styled.div`
  padding: 2rem 0;
`;

interface CircleProps {
  read: boolean;
}

const Circle = styled.div<CircleProps>`
  width: 8px;
  height: 8px;
  background-color: ${({ read }) => (read ? 'transparent' : '#1876f2')};
  border-radius: 50%;
`;

const Timestamp = styled.span`
  color: rgb(107, 119, 140);
  font-weight: 500;
  font-size: 12px;
`;

const NotificationAnchor = styled.div`
  padding: 6px 8px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }: { theme: Theme }) => theme.palette.grey[200]};
  }
`;

const NotificationReadIconButton = styled(IconButton)`
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.grey[400]};
  }
`;

const SleepSvgComponent = styled(SleepSvg)`
  width: 100%;
`;

const Notifications = () => {
  const { notifications, notificationsAreFetched } = useSelector((state: RootState) => state.ui);
  const { setNotificationAnchor } = usePrivateLayout();

  const markAllAsRead = () => {
    notifications
      .filter((notification) => !notification.is_read)
      .forEach((notification) => {
        websocket.send(
          JSON.stringify({
            eventType: 'READ_NOTIFICATION',
            data: notification,
          }),
        );
      });
  };

  return (
    <Container>
      <Stack p={2} direction='row' justifyContent='space-between' alignItems='center'>
        <Font type='h1'>Notifications</Font>

        <IconButton onClick={() => setNotificationAnchor(null)}>
          <Close />
        </IconButton>
      </Stack>

      <Divider sx={{ m: '0 16px 6px 16px' }} />

      <NotificationsWrapper>
        <Stack direction='row' justifyContent='space-between' p='0 8px 6px 8px'>
          <Font type='label'>Latest</Font>

          <Link
            underline='hover'
            color='inherit'
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={markAllAsRead}
          >
            Mark all as read
          </Link>
        </Stack>

        {notificationsAreFetched && !notifications.length ? (
          <SvgWrapper>
            <SleepSvgComponent />

            <Font
              type='h3'
              style={{
                display: 'block',
                textAlign: 'center',
                marginTop: 24,
              }}
            >
              No Notifications
            </Font>
          </SvgWrapper>
        ) : (
          notifications.map((notification) => {
            return (
              <NotificationAnchor key={notification.id}>
                <Stack direction='row'>
                  <Box mr={1}>
                    <EventAvatar action={notification.notification_type} />
                  </Box>

                  <Stack direction='row'>
                    <Stack mr={1}>
                      <Font type='h5'>
                        {notification.title}{' '}
                        <Timestamp>{moment(notification.timestamp).fromNow()}</Timestamp>
                      </Font>
                      <Box>
                        <Font>{notification.message}</Font>
                      </Box>
                    </Stack>

                    <Box display='flex' alignItems='center' p={1}>
                      <Tooltip title={notification.is_read ? 'Mark as unread' : 'Mark as read'}>
                        <NotificationReadIconButton
                          onClick={(e) => {
                            e.stopPropagation();

                            websocket.send(
                              JSON.stringify({
                                eventType: 'READ_NOTIFICATION',
                                data: notification,
                              }),
                            );
                          }}
                        >
                          <Circle read={!!notification.is_read} />
                        </NotificationReadIconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </Stack>
              </NotificationAnchor>
            );
          })
        )}
      </NotificationsWrapper>
    </Container>
  );
};

export default Notifications;
