import { useMemo } from 'react';

// dependencies
import { Card, CardContent, CardHeader } from '@mui/material';
import styled from 'styled-components';
import { RootState } from 'store/config';
import { Histogram } from 'shared';

// redux
import { useSelector } from 'react-redux';

const Chart = styled.div`
  height: 200px;
  display: flex;
`;

function StaffAppointments() {
  const { dailyBookings } = useSelector((state: RootState) => state.dashboard);
  const grid = useMemo(() => true, []);
  const xAxisOptions = useMemo(
    () => ({
      xAxisMaxBandWidth: 20,
      xAxisPadding: 0.05,
      xAxisRotateText: false,
    }),
    [],
  );
  const data = useMemo(() => {
    const dailyBookingPreprocess = dailyBookings.reduce((result: any, current) => {
      const value = result[current.staff.name] ?? 0;
      return {
        ...result,
        [current.staff.name]: value + 1,
      };
    }, {});

    const returnData = Object.entries(dailyBookingPreprocess ?? {}).map((entry) => ({
      category: entry[0],
      value: entry[1],
    }));

    return returnData;
  }, [dailyBookings]);

  return (
    <Card sx={{ m: '1rem 0 0 1rem', flex: 'unset' }}>
      <CardHeader title='Staff Appointments' />

      <CardContent sx={{ p: 2, pb: '1rem !important' }}>
        <Chart>
          <Histogram grid={grid} xAxisOptions={xAxisOptions} data={data} />
        </Chart>
      </CardContent>
    </Card>
  );
}

export default StaffAppointments;
