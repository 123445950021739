// dependencies
import styled from 'styled-components';
import { Font, Logo, PhoneInput } from 'elements';
import { Divider, TextField, Link, Theme, Stack, Box, InputLabel, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import useWillUnmount from 'hooks/useWillUnmount';
import { CheckCircle } from '@mui/icons-material';
import { GoogleButton } from 'shared';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { updateUserFormCreate, signUpSubmit, clearUserFormCreate } from '..';

const Container = styled.div`
  max-width: 1080px;
  display: flex;
  z-index: 1;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin: 0 0 32px 20px;
`;

const LogoWrapper = styled.div`
  padding: 0 0 40px 32px;
`;

const LogoWrapperMobile = styled(LogoWrapper)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

const LeftSection = styled.div`
  padding-top: 56px;
  margin-right: 4rem;
  flex: 1;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

const Features = styled.div``;

const Form = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 56px 48px;
  border-radius: 8px;
  box-shadow: 0 14px 32px 0 rgb(22 45 61 / 8%), 0 1px 4px 0 rgb(22 45 61 / 10%);
  width: 540px;
  background-color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 40px 8px;
  }
`;

const FooterText = styled.div`
  text-align: center;
  font-size: 0.875rem;
  color: ${({ theme }: { theme: Theme }) => theme.palette.grey[600]};
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1rem;
  }
`;

const SignUp = () => {
  const { loading, userFormCreate, userFormCreateError } = useSelector(
    (state: RootState) => state.signup,
  );
  const dispatch = useDispatch();

  useWillUnmount(() => {
    dispatch(clearUserFormCreate());
  });

  return (
    <Container>
      <LeftSection>
        <LogoWrapper>
          <Logo link='/' />
        </LogoWrapper>

        <Features>
          <Stack direction='row' spacing={1.5} mb={3}>
            <Box>
              <CheckCircle color='primary' fontSize='small' />
            </Box>

            <Stack>
              <Font type='h4'>Get started quickly</Font>

              <Font>
                Create your business, set up your services, availability and you are ready to go.
              </Font>
            </Stack>
          </Stack>

          <Stack direction='row' spacing={1.5} mb={3}>
            <Box>
              <CheckCircle color='primary' fontSize='small' />
            </Box>

            <Stack>
              <Font type='h4'>Unlimited appointments</Font>

              <Font>
                Gotei provides you flexibility, power and unlimited appointments every single month
                totally free.
              </Font>
            </Stack>
          </Stack>

          <Stack direction='row' spacing={1.5} mb={3}>
            <Box>
              <CheckCircle color='primary' fontSize='small' />
            </Box>

            <Stack>
              <Font type='h4'>Invite your staff</Font>

              <Font>
                Give credentials and permissions to your staff to manage their or others'
                appointments.
              </Font>
            </Stack>
          </Stack>
        </Features>
      </LeftSection>

      <RightSection>
        <LogoWrapperMobile>
          <Logo link='/' />
        </LogoWrapperMobile>

        <Form
          onSubmit={(event) => {
            event.preventDefault();

            dispatch(signUpSubmit());
          }}
        >
          <Title>Create your free account</Title>
          <InputLabel sx={{ m: '24px 0 16px 20px' }}>Enter your details</InputLabel>
          <TextField
            required
            color='primary'
            variant='outlined'
            label='Name'
            type='text'
            margin='normal'
            sx={{ m: '0 20px 24px 20px' }}
            error={!userFormCreate.name && !!userFormCreateError.name}
            helperText={!userFormCreate.name && userFormCreateError.name}
            value={userFormCreate.name}
            onChange={(event) =>
              dispatch(
                updateUserFormCreate({
                  key: 'name',
                  value: event.target.value,
                }),
              )
            }
          />
          <TextField
            required
            color='primary'
            variant='outlined'
            label='Email'
            type='email'
            margin='normal'
            sx={{ m: '0 20px 24px 20px' }}
            error={!!userFormCreateError.email}
            helperText={userFormCreateError.email}
            value={userFormCreate.email}
            onChange={(event) =>
              dispatch(
                updateUserFormCreate({
                  key: 'email',
                  value: event.target.value,
                }),
              )
            }
          />
          <PhoneInput
            color='primary'
            variant='outlined'
            label='Phone'
            margin='normal'
            sx={{ m: '0 20px 24px 20px' }}
            value={userFormCreate.phone}
            error={!!userFormCreateError.phone}
            helperText={userFormCreateError.phone ?? 'Optional'}
            onChange={(_, value) => {
              dispatch(
                updateUserFormCreate({
                  key: 'phone',
                  value: value,
                }),
              );
            }}
          />
          <TextField
            required
            color='primary'
            variant='outlined'
            label='Password'
            type='password'
            margin='normal'
            sx={{ m: '0 20px 32px 20px' }}
            error={!!userFormCreateError.password}
            helperText={
              userFormCreateError.password
                ? userFormCreateError.password
                : 'Use 8 or more characters with a combination of letters, numbers and symbols'
            }
            value={userFormCreate.password}
            onChange={(event) =>
              dispatch(
                updateUserFormCreate({
                  key: 'password',
                  value: event.target.value,
                }),
              )
            }
          />

          <LoadingButton
            type='submit'
            variant='contained'
            size='large'
            sx={{ m: '0 20px' }}
            loading={loading}
          >
            Create account
          </LoadingButton>

          <Stack m='0 20px'>
            <Typography align='center' margin='1rem 0'>
              OR
            </Typography>

            <GoogleButton text='Sign up with Google' />

            <Divider sx={{ m: '2rem 0 1rem 0' }} />
          </Stack>

          <FooterText>
            Already have an account?{' '}
            <Link component={RouterLink} to='/login' variant='body2'>
              Log in
            </Link>
          </FooterText>
        </Form>
      </RightSection>
    </Container>
  );
};
export default SignUp;
