import { takeLatest, fork, call, select, put } from 'redux-saga/effects';
import { fieldIsEmpty, formErrorIsEmpty, logger } from 'utils';

import { RootState } from 'store/config';
import { userModuleRpc } from 'services/api';
import {
  ResetPasswordActionTypes,
  selectEmail,
  setEmail,
  setResetPasswordLoading,
  setResetPasswordSuccess,
  setResetPasswordError,
  clearResetPasswordError,
  selectConfirmResetPassword,
  clearConfirmResetPassword,
  setConfirmResetPasswordLoading,
  setConfirmResetPasswordError,
  clearConfirmResetPasswordError,
  setConfirmResetPasswordSuccess,
  selectToken,
  selectConfirmResetPasswordError,
} from '.';
import { FieldsErrors } from 'model';

function* resetPassword() {
  const state: RootState = yield select();
  const email = selectEmail(state);

  try {
    logger('Reset password request');

    yield put(setResetPasswordLoading(true));
    yield put(clearResetPasswordError());

    yield call([userModuleRpc, userModuleRpc.resetPassword], email);

    logger('Reset password success');

    yield put(setEmail(''));
    yield put(setResetPasswordLoading(false));
    yield put(setResetPasswordSuccess(true));
  } catch (error: any) {
    logger('Reset password failure');

    yield put(setResetPasswordError(error));
    yield put(setResetPasswordLoading(false));
  }
}

function* confirmResetPassword() {
  yield call(confirmResetPasswordValidation);

  const state: RootState = yield select();
  const confirmResetPassword = selectConfirmResetPassword(state);
  const confirmResetPasswordError = selectConfirmResetPasswordError(state);
  const token = selectToken(state);

  if (formErrorIsEmpty(confirmResetPasswordError)) return;

  try {
    logger('Confirm reset password request');

    yield put(setConfirmResetPasswordLoading(true));

    yield call([userModuleRpc, userModuleRpc.confirmResetPassword], confirmResetPassword.password, {
      params: { token },
    });

    logger('Confirm reset password success');

    yield put(clearConfirmResetPassword());
    yield put(setConfirmResetPasswordLoading(false));
    yield put(setConfirmResetPasswordSuccess(true));
  } catch (error: any) {
    logger('Confirm reset password failure');

    yield put(setConfirmResetPasswordError(error));
    yield put(setConfirmResetPasswordLoading(false));
  }
}

function* confirmResetPasswordValidation() {
  yield put(clearConfirmResetPasswordError());

  const state: RootState = yield select();
  const confirmResetPassword = selectConfirmResetPassword(state);
  const errors: FieldsErrors = {};

  if (fieldIsEmpty(confirmResetPassword.password)) {
    errors['password'] = 'This field is required';
  }
  if (confirmResetPassword.password !== confirmResetPassword.confirmPassword) {
    errors['password'] = "Password doesn't match confirm password.";
    errors['confirmPassword'] = ' ';
  }
  if (fieldIsEmpty(confirmResetPassword.confirmPassword)) {
    errors['confirmPassword'] = 'This field is required';
  }

  yield put(setConfirmResetPasswordError(errors));
}

function* resetPasswordWatcher() {
  yield takeLatest(ResetPasswordActionTypes.RESET_PASSWORD, resetPassword);
}

function* confirmResetPasswordWatcher() {
  yield takeLatest(ResetPasswordActionTypes.CONFIRM_RESET_PASSWORD, confirmResetPassword);
}

export const resetPasswordSagas = [fork(resetPasswordWatcher), fork(confirmResetPasswordWatcher)];
