import { useEffect, useMemo, useState } from 'react';

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  colors,
  Stack,
  TextField,
} from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/lab';
import { ChevronRight } from '@mui/icons-material';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment-timezone';
import { BusinessHour, Company, WorkingHour } from 'model';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  margin-right: 1rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-right: 0;
  }
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: 0;
    margin-top: 2rem;
  }
`;

const DateTitle = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

interface Props {
  company: Company;
  date: string;
  businessHours: BusinessHour[];
  staffWorkingHours: WorkingHour[];
  availability: string[];
  availabilityLoading: boolean;
  availabilityError: string;
  availabilityAreFetched: boolean;
  setDate: (date: string) => void;
  onSlotSelect: (stateDate: Moment) => void;
}

const SlotPicker = ({
  company,
  date,
  businessHours,
  staffWorkingHours,
  availability,
  availabilityLoading,
  availabilityError,
  availabilityAreFetched,
  setDate,
  onSlotSelect,
}: Props) => {
  const [clientTimezone, setClientTimezone] = useState(moment.tz.guess());
  const [nextValidDayExecuted, setNextValidDayExecuted] = useState(false);
  const weekday = useMemo(() => moment(date).weekday(), [date]);
  const businessHoursWeekdayExists = useMemo(
    () => businessHours.find((day) => day.weekday === weekday),
    [businessHours, weekday],
  );
  const staffWorkingHoursWeekdayExists = useMemo(
    () => staffWorkingHours.find((day) => day.weekday === weekday),
    [staffWorkingHours, weekday],
  );

  useEffect(() => {
    if (nextValidDayExecuted) return;

    if (businessHours.length && !businessHoursWeekdayExists) {
      const businessHoursCopy = [...staffWorkingHours];

      const firstWeekday = businessHoursCopy.shift();

      if (firstWeekday) {
        const nextValidDate = moment(date).add(1, 'weeks').weekday(firstWeekday.weekday).format();
        setDate(nextValidDate);
        setNextValidDayExecuted(true);
      }
    } else if (staffWorkingHours.length && !staffWorkingHoursWeekdayExists) {
      const staffWorkingHoursCopy = [...staffWorkingHours];

      const firstWeekday = staffWorkingHoursCopy.shift();

      if (firstWeekday) {
        const nextValidDate = moment(date).add(1, 'weeks').weekday(firstWeekday.weekday).format();
        setDate(nextValidDate);
        setNextValidDayExecuted(true);
      }
    }
  }, [
    nextValidDayExecuted,
    date,
    setDate,
    businessHours,
    staffWorkingHours,
    businessHoursWeekdayExists,
    staffWorkingHoursWeekdayExists,
  ]);

  return (
    <Container>
      <LeftSection>
        <Box ml={-2.3} mt={-2}>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <StaticDatePicker
              disablePast
              displayStaticWrapperAs='desktop'
              shouldDisableDate={(date: Moment) => {
                const weekday = date.weekday();
                const businessHour = businessHours.find((hour) => hour.weekday === weekday);
                const staffWorkingHour = staffWorkingHours.find((hour) => hour.weekday === weekday);

                if (!businessHour || !staffWorkingHour) {
                  return true;
                } else {
                  return false;
                }
              }}
              value={date}
              onChange={(date) => {
                if (date) {
                  setDate(moment(date).format());
                }
              }}
              renderInput={(params) => <TextField sx={{ ml: -4, background: 'red' }} {...params} />}
            />
          </LocalizationProvider>
        </Box>

        <Autocomplete
          value={clientTimezone}
          defaultValue={company.timezone}
          options={moment.tz.names()}
          renderInput={(params) => <TextField {...params} variant='standard' />}
          onChange={(_, value) => {
            if (value) setClientTimezone(value);
          }}
        />
      </LeftSection>

      <RightSection>
        <DateTitle>{moment(date).format('dddd, MMMM DD')}</DateTitle>

        {availabilityLoading && !availabilityError && (
          <Stack flex={1} alignItems={'center'} justifyContent={'center'}>
            <CircularProgress size={24} />
          </Stack>
        )}

        {!!availabilityError && (
          <Alert sx={{ maxWidth: 300 }} icon={false} severity='error'>
            {availabilityError}
          </Alert>
        )}

        {availabilityAreFetched && !availabilityError && !availability.length && (
          <Alert
            icon={false}
            severity='info'
            sx={{ maxWidth: 300, alignItems: 'center' }}
            action={
              <Button
                color='inherit'
                sx={{ whiteSpace: 'nowrap' }}
                endIcon={<ChevronRight />}
                onClick={() => {
                  setDate(moment(date).add(1, 'days').format('DD/MMM/YYYY'));
                }}
              >
                Next day
              </Button>
            }
          >
            There are no available slots.
          </Alert>
        )}

        {!availabilityLoading && !!availability.length && !availabilityError && (
          <Stack
            overflow={'auto'}
            maxHeight={{
              sm: 'unset',
              md: 330,
            }}
          >
            {availability.map((time) => {
              return (
                <Button
                  fullWidth
                  variant='outlined'
                  color='inherit'
                  key={time}
                  sx={{ mb: 1, borderColor: colors.grey[300] }}
                  onClick={() => {
                    const dateTime = moment(date).format('YYYY/MM/DD');
                    const startTime = moment(dateTime + ' ' + time); // we set time on 00:00:00 in order to avoid errors when comparing two dates

                    onSlotSelect(startTime);
                  }}
                >
                  {moment.tz(moment(time, 'HH:mm'), clientTimezone).format('HH:mm')}
                </Button>
              );
            })}
          </Stack>
        )}
      </RightSection>
    </Container>
  );
};

export default SlotPicker;
