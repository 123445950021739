// dependencies
import { Font, EventAvatar } from 'elements';
import { Skeleton, Card, CardHeader, CardContent, Stack, Box } from '@mui/material';
import styled from 'styled-components';
import { useAuthEffect } from 'hooks';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { activityRequest } from '..';

import { ReactComponent as SVG } from 'assets/svg/no-activity.svg';

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const SvgContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 2rem 0;
`;

const NoActivitySvg = styled(SVG)`
  width: 15%;
  height: auto;
  max-width: 280px;
`;

function Activity() {
  const { activity, activityLoading, activityIsFetched } = useSelector(
    (state: RootState) => state.dashboard,
  );
  const dispatch = useDispatch();

  useAuthEffect(() => {
    if (!activityIsFetched) dispatch(activityRequest());
  }, [dispatch]);

  return (
    <Card sx={{ m: '1rem 0 0 1rem' }}>
      <CardHeader title='Activity' />

      <CardContent
        sx={{
          flex: '1 0 0',
          minHeight: 300,
          overflow: 'auto',
          display: 'flex',
        }}
      >
        <Content>
          {activityLoading ? (
            [1, 2, 3, 4, 5].map((row, index) => (
              <Row key={index}>
                <Skeleton
                  variant='circular'
                  style={{
                    marginRight: '1rem',
                    width: '30px',
                    height: '30px',
                  }}
                />
                <Skeleton variant='text' width={'80%'} height={30} />
              </Row>
            ))
          ) : !activity.length ? (
            <SvgContainer>
              <NoActivitySvg />

              <Font style={{ marginTop: '2rem' }} type='h4'>
                You have no activity yet
              </Font>
              <Font>New activity will show up here.</Font>
            </SvgContainer>
          ) : (
            activity.map((activityDetail) => (
              <Stack key={activityDetail.id} direction='row' mb={2}>
                <Stack direction='row'>
                  <Box mr={2}>
                    <EventAvatar action={activityDetail.notification_type} />
                  </Box>

                  <Stack direction='row' alignItems='center'>
                    <Font>{activityDetail.message}</Font>
                  </Stack>
                </Stack>
              </Stack>
            ))
          )}
        </Content>
      </CardContent>
    </Card>
  );
}

export default Activity;
