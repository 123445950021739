import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
  clearLoginFormError,
  clearServerError,
  LoginActionTypes,
  selectLoginForm,
  selectLoginFormError,
  setLoginFormError,
  setLoginLoading,
  setLoginServerError,
  clearLoginForm,
} from '.';
import { setIsAuthenticated, setIsAuthenticatedVerified, setUser } from 'store/auth';
import { RootState } from 'store/config';

// api
import { userModuleRpc } from 'services/api';

import { FieldsErrors, LoginFormKeys, LoginUserForm, User } from 'model';
import { avatarGenerator, fieldIsEmpty, formErrorIsEmpty, getError, logger } from 'utils';

interface LoginResponse {
  user: User;
}

function* loginSubmit() {
  yield call(validateLogin);

  const state: RootState = yield select();
  const loginForm: LoginUserForm = yield selectLoginForm(state);
  const loginFormError: FieldsErrors = yield selectLoginFormError(state);

  if (formErrorIsEmpty(loginFormError)) {
    return;
  }

  yield put(setLoginLoading(true));

  try {
    logger('Login Submit Request');

    const response: LoginResponse = yield call(userModuleRpc.login.bind(userModuleRpc, loginForm));

    logger('Login Submit Success');
    yield put(setUser(response.user));

    if (!response.user.image) {
      const { phone, ...rest } = response.user;
      const user = { ...rest };
      const match = response.user.name.match(/\b(\w)/g)?.slice(0, 2);
      const letters = match?.join('').toUpperCase();
      const imageURI = avatarGenerator(letters);

      user.image = imageURI ?? null;

      const response2: User = yield call(
        [userModuleRpc, userModuleRpc.updateUserPartial],
        response.user,
        user,
      );
      const imageUrl = response2.image?.split('localhost:8000')[1] ?? null;

      yield put(setUser({ ...response2, image: imageUrl }));
    }

    yield put(setIsAuthenticated(true));
    yield put(setIsAuthenticatedVerified(true));
    yield put(clearLoginForm());
    yield put(setLoginLoading(false));
  } catch (error: any) {
    logger('Login Submit Failure');

    yield put(setLoginLoading(false));
    yield put(setLoginServerError(getError(error)));
  }
}

function* validateLogin() {
  yield put(clearLoginFormError());
  yield put(clearServerError());

  const state: RootState = yield select();
  const loginForm: LoginUserForm = yield selectLoginForm(state);

  if (fieldIsEmpty(loginForm.username)) {
    yield put(
      setLoginFormError({
        key: LoginFormKeys.username,
        value: 'Username field is required.',
      }),
    );
  } else if (fieldIsEmpty(loginForm.password)) {
    yield put(
      setLoginFormError({
        key: LoginFormKeys.password,
        value: 'Password field is required.',
      }),
    );
  }
}

export function* loginSubmitWatcher() {
  yield takeLatest(LoginActionTypes.LOGIN_FORM_SUBMIT, loginSubmit);
}

export const loginSagas = [fork(loginSubmitWatcher)];
