import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';

// apis
import { authenticationApi, companyApi, integrationsApi } from 'services/query';

//sagas
import rootSagas from './rootSagas';

// sagas middleware
const sagaMiddleware = createSagaMiddleware();

const logger = (createLogger as any)({
  collapsed: true,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      immutableCheck: false,
    }).concat([
      logger,
      authenticationApi.middleware,
      integrationsApi.middleware,
      companyApi.middleware,
    ]),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(rootSagas);

export default store;
