// dependencies
import styled from 'styled-components';
import { Font } from 'elements';

import time from 'assets/images/time.png';
import { Box, Button, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from 'hooks';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
  }
`;

const Header = styled.div`
  text-align: center;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const Img = styled.img``;

interface Props {
  companyUrl: string;
}

function BookingNotFound({ companyUrl }: Props) {
  const history = useHistory();

  useDocumentTitle('Not found - Gotei');

  return (
    <Container>
      <Content>
        <Image>
          <Img src={time} />
        </Image>

        <Header>
          <Font
            type='h2'
            style={{
              display: 'block',
              marginBottom: 16,
            }}
          >
            We're sorry, the link to reschedule/cancel the <br /> appointment has expired
          </Font>

          <Font
            style={{
              color: 'rgba(26, 26, 26, 0.6)',
              display: 'block',
            }}
          >
            Please contact this business directly to reschedule or cancel.
          </Font>
        </Header>

        <Stack direction='row' justifyContent='center' mt={2}>
          <Box>
            <Button
              size='large'
              variant='contained'
              onClick={() => history.push(`/booking/${companyUrl}`)}
            >
              Book other appointment
            </Button>
          </Box>
        </Stack>
      </Content>
    </Container>
  );
}

export default BookingNotFound;
