import { ComponentType, useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  ButtonGroup,
  TextField,
  Theme,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Checkbox,
  Tooltip,
  Stack,
  Box,
  Link,
} from '@mui/material';
import { ArrowDropDown, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { LocalizationProvider, StaticDatePicker } from '@mui/lab';
import { BookingUpdateType, BookingType } from 'model';
import styled from 'styled-components';
import moment from 'moment-timezone';
import {
  Calendar as BigCalendar,
  CalendarProps,
  EventProps,
  momentLocalizer,
  ToolbarProps,
} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { debounce, useAuthEffect, useQuery } from 'hooks';
import MomentUtils from '@date-io/moment';
import qs from 'querystring';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { uppercaseFirstLetter } from 'utils';
import { Modal, Font, Popper } from 'elements';
import WithPermission from 'hoc/WithPermission';
import { DOMAIN } from 'config';
import { usePrivateLayout } from 'contexts';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { setEventPreview, updateEventFormCreate, setEventFormDropUpdate } from 'store/ui';
import {
  setCalendarView,
  setDate,
  bookingsRequest,
  setStaffSelected,
  setBookingsAreFetched,
} from '..';

// styles
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../styles/calendar.css';

// image
import warning from 'assets/images/warning.png';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CalendarToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StaffFilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CalendarSection = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  height: 0;
  margin: 0 -1rem -1rem -1rem;
`;

interface AvatarProps {
  selected?: boolean;
}

const StaffAvatar = styled(Avatar)<AvatarProps>`
  width: 32px;
  height: 32px;
  border: 2px solid
    ${({ selected, theme }: { selected?: boolean; theme: Theme }) =>
      selected ? theme.palette.primary.main : 'white'};

  & :hover {
    cursor: pointer;
  }
`;

const StaffAvatarGroup = styled.div`
  display: flex;

  > button:not(:first-child) {
    margin-left: -0.8rem;
  }
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const ToolbarWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const ToolbarLeft = styled(ToolbarWrapper)`
  justify-content: flex-start;
`;

const ToolbarCenter = styled(ToolbarWrapper)`
  justify-content: center;
`;

const ToolbarRight = styled(ToolbarWrapper)`
  justify-content: flex-end;
`;

const OffHoursWrapper = styled.div`
  text-align: center;
`;

interface BookingProps {
  small: boolean;
}

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.55);
`;

const Booking = styled.div<BookingProps>`
  flex: 1;
  font-size: 12.8px;
  font-weight: 600;
  background-color: #eaeffe;
  position: relative;
  padding: 5px 9px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);

  ::before {
    content: '';
    display: ${({ small }) => (small ? 'none' : 'block')};
    position: absolute;
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
  }
`;

const BookingClient = styled.div`
  white-space: nowrap;
  font-size: 12px;
`;

const BookingTime = styled.div`
  white-space: nowrap;
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
`;

const WarningImg = styled.img`
  height: 100px;
`;

const VIEWS = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
];

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(BigCalendar as ComponentType<CalendarProps<any>>);

function Calendar() {
  const AVATAR_MAX = 5;
  const { notifications } = useSelector((state: RootState) => state.ui);
  const { date, bookingLoading, bookings, bookingsAreFetched, calendarView, staff, staffSelected } =
    useSelector((state: RootState) => state.calendar);
  const { businessHours, company } = useSelector((state: RootState) => state.organization);
  const [datePopperEl, setDatePopperEl] = useState<HTMLButtonElement | null>(null);
  const [staffPopperEl, setStaffPopperEl] = useState<HTMLButtonElement | null>(null);
  const [offHoursModal, setOffHoursModal] = useState(false);
  const [popperEl, setPopperEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const { setAppointmentAnchor } = usePrivateLayout();
  const staffParam = query.get('staff');
  const weekday = moment(date).weekday();
  const businessHour = businessHours.find((hour) => hour.weekday === weekday);

  // eslint-disable-next-line
  const appointmentsUpdate = useCallback(
    debounce(() => dispatch(bookingsRequest()), 500),
    [],
  );

  useAuthEffect(() => {
    /**
     * @Appointment_Update_Watcher
     * This useEffect watch notifications and when a new appears
     * trigger appointment request to update the calendar
     */
    appointmentsUpdate();
  }, [notifications]);

  useEffect(() => {
    // Initialize filtered staff filtering
    if (staff.length) {
      const staffIds = staffParam?.split(' ');

      const staffFiltered = staff.filter((staffDetail) =>
        staffIds?.includes(staffDetail.id.toString()),
      );

      dispatch(setStaffSelected(staffFiltered));
    }
    // eslint-disable-next-line
  }, [staff]);

  useEffect(() => {
    // Update query params when staff selected change

    if (staffSelected.length) {
      const query = {
        staff: staffSelected.map((staff) => staff.id).join(' '),
      };

      history.push({
        pathname: '/calendar',
        search: qs.stringify(query),
      });
    } else if (!staffSelected.length && staff.length) {
      history.push('/calendar');
    }
  }, [history, staffSelected, staff]);

  useAuthEffect(() => {
    dispatch(setBookingsAreFetched(false));
    dispatch(bookingsRequest());
  }, [dispatch, date, calendarView, staffSelected]);

  const bookingPreviewHandler = (event: BookingType, e: any) => {
    const serializeEvent: BookingType = {
      ...event,
      start_time: moment(event.start_time).format(),
      end_time: moment(event.end_time).format(),
    };

    dispatch(setEventPreview(serializeEvent));
    setAppointmentAnchor(e);
  };

  const dayPropGetter = useCallback(
    (date) => {
      const weekday = moment(date).weekday();
      const businessHour = businessHours.find((hour) => hour.weekday === weekday);

      return {
        className: 'slot-event',
        ...(!businessHour && {
          className: 'day-off',
        }),
      };
    },
    [businessHours],
  );

  const slotPropGetter = useCallback(
    (time) => {
      const weekday = moment(time).weekday();
      const businessHour = businessHours.find((hour) => hour.weekday === weekday);
      const momentTime = moment(time);

      if (businessHour) {
        const insideBusinessHour =
          momentTime.format('HH:mm:ss') >= businessHour.start_time &&
          momentTime.format('HH:mm:ss') < businessHour.end_time;

        return {
          ...(!insideBusinessHour && { className: 'day-off' }),
        };
      } else {
        return {};
      }
    },
    [businessHours],
  );

  useEffect(() => {
    const slots = document.querySelectorAll('.rbc-timeslot-group');

    slots.forEach((node) => {
      const slotLabels = node.querySelectorAll('.rbc-label');

      if (slotLabels.length) {
        const children = node.children;

        for (let i = 0; i < children.length; i++) {
          const childElement: any = children[i];

          childElement.style.background = 'white';
          childElement.style.pointerEvent = 'none';
        }
      }
    });
  }, [calendarView]);

  return (
    <Container>
      <WithPermission permission='company_receptionist'>
        <CalendarToolbar>
          <StaffFilterWrapper>
            <StaffAvatarGroup>
              {staff.slice(0, AVATAR_MAX).map((staffDetail) => (
                <Tooltip title={staffDetail.name} key={staffDetail.id}>
                  <IconButton
                    key={staffDetail.id}
                    size='small'
                    sx={{ width: 42, height: 42 }}
                    onClick={() => {
                      const findStaff = staffSelected.find((item) => item.id === staffDetail.id);
                      const staffSelectedCopy = [...staffSelected];

                      if (findStaff) {
                        const staffIndex = staffSelectedCopy.indexOf(staffDetail);
                        staffSelectedCopy.splice(staffIndex, 1);

                        dispatch(setStaffSelected(staffSelectedCopy));
                      } else {
                        staffSelectedCopy.push(staffDetail);

                        dispatch(setStaffSelected(staffSelectedCopy));
                      }
                    }}
                  >
                    <StaffAvatar
                      src={staffDetail.image}
                      sx={{
                        fontSize: 16,
                      }}
                      selected={staffSelected.some((item) => item.id === staffDetail.id)}
                    />
                  </IconButton>
                </Tooltip>
              ))}

              {staff.length > AVATAR_MAX && (
                <IconButton
                  size='small'
                  sx={{ width: 42, height: 42 }}
                  onClick={(event) => {
                    setStaffPopperEl(event.currentTarget);
                  }}
                >
                  <StaffAvatar
                    sx={{ fontSize: 16 }}
                    selected={staffSelected.some((item) =>
                      staff.slice(AVATAR_MAX).some((staffDetail) => staffDetail.id === item.id),
                    )}
                  >
                    +{staff.length - AVATAR_MAX}
                  </StaffAvatar>
                </IconButton>
              )}
            </StaffAvatarGroup>

            <div>
              {!!staffSelected.length && (
                <Button
                  color='inherit'
                  sx={{ m: '0 8px' }}
                  onClick={() => {
                    dispatch(setStaffSelected([]));
                  }}
                >
                  Clear Filters
                </Button>
              )}
            </div>
          </StaffFilterWrapper>
        </CalendarToolbar>
      </WithPermission>

      <CalendarSection>
        {!bookingsAreFetched && bookingLoading && (
          <Loader>
            <CircularProgress />
          </Loader>
        )}

        <DragAndDropCalendar
          selectable
          localizer={localizer}
          showMultiDayTimes
          date={moment(date).toDate()}
          scrollToTime={
            businessHour
              ? moment(businessHour?.start_time, 'HH:mm:ss').toDate()
              : moment('12:00', 'HH:mm').toDate()
          }
          view={calendarView}
          views={['day', 'month', 'week']}
          startAccessor={'start_time'}
          endAccessor={'end_time'}
          dayPropGetter={dayPropGetter}
          slotPropGetter={slotPropGetter}
          events={bookings.map((booking) => {
            return {
              ...booking,
              start_time: moment(booking.start_time).toDate(),
              end_time: moment(booking.end_time).toDate(),
            };
          })}
          onSelectEvent={(event: BookingType, e) => {
            bookingPreviewHandler(event, e);
          }}
          onSelectSlot={(slot) => {
            let allowSlotCreate = false;
            const momentTime = moment(slot.start);
            const weekday = momentTime.weekday();
            const businessHour = businessHours.find((hour) => hour.weekday === weekday);

            if (businessHour) {
              const isInsideWorkingHours =
                momentTime.format('HH:mm:ss') >= businessHour.start_time &&
                momentTime.format('HH:mm:ss') < businessHour.end_time;

              allowSlotCreate = isInsideWorkingHours;

              if (calendarView === 'month') {
                allowSlotCreate = true;
              }
            }

            if (allowSlotCreate) {
              dispatch(
                updateEventFormCreate({
                  key: 'start_time',
                  value: momentTime.format(),
                }),
              );

              dispatch(
                updateEventFormCreate({
                  key: 'company',
                  value: company.uuid,
                }),
              );
            } else {
              setOffHoursModal(true);
            }
          }}
          onNavigate={(date) => {
            dispatch(setDate(moment(date).format()));
          }}
          onView={(view) => {
            dispatch(setCalendarView(view));
          }}
          onEventDrop={(dropData) => {
            const dropBooking: BookingType = dropData.event;

            const start_time = dropData.start;

            const booking: BookingUpdateType = {
              ...dropBooking,
              start_time: moment(start_time).format(),
              end_time: moment(dropData.event.end_time).format(),
              client: dropBooking.client.id,
              staff: dropBooking.staff.id,
              service: dropBooking.service.id,
            };

            dispatch(setEventFormDropUpdate(booking));
          }}
          components={{
            toolbar: (props: ToolbarProps) => {
              return (
                <Toolbar>
                  <ToolbarLeft>
                    <Button
                      color='inherit'
                      variant='outlined'
                      sx={{ height: 40, borderRadius: '4px !important' }}
                      endIcon={<ArrowDropDown />}
                      onClick={(event) => {
                        setPopperEl(event.currentTarget);
                      }}
                    >
                      {uppercaseFirstLetter(props.view)}
                    </Button>
                  </ToolbarLeft>

                  <ToolbarCenter>
                    <ButtonGroup color='inherit' variant='text'>
                      <Button
                        sx={{ borderRight: 'none !important' }}
                        onClick={() => {
                          props.onNavigate('PREV');
                        }}
                      >
                        <ChevronLeft />
                      </Button>

                      <Button
                        sx={{ borderRight: 'none !important', whiteSpace: 'nowrap' }}
                        onClick={(event) => {
                          setDatePopperEl(event.currentTarget);
                        }}
                      >
                        {props.label}
                      </Button>

                      <Button
                        sx={{ border: 'none' }}
                        onClick={() => {
                          props.onNavigate('NEXT');
                        }}
                      >
                        <ChevronRight />
                      </Button>
                    </ButtonGroup>
                  </ToolbarCenter>

                  <ToolbarRight>
                    <div>
                      <Button
                        color='inherit'
                        variant='outlined'
                        sx={{ height: 40, borderRadius: '4px !important' }}
                        onClick={() => {
                          props.onNavigate('TODAY');
                        }}
                      >
                        Today
                      </Button>
                    </div>
                  </ToolbarRight>
                </Toolbar>
              );
            },
            event: (props: EventProps<BookingType>) => {
              if (!!props.slotStart) {
                return (
                  <Booking small={true}>
                    <BookingClient>{`${props.event?.client.name}`}</BookingClient>
                  </Booking>
                );
              } else
                return (
                  <Booking small={false}>
                    <BookingClient>{`${props.event?.client.name}`}</BookingClient>

                    <BookingTime>
                      {moment(props.event?.start_time).format('hh:mm A')} -{' '}
                      {moment(props.event?.end_time).format('hh:mm A')}
                    </BookingTime>
                  </Booking>
                );
            },
          }}
        />
      </CalendarSection>

      <Popper open={!!datePopperEl} anchorEl={datePopperEl} onClose={() => setDatePopperEl(null)}>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <StaticDatePicker
            displayStaticWrapperAs='desktop'
            value={moment(date)}
            onChange={(date) => {
              dispatch(setDate(moment(date).format()));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Popper>

      <Popper
        open={!!staffPopperEl}
        anchorEl={staffPopperEl}
        onClose={() => setStaffPopperEl(null)}
      >
        <List>
          {staff.slice(AVATAR_MAX).map((staffDetail) => (
            <ListItem key={staffDetail.id} disablePadding>
              <ListItemButton
                sx={{ p: '0 16px' }}
                onClick={() => {
                  const findStaff = staffSelected.find((item) => item.id === staffDetail.id);

                  if (findStaff) {
                    const staffSelectedCopy = [...staffSelected];
                    const staffIndex = staffSelectedCopy.indexOf(staffDetail);
                    staffSelectedCopy.splice(staffIndex, 1);

                    dispatch(setStaffSelected(staffSelectedCopy));
                  } else {
                    const staffSelectedCopy = [...staffSelected];
                    staffSelectedCopy.push(staffDetail);

                    dispatch(setStaffSelected(staffSelectedCopy));
                  }
                }}
              >
                <Checkbox
                  edge='start'
                  size='small'
                  disableRipple
                  checked={staffSelected.some((item) => item.id === staffDetail.id)}
                />

                <StaffAvatar
                  src={DOMAIN.API + staffDetail.image}
                  style={{
                    fontSize: 12,
                    width: '24px ',
                    height: '24px',
                  }}
                  selected={staffSelected.some((item) => item.id === staffDetail.id)}
                />

                <ListItemText sx={{ ml: 2 }}>{staffDetail.name}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popper>

      <Modal open={offHoursModal} onClose={() => setOffHoursModal(false)}>
        <OffHoursWrapper>
          <Stack direction='row' justifyContent='center' mb={3}>
            <WarningImg src={warning} />
          </Stack>

          <Font type='h2' style={{ display: 'block', marginBottom: 10 }}>
            Off-Hours slot
          </Font>
          <Font>
            You are choosing a slot out of business hours. <br />
            If you want to edit your business hours click{' '}
            <Link component={RouterLink} to='/company' variant='body2'>
              here
            </Link>
            .
          </Font>

          <Box mt={2}>
            <Button variant='contained' onClick={() => setOffHoursModal(false)}>
              Ok
            </Button>
          </Box>
        </OffHoursWrapper>
      </Modal>

      <Popper open={!!popperEl} anchorEl={popperEl} onClose={() => setPopperEl(null)}>
        <List>
          {VIEWS.map((view) => (
            <ListItem key={view.value} disablePadding>
              <ListItemButton
                onClick={() => {
                  const value: any = view.value;
                  dispatch(setCalendarView(value));
                  setPopperEl(null);
                }}
              >
                <ListItemText primary={view.label} disableTypography />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popper>
    </Container>
  );
}

export default Calendar;
