import { call, fork, takeLatest, put, select } from 'redux-saga/effects';

// api
import { publicModuleRpc } from 'services/api';
import {
  emailIsValid,
  fieldIsEmpty,
  formErrorIsEmpty,
  getError,
  getErrorFields,
  logger,
} from 'utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/config';
import moment from 'moment-timezone';
import { GTM } from 'triggers';
import {
  AvailabilityParams,
  BookingType,
  BusinessHour,
  Client,
  Company,
  Service,
  Staff,
  WorkingHour,
} from 'model';
import {
  BookingPageActionTypes,
  setCompany,
  setCompanyLoading,
  setCompanyError,
  selectCompany,
  setServices,
  setStaff,
  setStaffAreFetched,
  selectDate,
  selectBookingForm,
  setAvailability,
  setAvailabilityError,
  setAvailabilityAreFetched,
  setIsLoading,
  selectClientForm,
  setClient,
  confirmBookingRequest,
  selectClient,
  clearClientForm,
  setConfirmBookingIsDone,
  setError,
  clearClientFormError,
  setClientFormError,
  updateClientFormError,
  selectClientFormError,
  setBusinessHours,
  setBooking,
  setServicesAreFetched,
  setStaffWorkingHours,
} from '.';

function* getCompanyDetail(action: PayloadAction<string>) {
  const companyUrl = action.payload;

  try {
    logger('Company Detail Request');

    yield put(setCompanyLoading(true));

    const response: Company = yield call(
      publicModuleRpc.getCompanyByURL.bind(publicModuleRpc, companyUrl),
    );

    yield put(setCompany(response));
    yield put(setCompanyLoading(false));

    logger('Company Detail Success');
  } catch (error: any) {
    logger.error('Company Detail Failure');

    yield put(setCompanyLoading(false));
    yield put(setCompanyError(getError(error)));
  }
}

function* getServices() {
  const state: RootState = yield select();
  const company = selectCompany(state);

  try {
    logger('Services Request');

    yield put(setIsLoading(true));

    const response: Array<Service> = yield call(
      [publicModuleRpc, publicModuleRpc.getServices],
      company.uuid,
    );

    logger('Services Success');

    yield put(setServices(response));
    yield put(setServicesAreFetched(true));
    yield put(setIsLoading(false));
  } catch (error: any) {
    logger.error('Services Failure');

    yield put(setIsLoading(false));
  }
}

function* getStaff() {
  const state: RootState = yield select();
  const bookingForm = selectBookingForm(state);

  try {
    logger('Staff Request');

    yield put(setIsLoading(true));

    const response: Array<Staff> = yield call(
      [publicModuleRpc, publicModuleRpc.getStaffByService],
      bookingForm.service,
    );

    logger('Staff Success');

    yield put(setStaff(response));
    yield put(setIsLoading(false));
    yield put(setStaffAreFetched(true));
  } catch (error: any) {
    logger.error('Staff Failure');

    yield put(setIsLoading(false));
    yield put(setStaffAreFetched(true));
  }
}

function* getAvailability() {
  const state: RootState = yield select();
  const date = selectDate(state);
  const bookingForm = selectBookingForm(state);

  if (!bookingForm.staff) return;

  try {
    logger('Availability Request');

    yield put(setIsLoading(true));
    yield put(setAvailabilityError(''));

    const params: AvailabilityParams = {
      date: moment(date).format('DD/MM/YYYY'),
      service: bookingForm.service,
    };

    const response: Array<string> = yield call(
      publicModuleRpc.getAvailability.bind(publicModuleRpc, +bookingForm.staff, params),
    );
    logger('Availability Success');

    yield put(setAvailability(response));
    yield put(setAvailabilityAreFetched(true));
    yield put(setIsLoading(false));
  } catch (error: any) {
    logger.error('Availability Failure');

    yield put(setIsLoading(false));
    yield put(setAvailabilityError(getError(error)));
  }
}

function* confirmBooking() {
  const state: RootState = yield select();
  const client = selectClient(state);
  const company = selectCompany(state);

  if (!client.id) return;

  try {
    logger('Booking Submit Request');

    yield put(setIsLoading(true));

    const bookingForm = { ...selectBookingForm(state) };
    bookingForm.client = client.id;

    const response: BookingType = yield call(
      publicModuleRpc.createBooking.bind(publicModuleRpc, bookingForm),
    );

    logger('Booking Submit Success');

    yield call(GTM.clientCreateEvent, {
      clientName: response.client.name,
      providerName: response.staff.name,
      companyName: company.name,
    });

    yield put(setBooking(response));
    yield put(clearClientForm());
    yield put(setConfirmBookingIsDone(true));
    yield put(setIsLoading(false));
  } catch (error: any) {
    logger.error('Booking Submit Failure');

    yield put(setError(getError(error)));
    yield put(setIsLoading(false));
  }
}

function* clientRegister() {
  yield call(validateClientForm);

  const state: RootState = yield select();
  const clientForm = selectClientForm(state);
  const clientFormError = selectClientFormError(state);
  const company = selectCompany(state);

  if (formErrorIsEmpty(clientFormError)) return;

  try {
    logger('Client Register Request');

    yield put(setIsLoading(true));

    const clientFormCreate = {
      ...clientForm,
      company: company.uuid,
    };

    const response: Client = yield call(
      publicModuleRpc.createClient.bind(publicModuleRpc, clientFormCreate),
    );

    logger('Client Register Success');

    yield put(setIsLoading(false));
    yield put(setClient(response));
    yield put(confirmBookingRequest());
  } catch (error: any) {
    logger.error('Client Register Failure');

    yield put(setClientFormError(getErrorFields(error)));
    yield put(setIsLoading(false));
  }
}

function* validateClientForm() {
  yield put(clearClientFormError());

  const state: RootState = yield select();
  const clientForm = selectClientForm(state);

  if (fieldIsEmpty(clientForm.email)) {
    yield put(
      updateClientFormError({
        email: 'Email is required',
      }),
    );
  } else if (!emailIsValid(clientForm.email)) {
    yield put(
      updateClientFormError({
        email: 'Email format is not correct',
      }),
    );
  }

  if (fieldIsEmpty(clientForm.name)) {
    yield put(
      updateClientFormError({
        name: 'Name is required',
      }),
    );
  }
}

function* getBusinessHours(action: PayloadAction<string>) {
  const companyUrl = action.payload;

  try {
    logger('Business Hours Request');

    const response: Array<BusinessHour> = yield call(
      [publicModuleRpc, publicModuleRpc.getBusinessHours],
      companyUrl,
    );

    logger('Business Hours Success');

    yield put(setBusinessHours(response));
  } catch (error) {
    logger.error('Business Hours Failure');
  }
}

function* getStaffWorkingHours() {
  const state: RootState = yield select();
  const bookingForm = selectBookingForm(state);

  if (!bookingForm.staff) return;

  try {
    logger('Staff Working Hours Request');

    const response: WorkingHour[] = yield call(
      [publicModuleRpc, publicModuleRpc.getStaffWorkingHours],
      bookingForm.staff,
    );

    logger('Staff Working Hours Success');

    yield put(setStaffWorkingHours(response));
  } catch (error) {
    logger.error('Staff Working Hours Failure');
  }
}

function* companyDetailWatcher() {
  yield takeLatest(BookingPageActionTypes.COMPANY_DETAIL_REQUEST, getCompanyDetail);
}

function* servicesWatcher() {
  yield takeLatest(BookingPageActionTypes.SERVICES_REQUEST, getServices);
}

function* staffWatcher() {
  yield takeLatest(BookingPageActionTypes.STAFF_REQUEST, getStaff);
}

function* availabilityWatcher() {
  yield takeLatest(BookingPageActionTypes.AVAILABILITY_REQUEST, getAvailability);
}

function* confirmBookingWatcher() {
  yield takeLatest(BookingPageActionTypes.CONFIRM_BOOKING_REQUEST, confirmBooking);
}

function* clientRegisterWatcher() {
  yield takeLatest(BookingPageActionTypes.CLIENT_REGISTER_REQUEST, clientRegister);
}

function* businessHoursWatcher() {
  yield takeLatest(BookingPageActionTypes.BUSINESS_HOURS_REQUEST, getBusinessHours);
}

function* staffWorkingHoursWatcher() {
  yield takeLatest(BookingPageActionTypes.STAFF_WORKING_HOURS_REQUEST, getStaffWorkingHours);
}

export const bookingPageSagas = [
  fork(companyDetailWatcher),
  fork(servicesWatcher),
  fork(staffWatcher),
  fork(availabilityWatcher),
  fork(confirmBookingWatcher),
  fork(clientRegisterWatcher),
  fork(businessHoursWatcher),
  fork(staffWorkingHoursWatcher),
];
