import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  colors,
  Divider,
  FormHelperText,
  IconButton,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { Edit, Check, MoreVertTwoTone, Delete, PhotoCamera } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import { useAuthEffect, useWillUnmount } from 'hooks';
import { FileUpload, Font, Modal, Popper } from 'elements';
import { useHistory, useParams } from 'react-router-dom';

// redux
import { RootState } from 'store/config';
import { useDispatch, useSelector } from 'react-redux';
import {
  serviceRequest,
  setServiceEdit,
  staffByServiceRequest,
  clearServiceEdit,
  updateServiceEdit,
  serviceUpdateRequest,
  setServiceDelete,
  serviceDeleteRequest,
  clearServiceDelete,
  setServiceDeleteSuccess,
  setServiceFormPartial,
  serviceUpdatePartialRequest,
} from '..';

import image from 'assets/images/placeholder.png';
import { fileToBase64 } from 'utils';

const Container = styled.div``;

const Content = styled.div``;

const Form = styled.form``;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionTitle = styled.div`
  flex: 1;
  padding: 0 1.5rem 0 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const SectionContent = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
`;

const SectionActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const ServiceName = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
`;

const AvatarWrapper = styled.div`
  position: relative;

  &:hover {
    div:nth-child(2) {
      display: flex;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
`;

const LabelWrapper = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

const LoadingWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

const Label = styled.label`
  &:hover {
    cursor: pointer;
  }
`;

const MobileHidden = styled.div`
  flex: 1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

interface Params {
  id?: string;
}

function ServiceDetail() {
  const {
    service,
    serviceEdit,
    serviceStaff,
    serviceEditLoading,
    serviceEditError,
    serviceDelete,
    serviceDeleteSuccess,
  } = useSelector((state: RootState) => state.serviceDetail);
  const staff = useSelector((state: RootState) => state.organization.staff);
  const [popperEl, setPopperEl] = useState<any>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<Params>();

  useWillUnmount(() => {
    dispatch(setServiceDeleteSuccess(false));
    dispatch(clearServiceEdit());
  });

  useAuthEffect(() => {
    if (id && service.id !== +id) {
      dispatch(serviceRequest(+id));
    }
  }, [dispatch, id]);

  useAuthEffect(() => {
    if (service.id) {
      dispatch(staffByServiceRequest());
    }
  }, [dispatch, service]);

  useEffect(() => {
    if (serviceDeleteSuccess) history.push('/services');
  }, [history, serviceDeleteSuccess]);

  return (
    <Container>
      <Content>
        <Form
          onSubmit={(event) => {
            event.preventDefault();

            dispatch(serviceUpdateRequest());
          }}
        >
          <Section>
            <SectionTitle>
              <Font type='h5'>Overview</Font>
              <Font>Service Information</Font>
            </SectionTitle>

            <SectionContent>
              <ServiceName>
                <FileUpload
                  style={{ display: 'none' }}
                  accept={['image/*']}
                  htmlFor='upload-file'
                  onFileUpload={async (event) => {
                    const file = event.target.files?.[0];

                    if (file) {
                      const image = await fileToBase64(file);

                      dispatch(
                        setServiceFormPartial({
                          id: service.id,
                          image,
                        }),
                      );

                      dispatch(serviceUpdatePartialRequest());
                    }
                  }}
                />

                <AvatarWrapper>
                  <Avatar src={service.image} sx={{ height: 110, width: 110 }}>
                    <Avatar src={image} sx={{ height: 110, width: 110 }} />
                  </Avatar>

                  {serviceEditLoading ? (
                    <LoadingWrapper>
                      <CircularProgress sx={{ color: colors.common.white }} />
                    </LoadingWrapper>
                  ) : (
                    <LabelWrapper>
                      <Label htmlFor='upload-file'>
                        <PhotoCamera fontSize='large' sx={{ color: colors.common.white }} />
                      </Label>
                    </LabelWrapper>
                  )}
                </AvatarWrapper>

                <Font type='h2' style={{ marginLeft: 24 }}>
                  {service.name}
                </Font>
              </ServiceName>

              {serviceEditError.image && (
                <FormHelperText error>{serviceEditError.image}</FormHelperText>
              )}

              {serviceEdit.id ? (
                <>
                  <TextField
                    type='text'
                    label='Name'
                    fullWidth
                    margin='normal'
                    sx={{ mt: 3 }}
                    value={serviceEdit.name}
                    error={!!serviceEditError.name}
                    helperText={serviceEditError.name}
                    onChange={(event) => {
                      dispatch(
                        updateServiceEdit({
                          key: 'name',
                          value: event.currentTarget.value,
                        }),
                      );
                    }}
                  />

                  <TextField
                    type='text'
                    label='Duration in minutes'
                    fullWidth
                    margin='normal'
                    value={serviceEdit.duration}
                    error={!!serviceEditError.duration}
                    helperText={serviceEditError.duration}
                    onChange={(event) => {
                      dispatch(
                        updateServiceEdit({
                          key: 'duration',
                          value: event.currentTarget.value,
                        }),
                      );
                    }}
                  />

                  <TextField
                    type='text'
                    size='small'
                    multiline
                    rows={4}
                    label='Description'
                    margin='normal'
                    value={serviceEdit.description ?? ''}
                    error={!!serviceEditError.description}
                    helperText={serviceEditError.description}
                    onChange={(event) => {
                      dispatch(
                        updateServiceEdit({
                          key: 'description',
                          value: event.currentTarget.value,
                        }),
                      );
                    }}
                  />
                </>
              ) : (
                <>
                  <Stack mt={3}>
                    <Font type='h5'>Name</Font>
                    <Font>{service.name}</Font>
                  </Stack>

                  <Stack mt={2}>
                    <Font type='h5'>Duration</Font>
                    <Font>{service.duration} mins</Font>
                  </Stack>

                  <Stack mt={2}>
                    <Font type='h5'>Description</Font>
                    <Font>{service.description} </Font>
                  </Stack>
                </>
              )}
            </SectionContent>

            <MobileHidden>
              <SectionActions>
                <div>
                  {serviceEdit.id ? (
                    <>
                      <Button color='inherit' onClick={() => dispatch(clearServiceEdit())}>
                        Cancel
                      </Button>

                      <LoadingButton
                        sx={{
                          ml: 1,
                        }}
                        loading={serviceEditLoading}
                        startIcon={<Check />}
                        variant='contained'
                        type='submit'
                      >
                        Save
                      </LoadingButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={(event) => {
                          setPopperEl(event.currentTarget);
                        }}
                      >
                        <MoreVertTwoTone />
                      </IconButton>

                      <Popper
                        open={!!popperEl}
                        anchorEl={popperEl}
                        onClose={() => setPopperEl(null)}
                      >
                        <Stack sx={{ width: 150, p: 1 }}>
                          <Box>
                            <Button
                              fullWidth
                              color='inherit'
                              startIcon={<Edit />}
                              sx={{ justifyContent: 'flex-start' }}
                              onClick={async () => {
                                if (service) {
                                  dispatch(
                                    setServiceEdit({
                                      ...service,
                                      staff: service.staff.map((staffDetail) => staffDetail.id),
                                    }),
                                  );
                                }
                                setPopperEl(null);
                              }}
                            >
                              Edit
                            </Button>
                          </Box>

                          <Button
                            fullWidth
                            color='inherit'
                            startIcon={<Delete />}
                            sx={{ justifyContent: 'flex-start' }}
                            onClick={() => {
                              dispatch(setServiceDelete(service));
                              setPopperEl(null);
                            }}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </Popper>
                    </>
                  )}
                </div>
              </SectionActions>
            </MobileHidden>
          </Section>

          <Divider sx={{ m: '24px 0' }} />

          <Section>
            <SectionTitle>
              <Font type='h5'>Status</Font>
              <Font>
                Turn it on to make the service visible on your customers and your booking page, or
                turn it off in order to hide the service.
              </Font>
            </SectionTitle>

            <SectionContent>
              <Stack direction='row' alignItems='center'>
                <Font>Off</Font>
                {serviceEdit.id ? (
                  <Switch
                    checked={serviceEdit.is_active}
                    onChange={(event) => {
                      dispatch(
                        updateServiceEdit({
                          key: 'is_active',
                          value: event.target.checked,
                        }),
                      );
                    }}
                  />
                ) : (
                  <Switch
                    color='success'
                    checked={service.is_active}
                    onChange={(event) => {
                      dispatch(
                        setServiceFormPartial({
                          id: service.id,
                          is_active: event.target.checked,
                        }),
                      );

                      dispatch(serviceUpdatePartialRequest());
                    }}
                  />
                )}
                <Font>On</Font>
              </Stack>
            </SectionContent>

            <SectionActions>
              <div></div>
            </SectionActions>
          </Section>

          <Divider sx={{ m: '24px 0' }} />

          <Section>
            <SectionTitle>
              <Font type='h5'>Service staff</Font>
              <Font>
                Staff members who provide this service. The assigned staff members will be shown to
                clients when they book an appointment
              </Font>
            </SectionTitle>

            <SectionContent>
              <Box m={-1}>
                {serviceEdit.id
                  ? staff.map((item) => {
                      if (serviceEdit.staff.some((id) => id === item.id)) {
                        return (
                          <Chip
                            color='primary'
                            label={item.name}
                            key={item.id}
                            onDelete={() => {
                              const staffCopy = [...serviceEdit.staff];
                              const updatedStaff = staffCopy.filter((id) => id !== item.id);

                              dispatch(
                                updateServiceEdit({
                                  key: 'staff',
                                  value: updatedStaff,
                                }),
                              );
                            }}
                          />
                        );
                      } else {
                        return (
                          <Chip
                            label={item.name}
                            key={item.id}
                            onClick={() => {
                              const staffCopy = [...serviceEdit.staff];
                              staffCopy.push(item.id);

                              dispatch(
                                updateServiceEdit({
                                  key: 'staff',
                                  value: staffCopy,
                                }),
                              );
                            }}
                          />
                        );
                      }
                    })
                  : serviceStaff.map((item) => (
                      <Chip color='primary' label={item.name} key={item.id} />
                    ))}
              </Box>
            </SectionContent>

            <SectionActions>
              <div></div>
            </SectionActions>
          </Section>

          <Divider sx={{ m: '24px 0' }} />

          <Section>
            <SectionTitle>
              <Font type='h5'>Create meeting</Font>
              <Font>
                Enable video conferencing service that allow to communicate via audio, video, chat,
                and screen sharing.
              </Font>
            </SectionTitle>

            <SectionContent>
              {serviceEdit.id ? (
                <Switch
                  checked={serviceEdit.is_meeting}
                  onChange={(event) => {
                    dispatch(
                      updateServiceEdit({
                        key: 'is_meeting',
                        value: event.target.checked,
                      }),
                    );
                  }}
                />
              ) : (
                <Switch
                  checked={service.is_meeting}
                  onChange={(event) => {
                    dispatch(
                      setServiceFormPartial({
                        id: service.id,
                        is_meeting: event.target.checked,
                      }),
                    );

                    dispatch(serviceUpdatePartialRequest());
                  }}
                />
              )}
            </SectionContent>

            <SectionActions>
              <div></div>
            </SectionActions>
          </Section>
        </Form>
      </Content>

      <Modal
        open={!!serviceDelete.id}
        title='Delete Service'
        loading={!!serviceEditLoading}
        onClose={() => {
          dispatch(clearServiceDelete());
        }}
        onSubmit={() => {
          dispatch(serviceDeleteRequest());
        }}
        submitText='Delete'
        danger
      >
        <Font>
          Service <strong> {serviceDelete.name} </strong> will be permanently deleted. this action
          can not be undone.
        </Font>
      </Modal>
    </Container>
  );
}

export default ServiceDetail;
