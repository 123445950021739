import { Alert, TextField } from '@mui/material';
import { Font, PhoneInput } from 'elements';
import styled from 'styled-components';
import useWillUnmount from 'hooks/useWillUnmount';
import { LoadingButton } from '@mui/lab';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { updateClientForm, clientCreateRequest, setError, clearClientForm } from '..';
import { useDocumentTitle } from 'hooks';

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 2rem;
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
`;

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

function ConfirmBooking() {
  const { isLoading, clientForm, error, clientFormError } = useSelector(
    (state: RootState) => state.bookingPage,
  );
  const dispatch = useDispatch();

  useDocumentTitle('Enter Booking Details - Gotei');

  useWillUnmount(() => {
    dispatch(clearClientForm());
    dispatch(setError(''));
  });

  return (
    <Container>
      <Font type='h2'>Enter your details</Font>

      <Content>
        {!!error && (
          <Alert severity='error' sx={{ m: '1rem 0' }}>
            {error}
          </Alert>
        )}

        <LeftSection>
          <Form
            onSubmit={(event) => {
              event.preventDefault();
              dispatch(clientCreateRequest());
            }}
          >
            <TextField
              required
              type='text'
              label='Name'
              sx={{ mb: 3 }}
              value={clientForm.name}
              error={!!clientFormError.name}
              helperText={clientFormError.name}
              onChange={(event) => {
                dispatch(
                  updateClientForm({
                    key: 'name',
                    value: event.currentTarget.value,
                  }),
                );
              }}
            />

            <TextField
              required
              type='email'
              label='Email'
              sx={{ mb: 3 }}
              value={clientForm.email}
              error={!!clientFormError.email}
              helperText={clientFormError.email}
              onChange={(event) => {
                dispatch(
                  updateClientForm({
                    key: 'email',
                    value: event.currentTarget.value,
                  }),
                );
              }}
            />

            <PhoneInput
              label='Phone'
              sx={{ mb: 5 }}
              value={clientForm.phone}
              error={!!clientFormError.phone}
              helperText={clientFormError.phone}
              onChange={(_, value) => {
                dispatch(
                  updateClientForm({
                    key: 'phone',
                    value: value,
                  }),
                );
              }}
            />

            <LoadingButton
              loading={isLoading}
              variant='contained'
              fullWidth
              size='large'
              type='submit'
            >
              Schedule Appointment
            </LoadingButton>
          </Form>
        </LeftSection>
      </Content>
    </Container>
  );
}

export default ConfirmBooking;
