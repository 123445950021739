import { useEffect, useState } from 'react';

import { Logo, Modal } from 'elements';
import styled from 'styled-components';
import { Link, Stack, Theme } from '@mui/material';
import moment from 'moment-timezone';
import { useQuery } from 'hooks';
import { useHistory } from 'react-router-dom';

// components
import Privacy from '../Privacy/Privacy';
import Cookies from '../Cookie/Cookie';
import Terms from '../Terms/Terms';
import ContactUs from '../ContactUs/ContactUs';

// icons
import { ReactComponent as LinkedinSvg } from 'assets/svg/linkedin.svg';
import { ReactComponent as FacebookSvg } from 'assets/svg/facebook.svg';
import { ReactComponent as InstagramSvg } from 'assets/svg/instagram.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1rem;
  }
`;

const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  background-image: linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
`;

const FooterSection = styled.div`
  margin: 80px 0;
  display: flex;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
    margin: 60px 0;
  }
`;

const Branding = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Social = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
`;

const SocialAnchor = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }: { theme: Theme }) => theme.palette.text.primary};
  color: ${({ theme }: { theme: Theme }) => theme.palette.common.white};
  width: 26px;
  height: 26px;
  border-radius: 4px;

  &:hover {
    background: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
  }
`;

const StyledLinkedinSvg = styled(LinkedinSvg)`
  fill: white;
  width: 18px;
  height: auto;
`;

const StyledFacebookSvg = styled(FacebookSvg)`
  fill: white;
  height: 18px;
  width: auto;
`;

const StyledInstagramSvg = styled(InstagramSvg)`
  fill: white;
  height: 24px;
  width: auto;
`;

const Copyright = styled.div`
  display: flex;
  justify-content: center;
  background-image: linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  padding: 1rem 0;
`;

function Footer() {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookies, setShowCookies] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const showParam = query.get('show');

  useEffect(() => {
    if (showParam === 'privacy') {
      setShowPrivacy(true);
    } else if (showParam === 'terms') {
      setShowTerms(true);
    } else if (showParam === 'cookies') {
      setShowCookies(true);
    }
  }, [showParam]);

  const clearParams = () => history.push('/');

  return (
    <Container>
      <Content>
        <FooterSection>
          <Branding>
            <Stack>
              <Logo size='small' />
            </Stack>

            <Stack direction='row' spacing={2} mt={2}>
              <Link
                color='inherit'
                underline='hover'
                component='button'
                onClick={() => setShowPrivacy(true)}
              >
                Privacy Policy
              </Link>

              <Link
                color='inherit'
                underline='hover'
                component='button'
                onClick={() => setShowCookies(true)}
              >
                Cookie Policy
              </Link>

              <Link
                color='inherit'
                underline='hover'
                component='button'
                onClick={() => setShowTerms(true)}
              >
                Terms
              </Link>

              <Link
                color='inherit'
                underline='hover'
                component='button'
                onClick={() => setShowContactUs(true)}
              >
                Contact us
              </Link>
            </Stack>
          </Branding>

          <Social>
            <Stack direction='row' spacing={2}>
              <SocialAnchor target='_blank' href='https://www.linkedin.com/company/gotei'>
                <StyledLinkedinSvg />
              </SocialAnchor>

              <SocialAnchor
                target='_blank'
                href='https://www.facebook.com/people/Gotei/61551931868793/'
              >
                <StyledFacebookSvg />
              </SocialAnchor>

              <SocialAnchor target='_blank' href='https://www.instagram.com/gotei_booking/'>
                <StyledInstagramSvg />
              </SocialAnchor>
            </Stack>
          </Social>
        </FooterSection>

        <Copyright>Copyright © Gotei {moment().format('YYYY')}</Copyright>
      </Content>

      <Modal
        title='Privacy Policy'
        open={showPrivacy}
        onClose={() => {
          setShowPrivacy(false);
          clearParams();
        }}
      >
        <Privacy />
      </Modal>

      <Modal
        title='Cookies Policy'
        open={showCookies}
        onClose={() => {
          setShowCookies(false);
          clearParams();
        }}
      >
        <Cookies />
      </Modal>

      <Modal
        title='Terms and Conditions'
        open={showTerms}
        onClose={() => {
          setShowTerms(false);
          clearParams();
        }}
      >
        <Terms />
      </Modal>

      <Modal
        title='Contact us'
        open={showContactUs}
        onClose={() => {
          setShowContactUs(false);
          clearParams();
        }}
      >
        <ContactUs />
      </Modal>
    </Container>
  );
}

export default Footer;
