import { Chip, Skeleton, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { Done } from '@mui/icons-material';
import { useGoogleCalendarQuery } from 'services/query/integrationsApi';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store/config';

// images
import { ReactComponent as GoogleCalendarIcon } from 'assets/svg/google-calendar.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GoogleCalendar = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const { isLoading, isSuccess } = useGoogleCalendarQuery(user.id);

  if (isLoading)
    return (
      <Container>
        <Stack direction='row' justifyContent='space-between'>
          <Skeleton variant='rectangular' width={50} height={50} sx={{ borderRadius: 2 }} />

          <Skeleton variant='rectangular' width={110} height={32} sx={{ borderRadius: 30 }} />
        </Stack>

        <Stack mt={2}>
          <Skeleton width={150} sx={{ mb: 2, mt: 1 }} />

          <Skeleton sx={{ mb: 1 }} />
          <Skeleton width='60%' />
        </Stack>
      </Container>
    );

  return (
    <Container>
      <Stack direction='row' justifyContent='space-between'>
        <GoogleCalendarIcon />

        {isSuccess ? (
          <Chip size='small' color='success' label='Connected' icon={<Done />} />
        ) : (
          <Chip size='small' label='Not connected' />
        )}
      </Stack>

      <Stack mt={2}>
        <Typography variant='h6'>Google Calendar</Typography>
        <Typography mt={0.5} variant='body2'>
          Automatically sync your Gotei calendar to Google Calendar.
        </Typography>
      </Stack>
    </Container>
  );
};
