import { useState } from 'react';

// dependencies
import { Check, Delete, Edit, Event, MoreVertTwoTone } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Skeleton,
} from '@mui/material';
import { Font, Modal, PhoneInput, Popper } from 'elements';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { BookingType } from 'model';
import { useAuthEffect } from 'hooks';
import { usePrivateLayout } from 'contexts/usePrivateLayout';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { setEventPreview } from 'store/ui';
import {
  setClientFormUpdate,
  updateClientFormUpdate,
  clearClientFormUpdate,
  clientUpdateRequest,
  clientDeleteRequest,
  clearClientFormDelete,
  setClientFormDelete,
  clientAppointmentsRequest,
} from '..';

const Container = styled.div``;

const Content = styled.div``;

const Form = styled.form``;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionTitle = styled.div`
  flex: 1;
  padding: 0 1.5rem 0 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const SectionContent = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
`;

const SectionActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const ServiceName = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
`;

const AppointmentDummyElement = styled.div`
  flex: 1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const MobileHidden = styled.div`
  flex: 1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

function Client() {
  const {
    client,
    clientLoading,
    clientFormLoading,
    clientFormError,
    clientFormUpdate,
    clientFormDelete,
    appointments,
    appointmentsLoading,
    appointmentsAreFetched,
  } = useSelector((state: RootState) => state.client);
  const [popperEl, setPopperEl] = useState<any>(null);
  const dispatch = useDispatch();
  const { setAppointmentAnchor } = usePrivateLayout();

  useAuthEffect(() => {
    if (client.id) dispatch(clientAppointmentsRequest());
  }, [dispatch, client]);

  const bookingPreviewHandler = (appointment: BookingType, e: any) => {
    dispatch(setEventPreview(appointment));
    setAppointmentAnchor(e);
  };

  return (
    <Container>
      <Content>
        <Form
          onSubmit={(event) => {
            event.preventDefault();

            dispatch(clientUpdateRequest());
          }}
        >
          <Section>
            <SectionTitle>
              <Font type='h5'>Overview</Font>
              <Font>Client Information</Font>
            </SectionTitle>

            <SectionContent>
              <ServiceName>
                <Avatar src={''} />

                <Font type='h2' style={{ marginLeft: 24 }}>
                  {client.name}
                </Font>
              </ServiceName>
              {clientFormUpdate.id ? (
                <>
                  <TextField
                    label='Name'
                    type='text'
                    fullWidth
                    margin='normal'
                    sx={{ mt: 3 }}
                    value={clientFormUpdate.name}
                    error={!!clientFormError.name}
                    helperText={clientFormError.name}
                    onChange={(event) => {
                      dispatch(
                        updateClientFormUpdate({
                          key: 'name',
                          value: event.currentTarget.value,
                        }),
                      );
                    }}
                  />

                  <TextField
                    label='Email'
                    type='email'
                    margin='normal'
                    value={clientFormUpdate.email}
                    error={!!clientFormError.email}
                    helperText={clientFormError.email}
                    onChange={(event) => {
                      dispatch(
                        updateClientFormUpdate({
                          key: 'email',
                          value: event.currentTarget.value,
                        }),
                      );
                    }}
                  />

                  <PhoneInput
                    label='Phone'
                    margin='normal'
                    value={clientFormUpdate.phone}
                    error={!!clientFormError.phone}
                    helperText={clientFormError.phone ?? 'Optional'}
                    onChange={(_, value) => {
                      dispatch(
                        updateClientFormUpdate({
                          key: 'phone',
                          value: value,
                        }),
                      );
                    }}
                  />
                </>
              ) : (
                <>
                  <Stack mt={3}>
                    <Font type='h5'>Name</Font>

                    {clientLoading ? (
                      <Skeleton variant='text' width={100} />
                    ) : (
                      <Font>{client.name}</Font>
                    )}
                  </Stack>

                  <Stack mt={2}>
                    <Font type='h5'>Email</Font>

                    {clientLoading ? (
                      <Skeleton variant='text' width={150} />
                    ) : (
                      <Font> {client.email}</Font>
                    )}
                  </Stack>

                  <Stack mt={2}>
                    <Font type='h5'>Phone</Font>

                    {clientLoading ? (
                      <Skeleton variant='text' width={100} />
                    ) : (
                      <Font>{client.phone} </Font>
                    )}
                  </Stack>
                </>
              )}
            </SectionContent>

            <MobileHidden>
              <SectionActions>
                <div>
                  {clientFormUpdate.id ? (
                    <>
                      <Button
                        color='inherit'
                        onClick={() => {
                          dispatch(clearClientFormUpdate());
                        }}
                      >
                        Cancel
                      </Button>

                      <LoadingButton
                        sx={{
                          ml: 1,
                        }}
                        loading={clientFormLoading}
                        startIcon={<Check />}
                        variant='contained'
                        type='submit'
                      >
                        Save
                      </LoadingButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={(event) => {
                          setPopperEl(event.currentTarget);
                        }}
                      >
                        <MoreVertTwoTone />
                      </IconButton>

                      <Popper
                        open={!!popperEl}
                        anchorEl={popperEl}
                        onClose={() => setPopperEl(null)}
                      >
                        <Stack sx={{ width: 150, p: 1 }}>
                          <Box>
                            <Button
                              fullWidth
                              color='inherit'
                              startIcon={<Edit />}
                              sx={{ justifyContent: 'flex-start' }}
                              onClick={() => {
                                dispatch(setClientFormUpdate(client));
                                setPopperEl(null);
                              }}
                            >
                              Edit
                            </Button>
                          </Box>

                          <Button
                            fullWidth
                            color='error'
                            startIcon={<Delete />}
                            sx={{ justifyContent: 'flex-start' }}
                            onClick={() => {
                              dispatch(setClientFormDelete(client));
                              setPopperEl(null);
                            }}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </Popper>
                    </>
                  )}
                </div>
              </SectionActions>
            </MobileHidden>
          </Section>
        </Form>

        <Divider sx={{ m: '24px 0' }} />

        <Section>
          <SectionTitle>
            <Font type='h5'>Appointments</Font>
            <Font>Client most recent appointments</Font>
          </SectionTitle>

          <SectionContent>
            <List disablePadding sx={{ mt: -2 }}>
              {appointmentsLoading ? (
                [1, 2, 3].map((_, index) => (
                  <Stack key={index} direction='row' p={'1rem 1rem 1rem 0'} spacing={2}>
                    <Box>
                      <Skeleton
                        variant='rectangular'
                        width={35}
                        height={35}
                        sx={{ borderRadius: 1 }}
                      />
                    </Box>

                    <Stack>
                      <Skeleton variant='text' width={250} />

                      <Skeleton variant='text' width={100} />
                    </Stack>
                  </Stack>
                ))
              ) : appointmentsAreFetched && !appointments.length ? (
                <Box mt={2}>
                  <Font>Client does not have any appointments yet.</Font>
                </Box>
              ) : (
                appointments.map((appointment) => (
                  <ListItemButton
                    key={appointment.id}
                    sx={{ ml: -2 }}
                    onClick={(e) => bookingPreviewHandler(appointment, e)}
                  >
                    <ListItemIcon>
                      <Event fontSize='large' />
                    </ListItemIcon>

                    <ListItemText
                      primary={`${moment(appointment.start_time).format(
                        'ddd D MMMM YYYY, HH:mm',
                      )} ${moment(appointment.end_time).format('HH:mm')}`}
                      secondary={`${appointment.service.name} with ${appointment.staff.name}`}
                    />
                  </ListItemButton>
                ))
              )}
            </List>
          </SectionContent>

          <AppointmentDummyElement />
        </Section>
      </Content>

      <Modal
        open={!!clientFormDelete.id}
        title={'Delete Client'}
        loading={clientFormLoading}
        onClose={() => dispatch(clearClientFormDelete())}
        submitText='Delete'
        danger
        onSubmit={() => {
          dispatch(clientDeleteRequest());
        }}
      >
        <Font>
          Are you sure you want to delete <strong>{clientFormDelete.name}</strong>?
        </Font>
      </Modal>
    </Container>
  );
}

export default Client;
