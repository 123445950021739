// dependencies
import styled from 'styled-components';
import {
  Button,
  Modal as ModalMui,
  Theme,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Slide,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const Container = styled.div`
  position: absolute;
  min-width: 400px;
  flex: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-width: unset;
    width: 100vw;
    top: unset;
    left: unset;
    transform: unset;
    bottom: 0;
    left: 0;
    max-height: 95%;
    overflow-y: auto;
    border-radius: 13px 13px 0 0;
  }
`;

const StyledCard = styled(Card)`
  border-radius: 0;
`;

const StyledCardHeader = styled(CardHeader)`
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }: { theme: Theme }) => theme.palette.grey[200]};
`;

const StyledCardContent = styled(CardContent)`
  padding: 24px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
  }
`;

const StyledButton = styled(LoadingButton)`
  margin-left: 1rem !important;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0 !important;
  }
`;

const ButtonDanger = styled(LoadingButton)`
  margin-left: 1rem !important;
  background-color: ${({ theme }: { theme: Theme }) => theme.palette.error.main};

  &:hover {
    background-color: ${({ theme }: { theme: Theme }) => theme.palette.error.dark};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0 !important;
  }
`;

interface Props {
  open?: boolean;
  loading?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  title?: string;
  submitText?: string;
  cancelText?: string;
  danger?: boolean;
  className?: string;
  children: React.ReactNode;
}

const Modal = ({
  children,
  open = false,
  onClose,
  onSubmit,
  title,
  submitText = 'Submit',
  cancelText = 'Cancel',
  danger = false,
  loading = false,
  className = '',
}: Props) => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <ModalMui open={open} onClose={onCloseHandler} className={className}>
      <Slide direction='up' in={open} mountOnEnter unmountOnExit appear={mobileScreen}>
        <Container className='modal-container'>
          <StyledCard>
            {title && (
              <StyledCardHeader
                title={title}
                action={
                  <IconButton onClick={onClose}>
                    <Close />
                  </IconButton>
                }
              />
            )}

            <StyledCardContent>
              {children}

              {onSubmit && (
                <ButtonWrapper>
                  <Button
                    size={mobileScreen ? 'large' : 'medium'}
                    onClick={onClose}
                    color='inherit'
                  >
                    {cancelText}
                  </Button>

                  {danger ? (
                    <ButtonDanger
                      variant='contained'
                      color={'primary'}
                      onClick={onSubmit}
                      loading={loading}
                      size={mobileScreen ? 'large' : 'medium'}
                    >
                      {submitText}
                    </ButtonDanger>
                  ) : (
                    <StyledButton
                      variant='contained'
                      color='primary'
                      onClick={onSubmit}
                      loading={loading}
                      size={mobileScreen ? 'large' : 'medium'}
                    >
                      {submitText}
                    </StyledButton>
                  )}
                </ButtonWrapper>
              )}
            </StyledCardContent>
          </StyledCard>
        </Container>
      </Slide>
    </ModalMui>
  );
};

export default Modal;
