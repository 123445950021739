import styled from 'styled-components';

// components
import ResetPasswordComposition from './ResetPasswordComposition';

const Container = styled.div``;

function ResetPasswordScreen() {
  return (
    <Container>
      <ResetPasswordComposition />
    </Container>
  );
}

export default ResetPasswordScreen;
