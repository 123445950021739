export enum LoginFormKeys {
  username = 'username',
  password = 'password',
}

export interface LoginUserForm {
  [LoginFormKeys.username]: string;
  [LoginFormKeys.password]: string;
}

export interface LoginResponse {
  [key: string]: string;
}
