import React, { ReactNode } from 'react';

// dependencies
import styled from 'styled-components';
import { FontColors, FontTypes } from 'model';

interface StyledProps {
  type: FontTypes;
  color: FontColors;
}

const Text = styled.span<StyledProps>`
  ${({ type }) => {
    return { ...styles[type] };
  }};

  color: ${({ color }) => (color === 'primary' ? '#1F4B81' : '#42526e')};
`;

type Style = {
  [key in FontTypes]: React.CSSProperties;
};

const styles: Style = {
  body1: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  h1: {
    fontSize: '1.375rem',
    fontWeight: 600,
    lineHeight: 1.167,
    marginBottom: '0.3rem',
  },
  h2: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: 1.2,
    marginBottom: '0.5rem',
  },
  h3: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: 1.167,
    marginBottom: '0.5rem',
  },
  h4: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: 1.235,
    display: 'block',
    marginBottom: '0.5rem',
  },
  h5: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: 1.334,
    display: 'block',
    margin: '0.2rem 0',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  secondary: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: '#687078',
  },
  label: {
    color: '#42526e',
    textTransform: 'uppercase',
    fontSize: 11,
    fontWeight: 700,
  },
};
// padding-left: 1rem;
// margin: 6px 0;
// color: #42526e;
// text-transform: uppercase;
// font-size: 11px;
// font-weight: 700;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  type?: FontTypes;
  color?: FontColors;
  className?: string;
}

const Font = ({ className, children, type = 'body1', color = 'default', ...props }: Props) => {
  return (
    <Text {...props} type={type} className={className} color={color}>
      {children}
    </Text>
  );
};

export default Font;
