import { useState } from 'react';

// dependencies
import {
  Check,
  ChevronLeft,
  Delete,
  Edit,
  KeyboardBackspaceRounded,
  MoreVertTwoTone,
} from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Stack } from '@mui/material';
import { Font, Popper } from 'elements';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthEffect, useWillUnmount } from 'hooks';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import {
  setClientFormUpdate,
  clearClientFormUpdate,
  setClientFormDelete,
  setClientFormDeleteSuccess,
  clientRequest,
  clientUpdateRequest,
} from '.';

// components
import Client from './components/Client';
import { LoadingButton } from '@mui/lab';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem;
  }
`;

const Content = styled.div`
  margin-top: 1rem;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const XsScreenShow = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
  }
`;

const MdScreenShow = styled.div`
  display: none;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
  }
`;

interface Params {
  id?: string;
}

function ClientComposition() {
  const { client, clientFormDeleteSuccess, clientFormLoading, clientFormUpdate } = useSelector(
    (state: RootState) => state.client,
  );
  const [popperEl, setPopperEl] = useState<any>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<Params>();

  useWillUnmount(() => {
    dispatch(setClientFormDeleteSuccess(false));
  });

  useAuthEffect(() => {
    if (clientFormDeleteSuccess) {
      history.push('/clients');
    }
  }, [clientFormDeleteSuccess]);

  useAuthEffect(() => {
    if (id && +id !== client.id) {
      dispatch(clientRequest(+id));
    }
  }, [id]);

  return (
    <Container>
      <PageHeader>
        <XsScreenShow>
          <Box flex={1}>
            <IconButton onClick={() => history.goBack()}>
              <KeyboardBackspaceRounded sx={{ fontSize: 30 }} />
            </IconButton>
          </Box>

          <Box flex={1.5} textAlign='center'>
            <Font type='h1'>Client Detail</Font>
          </Box>

          <Stack flex={1} direction='row' justifyContent='flex-end'>
            {clientFormUpdate.id ? (
              <>
                <Button
                  color='inherit'
                  onClick={() => {
                    dispatch(clearClientFormUpdate());
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  sx={{
                    ml: 1,
                  }}
                  loading={clientFormLoading}
                  startIcon={<Check />}
                  variant='contained'
                  onClick={() => dispatch(clientUpdateRequest())}
                >
                  Save
                </LoadingButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={(event) => {
                    setPopperEl(event.currentTarget);
                  }}
                >
                  <MoreVertTwoTone />
                </IconButton>

                <Popper open={!!popperEl} anchorEl={popperEl} onClose={() => setPopperEl(null)}>
                  <Stack p={1} spacing={1} divider={<Divider />}>
                    <Box>
                      <Button
                        fullWidth
                        color='inherit'
                        size='large'
                        startIcon={<Edit />}
                        onClick={() => {
                          dispatch(setClientFormUpdate(client));
                          setPopperEl(null);
                        }}
                      >
                        Edit
                      </Button>
                    </Box>

                    <Button
                      fullWidth
                      color='error'
                      size='large'
                      startIcon={<Delete />}
                      onClick={() => {
                        dispatch(setClientFormDelete(client));
                        setPopperEl(null);
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                </Popper>
              </>
            )}
          </Stack>
        </XsScreenShow>

        <MdScreenShow>
          <Stack>
            <Font type='h1'>Client Detail</Font>

            <Font>
              Client detailed page, here you can edit client information or permanently delete them.
              Also, check their most recent appointments.
            </Font>
          </Stack>

          <Box mt={2} ml={-1}>
            <Button
              color='inherit'
              startIcon={<ChevronLeft />}
              onClick={() => history.push('/clients')}
            >
              Back
            </Button>
          </Box>
        </MdScreenShow>
      </PageHeader>

      <Divider />

      <Content>
        <Client />
      </Content>
    </Container>
  );
}

export default ClientComposition;
