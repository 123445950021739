import { useDocumentTitle } from 'hooks';
import { PrivateLayout } from 'layout';

// components
import DashboardComposition from './DashboardComposition';

const DashboardScreen = () => {
  useDocumentTitle('Dashboard - Gotei');

  return (
    <PrivateLayout>
      <DashboardComposition />
    </PrivateLayout>
  );
};

export default DashboardScreen;
