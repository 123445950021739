import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { FieldsErrors, FormFieldPayload, Service, ServiceFormAdd } from 'model';
import { RootState } from 'store/config';

interface State {
  services: Array<Service>;
  servicesLoading: boolean;
  servicesAreFetched: boolean;
  serviceFormAddModalShow: boolean;
  serviceEditLoading: boolean;
  serviceFormAdd: ServiceFormAdd;
  serviceFormAddError: FieldsErrors;
  serviceDelete: Service;
  serviceDeleteSuccess: boolean;
}

const initialState: State = {
  services: [],
  servicesLoading: false,
  servicesAreFetched: false,
  serviceFormAddModalShow: false,
  serviceEditLoading: false,
  serviceFormAdd: {
    name: '',
    duration: '',
    description: '',
    company: '',
    staff: [],
  },
  serviceFormAddError: {},
  serviceDelete: {
    company: '',
    created: '',
    description: '',
    duration: '',
    id: 0,
    name: '',
    staff: [],
    updated: '',
    image: '',
    is_meeting: false,
    is_active: true,
  },
  serviceDeleteSuccess: false,
};

const ServicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices: (state, action: PayloadAction<Array<Service>>) => {
      return {
        ...state,
        services: action.payload,
      };
    },
    setServicesLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        servicesLoading: action.payload,
      };
    },
    setServicesAreFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        servicesAreFetched: action.payload,
      };
    },
    setServiceFormAddModalShow: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        serviceFormAddModalShow: action.payload,
      };
    },
    serviceEditLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        serviceEditLoading: action.payload,
      };
    },
    updateServiceFormAdd: (
      state,
      action: PayloadAction<FormFieldPayload<keyof ServiceFormAdd>>,
    ) => {
      return {
        ...state,
        serviceFormAdd: {
          ...state.serviceFormAdd,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearServiceFormAdd: (state) => {
      return {
        ...state,
        serviceFormAdd: {
          name: '',
          duration: '',
          description: '',
          company: '',
          staff: [],
        },
      };
    },
    setServiceFormAddError: (
      state,
      action: PayloadAction<FormFieldPayload<keyof Service | 'server'>>,
    ) => {
      return {
        ...state,
        serviceFormAddError: {
          ...state.serviceFormAddError,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearServiceFormAddError: (state) => {
      return {
        ...state,
        serviceFormAddError: {},
      };
    },
    setServiceDelete: (state, action: PayloadAction<Service>) => {
      return {
        ...state,
        serviceDelete: action.payload,
      };
    },
    clearServiceDelete: (state) => {
      return {
        ...state,
        serviceDelete: {
          company: '',
          created: '',
          description: '',
          duration: '',
          id: 0,
          name: '',
          staff: [],
          updated: '',
          image: '',
          is_meeting: false,
          is_active: true,
        },
      };
    },
    setServiceDeleteSuccess: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        serviceDeleteSuccess: action.payload,
      };
    },
  },
});

export const {
  setServices,
  setServicesLoading,
  setServicesAreFetched,
  setServiceFormAddModalShow,
  updateServiceFormAdd,
  clearServiceFormAdd,
  setServiceFormAddError,
  clearServiceFormAddError,
  setServiceDelete,
  clearServiceDelete,
  setServiceDeleteSuccess,
  serviceEditLoading,
} = ServicesSlice.actions;

export enum ServicesActionTypes {
  SERVICES_REQUEST = 'services/SERVICES_REQUEST',
  SERVICE_ADD_REQUEST = 'services/SERVICE_ADD_REQUEST',
  SERVICE_DELETE_REQUEST = 'services/SERVICE_DELETE_REQUEST',
}

export const servicesRequest = () => {
  return {
    type: ServicesActionTypes.SERVICES_REQUEST,
  };
};

export const serviceAddRequest = () => {
  return {
    type: ServicesActionTypes.SERVICE_ADD_REQUEST,
  };
};

export const serviceDeleteRequest = () => {
  return {
    type: ServicesActionTypes.SERVICE_DELETE_REQUEST,
  };
};

export const selectServices = (state: RootState) => {
  return state.services;
};

export const selectServiceList = createSelector([selectServices], (value) => value.services);

export const selectServiceFormAdd = createSelector(
  [selectServices],
  (value) => value.serviceFormAdd,
);

export const selectServiceFormAddError = createSelector(
  [selectServices],
  (value) => value.serviceFormAddError,
);

export const selectServiceDelete = createSelector([selectServices], (value) => value.serviceDelete);

export const servicesReducer = ServicesSlice.reducer;
