import { Logo } from 'elements';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { GoogleButton } from 'shared';

// components
import LoginForm from './LoginForm';
import { Link, Theme, Typography, Divider } from '@mui/material';

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  z-index: 1;
`;

const Content = styled.div`
  width: 540px;
  box-sizing: border-box;
  padding: 3rem 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 90%;
    padding: 3rem 0;
  }
`;

const LogoWrapper = styled.div`
  padding: 0 0 40px 20px;
`;

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 56px 68px;
  box-shadow: 0 14px 32px 0 rgb(22 45 61 / 8%), 0 1px 4px 0 rgb(22 45 61 / 10%);
  border-radius: 3px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 40px 28px;
  }
`;

const FooterText = styled.div`
  text-align: center;
  font-size: 0.875rem;
  color: ${({ theme }: { theme: Theme }) => theme.palette.grey[600]};
`;

const Login = () => {
  return (
    <Container>
      <Content>
        <LogoWrapper>
          <Logo link='/' />
        </LogoWrapper>

        <FormWrapper>
          <LoginForm />

          <Typography align='center' margin='1rem 0'>
            OR
          </Typography>

          <GoogleButton text='Sign in with Google' />

          <Divider sx={{ m: '2rem 0 1rem 0' }} />

          <FooterText>
            Don't have an account yet?{' '}
            <Link component={RouterLink} to='/signup' variant='body2'>
              Sign up
            </Link>
          </FooterText>
        </FormWrapper>
      </Content>
    </Container>
  );
};

export default Login;
