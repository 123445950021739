import { useState, useEffect, ChangeEvent } from 'react';

// dependencies
import { InputAdornment, MenuItem, Select, TextField, TextFieldProps } from '@mui/material';
import { Font } from 'elements';
import { countryCodes } from 'utils';
import { CountryCode } from 'model';

interface BasicProps {
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string | null) => void;
  value: string | null;
}

type Props = Omit<TextFieldProps, 'onChange' | 'value'> & BasicProps;

const PhoneInput = ({ onChange = () => {}, value, ...rest }: Props) => {
  const [countryCode, setCountryCode] = useState<CountryCode>({
    name: '',
    flag: '',
    code: '',
    dial_code: '',
  });
  const regex = /^\S*/g; // this regex find the first word

  useEffect(() => {
    const UnitedKingdomCode = countryCodes.find((country) => country.name === 'United Kingdom');

    if (UnitedKingdomCode) setCountryCode(UnitedKingdomCode);
  }, []);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value) {
      onChange(event, countryCode.dial_code + ' ' + value);
    } else {
      onChange(event, null);
    }
  };

  return (
    <TextField
      {...rest}
      type='tel'
      value={value ? value?.trim().replace(regex, '').trim() : ''}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Select
              variant='standard'
              disableUnderline
              value={countryCode.name}
              renderValue={(value: any) => {
                const countryCode = countryCodes.find((countryCode) => countryCode.name === value);

                if (countryCode) {
                  return `${countryCode.flag} ${countryCode.dial_code}`;
                } else {
                  return value;
                }
              }}
              onChange={(event) => {
                const countryName = event.target.value;
                const countryCode = countryCodes.find(
                  (countryCode) => countryCode.name === countryName,
                );

                if (countryCode) setCountryCode(countryCode);
              }}
            >
              {countryCodes.map((countryCode) => (
                <MenuItem key={countryCode.code} value={countryCode.name}>
                  {countryCode.flag} {countryCode.name}
                  <Font
                    style={{
                      marginLeft: 8,
                      fontSize: 16,
                      opacity: 0.6,
                    }}
                  >
                    {countryCode.dial_code}
                  </Font>
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        ),
      }}
      onChange={onChangeHandler}
    />
  );
};

export default PhoneInput;
