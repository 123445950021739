import styled, { CSSObject } from 'styled-components';
import { ReactComponent as LogoSVG } from 'assets/svg/logo.svg';
import { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

interface StyledProps {
  link?: boolean;
}

const sizeMapper: Record<Size, CSSObject> = {
  large: {
    height: 40,
    width: 'auto',
  },
  normal: {
    height: 35,
    width: 'auto',
  },
  small: {
    height: 30,
    width: 'auto',
  },
};

const Container = styled.div<StyledProps>`
  display: flex;
  align-items: center;

  &:hover {
    cursor: ${({ link }) => (link ? 'pointer' : 'unset')};
  }
`;

const LogoSvg = styled(LogoSVG)<{ size: Size }>`
  ${({ size }) => sizeMapper[size]}
`;

type Size = 'large' | 'normal' | 'small';

interface Props extends HTMLAttributes<HTMLDivElement> {
  link?: string;
  size?: Size;
}

const Logo = ({ link = '', size = 'normal', ...rest }: Props) => (
  <Container {...rest} link={!!link}>
    <Link to={link}>
      <LogoSvg size={size} />
    </Link>
  </Container>
);

export default Logo;
