import { call, fork, takeLatest, put, select, all } from 'redux-saga/effects';

import { staffModuleRpc } from 'services/api';
import { avatarGenerator, getErrorEntry, logger } from 'utils';
import { BusinessHour, Staff } from 'model';
import { RootState } from 'store/config';
import { selectCompanyBusinessHours } from 'store/organization';
import {
  StaffActionTypes,
  setStaff,
  setStaffLoading,
  setStaffAreFetched,
  selectStaffDetailCreate,
  setStaffDetailCreateError,
  selectStaffList,
  clearStaffDetailCreate,
  selectStaffDetailDelete,
  clearStaffDetailDelete,
  setStaffDetailDeleteLoading,
  setStaffDetailCreateLoading,
  selectSearchText,
  setFilteredStaff,
  staffRequest,
} from '.';

function* getStaff() {
  try {
    logger('Staff Request');
    yield put(setStaffLoading(true));

    const response: Array<Staff> = yield call(staffModuleRpc.getStaff.bind(staffModuleRpc));

    yield put(setStaff(response));
    yield put(setFilteredStaff(response));
    yield put(setStaffAreFetched(true));
    yield put(setStaffLoading(false));
    logger('Staff Success');
  } catch (error: any) {
    yield put(setStaffLoading(false));
    logger('Staff Failure');
  }
}

function* deleteStaffDetail() {
  const state: RootState = yield select();
  const staffDetailDelete = selectStaffDetailDelete(state);

  if (!staffDetailDelete.id) {
    return;
  }

  try {
    logger('Staff Detail Delete Request');

    yield put(setStaffDetailDeleteLoading(true));

    yield call([staffModuleRpc, staffModuleRpc.deleteStaffDetail], staffDetailDelete.id);

    yield put(clearStaffDetailDelete());
    yield put(staffRequest());
    yield put(setStaffDetailDeleteLoading(false));
    logger('Staff Detail Delete Success');
  } catch (error: any) {
    yield put(setStaffDetailDeleteLoading(false));
    logger('Staff Detail Delete Failure');
  }
}

function* createStaffDetail() {
  const state: RootState = yield select();
  const staffDetailCreate = selectStaffDetailCreate(state);

  try {
    logger('Staff Detail Create Request');

    yield put(setStaffDetailCreateLoading(true));

    const match = staffDetailCreate.name.match(/\b(\w)/g)?.slice(0, 2);
    const letters = match?.join('').toUpperCase();
    const image = avatarGenerator(letters);

    const staffDetail: Staff = yield call([staffModuleRpc, staffModuleRpc.createStaffDetail], {
      ...staffDetailCreate,
      image: image ?? '',
    });

    yield put(staffRequest());
    yield put(clearStaffDetailCreate());
    yield put(setStaffDetailCreateLoading(false));
    logger('Staff Detail Create Success');

    yield call(createStaffWorkingHourBulk, staffDetail);
  } catch (error: any) {
    const { key, value } = getErrorEntry(error);

    yield put(
      setStaffDetailCreateError({
        key,
        value,
      }),
    );

    yield put(setStaffDetailCreateLoading(false));
    logger('Staff Detail Create Failure');
  }
}

function* filterStaff() {
  const state: RootState = yield select();
  const searchText = selectSearchText(state).trim().toLowerCase();
  const staff = selectStaffList(state);

  const filteredStaff: Staff[] = [];

  if (searchText) {
    staff.forEach((staffDetail) => {
      const check = Object.values(staffDetail).some((value: string | number) =>
        (value || '').toString().toLowerCase().includes(searchText),
      );

      if (check) filteredStaff.push(staffDetail);
    });
  } else {
    filteredStaff.push(...staff);
  }

  yield put(setFilteredStaff(filteredStaff));
}

function* createStaffWorkingHourBulk(staffDetail: Staff) {
  const state: RootState = yield select();
  const businessHours = selectCompanyBusinessHours(state);

  yield all(
    businessHours.map((businessHour) => call(createStaffWorkingHour, staffDetail, businessHour)),
  );
}

function* createStaffWorkingHour(staffDetail: Staff, businessHour: BusinessHour) {
  try {
    logger('Staff Working Hours Create Request');

    yield call([staffModuleRpc, staffModuleRpc.createWorkingHour], {
      ...businessHour,
      staff: staffDetail.id,
    });

    logger('Staff Working Hours Create Success');
  } catch (error) {
    logger.error('Staff Working Hours Create Failure');
  }
}

function* staffRequestWatcher() {
  yield takeLatest(StaffActionTypes.STAFF_REQUEST, getStaff);
}

function* staffDetailDeleteRequestWatcher() {
  yield takeLatest(StaffActionTypes.STAFF_DETAIL_DELETE_REQUEST, deleteStaffDetail);
}

function* staffDetailCreateRequestWatcher() {
  yield takeLatest(StaffActionTypes.STAFF_DETAIL_CREATE_REQUEST, createStaffDetail);
}

function* filterStaffWatcher() {
  yield takeLatest(StaffActionTypes.FILTER_STAFF, filterStaff);
}

export const staffSagas = [
  fork(staffRequestWatcher),
  fork(staffDetailDeleteRequestWatcher),
  fork(staffDetailCreateRequestWatcher),
  fork(filterStaffWatcher),
];
