import { useEffect } from 'react';

import {
  Step,
  StepLabel,
  Stepper,
  Theme,
  StepContent,
  Box,
  Stack,
  IconButton,
  CircularProgress,
  Divider,
  useMediaQuery,
} from '@mui/material';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Background, Font } from 'elements';
import moment from 'moment-timezone';
import {
  ChevronLeft,
  AccessTime,
  InsertInvitation,
  Person,
  RoomService,
} from '@mui/icons-material';

// redux
import { RootState } from 'store/config';
import { useDispatch, useSelector } from 'react-redux';
import { companyDetailRequest, setStep, businessHoursRequest, updateBookingForm } from '.';

// components
import ConfirmBooking from './components/ConfirmBooking';
import DateForm from './components/DateForm';
import ProviderForm from './components/ProviderForm';
import ServiceForm from './components/ServiceForm';
import MobileStepper from './components/MobileStepper';

import { ReactComponent as SVG } from 'assets/svg/dog_walking.svg';
import calendarSuccess from 'assets/images/calendar-success.png';

const mobileMediaQuery = '(max-width:770px)';

const steps = [
  {
    title: 'Select your service',
    label: 'Service',
    description: 'Choose the service you would like to book for your appointment.',
  },
  {
    title: 'Select your provider',
    label: 'Provider',
    description: 'Choose the person who would like to make the appointment.',
  },
  {
    title: 'Select your date & time',
    label: 'Date',
    description: 'Pick a date, then choose one of the available time slots.',
  },
  {
    title: 'Confirm',
    label: 'Confirm',
    description: 'Enter your details and confirm the booking.',
  },
];

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }: { theme: Theme }) => theme.palette.grey[50]};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
`;

const Content = styled.div`
  z-index: 1;
  margin-top: 60px;
  display: flex;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 56px 48px;
  box-shadow: 0 14px 32px 0 rgb(22 45 61 / 8%), 0 1px 4px 0 rgb(22 45 61 / 10%);
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 90%;

  @media ${mobileMediaQuery} {
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 2rem 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 90%;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 56px 1.5rem;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const RightSection = styled.div`
  box-sizing: border-box;
  flex: 2;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const CenterSection = styled.div`
  margin-top: 60px;
  z-index: 100;
  flex: 1;
  display: flex;
  padding: 2rem;
`;

const SvgContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddStaffSvg = styled(SVG)`
  height: auto;
  width: 300px;
`;

const LoadingWrapper = styled.div`
  z-index: 1;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  margin-right: 1.5rem;
`;

const Img = styled.img`
  width: 100%;
  max-width: 200px;
  min-width: 150px;
  height: auto;
`;

const AppointmentContent = styled(Content)`
  width: unset;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 90%;
  }
`;

const AppointmentOverview = styled.div`
  flex: 1;
  min-width: 500px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-width: unset;
  }
`;

const Header = styled.div`
  text-align: center;
`;

const Service = styled.div`
  flex: 1;
  margin-left: 24px;
`;

const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const SuccessImg = styled.img``;

const activeStepper = (step: number, companyUrl: string) => {
  if (step === 0) {
    return <ServiceForm companyUrl={companyUrl} />;
  } else if (step === 1) {
    return <ProviderForm />;
  } else if (step === 2) {
    return <DateForm />;
  } else if (step === 3) {
    return <ConfirmBooking />;
  }
};

interface Params {
  companyUrl: string;
}

function BookingPageComposition() {
  const {
    step,
    stepsLabelOptional,
    company,
    companyLoading,
    companyError,
    confirmBookingIsDone,
    booking,
  } = useSelector((state: RootState) => state.bookingPage);
  const dispatch = useDispatch();
  const { companyUrl } = useParams<Params>();
  const mobileScreen = useMediaQuery(mobileMediaQuery);

  useEffect(() => {
    if (company.uuid) {
      moment.tz.setDefault(company.timezone);

      moment.locale('en', {
        week: {
          dow: 1,
          doy: 1,
        },
      });
    }
  }, [company]);

  useEffect(() => {
    dispatch(companyDetailRequest(companyUrl));
    dispatch(businessHoursRequest(companyUrl));
  }, [dispatch, companyUrl]);

  useEffect(() => {
    dispatch(
      updateBookingForm({
        key: 'company',
        value: company.uuid,
      }),
    );
  }, [dispatch, company]);

  return (
    <Container>
      <Background />

      {confirmBookingIsDone && (
        <AppointmentContent>
          <AppointmentOverview>
            <Image>
              <SuccessImg src={calendarSuccess} />
            </Image>

            <Header>
              <Font
                type='h2'
                style={{
                  display: 'block',
                  marginBottom: 8,
                }}
              >
                Confirmed
              </Font>

              <Font
                style={{
                  color: 'rgba(26, 26, 26, 0.6)',
                  display: 'block',
                }}
              >
                You are scheduled with <strong>{booking.staff.name}</strong>.
              </Font>
            </Header>

            <Divider sx={{ m: '24px 0' }} />

            <Service>
              <Font type='h4' style={{ marginBottom: 16, color: 'rgba(26, 26, 26, 0.6)' }}>
                Appointment Overview
              </Font>

              <ServiceItem>
                <RoomService sx={{ mr: 2 }} />

                <Font>{booking.service.name}</Font>
              </ServiceItem>

              <ServiceItem>
                <Person sx={{ mr: 2 }} />

                <Font>{`${booking.client.name}`}</Font>
              </ServiceItem>

              <ServiceItem>
                <InsertInvitation sx={{ mr: 2 }} />

                <Font>{moment(booking.start_time).format('ddd D MMMM YYYY, HH:mm')}</Font>
              </ServiceItem>

              <ServiceItem>
                <AccessTime sx={{ mr: 2 }} />

                <Font>{moment(booking.end_time).diff(booking.start_time, 'minutes')} minutes</Font>
              </ServiceItem>
            </Service>

            <Divider sx={{ margin: '8px 0 0 0' }} />
          </AppointmentOverview>
        </AppointmentContent>
      )}

      {companyLoading && (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      )}

      {!companyLoading && companyError && (
        <CenterSection>
          <SvgContainer>
            <AddStaffSvg />

            <Font style={{ marginTop: '4rem', fontSize: '2rem', marginBottom: 16 }} type='h4'>
              We are unable to find that page
            </Font>

            <Font style={{ fontSize: 16 }}>Please make sure you have the right URL.</Font>
          </SvgContainer>
        </CenterSection>
      )}

      {!confirmBookingIsDone && !companyError && !companyLoading && (
        <Content>
          <LeftSection style={{ display: confirmBookingIsDone ? 'none' : 'flex' }}>
            {mobileScreen ? (
              <MobileStepper />
            ) : (
              <>
                <Stack mb={4}>
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Box flex={1}>
                      <StyledIconButton
                        disabled={step === 0}
                        sx={{ opacity: step === 0 ? 0 : 1 }}
                        onClick={() => {
                          dispatch(setStep(Math.max(step - 1, 0)));
                        }}
                      >
                        <ChevronLeft color='primary' fontSize='large' />
                      </StyledIconButton>
                    </Box>

                    <Box textAlign='center' flex={4}>
                      {company.image ? (
                        <Img src={company.image} />
                      ) : (
                        <Font style={{ fontSize: 30 }} type='h1'>
                          {company.name}
                        </Font>
                      )}
                    </Box>

                    <Box flex={1} />
                  </Stack>
                </Stack>

                <Font style={{ marginBottom: 16 }} type='h4'>
                  {steps[step].title}
                </Font>

                <Stepper activeStep={step} orientation='vertical'>
                  {steps.map((step) => (
                    <Step key={step.label}>
                      <StepLabel optional={<Font>{stepsLabelOptional[step.label]}</Font>}>
                        {step.label}
                      </StepLabel>

                      <StepContent>
                        <Box display='flex' maxWidth={300} minWidth={250}>
                          <Font>{step.description}</Font>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </>
            )}
          </LeftSection>

          {!mobileScreen && (
            <Divider
              sx={{
                m: {
                  sm: '-56px 24px',
                  md: '-56px 24px',
                  lg: '-56px 48px',
                },
              }}
              orientation='vertical'
              variant='middle'
              flexItem
            />
          )}

          <RightSection>{activeStepper(step, companyUrl)}</RightSection>
        </Content>
      )}
    </Container>
  );
}

export default BookingPageComposition;
