import { ReactNode } from 'react';

// dependencies
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Theme,
} from '@mui/material';
import styled from 'styled-components';

const Container = styled.div``;

const StyledTableContainer = styled(TableContainer)<StyledProps>`
  max-height: ${({ height }) => `${height}px`};
`;

const Separator = styled.span`
  background-color: ${({ theme }: { theme: Theme }) => theme.palette.grey[200]};
  display: inline-block;
  min-width: 1px;
  height: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(50%);
`;

const TableCellChecked = styled(TableCell)`
  flex: 0 !important;
  display: flex !important;
  align-items: center !important; ;
`;

interface StyledProps {
  height: number;
}

interface Render {
  render?: (item: any) => ReactNode;
}

interface ColumnOptions {
  renderer?: Array<Render>;
}

interface RowOptions {
  onClick?: (item: any) => void;
  hover?: boolean;
}

interface Props {
  headers?: Array<string>;
  data?: Array<any>;
  columnOptions?: ColumnOptions;
  rowOptions?: RowOptions;
  selected?: Array<string>;
  selectable?: boolean;
  onDeSelectAll?: () => void;
  height?: number;
  separator?: boolean;
}

const DataGrid = ({
  headers = [],
  data = [],
  columnOptions = {},
  rowOptions = {},
  selected,
  selectable = false,
  onDeSelectAll,
  height = 450,
  separator = false,
}: Props) => {
  const { renderer = [] } = columnOptions;
  const { onClick, hover } = rowOptions;

  const cellRender = (cell: any, index: number) => {
    const colRenderer = renderer[index];

    if (colRenderer?.render) {
      return <TableCell key={index}>{colRenderer.render(cell)}</TableCell>;
    } else {
      return <TableCell key={index}>{cell}</TableCell>;
    }
  };

  const rowRender = (row: Array<any>) => {
    return row.map((cell, cellIndex) => cellRender(cell, cellIndex));
  };

  return (
    <Container>
      <StyledTableContainer height={height}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {selectable && (
                <TableCellChecked padding='checkbox'>
                  <Checkbox
                    color='primary'
                    indeterminate
                    checked={!!selected?.length}
                    disabled={!selected?.length}
                    onClick={() => {
                      if (onDeSelectAll) {
                        onDeSelectAll();
                      }
                    }}
                  />
                </TableCellChecked>
              )}

              {headers.map((column, index) => (
                <TableCell key={index}>
                  {column}
                  {separator && <Separator />}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, index) => {
              const rowId = row[0];
              const isRowSelected = selected?.includes(rowId);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover={hover}
                  onClick={() => {
                    if (onClick) {
                      onClick(row);
                    }
                  }}
                  role='checkbox'
                  aria-checked={true}
                  tabIndex={-1}
                  key={index}
                  selected={isRowSelected}
                >
                  {selectable && (
                    <TableCellChecked padding='checkbox'>
                      <Checkbox
                        checked={isRowSelected}
                        color='primary'
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCellChecked>
                  )}

                  {rowRender(row)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Container>
  );
};

export default DataGrid;
