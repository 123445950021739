import styled from 'styled-components';

// components
import NavBar from './components/Navbar';
import Hero from './components/Hero';
import QuickStart from './components/QuickStart';
import ScheduleEventsAnytime from './components/ScheduleEventsAnytime';
import ThingsThatMatter from './components/ThingsThatMatter';
import Features from './components/Features';
import Register from './components/Register';
import Footer from './components/Footer/Footer';

const Container = styled.div``;

function LandingPageComposition() {
  return (
    <Container>
      <NavBar />
      <Hero />
      <QuickStart />
      <ScheduleEventsAnytime />
      <ThingsThatMatter />
      <Features />
      <Register />
      <Footer />
    </Container>
  );
}

export default LandingPageComposition;
