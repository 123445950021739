import { useDocumentTitle } from 'hooks';
import { PrivateLayout } from 'layout';

// components
import { GoogleMeetIntegrationComposition } from 'screens/GoogleMeetIntegration';

export const GoogleMeetIntegrationScreen = () => {
  useDocumentTitle('Google Meet - Gotei');

  return (
    <PrivateLayout>
      <GoogleMeetIntegrationComposition />
    </PrivateLayout>
  );
};
