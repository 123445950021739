import { Font } from 'elements';
import styled from 'styled-components';

import { ReactComponent as Meeting1 } from 'assets/svg/meeting-1.svg';
import { ReactComponent as Meeting2 } from 'assets/svg/meeting-2.svg';

import blob1 from 'assets/svg/blob-1.svg';
import blob2 from 'assets/svg/blob-2.svg';
import { Stack } from '@mui/material';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1rem;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-bottom: 100px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 4rem;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Font)`
  text-align: center;
  font-size: 52px;
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 38px;
  }
`;

const Description = styled(Font)`
  text-align: center;
  font-size: 24px;
  margin-top: 24px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 2rem;
  }
`;

const Blob1Background = styled.div`
  flex: 1;
  background-image: url(${blob1});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 450px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Blob2Background = styled.div`
  flex: 1;
  background-image: url(${blob2});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 450px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BookingExplainDescriptionTitle = styled(Font)`
  font-size: 24px;
  font-weight: 700;
`;

const BookingExplainDescription = styled(Font)`
  margin-top: 24px;
  font-size: 18px;
`;

const MeetingSvg1 = styled(Meeting1)`
  width: 220px;
`;

const MeetingSvg2 = styled(Meeting2)`
  width: 220px;
`;

function ScheduleEventsAnytime() {
  return (
    <Container>
      <Content>
        <TitleWrapper>
          <Title type='h1'>Schedule events anytime without worrying about anything</Title>

          <Description>
            Just sit back and relax, Gotei will automate your appointments and handle everything for
            you.
          </Description>
        </TitleWrapper>

        <Stack direction={{ sm: 'row', xs: 'column' }} spacing={4} mt={{ sm: 8 }}>
          <Stack flex={1}>
            <Blob1Background>
              <MeetingSvg1 />
            </Blob1Background>

            <Stack maxWidth={400}>
              <BookingExplainDescriptionTitle>
                Unlimited Appointments
              </BookingExplainDescriptionTitle>

              <BookingExplainDescription>
                We don't like Boundaries and you will not have them. With Gotei you have unlimited
                appointments every month totally free.
              </BookingExplainDescription>
            </Stack>
          </Stack>

          <Stack flex={1}>
            <Blob2Background>
              <MeetingSvg2 />
            </Blob2Background>
            <Stack maxWidth={400}>
              <BookingExplainDescriptionTitle>
                User friendly interface
              </BookingExplainDescriptionTitle>

              <BookingExplainDescription>
                Our platform is super friendly and easy to use. It is tailored to make your daily
                appointment management fun and enjoyable.
              </BookingExplainDescription>
            </Stack>
          </Stack>
        </Stack>
      </Content>
    </Container>
  );
}

export default ScheduleEventsAnytime;
