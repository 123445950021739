import { useEffect } from 'react';

import styled from 'styled-components';
import moment from 'moment-timezone';
import { Font } from 'elements';
import { SlotPicker } from 'shared';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import {
  setStep,
  setDate,
  availabilityRequest,
  updateBookingForm,
  setStepsLabelOptional,
  staffWorkingHoursRequest,
} from '..';
import { useDocumentTitle } from 'hooks';

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 2rem;
`;

function DateForm() {
  const {
    businessHours,
    staffWorkingHours,
    date,
    isLoading,
    availability,
    availabilityError,
    availabilityAreFetched,
    company,
  } = useSelector((state: RootState) => state.bookingPage);
  const dispatch = useDispatch();

  useDocumentTitle('Select a Date & Time - Gotei');

  useEffect(() => {
    dispatch(
      setStepsLabelOptional({
        key: 'Date',
        value: '',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (date) dispatch(availabilityRequest());
  }, [dispatch, date]);

  useEffect(() => {
    dispatch(staffWorkingHoursRequest());
  }, [dispatch]);

  return (
    <Container>
      <Font type='h2'>Date & Time</Font>

      <Content>
        <SlotPicker
          date={date}
          company={company}
          businessHours={businessHours}
          staffWorkingHours={staffWorkingHours}
          availability={availability}
          availabilityError={availabilityError}
          availabilityLoading={isLoading}
          availabilityAreFetched={availabilityAreFetched}
          setDate={(date) => dispatch(setDate(date))}
          onSlotSelect={(stateTime) => {
            dispatch(
              setStepsLabelOptional({
                key: 'Date',
                value: moment(stateTime).format('HH:mm ddd, DD MMMM YYYY'),
              }),
            );

            dispatch(
              updateBookingForm({
                key: 'start_time',
                value: stateTime.format(),
              }),
            );

            dispatch(setStep(3));
          }}
        />
      </Content>
    </Container>
  );
}

export default DateForm;
