import { Fragment, useState } from 'react';

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Add, Delete, MoreVert } from '@mui/icons-material';
import styled from 'styled-components';
import { Font, Modal, Popper } from 'elements';
import { useAuthEffect } from 'hooks';
import { useHistory } from 'react-router';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import {
  servicesRequest,
  setServiceFormAddModalShow,
  serviceAddRequest,
  setServiceDelete,
  clearServiceDelete,
  serviceDeleteRequest,
  updateServiceFormAdd,
} from '..';

import ServiceFormAdd from './ServiceFormAdd';

import { ReactComponent as SVG } from 'assets/svg/services.svg';
import image from 'assets/images/placeholder.png';

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
`;

const Service = styled.div`
  width: 300px;
  height: 200px;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem 0 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[50]};
  }
`;

const ServiceHead = styled.div``;

const ServiceName = styled.div`
  display: flex;
`;

const ServiceDescription = styled.div`
  margin-top: 1rem;
`;

const ServiceAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SvgContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 2rem 0;
`;

const AddClientSvg = styled(SVG)`
  width: 30%;
  height: auto;
  max-width: 280px;
`;

function Services() {
  const { company } = useSelector((state: RootState) => state.organization);
  const {
    services,
    servicesLoading,
    servicesAreFetched,
    serviceFormAddModalShow,
    serviceEditLoading,
    serviceDelete,
  } = useSelector((state: RootState) => state.services);
  const [popperEl, setPopperEl] = useState<any>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useAuthEffect(() => {
    dispatch(servicesRequest());
  }, []);

  return (
    <Container>
      <Content>
        {servicesLoading && !servicesAreFetched ? (
          <CircularProgress sx={{ mt: 1 }} size={24} />
        ) : !services.length ? (
          <SvgContainer>
            <AddClientSvg />

            <Font style={{ marginTop: '2rem' }} type='h4'>
              Your services are empty
            </Font>

            <Font>Create a new service and get booked.</Font>

            <Button
              sx={{ mt: 3 }}
              startIcon={<Add />}
              onClick={() => {
                dispatch(
                  updateServiceFormAdd({
                    key: 'company',
                    value: company.uuid,
                  }),
                );
                dispatch(setServiceFormAddModalShow(true));
              }}
              variant='contained'
              color='primary'
            >
              Add Service
            </Button>
          </SvgContainer>
        ) : (
          services.map((service) => (
            <Fragment key={service.id}>
              <Service
                onClick={() => {
                  history.push({
                    pathname: `/services/${service.id}`,
                  });
                }}
              >
                <ServiceHead>
                  <Stack direction='row' justifyContent='space-between'>
                    <ServiceName>
                      <Avatar src={service.image} sx={{ width: 55, height: 55 }}>
                        <Avatar src={image} sx={{ width: 55, height: 55 }} />
                      </Avatar>

                      <Stack ml={2}>
                        <Font type='h5'>{service.name}</Font>
                        <Font>{service.duration} mins</Font>
                      </Stack>
                    </ServiceName>

                    <Box>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          setPopperEl(event.currentTarget);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </Box>
                  </Stack>

                  <ServiceDescription>
                    <Font type='h5'>Description</Font>
                    <Font>{service.description}</Font>
                  </ServiceDescription>
                </ServiceHead>

                <ServiceAction>
                  <Box>
                    <AvatarGroup
                      sx={{
                        '> div': {
                          width: 30,
                          height: 30,
                          fontSize: 16,
                        },
                      }}
                    >
                      {service.staff.slice(0, 4).map((staff) => (
                        <Tooltip key={staff.id} title={staff.name}>
                          <Avatar src={staff.image} />
                        </Tooltip>
                      ))}

                      {service.staff.length > 4 && (
                        <Tooltip
                          title={
                            <ul style={{ paddingLeft: 16 }}>
                              {service.staff.slice(4).map((staff) => (
                                <li key={staff.id}>{staff.name}</li>
                              ))}
                            </ul>
                          }
                        >
                          <Avatar>+{service.staff.slice(4).length}</Avatar>
                        </Tooltip>
                      )}
                    </AvatarGroup>
                  </Box>
                </ServiceAction>
              </Service>

              <Popper open={!!popperEl} anchorEl={popperEl} onClose={() => setPopperEl(null)}>
                <Stack
                  sx={{
                    width: {
                      md: 'fit-content',
                      lg: 150,
                    },
                    p: 1,
                  }}
                >
                  <Button
                    fullWidth
                    color='error'
                    startIcon={<Delete />}
                    size={mobileScreen ? 'large' : 'medium'}
                    onClick={() => {
                      dispatch(setServiceDelete(service));
                      setPopperEl(null);
                    }}
                  >
                    Delete
                  </Button>
                </Stack>
              </Popper>
            </Fragment>
          ))
        )}
      </Content>

      <Modal
        title='Create service'
        open={!!serviceFormAddModalShow}
        loading={serviceEditLoading}
        onClose={() => {
          dispatch(setServiceFormAddModalShow(false));
        }}
        onSubmit={() => {
          dispatch(serviceAddRequest());
        }}
        submitText='Add'
      >
        <ServiceFormAdd />
      </Modal>

      <Modal
        open={!!serviceDelete.id}
        title='Delete Service'
        loading={!!serviceEditLoading}
        onClose={() => {
          dispatch(clearServiceDelete());
        }}
        onSubmit={() => {
          dispatch(serviceDeleteRequest());
        }}
        submitText='Delete'
        danger
      >
        <Font>
          Service <strong> {serviceDelete.name} </strong> will be permanently deleted. this action
          can not be undone.
        </Font>
      </Modal>
    </Container>
  );
}

export default Services;
