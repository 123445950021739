import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import {
  FieldsErrors,
  FormFieldPayload,
  ServiceFormEditPartial,
  Staff,
  StaffBreak,
  StaffBreakFormCreate,
  StaffBreakFormUpdate,
  Permission,
  WorkingHour,
  WorkingHourFormCreate,
  Service,
  StaffFormUpdate,
} from 'model';
import { RootState } from 'store/config';

interface State {
  staffLoading: boolean;
  staffDetail: Staff;
  staffUserPermission: Permission;
  staffDetailEditLoading: boolean;
  staffDetailEdit: Staff;
  staffDetailEditPartial: Partial<StaffFormUpdate>;
  staffDetailEditError: FieldsErrors;
  staffBreaks: Array<StaffBreak>;
  staffBreakFormLoading: boolean;
  staffBreakFormCreate: StaffBreakFormCreate;
  staffBreakFormCreateError: string;
  staffBreakFormUpdate: StaffBreakFormUpdate;
  staffBreakFormUpdateError: string;
  staffDetailDelete: Staff;
  staffDetailDeleteLoading: boolean;
  staffDetailDeleteSuccess: boolean;
  userInviteModalShow: boolean;
  userInvite: string;
  userInviteError: string;
  updateServiceStaffError: string;
  workingHours: Array<WorkingHour>;
  workingHoursForm: Array<WorkingHour>;
  workingHoursError: FieldsErrors;
}

const initialState: State = {
  staffLoading: true,
  staffDetail: {
    id: 0,
    company: '',
    created: '',
    description: '',
    name: '',
    updated: '',
    user: null,
    image: '',
  },
  staffDetailEditLoading: false,
  staffDetailEdit: {
    id: 0,
    company: '',
    created: '',
    description: '',
    name: '',
    updated: '',
    user: null,
    image: '',
  },
  staffDetailEditPartial: {},
  staffDetailEditError: {},
  staffBreaks: [],
  staffBreakFormLoading: false,
  staffBreakFormCreate: {
    weekday: 0,
    start_time: '',
    end_time: '',
    staff: 0,
  },
  staffBreakFormCreateError: '',
  staffBreakFormUpdate: {
    id: 0,
    weekday: 0,
    start_time: '',
    end_time: '',
    staff: 0,
  },
  staffBreakFormUpdateError: '',
  staffDetailDelete: {
    id: 0,
    company: '',
    created: '',
    description: '',
    name: '',
    updated: '',
    user: null,
    image: '',
  },
  staffDetailDeleteLoading: false,
  staffDetailDeleteSuccess: false,
  userInviteModalShow: false,
  userInvite: '',
  userInviteError: '',
  staffUserPermission: ['company_staff', 'Company Staff'],
  updateServiceStaffError: '',
  workingHours: [],
  workingHoursForm: [],
  workingHoursError: {},
};

const StaffDetailSlice = createSlice({
  name: 'staffDetail',
  initialState,
  reducers: {
    staffLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        staffLoading: action.payload,
      };
    },
    setShowAddStaffModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showAddStaffModal: action.payload,
      };
    },
    setStaffDetail: (state, action: PayloadAction<Staff>) => {
      return {
        ...state,
        staffDetail: action.payload,
      };
    },

    setStaffDetailEditLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        staffDetailEditLoading: action.payload,
      };
    },
    setStaffDetailEdit: (state, action: PayloadAction<Staff>) => {
      return {
        ...state,
        staffDetailEdit: action.payload,
      };
    },
    clearStaffDetailEdit: (state) => {
      return {
        ...state,
        staffDetailEdit: {
          id: 0,
          company: '',
          created: '',
          description: '',
          name: '',
          updated: '',
          user: null,
          image: '',
        },
      };
    },
    updateStaffDetailEdit: (state, action: PayloadAction<FormFieldPayload<keyof Staff>>) => {
      return {
        ...state,
        staffDetailEdit: {
          ...state.staffDetailEdit,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    setStaffDetailEditPartial: (state, action: PayloadAction<Partial<StaffFormUpdate>>) => {
      return {
        ...state,
        staffDetailEditPartial: action.payload,
      };
    },
    clearStaffDetailEditPartial: (state) => {
      return {
        ...state,
        staffDetailEditPartial: {},
      };
    },
    setStaffDetailEditError: (
      state,
      action: PayloadAction<FormFieldPayload<keyof Staff | 'server'>>,
    ) => {
      return {
        ...state,
        staffDetailEditError: {
          ...state.staffDetailEditError,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearStaffDetailEditError: (state) => {
      return {
        ...state,
        staffDetailEditError: {},
      };
    },
    setStaffDetailDelete: (state, action: PayloadAction<Staff>) => {
      return {
        ...state,
        staffDetailDelete: action.payload,
      };
    },
    clearStaffDetailDelete: (state) => {
      return {
        ...state,
        staffDetailDelete: {
          id: 0,
          company: '',
          created: '',
          description: '',
          name: '',
          updated: '',
          user: null,
          image: '',
        },
      };
    },
    setStaffDetailDeleteLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        staffDetailDeleteLoading: action.payload,
      };
    },
    setStaffDetailDeleteSuccess: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        staffDetailDeleteSuccess: action.payload,
      };
    },
    setUserInviteModalShow: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        userInviteModalShow: action.payload,
      };
    },
    setUserInvite: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        userInvite: action.payload,
      };
    },
    setUserInviteError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        userInviteError: action.payload,
      };
    },
    setStaffUserPermission: (state, action: PayloadAction<Permission>) => {
      return {
        ...state,
        staffUserPermission: action.payload,
      };
    },
    setUpdateServiceStaffError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        updateServiceStaffError: action.payload,
      };
    },
    setStaffBreaks: (state, action: PayloadAction<Array<StaffBreak>>) => {
      return {
        ...state,
        staffBreaks: action.payload,
      };
    },
    setStaffBreakFormLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        staffBreakFormLoading: action.payload,
      };
    },
    setStaffBreakFormCreateError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        staffBreakFormCreateError: action.payload,
      };
    },
    updateStaffBreakFormCreate: (
      state,
      action: PayloadAction<FormFieldPayload<keyof StaffBreakFormCreate>>,
    ) => {
      return {
        ...state,
        staffBreakFormCreate: {
          ...state.staffBreakFormCreate,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearStaffBreakFormCreate: (state) => {
      return {
        ...state,
        staffBreakFormCreate: {
          weekday: 0,
          start_time: '',
          end_time: '',
          staff: 0,
        },
      };
    },
    setStaffBreakFormUpdate: (state, action: PayloadAction<StaffBreakFormUpdate>) => {
      return {
        ...state,
        staffBreakFormUpdate: action.payload,
      };
    },
    updateStaffBreakFormUpdate: (
      state,
      action: PayloadAction<FormFieldPayload<keyof StaffBreakFormUpdate>>,
    ) => {
      return {
        ...state,
        staffBreakFormUpdate: {
          ...state.staffBreakFormUpdate,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearStaffBreakFormUpdate: (state) => {
      return {
        ...state,
        staffBreakFormUpdate: {
          id: 0,
          weekday: 0,
          start_time: '',
          end_time: '',
          staff: 0,
        },
      };
    },
    setStaffBreakFormUpdateError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        staffBreakFormUpdateError: action.payload,
      };
    },
    setWorkingHours: (state, action: PayloadAction<Array<WorkingHour>>) => {
      return {
        ...state,
        workingHours: action.payload,
      };
    },
    setWorkingHoursForm: (state, action: PayloadAction<Array<WorkingHour>>) => {
      return {
        ...state,
        workingHoursForm: action.payload,
      };
    },
    setWorkingHoursError: (state, action: PayloadAction<FormFieldPayload<number>>) => {
      return {
        ...state,
        workingHoursError: {
          ...state.workingHoursError,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearWorkingHoursError: (state) => {
      return {
        ...state,
        workingHoursError: {},
      };
    },
  },
});

export const {
  staffLoading,
  setShowAddStaffModal,
  setStaffDetail,
  setStaffDetailEditLoading,
  setStaffDetailEdit,
  clearStaffDetailEdit,
  updateStaffDetailEdit,
  setStaffDetailEditPartial,
  clearStaffDetailEditPartial,
  setStaffDetailEditError,
  clearStaffDetailEditError,
  setUserInviteModalShow,
  setStaffDetailDelete,
  clearStaffDetailDelete,
  setStaffDetailDeleteLoading,
  setStaffDetailDeleteSuccess,
  setUserInvite,
  setUserInviteError,
  setStaffUserPermission,
  setUpdateServiceStaffError,
  setStaffBreaks,
  setStaffBreakFormLoading,
  setStaffBreakFormUpdate,
  updateStaffBreakFormCreate,
  clearStaffBreakFormCreate,
  setStaffBreakFormCreateError,
  updateStaffBreakFormUpdate,
  clearStaffBreakFormUpdate,
  setStaffBreakFormUpdateError,
  setWorkingHours,
  setWorkingHoursForm,
  setWorkingHoursError,
  clearWorkingHoursError,
} = StaffDetailSlice.actions;

export enum StaffActionTypes {
  STAFF_DETAIL_REQUEST = 'staffDetail/STAFF_DETAIL_REQUEST',
  STAFF_DETAIL_UPDATE_REQUEST = 'staffDetail/STAFF_DETAIL_UPDATE_REQUEST',
  STAFF_DETAIL_UPDATE_PARTIAL_REQUEST = 'staffDetail/STAFF_DETAIL_UPDATE_PARTIAL_REQUEST',
  STAFF_DETAIL_DELETE_REQUEST = 'staffDetail/STAFF_DETAIL_DELETE_REQUEST',
  USER_INVITE_REQUEST = 'staffDetail/USER_INVITE_REQUEST',
  USER_DELETE_SNACKBAR_REQUEST = 'staffDetail/USER_DELETE_SNACKBAR_REQUEST',
  USER_DELETE_SNACKBAR_SUCCESS = 'staffDetail/USER_DELETE_SNACKBAR_SUCCESS',
  USER_DELETE_SNACKBAR_FAILURE = 'staffDetail/USER_DELETE_SNACKBAR_FAILURE',
  STAFF_USER_PERMISSION_REQUEST = 'staffDetail/STAFF_USER_PERMISSION_REQUEST',
  UPDATE_STAFF_USER_PERMISSION_REQUEST = 'staffDetail/UPDATE_STAFF_USER_PERMISSION_REQUEST',
  UPDATE_SERVICE_STAFF_REQUEST = 'staffDetail/UPDATE_SERVICE_STAFF_REQUEST',
  BREAKS_REQUEST = 'staffDetail/BREAKS_REQUEST',
  CREATE_BREAK_REQUEST = 'staffDetail/CREATE_BREAK_REQUEST',
  DELETE_BREAK_REQUEST = 'staffDetail/DELETE_BREAK_REQUEST',
  UPDATE_BREAK_REQUEST = 'staffDetail/UPDATE_BREAK_REQUEST',
  STAFF_WORKING_HOURS_REQUEST = 'staff/STAFF_WORKING_HOURS_REQUEST',
  STAFF_WORKING_HOUR_UPDATE_REQUEST = 'staffDetail/STAFF_WORKING_HOUR_UPDATE_REQUEST',
  STAFF_WORKING_HOUR_CREATE_REQUEST = 'staffDetail/STAFF_WORKING_HOUR_CREATE_REQUEST',
  STAFF_WORKING_HOUR_DELETE_REQUEST = 'staffDetail/STAFF_WORKING_HOUR_DELETE_REQUEST',
}

export const staffDetailRequest = (id: number) => {
  return {
    type: StaffActionTypes.STAFF_DETAIL_REQUEST,
    payload: id,
  };
};

export const staffDetailUpdateRequest = () => {
  return {
    type: StaffActionTypes.STAFF_DETAIL_UPDATE_REQUEST,
  };
};

export const staffDetailUpdatePartialRequest = () => {
  return {
    type: StaffActionTypes.STAFF_DETAIL_UPDATE_PARTIAL_REQUEST,
  };
};

export const staffDetailDeleteRequest = () => {
  return {
    type: StaffActionTypes.STAFF_DETAIL_DELETE_REQUEST,
  };
};

export const userInviteRequest = (staffId: number) => {
  return {
    type: StaffActionTypes.USER_INVITE_REQUEST,
    payload: staffId,
  };
};

export const userDeleteSnackbarRequest = (userId: number) => {
  return {
    type: StaffActionTypes.USER_DELETE_SNACKBAR_REQUEST,
    payload: userId,
  };
};

export const userDeleteSnackbarSuccess = (userId: number) => {
  return {
    type: StaffActionTypes.USER_DELETE_SNACKBAR_SUCCESS,
    payload: userId,
  };
};

export const userDeleteSnackbarFailure = (user: Staff) => {
  return {
    type: StaffActionTypes.USER_DELETE_SNACKBAR_FAILURE,
    payload: user,
  };
};

export const staffUserPermissionRequest = () => {
  return {
    type: StaffActionTypes.STAFF_USER_PERMISSION_REQUEST,
  };
};

export const updateStaffUserPermissionRequest = (permission: Permission) => {
  return {
    type: StaffActionTypes.UPDATE_STAFF_USER_PERMISSION_REQUEST,
    payload: permission,
  };
};

export const updateServiceStaffRequest = (
  service: Service,
  serviceFormPartial: ServiceFormEditPartial,
) => {
  return {
    type: StaffActionTypes.UPDATE_SERVICE_STAFF_REQUEST,
    payload: {
      service,
      serviceFormPartial,
    },
  };
};

export const breaksRequest = () => {
  return {
    type: StaffActionTypes.BREAKS_REQUEST,
  };
};

export const createBreakRequest = () => {
  return {
    type: StaffActionTypes.CREATE_BREAK_REQUEST,
  };
};

export const deleteBreakRequest = (breakId: number) => {
  return {
    type: StaffActionTypes.DELETE_BREAK_REQUEST,
    payload: breakId,
  };
};

export const updateBreakRequest = () => {
  return {
    type: StaffActionTypes.UPDATE_BREAK_REQUEST,
  };
};

export const workingHoursRequest = () => {
  return {
    type: StaffActionTypes.STAFF_WORKING_HOURS_REQUEST,
  };
};

export const workingHoursUpdateRequest = (workingHour: WorkingHour) => {
  return {
    type: StaffActionTypes.STAFF_WORKING_HOUR_UPDATE_REQUEST,
    payload: workingHour,
  };
};

export const workingHoursCreateRequest = (workingHour: WorkingHourFormCreate) => {
  return {
    type: StaffActionTypes.STAFF_WORKING_HOUR_CREATE_REQUEST,
    payload: workingHour,
  };
};

export const workingHoursDeleteRequest = (workingHour: WorkingHour) => {
  return {
    type: StaffActionTypes.STAFF_WORKING_HOUR_DELETE_REQUEST,
    payload: workingHour,
  };
};

export const selectStaff = (state: RootState) => {
  return state.staffDetail;
};

export const selectStaffDetail = createSelector([selectStaff], (value) => value.staffDetail);

export const selectStaffDetailEdit = createSelector(
  [selectStaff],
  (value) => value.staffDetailEdit,
);

export const selectStaffDetailEditPartial = createSelector(
  [selectStaff],
  (value) => value.staffDetailEditPartial,
);

export const selectStaffDetailEditError = createSelector(
  [selectStaff],
  (value) => value.staffDetailEditError,
);

export const selectStaffDetailDelete = createSelector(
  [selectStaff],
  (value) => value.staffDetailDelete,
);

export const selectUserInvite = createSelector([selectStaff], (value) => value.userInvite);

export const selectStaffBreaks = createSelector([selectStaff], (value) => value.staffBreaks);

export const selectStaffBreakFormCreate = createSelector(
  [selectStaff],
  (value) => value.staffBreakFormCreate,
);

export const selectStaffBreakFormUpdate = createSelector(
  [selectStaff],
  (value) => value.staffBreakFormUpdate,
);

export const staffDetailReducer = StaffDetailSlice.reducer;
