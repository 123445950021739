import { useDocumentTitle } from 'hooks';
import { PrivateLayout } from 'layout';

// components
import { IntegrationsComposition } from 'screens/Integrations';

export const IntegrationsScreen = () => {
  useDocumentTitle('Integrations - Gotei');

  return (
    <PrivateLayout>
      <IntegrationsComposition />
    </PrivateLayout>
  );
};
