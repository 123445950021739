import { call, fork, takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// api
import { companyModuleRpc } from 'services/api';
import { fieldIsEmpty, formErrorIsEmpty, getError, getErrorEntry, logger } from 'utils';
import { BusinessHour, BusinessHourFormCreate, Company } from 'model';
import { RootState } from 'store/config';

import { setCompany, businessHoursRequest, selectCompanyDetail } from 'store/organization';

import {
  CompanyActionTypes,
  selectCompanyFormEdit,
  clearCompanyFormEdit,
  setCompanyFormEditError,
  clearCompanyFormEditError,
  selectCompanyFormEditError,
  setBusinessHoursError,
  clearBusinessHoursError,
  setCompanyFormEditLoading,
  updateCompanyFormEdit,
  selectCompanyFormEditPartial,
} from '.';

function* updateCompany() {
  yield call(validateCompanyFormEdit);

  const state: RootState = yield select();
  const companyFormEdit = selectCompanyFormEdit(state);
  const companyFormEditError = selectCompanyFormEditError(state);
  const company = selectCompanyDetail(state);

  if (formErrorIsEmpty(companyFormEditError)) return;

  try {
    logger('Company Update Request');

    yield put(setCompanyFormEditLoading(true));

    const response: Company = yield call(
      [companyModuleRpc, companyModuleRpc.updateCompany],
      company,
      companyFormEdit,
    );

    logger('Company Update Success');

    yield put(setCompany(response));
    yield put(clearCompanyFormEdit());
    yield put(setCompanyFormEditLoading(false));
  } catch (error: any) {
    logger.error('Company Update Failure');

    const { key, value } = getErrorEntry(error);

    yield put(
      setCompanyFormEditError({
        key,
        value,
      }),
    );
    yield put(setCompanyFormEditLoading(false));
  }
}

function* validateCompanyFormEdit() {
  yield put(clearCompanyFormEditError());

  const state: RootState = yield select();
  const companyFormEdit = selectCompanyFormEdit(state);

  if (fieldIsEmpty(companyFormEdit.name)) {
    yield put(
      setCompanyFormEditError({
        key: 'name',
        value: 'This field is required.',
      }),
    );
  } else if (fieldIsEmpty(companyFormEdit.url_component)) {
    yield put(
      setCompanyFormEditError({
        key: 'url_component',
        value: 'This field is required.',
      }),
    );
  }
}

function* updateCompanyPartial() {
  const state: RootState = yield select();
  const companyFormEditPartial = selectCompanyFormEditPartial(state);
  const company = selectCompanyDetail(state);

  try {
    yield put(setCompanyFormEditLoading(true));
    yield put(clearCompanyFormEditError());

    logger('Company update partial request');

    const response: Company = yield call(
      [companyModuleRpc, companyModuleRpc.updateCompanyPartial],
      company,
      companyFormEditPartial,
    );

    yield put(setCompany(response));
    yield put(setCompanyFormEditLoading(false));
    yield put(
      updateCompanyFormEdit({
        key: 'image',
        value: null,
      }),
    );
    yield put(setCompanyFormEditLoading(false));

    logger('Company update partial success');
  } catch (error: any) {
    const { key, value } = getErrorEntry(error);

    yield put(
      setCompanyFormEditError({
        key: key,
        value: value,
      }),
    );

    yield put(setCompanyFormEditLoading(false));

    logger('Company update partial failure');
  }
}

function* updateBusinessHour(action: PayloadAction<BusinessHour>) {
  yield put(clearBusinessHoursError());

  try {
    logger('Business Hour Update Request');

    yield call(companyModuleRpc.updateBusinessHour.bind(companyModuleRpc, action.payload));

    logger('Business Hour Update Success');

    yield put(businessHoursRequest());
  } catch (error) {
    logger.error('Business Hour Update Failure');

    yield put(
      setBusinessHoursError({
        key: action.payload.weekday,
        value: getError(error),
      }),
    );
  }
}

function* createBusinessHour(action: PayloadAction<BusinessHourFormCreate>) {
  try {
    logger('Business Hour Create Request');

    yield call(companyModuleRpc.createBusinessHour.bind(companyModuleRpc, action.payload));

    logger('Business Hour Create Success');

    yield put(businessHoursRequest());
  } catch (error) {
    logger.error('Business Hour Create Failure');
  }
}

function* deleteBusinessHour(action: PayloadAction<BusinessHour>) {
  try {
    logger('Business Hour Create Request');

    yield call(companyModuleRpc.deleteBusinessHour.bind(companyModuleRpc, action.payload));

    logger('Business Hour Create Success');

    yield put(businessHoursRequest());
  } catch (error) {
    logger.error('Business Hour Create Failure');
  }
}

function* companyUpdateWatcher() {
  yield takeLatest(CompanyActionTypes.COMPANY_UPDATE_REQUEST, updateCompany);
}

function* companyUpdatePArtialWatcher() {
  yield takeLatest(CompanyActionTypes.COMPANY_UPDATE_PARTIAL_REQUEST, updateCompanyPartial);
}

function* companyBusinessHourUpdateWatcher() {
  yield takeEvery(CompanyActionTypes.BUSINESS_HOUR_UPDATE_REQUEST, updateBusinessHour);
}

function* companyBusinessHourCreateWatcher() {
  yield takeLatest(CompanyActionTypes.BUSINESS_HOUR_CREATE_REQUEST, createBusinessHour);
}

function* companyBusinessHourDeleteWatcher() {
  yield takeLatest(CompanyActionTypes.BUSINESS_HOUR_DELETE_REQUEST, deleteBusinessHour);
}

export const companySagas = [
  fork(companyUpdateWatcher),
  fork(companyUpdatePArtialWatcher),
  fork(companyBusinessHourUpdateWatcher),
  fork(companyBusinessHourCreateWatcher),
  fork(companyBusinessHourDeleteWatcher),
];
