// dependencies
import { DOMAIN, GOOGLE_CLIENT_ID, GOOGLE_PROFILE_SCOPE, GOOGLE_CALENDAR_SCOPE } from 'config';
import styled from 'styled-components';

// icons
import { ReactComponent as GoogleIcon } from 'assets/svg/google-icon.svg';

const GOOGLE_OAUTH_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';
const GOOGLE_RESPONSE_TYPE = 'code';
const GOOGLE_REDIRECT_URI = DOMAIN.ORIGIN + '/google-login';

interface ButtonProps {
  fullWidth?: boolean;
}

const Button = styled.button<ButtonProps>`
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;

  :disabled {
    & > :last-child {
      opacity: 38%;
    }

    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
  }

  :enabled:active {
    & > :first-child {
      background-color: #303030;
      opacity: 12%;
    }
  }

  :enabled:focus {
    & > :first-child {
      background-color: #303030;
      opacity: 12%;
    }
  }

  :enabled:hover {
    & > :first-child {
      background-color: #303030;
      opacity: 8%;
    }
  }
`;

const IconWrapper = styled.div`
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
`;

const ButtonState = styled.div`
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const ContentWrapper = styled.div`
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
`;

type ButtonText = 'Sign in with Google' | 'Sign up with Google' | 'Continue with Google';

interface Props {
  text?: ButtonText;
  fullWidth?: boolean;
  variant?: 'login' | 'calendar';
  redirectUri?: string;
}

export const GoogleButton = ({
  text = 'Continue with Google',
  fullWidth,
  variant = 'login',
  redirectUri = GOOGLE_REDIRECT_URI,
}: Props) => {
  const handleGoogleOauth = () => {
    // Check out google oauth2 docs for more in depth
    // https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow

    const form = document.createElement('form');
    form.setAttribute('method', 'GET');
    form.setAttribute('action', GOOGLE_OAUTH_ENDPOINT);

    const params: any = {
      client_id: GOOGLE_CLIENT_ID,
      redirect_uri: redirectUri,
      response_type: GOOGLE_RESPONSE_TYPE,
      scope: GOOGLE_PROFILE_SCOPE + GOOGLE_CALENDAR_SCOPE,
    };

    if (variant === 'calendar') {
      params['access_type'] = 'offline';
      params['prompt'] = 'consent';
    }

    for (const p in params) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      // @ts-ignore
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <Button fullWidth={fullWidth} onClick={handleGoogleOauth}>
      <ButtonState />

      <ContentWrapper>
        <IconWrapper>
          <GoogleIcon />
        </IconWrapper>

        <Content>{text}</Content>
      </ContentWrapper>
    </Button>
  );
};
