import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { BusinessHour, Client, Company, Permission, Service, Staff } from 'model';
import { RootState } from 'store/config';

interface SliceState {
  company: Company;
  companyIsFetched: boolean;
  clients: Array<Client>;
  services: Array<Service>;
  staff: Array<Staff>;
  permissions: Array<Permission>;
  businessHours: Array<BusinessHour>;
}

const initialState: SliceState = {
  company: {
    uuid: '',
    name: '',
    url_component: '',
    created: '',
    updated: '',
    timezone: '',
    image: '',
    is_verified: false,
    verified_at: '',
  },
  companyIsFetched: false,
  clients: [],
  services: [],
  staff: [],
  permissions: [],
  businessHours: [],
};

const OrganizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<Company>) => {
      return {
        ...state,
        company: action.payload,
      };
    },
    setCompanyIsFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        companyIsFetched: action.payload,
      };
    },
    setClients: (state, action: PayloadAction<Array<Client>>) => {
      return {
        ...state,
        clients: action.payload,
      };
    },
    setServices: (state, action: PayloadAction<Array<Service>>) => {
      return {
        ...state,
        services: action.payload,
      };
    },
    setStaff: (state, action: PayloadAction<Array<Staff>>) => {
      return {
        ...state,
        staff: action.payload,
      };
    },
    setPermissions: (state, action: PayloadAction<Array<Permission>>) => {
      return {
        ...state,
        permissions: action.payload,
      };
    },
    setBusinessHours: (state, action: PayloadAction<Array<BusinessHour>>) => {
      return {
        ...state,
        businessHours: action.payload,
      };
    },
  },
});

export const {
  setCompany,
  setCompanyIsFetched,
  setClients,
  setServices,
  setStaff,
  setPermissions,
  setBusinessHours,
} = OrganizationSlice.actions;

export enum OrganizationActionTypes {
  COMPANY_REQUEST = 'organization/COMPANY_REQUEST',
  CLIENTS_REQUEST = 'organization/CLIENTS_REQUEST',
  SERVICES_REQUEST = 'organization/SERVICES_REQUEST',
  STAFF_REQUEST = 'organization/STAFF_REQUEST',
  PERMISSIONS_REQUEST = 'organization/PERMISSIONS_REQUEST',
  BUSINESS_HOURS_REQUEST = 'organization/BUSINESS_HOURS_REQUEST',
}

export const companyRequest = () => {
  return {
    type: OrganizationActionTypes.COMPANY_REQUEST,
  };
};

export const clientsRequest = () => {
  return {
    type: OrganizationActionTypes.CLIENTS_REQUEST,
  };
};

export const servicesRequest = () => {
  return {
    type: OrganizationActionTypes.SERVICES_REQUEST,
  };
};

export const staffRequest = () => {
  return {
    type: OrganizationActionTypes.STAFF_REQUEST,
  };
};

export const permissionsRequest = () => {
  return {
    type: OrganizationActionTypes.PERMISSIONS_REQUEST,
  };
};

export const businessHoursRequest = () => {
  return {
    type: OrganizationActionTypes.BUSINESS_HOURS_REQUEST,
  };
};

export const selectOrganization = (state: RootState) => state.organization;

export const selectCompanyDetail = createSelector([selectOrganization], (value) => value.company);

export const selectCompanyServices = createSelector(
  [selectOrganization],
  (value) => value.services,
);

export const selectCompanyBusinessHours = createSelector(
  [selectOrganization],
  (value) => value.businessHours,
);

export const organizationReducer = OrganizationSlice.reducer;
