import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { VideocamOutlined } from '@mui/icons-material';
import { useGoogleCalendarQuery } from 'services/query';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store/config';

// images
import { ReactComponent as GoogleMeetIcon } from 'assets/svg/google-meet.svg';
import videoCall from 'assets/images/google-meet-conference.png';

const Container = styled.div`
  flex: 1;
  max-width: 800px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 100%;
`;

export const GoogleMeetIntegration = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const {
    data: calendarData,
    isLoading: calendarIsLoading,
    isSuccess: calendarIsSuccess,
    isError: calendarIsError,
  } = useGoogleCalendarQuery(user.id);
  const history = useHistory();

  return (
    <Container>
      {calendarIsError && (
        <Alert severity='warning' sx={{ mb: 2 }}>
          You don't have a Google Calendar connected with your Calendly account.
        </Alert>
      )}

      {calendarIsSuccess && (
        <Alert sx={{ mb: 2 }}>You have successfully connected Google Meet integration.</Alert>
      )}

      <Card sx={{ flex: 'none', mb: 2, p: { sm: '2rem', xs: '1.5rem' } }}>
        {calendarIsLoading && (
          <Stack
            direction={{
              sm: 'row',
              xs: 'column',
            }}
            alignItems={{
              xs: 'normal',
              sm: 'center',
            }}
          >
            <Stack
              flex={3}
              direction={{
                sm: 'row',
                xs: 'column',
              }}
              alignItems={{
                xs: 'normal',
                sm: 'center',
              }}
            >
              <Stack flex={2} direction='row' alignItems='center' mr={2}>
                <Skeleton variant='rectangular' height={50} width={50} sx={{ borderRadius: 1 }} />

                <Skeleton height={20} width={150} sx={{ ml: 2 }} />
              </Stack>

              <Stack flex={1} spacing={1} direction='row' alignItems='center'>
                <Skeleton width={150} sx={{ ml: { sm: 2, xs: 0 }, mt: { xs: 2, sm: 0 } }} />
              </Stack>
            </Stack>

            <Stack
              flex={1}
              direction='row'
              justifyContent='flex-end'
              ml={{ sm: 2, xs: 0 }}
              mt={{ xs: 3, sm: 0 }}
            >
              <Skeleton variant='rectangular' height={42} width='100%' sx={{ borderRadius: 1 }} />
            </Stack>
          </Stack>
        )}

        {calendarIsSuccess && !calendarIsLoading && (
          <Stack>
            <Typography variant='h6' fontWeight={600} mb={2}>
              Google Meet conferencing is connected
            </Typography>

            <Typography>
              Congratulations! You can already schedule with Google Meet conferencing because your
              Google calendar is connected with Calendly.
            </Typography>

            <Divider sx={{ m: { sm: '2rem -2rem', xs: '1.5rem -1.5rem' } }} />

            <Stack
              direction={{ md: 'row', sm: 'column', xs: 'column' }}
              alignItems={{
                sm: 'normal',
                md: 'center',
                xs: 'column',
              }}
            >
              <Stack
                flex={3}
                direction={{
                  md: 'row',
                  sm: 'column',
                  xs: 'column',
                }}
                m={'0.5rem 0'}
              >
                <Stack flex={2} direction='row' alignItems='center' mr={2}>
                  <GoogleMeetIcon />

                  <Typography variant='h5' ml={2}>
                    Google Meet
                  </Typography>

                  <Chip label='connected' size='small' color='success' />
                </Stack>

                <Stack flex={1} spacing={1} direction='row' alignItems='center' mt={{ xs: 1 }}>
                  <Typography variant='body2' color='text.secondary'>
                    {calendarData?.user_info?.email}
                  </Typography>
                </Stack>
              </Stack>

              <Box flex={1.5} m={'0.5rem 0'}>
                <Button
                  fullWidth
                  size='large'
                  variant='outlined'
                  onClick={() => history.push('/integrations/calendar-connections')}
                >
                  Manage google calendar
                </Button>
              </Box>
            </Stack>
          </Stack>
        )}

        {calendarIsError && !calendarIsLoading && (
          <Stack>
            <Typography variant='h6' fontWeight={600} mb={2}>
              Google Meet conferencing is not connected
            </Typography>

            <Typography>For this integration you need:</Typography>

            <ul style={{ paddingLeft: 16, margin: '0.5rem 0 0 0' }}>
              <li>
                <Typography>Google Calendar integration active</Typography>
              </li>
            </ul>

            <Divider sx={{ m: { sm: '2rem -2rem', xs: '1.5rem -1.5rem' } }} />

            <Stack
              direction={{ sm: 'row', xs: 'column' }}
              alignItems={{
                md: 'center',
                xs: 'normal',
              }}
            >
              <Stack flex={2} direction='row'>
                <Stack flex={2} direction='row' alignItems='center' mr={2}>
                  <VideocamOutlined sx={{ fontSize: 55 }} />

                  <Typography variant='h5' ml={2}>
                    Google Meet
                  </Typography>

                  <Chip label='Not connected' size='small' />
                </Stack>
              </Stack>

              <Box flex={1} mt={{ xs: 2 }}>
                <Button
                  fullWidth
                  size='large'
                  variant='contained'
                  onClick={() => history.push('/integrations/calendar-connections')}
                >
                  Connect google calendar
                </Button>
              </Box>
            </Stack>
          </Stack>
        )}
      </Card>

      <Card sx={{ flex: 'none', mt: 2, p: { sm: '2rem', xs: '1.5rem' } }}>
        <Stack direction={{ sm: 'row', xs: 'column' }}>
          <Stack flex={7} direction='row' alignItems='center'>
            <Image src={videoCall} />
          </Stack>

          <Stack ml={{ sm: 4, xs: 0 }} flex={10} mt={{ xs: 4 }}>
            <Typography variant='h6' fontWeight={600} mb={2}>
              How to integrate with Google Meet
            </Typography>

            <Typography mb={1}>
              Integration is easy and straight forward. Google Meet should be automatically included
              with your events, the only thing you have to do is to connect a Google Calendar.
            </Typography>

            <Typography>
              Go to &nbsp;
              <Link component={RouterLink} to='/integrations/calendar-connections'>
                calendar connections
              </Link>
              &nbsp; and connect a Google Calendar to Gotei. After you have successfully added a
              Google Calendar you don't need to do anything else, Google Meet should be connected
              automatically and should be ready to use it.
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Container>
  );
};
