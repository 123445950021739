import BaseRpc from './BaseRpc';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BookingType, Service, ServiceFormAdd, ServiceFormUpdate } from 'model';
import { formDataGenerator, base64ToFile, generateString } from 'utils';

axios.defaults.withCredentials = true; // this is required to be able receive the cookies tokens
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

export class ServiceModuleRpc extends BaseRpc {
  private getImageName() {
    return `${generateString()}.jpg`;
  }

  public async getServices(config: AxiosRequestConfig = {}): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/services/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async getService(
    id: number,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/services/${id}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateService(
    service: Service,
    serviceFormUpdate: ServiceFormUpdate,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/services/${serviceFormUpdate.id}/`;

    try {
      const form: Partial<ServiceFormUpdate> = { ...serviceFormUpdate };

      if (form.image) {
        delete form.image;
      }

      const formData = formDataGenerator(form);

      if (config.headers == null) {
        config.headers = {
          'Content-Type': 'application/json',
        };
      }

      let response: AxiosResponse<any>;

      response = await axios.put<any>(url, formData, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateServicePartial(
    service: Service,
    serviceFormUpdatePartial: Partial<ServiceFormUpdate>,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/services/${service.id}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const image = serviceFormUpdatePartial.image
        ? await base64ToFile(serviceFormUpdatePartial.image, this.getImageName())
        : null;

      const formData = formDataGenerator({ ...serviceFormUpdatePartial, image });

      const response = await axios.patch<any>(url, formData, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async createService(
    service: ServiceFormAdd,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/services/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.post<any>(url, { ...service }, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }
  public async deleteService(
    id: number,
    config: AxiosRequestConfig = {},
  ): Promise<Array<BookingType>> {
    const url = `${this.baseUrl}/schedule/services/${id}`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    let response: AxiosResponse<any>;

    try {
      response = await axios.delete<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }
}

export default ServiceModuleRpc;
