/**
 * The GTM data layer can be extended with any number of custom variables.
 * These variables can then be accessed at https://tagmanager.google.com/.
 *
 * In an attempt to keep events documented this function will act as an
 * extra layer between GTM and our code.
 *
 * It may need to be extended later on, but for now it should do.
 * */

declare global {
  interface Window {
    dataLayer: any;
  }
}

export function fireGtmEvent(args: any) {
  const eventData = { ...args };
  const cleanedEventData = Object.fromEntries(Object.entries(eventData).filter(([, v]) => !!v));
  if (window?.dataLayer) window.dataLayer.push(cleanedEventData);
}
