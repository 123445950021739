// dependencies
import { TextField } from '@mui/material';
import styled from 'styled-components';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { updateClientFormAdd } from '..';
import { PhoneInput } from 'elements';

const Container = styled.div``;

const TextFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

function ClientFormAdd() {
  const { clientFormAdd, clientFormAddError } = useSelector((state: RootState) => state.clients);
  const dispatch = useDispatch();

  return (
    <Container>
      <TextFieldWrapper>
        <TextField
          label='Name'
          variant='outlined'
          type='text'
          fullWidth
          error={!!clientFormAddError.name}
          helperText={clientFormAddError.name}
          value={clientFormAdd.name}
          onChange={(event) => {
            dispatch(
              updateClientFormAdd({
                key: 'name',
                value: event.currentTarget.value,
              }),
            );
          }}
        />
      </TextFieldWrapper>

      <TextFieldWrapper>
        <TextField
          label='Email'
          variant='outlined'
          type='email'
          fullWidth
          error={!!clientFormAddError.email}
          helperText={clientFormAddError.email}
          value={clientFormAdd.email}
          onChange={(event) => {
            dispatch(
              updateClientFormAdd({
                key: 'email',
                value: event.currentTarget.value,
              }),
            );
          }}
        />
      </TextFieldWrapper>

      <TextFieldWrapper>
        <PhoneInput
          fullWidth
          label='Phone'
          variant='outlined'
          value={clientFormAdd.phone}
          error={!!clientFormAddError.phone}
          helperText={clientFormAddError.phone ?? 'Optional'}
          onChange={(_, value) => {
            dispatch(
              updateClientFormAdd({
                key: 'phone',
                value: value,
              }),
            );
          }}
        />
      </TextFieldWrapper>
    </Container>
  );
}

export default ClientFormAdd;
