import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN } from 'config';

export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: `${DOMAIN.API}/auth`,
  }),
  endpoints: (builder) => ({
    googleLogin: builder.mutation({
      query: (code) => {
        return {
          method: 'post',
          url: 'google-login/',
          body: { code },
        };
      },
    }),
  }),
});

export const { useGoogleLoginMutation } = authenticationApi;
