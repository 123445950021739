import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AvailabilityParams,
  BookingType,
  BusinessHour,
  Company,
  ManageSteps,
  WorkingHour,
} from 'model';
import { publicModuleRpc } from 'services/api';
import { getError, getErrorFields, logger } from 'utils';
import moment from 'moment-timezone';

import { RootState } from 'store/config';
import {
  ManageActionTypes,
  setCompany,
  setLoading,
  setBooking,
  selectDate,
  selectBooking,
  setAvailability,
  setAvailabilityLoading,
  setAvailabilityAreFetched,
  setAvailabilityError,
  selectBookingFormUpdate,
  bookingFormUpdateLoading,
  setBookingFormError,
  setStep,
  setBookingError,
  setDeleteLoading,
  setBusinessHours,
  setStaffWorkingHours,
} from '.';

function* getCompany(action: PayloadAction<string>) {
  const companyUuid = action.payload;

  try {
    logger('Company Detail Request');

    const response: Company = yield call(
      [publicModuleRpc, publicModuleRpc.getCompanyDetail],
      companyUuid,
    );

    yield put(setCompany(response));

    logger('Company Detail Success');
  } catch (error: any) {
    logger.error('Company Detail Failure');
  }
}

function* getBooking(action: PayloadAction<string>) {
  const uuid = action.payload;

  try {
    logger('Booking Request');

    yield put(setLoading(true));

    const response: BookingType = yield call([publicModuleRpc, publicModuleRpc.getBooking], uuid);

    logger('Booking Success');

    yield put(setBooking(response));
    yield put(setLoading(false));
  } catch (error: any) {
    logger.error('Booking Failure');

    yield put(setBookingError(getError(error)));
    yield put(setLoading(false));
  }
}

function* getAvailability() {
  const state: RootState = yield select();
  const date = selectDate(state);
  const booking = selectBooking(state);

  if (!booking.staff) return;

  try {
    logger('Availability Request');

    yield put(setAvailabilityLoading(true));
    yield put(setAvailabilityError(''));

    const params: AvailabilityParams = {
      date: moment(date).format('DD/MM/YYYY'),
      service: booking.service.id,
    };

    const response: Array<string> = yield call(
      [publicModuleRpc, publicModuleRpc.getAvailability],
      booking.staff.id,
      params,
    );

    logger('Availability Success');

    yield put(setAvailability(response));
    yield put(setAvailabilityAreFetched(true));
    yield put(setAvailabilityLoading(false));
  } catch (error: any) {
    logger.error('Availability Failure');

    yield put(setAvailabilityLoading(false));
    yield put(setAvailabilityError(getError(error)));
  }
}

function* bookingUpdateSubmit() {
  const state: RootState = yield select();
  const bookingFormUpdate = selectBookingFormUpdate(state);

  try {
    logger('Update Booking Request');

    yield put(bookingFormUpdateLoading(true));

    const response: BookingType = yield call(
      [publicModuleRpc, publicModuleRpc.updateBooking],
      bookingFormUpdate,
    );

    logger('Update Booking Request Success');

    yield put(setBooking(response));
    yield put(setStep(ManageSteps.success));
    yield put(bookingFormUpdateLoading(false));
  } catch (error: any) {
    logger.error('Update Booking Request Failure');

    yield put(setBookingFormError(getErrorFields(error)));
    yield put(bookingFormUpdateLoading(false));
  }
}

function* deleteBooking() {
  const state: RootState = yield select();
  const bookingFormUpdate = selectBookingFormUpdate(state);

  try {
    logger('Delete Bookings Request');

    yield put(setDeleteLoading(true));

    yield call([publicModuleRpc, publicModuleRpc.deleteBooking], bookingFormUpdate.uuid);

    logger('Delete Bookings Request Success');

    yield put(setStep(ManageSteps.cancelled));
    yield put(setDeleteLoading(false));
  } catch (error: any) {
    logger.error('Delete Bookings Request Failure');

    yield put(setDeleteLoading(false));
  }
}

function* getBusinessHours(action: PayloadAction<string>) {
  const companyUrl = action.payload;

  try {
    logger('Business Hours Request');

    const response: Array<BusinessHour> = yield call(
      [publicModuleRpc, publicModuleRpc.getBusinessHours],
      companyUrl,
    );

    logger('Business Hours Success');

    yield put(setBusinessHours(response));
  } catch (error) {
    logger.error('Business Hours Failure');
  }
}

function* getStaffWorkingHours() {
  const state: RootState = yield select();
  const bookingFormUpdate = selectBookingFormUpdate(state);

  if (!bookingFormUpdate.staff) return;

  try {
    logger('Staff Working Hours Request');

    const response: WorkingHour[] = yield call(
      [publicModuleRpc, publicModuleRpc.getStaffWorkingHours],
      bookingFormUpdate.staff,
    );

    logger('Staff Working Hours Success');

    yield put(setStaffWorkingHours(response));
  } catch (error) {
    logger.error('Staff Working Hours Failure');
  }
}

function* companyWatcher() {
  yield takeLatest(ManageActionTypes.COMPANY_REQUEST, getCompany);
}

function* bookingWatcher() {
  yield takeLatest(ManageActionTypes.BOOKING_REQUEST, getBooking);
}

function* availabilityWatcher() {
  yield takeLatest(ManageActionTypes.AVAILABILITY_REQUEST, getAvailability);
}

function* bookingUpdateSubmitWatcher() {
  yield takeLatest(ManageActionTypes.BOOKING_UPDATE_SUBMIT, bookingUpdateSubmit);
}

function* deleteBookingWatcher() {
  yield takeLatest(ManageActionTypes.DELETE_BOOKING_REQUEST, deleteBooking);
}

function* businessHoursWatcher() {
  yield takeLatest(ManageActionTypes.BUSINESS_HOURS_REQUEST, getBusinessHours);
}

function* staffWorkingHoursWatcher() {
  yield takeLatest(ManageActionTypes.STAFF_WORKING_HOURS_REQUEST, getStaffWorkingHours);
}

export const manageSagas = [
  fork(companyWatcher),
  fork(bookingWatcher),
  fork(availabilityWatcher),
  fork(bookingUpdateSubmitWatcher),
  fork(deleteBookingWatcher),
  fork(businessHoursWatcher),
  fork(staffWorkingHoursWatcher),
];
