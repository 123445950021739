import * as d3 from 'd3';

interface TooltipProps {
  selector: React.RefObject<HTMLElement>;
}

export const createTooltip = ({ selector }: TooltipProps) => {
  return d3
    .select(selector.current)
    .append('div')
    .attr('class', 'tooltip')
    .style('opacity', 0)
    .style('background-color', '#352B3A')
    .style('color', 'white')
    .style('border-radius', '2px')
    .style('padding', '5px')
    .style('position', 'absolute')
    .style('padding', '.5rem')
    .style('font-size', '14px')
    .style('pointer-events', 'none')
    .style('left', '10px')
    .style('top', '10px');
};
