import { call, fork, takeLatest, put } from 'redux-saga/effects';
import { logger } from 'utils';
import { calendarModuleRpc, companyModuleRpc } from 'services/api';
import moment from 'moment-timezone';

//Types
import { BookingType, GetBookingParams, NotificationType } from 'model';

//Redux
import {
  DashboardActionTypes,
  setDailyBookings,
  setDailyBookingsLoading,
  setDailyBookingsSelected,
  setActivity,
  setActivityLoading,
  setDailyBookingsAreFetched,
  setLastDailyBookings,
  setWeeklyAppointments,
  setLastWeeklyAppointments,
  setActivityIsFetched,
} from '.';

function* getDailyBookings() {
  try {
    logger('Daily Bookings Request');

    const params: GetBookingParams = {
      date: moment().format('DD/MM/YYYY'),
      staff: '',
    };

    const lastDailyParams: GetBookingParams = {
      date: moment().subtract(1, 'days').format('DD/MM/YYYY'),
      staff: '',
    };

    const lastWeeklyParams: GetBookingParams = {
      date: moment().subtract(1, 'weeks').format('DD/MM/YYYY'),
      staff: '',
    };

    yield put(setDailyBookingsLoading(true));

    const response: Array<BookingType> = yield call(
      [calendarModuleRpc, calendarModuleRpc.getDailyBookings],
      params,
    );

    const lastDailyResponse: Array<BookingType> = yield call(
      [calendarModuleRpc, calendarModuleRpc.getDailyBookings],
      lastDailyParams,
    );

    const weeklyResponse: Array<BookingType> = yield call(
      [calendarModuleRpc, calendarModuleRpc.getWeeklyBookings],
      params,
    );

    const lastWeeklyResponse: Array<BookingType> = yield call(
      [calendarModuleRpc, calendarModuleRpc.getWeeklyBookings],
      lastWeeklyParams,
    );

    logger('Daily Bookings Request Success');

    yield put(setDailyBookings(response));
    yield put(setLastDailyBookings(lastDailyResponse));
    yield put(setWeeklyAppointments(weeklyResponse));
    yield put(setLastWeeklyAppointments(lastWeeklyResponse));
    yield put(setDailyBookingsSelected(response));
    yield put(setDailyBookingsAreFetched(true));
    yield put(setDailyBookingsLoading(false));
  } catch (error: any) {
    logger.error('Daily Bookings Request Failure');
  }
}

function* getActivity() {
  try {
    logger('Activity Request');

    yield put(setActivityLoading(true));

    const response: Array<NotificationType> = yield call([
      companyModuleRpc,
      companyModuleRpc.getNotifications,
    ]);

    yield put(setActivity(response));
    yield put(setActivityIsFetched(true));
    yield put(setActivityLoading(false));
  } catch (error: any) {
    logger('Activity Request Failure', error);
  }
}

function* dailyBookingsWatcher() {
  yield takeLatest(DashboardActionTypes.DAILY_BOOKINGS_REQUEST, getDailyBookings);
}

function* activityWatcher() {
  yield takeLatest(DashboardActionTypes.ACTIVITY_REQUEST, getActivity);
}

export const dashboardSagas = [fork(dailyBookingsWatcher), fork(activityWatcher)];
