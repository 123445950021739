import { call, fork, takeLatest, put, select } from 'redux-saga/effects';

// api
import { serviceModuleRpc } from 'services/api';
import { fieldIsEmpty, formErrorIsEmpty, getErrorEntry, logger } from 'utils';
import { Service } from 'model';
import { RootState } from 'store/config';
import {
  ServicesActionTypes,
  setServices,
  setServiceFormAddError,
  selectServiceFormAdd,
  selectServiceFormAddError,
  clearServiceFormAddError,
  clearServiceFormAdd,
  setServiceFormAddModalShow,
  selectServiceList,
  selectServiceDelete,
  clearServiceDelete,
  setServiceDeleteSuccess,
  serviceEditLoading,
  setServicesAreFetched,
  setServicesLoading,
} from '.';

function* getServices() {
  try {
    logger('Services Request');

    yield put(setServicesLoading(true));

    const response: Array<Service> = yield call(
      serviceModuleRpc.getServices.bind(serviceModuleRpc),
    );

    yield put(setServices(response));
    yield put(setServicesAreFetched(true));
    yield put(setServicesLoading(false));

    logger('Services Success');
  } catch (error: any) {
    logger('Services Failure');
    yield put(setServicesLoading(false));
  }
}

function* addService() {
  yield call(validateServiceFormAdd);

  const state: RootState = yield select();
  const serviceFormAdd = selectServiceFormAdd(state);
  const serviceFormAddError = selectServiceFormAddError(state);

  if (formErrorIsEmpty(serviceFormAddError)) return;

  try {
    yield put(serviceEditLoading(true));
    logger('Service Create Request');

    const response: Service = yield call(
      serviceModuleRpc.createService.bind(serviceModuleRpc, serviceFormAdd),
    );

    const services = [...selectServiceList(state)];
    services.push(response);

    yield put(setServices(services));
    yield put(clearServiceFormAdd());
    yield put(serviceEditLoading(false));
    yield put(setServiceFormAddModalShow(false));
    logger('Service Create Success');
  } catch (error: any) {
    const { key, value } = getErrorEntry(error);

    yield put(
      setServiceFormAddError({
        key: key,
        value: value,
      }),
    );

    yield put(serviceEditLoading(false));
    logger('Service Create Failure');
  }
}

function* validateServiceFormAdd() {
  yield put(clearServiceFormAddError());

  const state: RootState = yield select();
  const serviceFormAdd = selectServiceFormAdd(state);

  if (fieldIsEmpty(serviceFormAdd.name)) {
    yield put(
      setServiceFormAddError({
        key: 'name',
        value: 'This field is required.',
      }),
    );
  } else if (fieldIsEmpty(serviceFormAdd.duration)) {
    yield put(
      setServiceFormAddError({
        key: 'duration',
        value: 'This field is required.',
      }),
    );
  } else if (!serviceFormAdd.staff.length) {
    yield put(
      setServiceFormAddError({
        key: 'staff',
        value: 'This field is required.',
      }),
    );
  }
}

function* deleteService() {
  const state: RootState = yield select();
  const serviceDelete = selectServiceDelete(state);

  try {
    yield put(serviceEditLoading(true));
    logger('Service Delete Request');

    yield call(serviceModuleRpc.deleteService.bind(serviceModuleRpc, serviceDelete.id));

    const services = [...selectServiceList(state)];
    const index = services.indexOf(serviceDelete);
    services.splice(index, 1);

    yield put(setServices(services));
    yield put(clearServiceDelete());
    yield put(serviceEditLoading(false));
    yield put(setServiceDeleteSuccess(true));

    logger('Service Delete Success');
  } catch (error: any) {
    const { key, value } = getErrorEntry(error);

    yield put(
      setServiceFormAddError({
        key: key,
        value: value,
      }),
    );

    yield put(serviceEditLoading(false));
    logger('Service Delete Failure');
  }
}

function* servicesRequestWatcher() {
  yield takeLatest(ServicesActionTypes.SERVICES_REQUEST, getServices);
}

function* serviceAddWatcher() {
  yield takeLatest(ServicesActionTypes.SERVICE_ADD_REQUEST, addService);
}

function* serviceDeleteWatcher() {
  yield takeLatest(ServicesActionTypes.SERVICE_DELETE_REQUEST, deleteService);
}

export const servicesSagas = [
  fork(servicesRequestWatcher),
  fork(serviceAddWatcher),
  fork(serviceDeleteWatcher),
];
