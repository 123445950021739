import { Client, Service, Staff } from '.';

export enum BookingFormKeys {
  client = 'client',
  start_time = 'start_time',
  staff = 'staff',
  service = 'service',
  company = 'company',
}

export interface BookingType {
  id: number;
  service: Service;
  client: Client;
  uuid: string;
  staff: Staff;
  start_time: string;
  end_time: string;
  created: string;
  updated: string;
  company: string;
  is_canceled: boolean;
}

export interface BookingCreateType {
  client: number;
  service: number;
  staff: number;
  company: string;
  start_time: string;
}

export interface BookingUpdateType extends Omit<BookingType, 'client' | 'staff' | 'service'> {
  client: number;
  service: number;
  staff: number;
}
