import { Font } from 'elements';
import styled from 'styled-components';
import { Stack } from '@mui/material';

import { ReactComponent as Reschedule } from 'assets/svg/reschedule.svg';

import calendar from 'assets/svg/calendar.svg';
import dashboard from 'assets/svg/dashboard.svg';
import blob3 from 'assets/svg/blob-3.svg';
import blob4 from 'assets/svg/blob-4.svg';
import videoCall from 'assets/images/video-call.png';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1rem;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-bottom: 4rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: 2rem;
  }
`;

const Title = styled(Font)`
  text-align: center;
  font-size: 52px;
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 38px;
  }
`;

const Description = styled(Font)`
  text-align: center;
  font-size: 24px;
  margin-top: 24px;
`;

const FeatureSectionText = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

interface FeatureSectionBlobProps {
  blob: string;
}

const FeatureSectionBlob = styled.div<FeatureSectionBlobProps>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${({ blob }) => blob});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 470px;
  min-height: 400px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 24px;
  }
`;

const FeatureSectionTitle = styled(Font)`
  font-size: 32px;
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 24px;
  }
`;

const FeatureSectionDescription = styled(Font)`
  font-size: 18px;
  margin-top: 24px;
`;

const CalendarImg = styled.img`
  width: 65%;
  min-width: 250px;
  height: auto;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 80%;
  }
`;

const DashboardImg = styled.img`
  width: 65%;
  min-width: 250px;
  height: auto;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 80%;
  }
`;

const RescheduleSvg = styled(Reschedule)`
  width: 100%;
  min-width: 250px;
  height: auto;
`;

function ThingsThatMatter() {
  return (
    <Container>
      <Content>
        <TitleWrapper>
          <Title type='h1'>Focus on the things that matter</Title>

          <Description>
            Gotei will do the heavy lifting, so you can focus on your business growth and things
            that matter more.
          </Description>
        </TitleWrapper>

        <Stack flex={1} spacing={4} direction={{ sm: 'row', xs: 'column' }} mt={{ xs: 8, sm: 0 }}>
          <FeatureSectionText>
            <FeatureSectionTitle>Cut down back and forth email</FeatureSectionTitle>

            <FeatureSectionDescription>
              Stop once for ever questions like “what time works for you”. Your customers choose the
              time and day depending on your customized availability.
            </FeatureSectionDescription>
          </FeatureSectionText>

          <FeatureSectionBlob blob={blob3}>
            <CalendarImg src={calendar} />
          </FeatureSectionBlob>
        </Stack>

        <Stack flex={1} spacing={4} direction={{ sm: 'row-reverse', xs: 'column' }} mt={{ xs: 5 }}>
          <FeatureSectionText>
            <FeatureSectionTitle>Customize appointments to your business needs</FeatureSectionTitle>

            <FeatureSectionDescription>
              Set up your appointments with flexibility and power. Gotei lets you customize a lot of
              things. You can configure your business opening and closing hours. You can set up your
              staff’s working hours and when they want a break.
            </FeatureSectionDescription>
          </FeatureSectionText>

          <FeatureSectionBlob blob={blob4}>
            <DashboardImg src={dashboard} />
          </FeatureSectionBlob>
        </Stack>

        <Stack flex={1} spacing={4} direction={{ sm: 'row', xs: 'column' }} mt={{ xs: 5 }}>
          <FeatureSectionText>
            <FeatureSectionTitle>
              Provide your costumers with flexibility and control
            </FeatureSectionTitle>

            <FeatureSectionDescription>
              Your costumers will get an email which allows them to manage their appointment. if
              something changes or the costumers can't attend the appointment, they can easily
              reschedule or cancel it with just a click.
            </FeatureSectionDescription>
          </FeatureSectionText>

          <FeatureSectionBlob
            blob={videoCall}
            style={{ backgroundSize: '60% auto', minHeight: 'auto' }}
          >
            <RescheduleSvg />
          </FeatureSectionBlob>
        </Stack>
      </Content>
    </Container>
  );
}

export default ThingsThatMatter;
