import { useState } from 'react';
import { Font } from 'elements';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Box, Button, Divider, IconButton, Stack } from '@mui/material';
import {
  ChevronLeft,
  Delete,
  KeyboardBackspaceRounded,
  MoreVertTwoTone,
  Check,
  Edit,
} from '@mui/icons-material';
import { Popper } from 'elements';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { setServiceEdit, clearServiceEdit, setServiceDelete, serviceUpdateRequest } from '.';

// components
import ServiceDetail from './components/ServiceDetail';
import { LoadingButton } from '@mui/lab';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem;
  }
`;

const Content = styled.div`
  margin-top: 1rem;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const XsScreenShow = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
  }
`;

const MdScreenShow = styled.div`
  display: none;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
  }
`;

function ServiceDetailComposition() {
  const { service, serviceEdit, serviceEditLoading } = useSelector(
    (state: RootState) => state.serviceDetail,
  );
  const [popperEl, setPopperEl] = useState<any>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Container>
      <PageHeader>
        <XsScreenShow>
          <Box flex={1}>
            <IconButton onClick={() => history.goBack()}>
              <KeyboardBackspaceRounded sx={{ fontSize: 30 }} />
            </IconButton>
          </Box>

          <Box flex={1.5} textAlign='center'>
            <Font type='h1'>Service Detail</Font>
          </Box>

          <Stack flex={1} direction='row' justifyContent='flex-end'>
            {serviceEdit.id ? (
              <>
                <Button color='inherit' onClick={() => dispatch(clearServiceEdit())}>
                  Cancel
                </Button>

                <LoadingButton
                  sx={{
                    ml: 1,
                  }}
                  loading={serviceEditLoading}
                  startIcon={<Check />}
                  variant='contained'
                  onClick={() => dispatch(serviceUpdateRequest())}
                >
                  Save
                </LoadingButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={(event) => {
                    setPopperEl(event.currentTarget);
                  }}
                >
                  <MoreVertTwoTone />
                </IconButton>

                <Popper open={!!popperEl} anchorEl={popperEl} onClose={() => setPopperEl(null)}>
                  <Stack p={1} spacing={1} divider={<Divider />}>
                    <Box>
                      <Button
                        fullWidth
                        color='inherit'
                        size='large'
                        startIcon={<Edit />}
                        onClick={async () => {
                          if (service) {
                            dispatch(
                              setServiceEdit({
                                ...service,
                                staff: service.staff.map((staffDetail) => staffDetail.id),
                              }),
                            );
                          }
                          setPopperEl(null);
                        }}
                      >
                        Edit
                      </Button>
                    </Box>

                    <Button
                      fullWidth
                      color='error'
                      size='large'
                      startIcon={<Delete />}
                      onClick={() => {
                        dispatch(setServiceDelete(service));
                        setPopperEl(null);
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                </Popper>
              </>
            )}
          </Stack>
        </XsScreenShow>

        <MdScreenShow>
          <Font type='h1'>Service Detail</Font>

          <Font>
            Service detailed page, here you edit your service information and assign or remove staff
            from performing this service.
          </Font>

          <Box mt={2} ml={-1}>
            <Button color='inherit' startIcon={<ChevronLeft />} onClick={() => history.goBack()}>
              Back
            </Button>
          </Box>
        </MdScreenShow>
      </PageHeader>

      <Divider />

      <Content>
        <ServiceDetail />
      </Content>
    </Container>
  );
}

export default ServiceDetailComposition;
