import React, { useEffect } from 'react';

// dependencies
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../store/config/rootReducer';
import { authTokenVerifyRequest, userPermissionRequest } from 'store/auth';
import { useAuthEffect } from 'hooks';

interface Props {
  children: React.ReactElement;
}

const CheckLogoutSuccessFlow = ({ children }: Props) => {
  const { isAuthenticated, isAuthenticatedVerified, userPermission } = useSelector(
    (state: RootState) => state.auth,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated && isAuthenticatedVerified) {
      history.push('/');
    }
  }, [isAuthenticated, isAuthenticatedVerified, dispatch, history]);

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticatedVerified) {
      dispatch(authTokenVerifyRequest());
    }
  }, [dispatch, isAuthenticated, isAuthenticatedVerified]);

  useAuthEffect(() => {
    if (!userPermission.length) {
      dispatch(userPermissionRequest());
    }
  }, [dispatch]);

  return children;
};

export default CheckLogoutSuccessFlow;
