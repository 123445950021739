import { call, fork, takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// api
import { companyModuleRpc, userModuleRpc, serviceModuleRpc, staffModuleRpc } from 'services/api';
import { fieldIsEmpty, formErrorIsEmpty, getError, getErrorEntry, logger } from 'utils';

import {
  Permission,
  Service,
  ServiceFormEditPartial,
  Staff,
  StaffBreak,
  StaffBreaksParams,
  WorkingHour,
  WorkingHourFormCreate,
  WorkingHoursByStaffParams,
} from 'model';
import { RootState } from 'store/config';
import { openSnackbarRequest } from 'store/ui';
import { userPermissionRequest } from 'store/auth';
import { setServices, selectCompanyServices } from 'store/organization';
import {
  StaffActionTypes,
  setStaffDetail,
  staffLoading,
  setStaffDetailEditLoading,
  selectStaffDetailEdit,
  updateStaffDetailEdit,
  clearStaffDetailEdit,
  selectStaffDetailEditPartial,
  clearStaffDetailEditPartial,
  setStaffDetailEditError,
  selectStaffDetailEditError,
  clearStaffDetailEditError,
  setUserInviteModalShow,
  selectUserInvite,
  setUserInvite,
  setUserInviteError,
  staffDetailRequest,
  setStaffUserPermission,
  staffUserPermissionRequest,
  setUpdateServiceStaffError,
  userDeleteSnackbarSuccess,
  selectStaffDetail,
  userDeleteSnackbarFailure,
  setStaffBreaks,
  selectStaffBreakFormCreate,
  selectStaffBreaks,
  clearStaffBreakFormCreate,
  selectStaffBreakFormUpdate,
  clearStaffBreakFormUpdate,
  setStaffBreakFormCreateError,
  setStaffBreakFormLoading,
  setStaffBreakFormUpdateError,
  setWorkingHours,
  setWorkingHoursForm,
  workingHoursRequest,
  setWorkingHoursError,
  clearWorkingHoursError,
  setStaffDetailDeleteLoading,
  setStaffDetailDeleteSuccess,
  selectStaffDetailDelete,
} from '.';

function* getStaffDetail(action: PayloadAction<number>) {
  if (!action.payload) {
    return;
  }

  try {
    logger('Staff Detail Request');

    yield put(staffLoading(true));

    const response: Staff = yield call(
      [staffModuleRpc, staffModuleRpc.getStaffDetail],
      action.payload,
    );

    yield put(setStaffDetail(response));

    yield put(staffLoading(false));

    logger('Staff Detail Success');
  } catch (error: any) {
    yield put(staffLoading(false));
    logger('Staff Detail Failure');
  }
}

function* updateStaffDetail() {
  yield call(validateUpdateStaffDetail);

  const state: RootState = yield select();
  const staffDetail = selectStaffDetail(state);
  const staffEdit = selectStaffDetailEdit(state);
  const staffEditError = selectStaffDetailEditError(state);

  if (formErrorIsEmpty(staffEditError)) return;

  try {
    logger('Staff Detail Update Request');

    yield put(setStaffDetailEditLoading(true));

    const response: Staff = yield call(
      [staffModuleRpc, staffModuleRpc.updateStaffDetail],
      staffDetail,
      { ...staffEdit, user: staffEdit.user?.id ? staffEdit.user.id : null },
    );
    logger('Staff Detail Update Success');

    yield put(setStaffDetail(response));
    yield put(clearStaffDetailEdit());
    yield put(setStaffDetailEditLoading(false));
  } catch (error: any) {
    const { key, value } = getErrorEntry(error);

    yield put(
      setStaffDetailEditError({
        key,
        value,
      }),
    );

    yield put(setStaffDetailEditLoading(false));
    logger.error('Staff Detail Update Failure');
  }
}

function* validateUpdateStaffDetail() {
  yield put(clearStaffDetailEditError());

  const state: RootState = yield select();
  const staffEdit = selectStaffDetailEdit(state);

  if (fieldIsEmpty(staffEdit.name)) {
    yield put(
      setStaffDetailEditError({
        key: 'name',
        value: 'This field is required.',
      }),
    );
  }
}

function* updateStaffDetailPartial() {
  const state: RootState = yield select();
  const staffDetailEditPartial = selectStaffDetailEditPartial(state);
  const staffDetail = selectStaffDetail(state);

  try {
    yield put(setStaffDetailEditLoading(true));
    yield put(clearStaffDetailEditError());

    logger('Staff detail form update partial request');

    const response: Staff = yield call(
      [staffModuleRpc, staffModuleRpc.updateStaffDetailPartial],
      staffDetail,
      staffDetailEditPartial,
    );

    yield put(setStaffDetail(response));
    yield put(clearStaffDetailEditPartial());
    yield put(
      updateStaffDetailEdit({
        key: 'image',
        value: null,
      }),
    );
    yield put(setStaffDetailEditLoading(false));

    logger('Staff detail form update partial success');
  } catch (error: any) {
    const { key, value } = getErrorEntry(error);

    yield put(
      setStaffDetailEditError({
        key,
        value,
      }),
    );

    yield put(setStaffDetailEditLoading(false));

    logger('Staff detail form update partial failure');
  }
}

function* deleteStaffDetail() {
  yield call(validateUpdateStaffDetail);

  const state: RootState = yield select();
  const staffDetailDelete = selectStaffDetailDelete(state);

  if (!staffDetailDelete.id) {
    return;
  }

  try {
    logger('Staff Detail Delete Request');

    yield put(setStaffDetailDeleteLoading(true));

    yield call(staffModuleRpc.deleteStaffDetail.bind(staffModuleRpc, staffDetailDelete.id));

    logger('Staff Detail Delete Success');

    yield put(setStaffDetailDeleteSuccess(true));
    yield put(setStaffDetailDeleteLoading(false));
  } catch (error: any) {
    yield put(setStaffDetailDeleteLoading(true));

    const { key, value } = getErrorEntry(error);

    yield put(
      setStaffDetailEditError({
        key,
        value,
      }),
    );

    yield put(setStaffDetailEditLoading(false));
    logger('Staff Detail Delete Failure');
  }
}

function* inviteUser(action: PayloadAction<number>) {
  const state: RootState = yield select();
  const userInvite = selectUserInvite(state);
  const staff = selectStaffDetail(state);

  try {
    logger('Invite User Request');

    yield put(setStaffDetailEditLoading(true));

    yield call(companyModuleRpc.inviteUser.bind(companyModuleRpc, userInvite, staff.id));

    yield put(staffDetailRequest(staff.id));
    yield put(setUserInvite(''));
    yield put(setUserInviteError(''));
    yield put(setUserInviteModalShow(false));
    yield put(setStaffDetailEditLoading(false));
    logger('Invite User Success');
  } catch (error: any) {
    const { value } = getErrorEntry(error);
    yield put(setUserInviteError(value));
    yield put(setStaffDetailEditLoading(false));
    logger('Invite User Failure');
  }
}

function* deleteUserSnackbarSuccess(action: PayloadAction<number>) {
  const userId = action.payload;

  try {
    logger('User Delete Request');

    yield put(setStaffDetailEditLoading(true));

    yield call(userModuleRpc.userDelete.bind(userModuleRpc, userId));

    // yield put(staffDetailRequest());
    yield put(setStaffDetailEditLoading(false));
    logger('User Delete Success');
  } catch (error: any) {
    const { value } = getErrorEntry(error);
    yield put(setUserInviteError(value));
    yield put(setStaffDetailEditLoading(false));
    logger('User Delete Failure');
  }
}

function* deleteUserSnackbarFailure(action: PayloadAction<Staff>) {
  logger('User Delete Snackbar Failure');

  yield put(setStaffDetail(action.payload));
}

function* deleteUserSnackbarRequest(action: PayloadAction<number>) {
  const state: RootState = yield select();
  const staffDetail = { ...selectStaffDetail(state) };
  const userId = action.payload;
  const staffDetailCopy = { ...staffDetail };

  logger('User Delete Snackbar Request');

  // remove temporary staff user
  staffDetail.user = null;
  yield put(setStaffDetail(staffDetail));

  yield put(
    openSnackbarRequest(
      `Staff credentials removed.`,
      userDeleteSnackbarSuccess(userId),
      userDeleteSnackbarFailure(staffDetailCopy),
    ),
  );
}

function* getStaffUserPermissions() {
  const state: RootState = yield select();
  const staffDetail = selectStaffDetail(state);

  if (!staffDetail.user?.id) {
    return;
  }

  try {
    logger('Staff Permissions Request');

    const response: Permission = yield call(
      staffModuleRpc.getStaffUserPermissions.bind(staffModuleRpc, staffDetail.user?.id),
    );

    yield put(setStaffUserPermission(response));

    logger('Staff Permissions Success');
  } catch (error: any) {
    logger('Staff Permissions Failure');
  }
}

function* updateStaffUserPermission(action: PayloadAction<Permission>) {
  const state: RootState = yield select();
  const staffDetail = selectStaffDetail(state);
  const permission = action.payload;

  if (!staffDetail.user?.id) {
    return;
  }

  try {
    logger('Staff User Update Permissions Request');

    yield call(
      staffModuleRpc.addStaffUserPermission.bind(staffModuleRpc, staffDetail.user.id, permission),
    );

    yield put(staffUserPermissionRequest());
    yield put(userPermissionRequest());
    yield put(openSnackbarRequest(`${staffDetail.name} update permission.`));
    logger('Staff User Update Permissions Success');
  } catch (error: any) {
    logger('Staff User Update Permissions Failure');
  }
}

interface UpdateServiceStaffPayload {
  service: Service;
  serviceFormPartial: ServiceFormEditPartial;
}

function* updateServiceStaff(action: PayloadAction<UpdateServiceStaffPayload>) {
  const serviceFormPartial = action.payload.serviceFormPartial;
  const service = action.payload.service;

  try {
    logger('Service Staff Update Request');

    yield put(setUpdateServiceStaffError(''));

    const response: Service = yield call(
      [serviceModuleRpc, serviceModuleRpc.updateServicePartial],
      service,
      serviceFormPartial,
    );

    const state: RootState = yield select();
    const services = selectCompanyServices(state);
    const updateServices = services.map((service) => {
      if (service.id === response.id) {
        return response;
      } else {
        return service;
      }
    });

    yield put(setServices(updateServices));
    logger('Service Staff Update Success');
  } catch (error: any) {
    const { value } = getErrorEntry(error);

    yield put(setUpdateServiceStaffError(value));

    logger('Service Staff Update Failure');
  }
}

function* getStaffBreaks() {
  const state: RootState = yield select();
  const staffDetail = selectStaffDetail(state);

  if (!staffDetail.id) return;

  try {
    logger('Staff Breaks Request');

    yield put(setUpdateServiceStaffError(''));

    const params: StaffBreaksParams = {
      staff: staffDetail.id,
    };

    const response: Array<StaffBreak> = yield call(
      staffModuleRpc.getStaffBreaks.bind(staffModuleRpc, params),
    );

    yield put(setStaffBreaks(response));

    logger('Staff Breaks Success');
  } catch (error: any) {
    const { value } = getErrorEntry(error);

    yield put(setUpdateServiceStaffError(value));

    logger('Staff Breaks Failure');
  }
}

function* createStaffBreak() {
  const state: RootState = yield select();
  const staffBreakFormCreate = selectStaffBreakFormCreate(state);

  try {
    logger('Create Staff Break Request');
    yield put(setStaffBreakFormCreateError(''));
    yield put(setStaffBreakFormLoading(true));

    const response: StaffBreak = yield call(
      staffModuleRpc.createStaffBreak.bind(staffModuleRpc, staffBreakFormCreate),
    );

    logger('Create Staff Break Success');

    const staffBreaks = [...selectStaffBreaks(state)];
    staffBreaks.push(response);

    yield put(setStaffBreaks(staffBreaks));
    yield put(clearStaffBreakFormCreate());
    yield put(setStaffBreakFormLoading(false));
    yield put(openSnackbarRequest(`Staff break created.`));
  } catch (error: any) {
    logger('Create Staff Break Failure');
    yield put(setStaffBreakFormCreateError(getError(error)));
    yield put(setStaffBreakFormLoading(false));
  }
}

function* deleteStaffBreak(action: PayloadAction<number>) {
  const state: RootState = yield select();
  const staffBreakId = action.payload;

  try {
    logger('Delete Staff Break Request');

    yield call(staffModuleRpc.deleteStaffBreak.bind(staffModuleRpc, staffBreakId));

    logger('Delete Staff Break Success');

    const staffBreaks = [
      ...selectStaffBreaks(state).filter((staffBreak) => staffBreak.id !== staffBreakId),
    ];

    yield put(setStaffBreaks(staffBreaks));
    yield put(openSnackbarRequest(`Staff break deleted.`));
  } catch (error: any) {
    logger('Delete Staff Break Failure');
  }
}

function* updateStaffBreak() {
  const state: RootState = yield select();
  const staffBreakFormUpdate = selectStaffBreakFormUpdate(state);

  try {
    logger('Update Staff Break Request');
    yield put(setStaffBreakFormUpdateError(''));

    const response: StaffBreak = yield call(
      staffModuleRpc.updateStaffBreak.bind(staffModuleRpc, staffBreakFormUpdate),
    );

    logger('Update Staff Break Success');

    const staffBreaks = [
      ...selectStaffBreaks(state).map((staffBreak) => {
        if (staffBreak.id === response.id) {
          return response;
        } else {
          return staffBreak;
        }
      }),
    ];

    yield put(setStaffBreaks(staffBreaks));
    yield put(clearStaffBreakFormUpdate());
    yield put(openSnackbarRequest(`Staff break updated.`));
  } catch (error: any) {
    yield put(setStaffBreakFormUpdateError(getError(error)));

    logger('Update Staff Break Failure');
  }
}

function* getStaffWorkingHours() {
  const state: RootState = yield select();
  const staffDetail = selectStaffDetail(state);

  if (!staffDetail.id) return;

  try {
    logger('Staff Working Hours Request');

    const params: WorkingHoursByStaffParams = {
      staff: staffDetail.id,
    };

    const response: Array<WorkingHour> = yield call(
      staffModuleRpc.geWorkingHoursByStaff.bind(staffModuleRpc, params),
    );

    logger('Staff Working Hours Success');

    yield put(setWorkingHours(response));
    yield put(setWorkingHoursForm(response));
  } catch (error) {
    logger.error('Staff Working Hours Failure');
  }
}

function* updateStaffWorkingHour(action: PayloadAction<WorkingHour>) {
  yield put(clearWorkingHoursError());

  try {
    logger('Staff Working Hours Update Request');

    yield call(staffModuleRpc.updateWorkingHour.bind(staffModuleRpc, action.payload));

    logger('Staff Working Hours Update Success');

    yield put(workingHoursRequest());
  } catch (error) {
    logger.error('Staff Working Hours Update Failure');

    yield put(
      setWorkingHoursError({
        key: action.payload.weekday,
        value: getError(error),
      }),
    );
  }
}

function* createStaffWorkingHour(action: PayloadAction<WorkingHourFormCreate>) {
  try {
    logger('Staff Working Hours Create Request');

    yield call(staffModuleRpc.createWorkingHour.bind(staffModuleRpc, action.payload));

    logger('Staff Working Hours Create Success');

    yield put(workingHoursRequest());
  } catch (error) {
    logger.error('Staff Working Hours Create Failure');
  }
}

function* deleteStaffWorkingHour(action: PayloadAction<WorkingHour>) {
  try {
    logger('Staff Working Hours Delete Request');

    yield call(staffModuleRpc.deleteWorkingHour.bind(staffModuleRpc, action.payload));

    logger('Staff Working Hours Delete Success');

    yield put(workingHoursRequest());
  } catch (error) {
    logger.error('Staff Working Hours Delete Failure');
  }
}

function* staffWorkingHoursWatcher() {
  yield takeLatest(StaffActionTypes.STAFF_WORKING_HOURS_REQUEST, getStaffWorkingHours);
}

function* staffWorkingHourUpdateWatcher() {
  yield takeEvery(StaffActionTypes.STAFF_WORKING_HOUR_UPDATE_REQUEST, updateStaffWorkingHour);
}

function* staffWorkingHourCreateWatcher() {
  yield takeLatest(StaffActionTypes.STAFF_WORKING_HOUR_CREATE_REQUEST, createStaffWorkingHour);
}

function* staffWorkingHourDeleteWatcher() {
  yield takeLatest(StaffActionTypes.STAFF_WORKING_HOUR_DELETE_REQUEST, deleteStaffWorkingHour);
}

function* staffDetailRequestWatcher() {
  yield takeLatest(StaffActionTypes.STAFF_DETAIL_REQUEST, getStaffDetail);
}

function* staffDetailUpdateRequestWatcher() {
  yield takeLatest(StaffActionTypes.STAFF_DETAIL_UPDATE_REQUEST, updateStaffDetail);
}

function* staffDetailUpdatePartialWatcher() {
  yield takeLatest(StaffActionTypes.STAFF_DETAIL_UPDATE_PARTIAL_REQUEST, updateStaffDetailPartial);
}

function* staffDetailDeleteRequestWatcher() {
  yield takeLatest(StaffActionTypes.STAFF_DETAIL_DELETE_REQUEST, deleteStaffDetail);
}

function* userInviteRequestWatcher() {
  yield takeLatest(StaffActionTypes.USER_INVITE_REQUEST, inviteUser);
}

function* userDeleteSNackbarRequestWatcher() {
  yield takeLatest(StaffActionTypes.USER_DELETE_SNACKBAR_REQUEST, deleteUserSnackbarRequest);
}

function* userDeleteSNackbarSuccessWatcher() {
  yield takeLatest(StaffActionTypes.USER_DELETE_SNACKBAR_SUCCESS, deleteUserSnackbarSuccess);
}

function* userDeleteSNackbarFailureWatcher() {
  yield takeLatest(StaffActionTypes.USER_DELETE_SNACKBAR_FAILURE, deleteUserSnackbarFailure);
}

function* staffUserPermissionsWatcher() {
  yield takeLatest(StaffActionTypes.STAFF_USER_PERMISSION_REQUEST, getStaffUserPermissions);
}

function* updateStaffUserPermissionWatcher() {
  yield takeLatest(
    StaffActionTypes.UPDATE_STAFF_USER_PERMISSION_REQUEST,
    updateStaffUserPermission,
  );
}

function* updateServiceStaffRequestWatcher() {
  yield takeLatest(StaffActionTypes.UPDATE_SERVICE_STAFF_REQUEST, updateServiceStaff);
}

function* staffBreaksRequestWatcher() {
  yield takeLatest(StaffActionTypes.BREAKS_REQUEST, getStaffBreaks);
}

function* createStaffBreakWatcher() {
  yield takeLatest(StaffActionTypes.CREATE_BREAK_REQUEST, createStaffBreak);
}

function* deleteStaffBreakWatcher() {
  yield takeLatest(StaffActionTypes.DELETE_BREAK_REQUEST, deleteStaffBreak);
}

function* updateStaffBreakWatcher() {
  yield takeLatest(StaffActionTypes.UPDATE_BREAK_REQUEST, updateStaffBreak);
}

export const StaffDetailSagas = [
  fork(staffDetailRequestWatcher),
  fork(staffDetailUpdateRequestWatcher),
  fork(staffDetailUpdatePartialWatcher),
  fork(staffDetailDeleteRequestWatcher),
  fork(userInviteRequestWatcher),
  fork(userDeleteSNackbarRequestWatcher),
  fork(userDeleteSNackbarSuccessWatcher),
  fork(userDeleteSNackbarFailureWatcher),
  fork(staffUserPermissionsWatcher),
  fork(updateStaffUserPermissionWatcher),
  fork(updateServiceStaffRequestWatcher),
  fork(staffBreaksRequestWatcher),
  fork(createStaffBreakWatcher),
  fork(deleteStaffBreakWatcher),
  fork(updateStaffBreakWatcher),
  fork(staffWorkingHoursWatcher),
  fork(staffWorkingHourUpdateWatcher),
  fork(staffWorkingHourCreateWatcher),
  fork(staffWorkingHourDeleteWatcher),
];
