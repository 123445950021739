import { Task } from '@redux-saga/types';
import { PayloadAction, Action } from '@reduxjs/toolkit';
import { EventChannel, eventChannel } from 'redux-saga';
import {
  fork,
  put,
  takeLatest,
  delay,
  cancel,
  call,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { RootState } from 'store/config';
import { Cookies } from 'react-cookie';
import { BookingType } from 'model';
import { fieldIsEmpty, formErrorIsEmpty, logger } from 'utils';
import { DOMAIN } from 'config';

import { calendarModuleRpc } from 'services/api';
import { selectCompanyDetail } from 'store/organization';
import { selectCalendarBookings, setBookings } from 'screens/calendar';
import { selectDailyBookingAreFetched, dailyBookingsRequest } from 'screens/dashboard';
import {
  UiActionTypes,
  closeSnackbar,
  clearSnackbar,
  openSnackbar,
  setSnackbarSuccessAction,
  selectUiSnackbarSuccessAction,
  setSnackbarActionUndo,
  clearSnackbarSuccessAction,
  selectUiSnackbarActionUndo,
  setSnackbarFailureAction,
  clearSnackbarFailureAction,
  selectUiSnackbarFailureAction,
  setNotificationWsOpen,
  setNotifications,
  updateNotifications,
  updateNotificationsReadStatus,
  setNotificationAreFetched,
  selectEventFormCreate,
  clearEventFormError,
  setEventFormError,
  selectEventFormError,
  clearEventFormCreate,
  setEventFormLoading,
  selectEventFormUpdate,
  clearEventFormUpdate,
  setEventDeleteModal,
  clearEventFormDropUpdate,
} from '.';
import { GTM } from 'triggers';

const cookie = new Cookies();
const url = DOMAIN.WEBSOCKET + '/ws/notifications/';

export let websocket: WebSocket;

function* snackbarClose() {
  const state: RootState = yield select();
  const undo = selectUiSnackbarActionUndo(state);
  const snackbarSuccessAction = { ...selectUiSnackbarSuccessAction(state) };
  const snackbarFailureAction = { ...selectUiSnackbarFailureAction(state) };

  if (undo && !!snackbarFailureAction.type) {
    yield put(snackbarFailureAction);
  } else if (!undo && !!snackbarSuccessAction.type) {
    yield put(snackbarSuccessAction);
  }

  yield put(closeSnackbar());
  yield delay(500);
  yield put(clearSnackbar());
  yield put(clearSnackbarSuccessAction());
  yield put(setSnackbarActionUndo(false));
}

function* executeExistingAction() {
  const state: RootState = yield select();
  const snackbarSuccessAction = { ...selectUiSnackbarSuccessAction(state) };

  try {
    if (snackbarSuccessAction.type) {
      yield put(snackbarSuccessAction);
      yield put(clearSnackbarSuccessAction());
      yield put(clearSnackbarFailureAction());
    }
  } catch (error: any) {}
}

interface OpenSnackbarPayload {
  message: string;
  onSuccessAction?: PayloadAction | Action;
  onFailureAction?: PayloadAction | Action;
}

function* openSnackbarRequest(action: PayloadAction<OpenSnackbarPayload>) {
  const message = action.payload.message;
  const onSuccessAction = action.payload.onSuccessAction;
  const onFailureAction = action.payload.onFailureAction;

  yield call(executeExistingAction);

  try {
    const closeSnackbarTask: Task = yield fork(snackbarClose);
    yield cancel(closeSnackbarTask);

    if (!!onSuccessAction) {
      yield put(setSnackbarSuccessAction(onSuccessAction));
    }

    if (!!onFailureAction) {
      yield put(setSnackbarFailureAction(onFailureAction));
    }

    yield put(openSnackbar({ message }));

    yield delay(7000);
    yield call(snackbarClose);
  } catch (error: any) {}
}

function* undoSnackbarRequest() {
  try {
    yield put(setSnackbarActionUndo(true));

    yield call(snackbarClose);
  } catch (error: any) {}
}

function* openSnackbarRequestWatcher() {
  yield takeLatest(UiActionTypes.OPEN_SNACKBAR_REQUEST, openSnackbarRequest);
}

function* undoSnackbarRequestWatcher() {
  yield takeLatest(UiActionTypes.UNDO_SNACKBAR_REQUEST, undoSnackbarRequest);
}

function* closeSnackbarRequestWatcher() {
  yield takeLatest(UiActionTypes.CLOSE_SNACKBAR_REQUEST, snackbarClose);
}

function websocketConnect(): EventChannel<any> {
  return eventChannel((emitter) => {
    const token = cookie.get('token');
    const ws = new WebSocket(`${url}?token=${token}`);
    websocket = ws;

    ws.onopen = () => {
      emitter(setNotificationWsOpen(true));

      ws.send(
        JSON.stringify({
          eventType: 'INITIALIZE_NOTIFICATIONS',
        }),
      );
    };

    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.eventType === 'INITIALIZE_NOTIFICATIONS') {
        emitter(setNotifications(response.data));
        emitter(setNotificationAreFetched(true));
      } else if (response.eventType === 'SEND_NOTIFICATIONS') {
        emitter(updateNotifications(response.data));
      } else if (response.eventType === 'UPDATE_NOTIFICATION') {
        emitter(updateNotificationsReadStatus(response.data));
      }
    };

    ws.onclose = () => {};

    return () => {
      ws.close();
    };
  });
}

function* getWebsocket() {
  const channel: EventChannel<boolean> = yield call(websocketConnect);

  while (true) {
    const action: Action<any> = yield take(channel);

    yield put(action);
  }
}

function* websocketDisconnect() {
  if (websocket) websocket.close();

  yield put(setNotifications([]));
  yield put(setNotificationAreFetched(false));
  yield put(setNotificationWsOpen(false));
}

function* eventCreateSubmit() {
  yield call(validateEventCreate);

  const state: RootState = yield select();
  const company = selectCompanyDetail(state);
  const bookingCreateFormError = selectEventFormError(state);
  const bookingCreateForm = { ...selectEventFormCreate(state) };

  if (formErrorIsEmpty(bookingCreateFormError)) {
    return;
  }

  bookingCreateForm.company = company.uuid;

  try {
    yield put(setEventFormLoading(true));
    logger('Create Bookings Request');

    const response: BookingType = yield call(
      calendarModuleRpc.createBooking.bind(calendarModuleRpc, bookingCreateForm),
    );

    logger('Create Bookings Request Success');

    yield call(GTM.userCreateEvent, {
      clientName: response.client.name,
      providerName: response.staff.name,
      companyName: company.name,
    });

    const bookings = [...selectCalendarBookings(state)];
    bookings.push(response);

    const dailyBookingAreFetched = selectDailyBookingAreFetched(state);

    if (dailyBookingAreFetched) {
      yield put(dailyBookingsRequest());
    }

    yield put(setBookings(bookings));
    yield put(clearEventFormCreate());
    yield put(setEventFormLoading(false));
  } catch (error: any) {
    logger('Create Bookings Request Failure');

    yield put(setEventFormError(error));
    yield put(setEventFormLoading(false));
  }
}

function* validateEventCreate() {
  yield put(clearEventFormError());

  const state: RootState = yield select();
  const bookingFormCreate = selectEventFormCreate(state);

  if (fieldIsEmpty(bookingFormCreate.service)) {
    yield put(setEventFormError({ service: 'This field is required' }));
  }

  if (fieldIsEmpty(bookingFormCreate.staff)) {
    yield put(setEventFormError({ staff: 'This field is required' }));
  }

  if (fieldIsEmpty(bookingFormCreate.client)) {
    yield put(setEventFormError({ client: 'This field is required' }));
  }

  if (fieldIsEmpty(bookingFormCreate.start_time)) {
    yield put(setEventFormError({ start_time: 'This field is required.' }));
  }
}

function* eventUpdateSubmit() {
  yield call(validateEventUpdate);

  const state: RootState = yield select();
  const eventFormError = selectEventFormError(state);
  const eventFormUpdate = selectEventFormUpdate(state);

  if (formErrorIsEmpty(eventFormError)) {
    return;
  }

  try {
    logger('Update Bookings Request');

    yield put(setEventFormLoading(true));

    const response: BookingType = yield call(
      calendarModuleRpc.updateBooking.bind(calendarModuleRpc, eventFormUpdate),
    );

    logger('Update Bookings Request Success');

    const bookings = [...selectCalendarBookings(state)];
    const filteredBookings = [...bookings.filter((element) => element.id !== response.id)];
    filteredBookings.push(response);

    const dailyBookingAreFetched = selectDailyBookingAreFetched(state);

    if (dailyBookingAreFetched) {
      yield put(dailyBookingsRequest());
    }

    yield put(clearEventFormUpdate());
    yield put(setBookings(filteredBookings));
    yield put(clearEventFormDropUpdate());
    yield put(setEventFormLoading(false));
  } catch (error: any) {
    logger('Update Bookings Request Failure');

    yield put(setEventFormError(error));
    yield put(setEventFormLoading(false));
  }
}

function* validateEventUpdate() {
  yield put(clearEventFormError());

  const state: RootState = yield select();
  const eventFormUpdate = selectEventFormUpdate(state);

  if (!eventFormUpdate.service) {
    yield put(setEventFormError({ service: 'This field is required' }));
  }

  if (!eventFormUpdate.staff) {
    yield put(setEventFormError({ staff: 'This field is required' }));
  }

  if (!eventFormUpdate.client) {
    yield put(setEventFormError({ client: 'This field is required' }));
  }

  if (!eventFormUpdate.start_time) {
    yield put(setEventFormError({ start_time: 'This field is required.' }));
  }
}

function* deleteEvent() {
  const state: RootState = yield select();
  const eventFormUpdate = selectEventFormUpdate(state);

  try {
    yield put(setEventFormLoading(true));

    logger('Delete Bookings Request');

    yield call(calendarModuleRpc.deleteBooking.bind(calendarModuleRpc, eventFormUpdate.id));

    logger('Delete Bookings Request Success');

    const bookings = [...selectCalendarBookings(state)];
    const filteredBookings = bookings.filter((booking) => booking.id !== eventFormUpdate.id);
    yield put(setBookings(filteredBookings));

    const dailyBookingAreFetched = selectDailyBookingAreFetched(state);

    if (dailyBookingAreFetched) {
      yield put(dailyBookingsRequest());
    }

    yield put(clearEventFormUpdate());
    yield put(setEventDeleteModal(false));
    yield put(setEventFormLoading(false));
  } catch (error: any) {
    yield put(setEventFormLoading(false));

    logger('Delete Bookings Request Failure');
  }
}

function* websocketConnectWatcher() {
  yield takeEvery(UiActionTypes.WS_NOTIFICATION_CONNECT, getWebsocket);
}

function* websocketDisconnectWatcher() {
  yield takeEvery(UiActionTypes.WS_NOTIFICATION_DISCONNECT, websocketDisconnect);
}

function* eventCreateSubmitWatcher() {
  yield takeLatest(UiActionTypes.EVENT_CREATE_SUBMIT, eventCreateSubmit);
}

function* eventUpdateSubmitWatcher() {
  yield takeLatest(UiActionTypes.EVENT_UPDATE_SUBMIT, eventUpdateSubmit);
}

function* deleteEventWatcher() {
  yield takeLatest(UiActionTypes.EVENT_DELETE_REQUEST, deleteEvent);
}

export const uiSagas = [
  fork(openSnackbarRequestWatcher),
  fork(undoSnackbarRequestWatcher),
  fork(closeSnackbarRequestWatcher),
  fork(websocketConnectWatcher),
  fork(websocketDisconnectWatcher),
  fork(eventCreateSubmitWatcher),
  fork(eventUpdateSubmitWatcher),
  fork(deleteEventWatcher),
];
