import { useState, createContext, useContext, ReactNode, useCallback, MouseEvent } from 'react';

type PrivateLayoutContextReturnType = {
  appointmentAnchor: HTMLButtonElement | null;
  notificationAnchor: HTMLButtonElement | null;
  setAppointmentAnchor: (event: MouseEvent<HTMLButtonElement> | null) => void;
  setNotificationAnchor: (event: MouseEvent<HTMLButtonElement> | null) => void;
};

const PrivateLayoutContext = createContext<PrivateLayoutContextReturnType>({
  appointmentAnchor: null,
  notificationAnchor: null,
  setAppointmentAnchor: () => {},
  setNotificationAnchor: () => {},
});

const usePrivateLayoutProvider = (): PrivateLayoutContextReturnType => {
  const [appointmentAnchor, setAppointmentAnchor] = useState<HTMLButtonElement | null>(null);
  const [notificationAnchor, setNotificationAnchor] = useState<HTMLButtonElement | null>(null);

  const setAppointmentAnchorHandler = useCallback((event: MouseEvent<HTMLButtonElement> | null) => {
    setAppointmentAnchor(event ? event.currentTarget : null);
  }, []);

  const setNotificationAnchorHandler = useCallback((event) => {
    setNotificationAnchor(event ? event.currentTarget : null);
  }, []);

  return {
    appointmentAnchor,
    notificationAnchor,
    setAppointmentAnchor: setAppointmentAnchorHandler,
    setNotificationAnchor: setNotificationAnchorHandler,
  };
};

interface Props {
  children: ReactNode | ReactNode[];
}

export const PrivateLayoutProvider = ({ children }: Props) => {
  const value = usePrivateLayoutProvider();

  return <PrivateLayoutContext.Provider value={value}>{children}</PrivateLayoutContext.Provider>;
};

export const usePrivateLayout = (): PrivateLayoutContextReturnType => {
  const context = useContext(PrivateLayoutContext);

  if (!context)
    throw new Error('usePrivateLayout() cannot be used outside of <PrivateLayoutProvider/>');
  return context;
};
