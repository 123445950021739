import { LoadingButton } from '@mui/lab';
import { Divider, TextField, Link, Alert, AlertTitle } from '@mui/material';
import { Logo } from 'elements';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { useDocumentTitle, useWillUnmount } from 'hooks';

// redux
import { RootState } from 'store/config';
import { useDispatch, useSelector } from 'react-redux';
import {
  setEmail,
  resetPasswordRequest,
  clearResetPasswordError,
  setResetPasswordSuccess,
} from '..';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
`;

const LogoWrapper = styled.div`
  padding: 0 0 40px 20px;
`;

const Content = styled.div`
  margin-top: 56px;
  min-width: 540px;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 90%;
    min-width: unset;
  }
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 56px 48px;
  box-shadow: 0 14px 32px 0 rgb(22 45 61 / 8%), 0 1px 4px 0 rgb(22 45 61 / 10%);
  border-radius: 3px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-width: unset;
    padding: 40px 8px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 0 20px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin: 0 0 0 20px;
`;

const FooterText = styled.div`
  text-align: center;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.grey[600]};
`;

function ResetPassword() {
  const { email, resetPasswordLoading, resetPasswordSuccess, resetPasswordError } = useSelector(
    (state: RootState) => state.resetPassword,
  );
  const dispatch = useDispatch();

  useDocumentTitle('Forgot Password - Gotei');

  useWillUnmount(() => {
    dispatch(setEmail(''));
    dispatch(clearResetPasswordError());
    dispatch(setResetPasswordSuccess(false));
  });

  return (
    <Container>
      <Content>
        <LogoWrapper>
          <Logo link='/' />
        </LogoWrapper>

        <FormSection>
          {resetPasswordSuccess ? (
            <Alert
              severity='success'
              sx={{
                width: {
                  xs: 'unset',
                  sm: 450,
                },
              }}
            >
              <AlertTitle>Forgot Password</AlertTitle>
              You should soon receive an email allowing you to reset your password. Please make sure
              to check your spam and trash if you can't find the email.
            </Alert>
          ) : (
            <>
              <Title>Forgot password</Title>

              <Form
                onSubmit={(event) => {
                  event.preventDefault();

                  dispatch(resetPasswordRequest());
                }}
              >
                <TextField
                  color='primary'
                  variant='outlined'
                  label='Email'
                  type='email'
                  value={email}
                  sx={{ mb: 4 }}
                  error={!!resetPasswordError.email}
                  helperText={resetPasswordError.email}
                  onChange={(event) => dispatch(setEmail(event.target.value))}
                />

                <LoadingButton
                  type='submit'
                  variant='contained'
                  size='large'
                  loading={resetPasswordLoading}
                >
                  Reset Password
                </LoadingButton>

                <Divider sx={{ m: '1rem 0' }} />

                <FooterText>
                  Not sure?{' '}
                  <Link component={RouterLink} to='/login' variant='body2'>
                    Login
                  </Link>
                </FooterText>
              </Form>
            </>
          )}
        </FormSection>
      </Content>
    </Container>
  );
}

export default ResetPassword;
