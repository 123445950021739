import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import {
  BookingType,
  Client,
  ClientFormDelete,
  ClientFormUpdate,
  FieldsErrors,
  FormFieldPayload,
} from 'model';
import { RootState } from 'store/config';

interface State {
  client: Client;
  clientLoading: boolean;
  clientFormError: FieldsErrors;
  clientFormLoading: boolean;
  clientFormDelete: ClientFormDelete;
  clientFormDeleteSuccess: boolean;
  clientFormUpdate: ClientFormUpdate;
  appointments: BookingType[];
  appointmentsLoading: boolean;
  appointmentsAreFetched: boolean;
}

const initialState: State = {
  client: {
    company: '',
    id: 0,
    name: '',
    email: '',
    phone: null,
  },
  clientLoading: false,
  clientFormError: {},
  clientFormLoading: false,
  clientFormDelete: {
    id: 0,
    company: '',
    name: '',
    phone: null,
    email: '',
  },
  clientFormDeleteSuccess: false,
  clientFormUpdate: {
    id: 0,
    company: '',
    name: '',
    phone: null,
    email: '',
  },
  appointments: [],
  appointmentsLoading: false,
  appointmentsAreFetched: false,
};

const ClientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClient: (state, action: PayloadAction<Client>) => {
      return {
        ...state,
        client: action.payload,
      };
    },
    setClientLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        clientLoading: action.payload,
      };
    },
    setClientFormError: (state, action: PayloadAction<FieldsErrors>) => {
      return {
        ...state,
        clientFormError: {
          ...state.clientFormError,
          ...action.payload,
        },
      };
    },
    clearClientFormError: (state) => {
      return {
        ...state,
        clientFormError: {},
      };
    },
    setClientFormLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        clientFormLoading: action.payload,
      };
    },
    setClientFormDelete: (state, action: PayloadAction<ClientFormDelete>) => {
      return {
        ...state,
        clientFormDelete: action.payload,
      };
    },
    clearClientFormDelete: (state) => {
      return {
        ...state,
        clientFormDelete: { id: 0, company: '', name: '', phone: null, email: '' },
      };
    },
    setClientFormDeleteSuccess: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        clientFormDeleteSuccess: action.payload,
      };
    },
    setClientFormUpdate: (state, action: PayloadAction<ClientFormUpdate>) => {
      return {
        ...state,
        clientFormUpdate: action.payload,
      };
    },
    updateClientFormUpdate: (
      state,
      action: PayloadAction<FormFieldPayload<keyof ClientFormUpdate>>,
    ) => {
      return {
        ...state,
        clientFormUpdate: {
          ...state.clientFormUpdate,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearClientFormUpdate: (state) => {
      return {
        ...state,
        clientFormUpdate: {
          company: '',
          id: 0,
          name: '',
          phone: null,
          email: '',
        },
      };
    },
    setAppointments: (state, action: PayloadAction<BookingType[]>) => {
      return {
        ...state,
        appointments: action.payload,
      };
    },
    setAppointmentsLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        appointmentsLoading: action.payload,
      };
    },
    setAppointmentsAreFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        appointmentsAreFetched: action.payload,
      };
    },
  },
});

export const {
  setClient,
  setClientLoading,
  setClientFormError,
  clearClientFormError,
  setClientFormLoading,
  setClientFormDelete,
  clearClientFormDelete,
  setClientFormDeleteSuccess,
  setClientFormUpdate,
  updateClientFormUpdate,
  clearClientFormUpdate,
  setAppointments,
  setAppointmentsLoading,
  setAppointmentsAreFetched,
} = ClientSlice.actions;

export enum ClientActionTypes {
  CLIENT_REQUEST = 'client/CLIENT_REQUEST',
  CLIENT_APPOINTMENTS_REQUEST = 'client/CLIENT_APPOINTMENTS_REQUEST',
  CLIENT_UPDATE_REQUEST = 'client/CLIENT_UPDATE_REQUEST',
  CLIENT_DELETE_REQUEST = 'client/CLIENT_DELETE_REQUEST',
}

export const clientRequest = (id: number) => {
  return {
    type: ClientActionTypes.CLIENT_REQUEST,
    payload: id,
  };
};

export const clientUpdateRequest = () => {
  return {
    type: ClientActionTypes.CLIENT_UPDATE_REQUEST,
  };
};

export const clientDeleteRequest = () => {
  return {
    type: ClientActionTypes.CLIENT_DELETE_REQUEST,
  };
};

export const clientAppointmentsRequest = () => {
  return {
    type: ClientActionTypes.CLIENT_APPOINTMENTS_REQUEST,
  };
};

export const selectClient = (state: RootState) => {
  return state.client;
};

export const selectClientDetail = createSelector([selectClient], (value) => value.client);

export const selectClientFormError = createSelector(
  [selectClient],
  (value) => value.clientFormError,
);

export const selectClientFormUpdate = createSelector(
  [selectClient],
  (value) => value.clientFormUpdate,
);

export const selectClientFormDelete = createSelector(
  [selectClient],
  (value) => value.clientFormDelete,
);

export const clientReducer = ClientSlice.reducer;
