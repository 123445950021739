import { useEffect } from 'react';

const useWillUnmount = (callback: () => void) => {
  useEffect(() => {
    return () => {
      callback();
    };
    // eslint-disable-next-line
  }, []);
};

export default useWillUnmount;
