import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Permission, User } from 'model';
import { RootState } from 'store/config';

interface SliceState {
  isAuthenticated: boolean;
  isAuthenticatedVerified: boolean;
  logoutLoading: boolean;
  logoutFailed: boolean;
  user: User;
  userPermission: Permission | [];
  userPermissionFetched: boolean;
  userPermissionError: string;
}

const initialState: SliceState = {
  isAuthenticated: false,
  isAuthenticatedVerified: false,
  logoutLoading: false,
  logoutFailed: false,
  user: {
    id: 0,
    name: '',
    email: '',
    phone: null,
    last_login: '',
    is_active: false,
    company: '',
    is_company_admin: false,
    is_company_staff: false,
    image: null,
  },
  userPermission: [],
  userPermissionFetched: false,
  userPermissionError: '',
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    },
    setIsAuthenticatedVerified: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isAuthenticatedVerified: action.payload,
      };
    },
    setLogoutLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        logoutLoading: action.payload,
      };
    },
    setLogoutFailed: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        logoutFailed: action.payload,
      };
    },
    setUser: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    setUserPermissionFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        userPermissionFetched: action.payload,
      };
    },
    setUserPermission: (state, action: PayloadAction<Permission>) => {
      return {
        ...state,
        userPermission: action.payload,
      };
    },
    clearUserPermission: (state) => {
      return {
        ...state,
        userPermission: [],
      };
    },
    setUserPermissionError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        userPermissionError: action.payload,
      };
    },
  },
});

export const {
  setIsAuthenticated,
  setLogoutLoading,
  setLogoutFailed,
  setIsAuthenticatedVerified,
  setUser,
  setUserPermission,
  setUserPermissionFetched,
  clearUserPermission,
  setUserPermissionError,
} = AuthSlice.actions;

export enum AuthActionTypes {
  AUTH_TOKEN_VERIFY_REQUEST = 'auth/AUTH_TOKEN_VERIFY_REQUEST',
  AUTH_TOKEN_REFRESH_REQUEST = 'auth/AUTH_TOKEN_REFRESH_REQUEST',
  AUTH_LOGOUT_REQUEST = 'auth/AUTH_LOGOUT_REQUEST',
  USER_PERMISSION_REQUEST = 'auth/USER_PERMISSION_REQUEST',
}

export const authLogoutRequest = () => {
  return {
    type: AuthActionTypes.AUTH_LOGOUT_REQUEST,
  };
};

export const authTokenVerifyRequest = () => {
  return {
    type: AuthActionTypes.AUTH_TOKEN_VERIFY_REQUEST,
  };
};

export const authTokenRefreshRequest = () => {
  return {
    type: AuthActionTypes.AUTH_TOKEN_REFRESH_REQUEST,
  };
};

export const userPermissionRequest = () => {
  return {
    type: AuthActionTypes.USER_PERMISSION_REQUEST,
  };
};

export const selectAuth = (state: RootState) => {
  return state.auth;
};

export const selectUser = createSelector([selectAuth], (value) => value.user);

export const authReducer = AuthSlice.reducer;
