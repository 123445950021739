import { useAuthEffect } from 'hooks';
import styled from 'styled-components';

// redux
import { useDispatch } from 'react-redux';
import { staffRequest } from '.';

import Calendar from './components/Calendar';

const Container = styled.div`
  flex: 1;
  display: flex;
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

function CalendarComposition() {
  const dispatch = useDispatch();

  useAuthEffect(() => {
    dispatch(staffRequest());
  }, []);

  return (
    <Container>
      <Calendar />
    </Container>
  );
}

export default CalendarComposition;
