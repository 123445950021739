import { useState } from 'react';

// dependencies
import { Button, Typography } from '@mui/material';
import styled from 'styled-components';

const EmailWrapper = styled.div`
  position: relative;
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #001e3c;
  color: white;
  border-radius: 4px;
`;

const EmailShowButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const ContactUs = () => {
  const [showEmail, setShowEmail] = useState(false);

  return (
    <div>
      <Typography variant='h5'>Get in Touch</Typography>

      <Typography sx={{ mt: 2 }}>
        If you have any questions or need help, please fill free to contact us. We do our best to
        respond within 48 hours.
      </Typography>

      <EmailWrapper>
        &nbsp;
        {showEmail && 'support@gotei.app'}
        <EmailShowButtonWrapper>
          {showEmail ? (
            <Button variant='outlined' sx={{ color: 'white' }} onClick={() => setShowEmail(false)}>
              Hide email
            </Button>
          ) : (
            <Button variant='outlined' sx={{ color: 'white' }} onClick={() => setShowEmail(true)}>
              Show email
            </Button>
          )}
        </EmailShowButtonWrapper>
      </EmailWrapper>
    </div>
  );
};

export default ContactUs;
