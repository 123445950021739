import { CSSProperties } from 'react';

export const MuiButtonRoot = {
  boxShadow: 'none !important',
};

export const MuiButtonOutlined = {
  borderRadius: 2,
};

export const MuiButtonContainedInherit = {
  backgroundColor: '#eeeeee',

  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
};

export const MuiButtonContained: CSSProperties = {
  boxShadow: 'none !important',
};

export const MuiButtonTextInherit: CSSProperties = {
  color: '#545b64',
};
