import { Box, Divider, Stack } from '@mui/material';
import { Font } from 'elements';
import styled from 'styled-components';

// components
import { GoogleCalendar, GoogleMeet } from './components';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem;
  }
`;

const Content = styled.div`
  margin: 1rem -2rem -2rem -2rem;
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0;
  }
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1rem;
`;

const IntegrationCard = styled.div`
  width: 300px;
  height: 170px;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 10px;
  padding: 1rem;
  margin: 2rem 0 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.grey[50]};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 1rem 0;
    width: 100%;
  }
`;

export const IntegrationsComposition = () => {
  const history = useHistory();

  return (
    <Container>
      <PageHeader>
        <Stack mr={1}>
          <Font type='h1'>Integrations</Font>

          <Font>Integrate your favorite apps and supercharge your workflow.</Font>
        </Stack>

        <Box></Box>
      </PageHeader>

      <Divider sx={{ mb: 2 }} />

      <Content>
        <IntegrationCard onClick={() => history.push('/integrations/calendar-connections')}>
          <GoogleCalendar />
        </IntegrationCard>

        <IntegrationCard onClick={() => history.push('/integrations/google-meet')}>
          <GoogleMeet />
        </IntegrationCard>
      </Content>
    </Container>
  );
};
