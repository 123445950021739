// dependencies
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/config';

interface Props {
  children: React.ReactNode;
}

const TimeHandler = ({ children }: Props) => {
  const { company } = useSelector((state: RootState) => state.organization);

  useEffect(() => {
    if (company.uuid) {
      moment.tz.setDefault(company.timezone);

      moment.locale('en', {
        week: {
          dow: 1,
          doy: 1,
        },
      });
    }
  }, [company]);

  return <>{children}</>;
};
export default TimeHandler;
