import { useEffect } from 'react';

// dependencies
import { Background } from 'elements';
import { useQuery } from 'hooks';
import styled from 'styled-components';

// redux
import { setToken } from '.';

// components
import ConfirmResetPassword from './components/ConfirmResetPassword';
import ResetPassword from './components/ResetPassword';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';

const Container = styled.div`
  height: 100vh;
  display: flex;
  position: relative;
  background-color: ${({ theme }) => theme.palette.grey[50]};
`;

function ResetPasswordComposition() {
  const resetPasswordToken = useSelector((state: RootState) => state.resetPassword.token);
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    if (token) dispatch(setToken(token));
  }, [dispatch, token]);

  return (
    <Container>
      <Background />

      {resetPasswordToken ? <ConfirmResetPassword /> : <ResetPassword />}
    </Container>
  );
}

export default ResetPasswordComposition;
