// dependencies
import styled from 'styled-components';
import { Font } from 'elements';
import { Alert, AlertTitle, Button } from '@mui/material';
import { AccessTime, ChevronLeft, InsertInvitation, Person } from '@mui/icons-material';
import { ManageSteps } from 'model';
import moment from 'moment-timezone';
import { LoadingButton } from '@mui/lab';
import { useDocumentTitle } from 'hooks';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { setStep, bookingUpdateSubmit } from '..';

const Container = styled.div`
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Service = styled.div`
  flex: 1;
`;

const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

function ConfirmReschedule() {
  const { booking, bookingFormUpdate, bookingFormError, bookingFormUpdateLoading } = useSelector(
    (state: RootState) => state.manage,
  );
  const dispatch = useDispatch();

  useDocumentTitle('Confirm Reschedule - Gotei');

  return (
    <Container>
      <Font
        style={{
          color: 'rgba(26, 26, 26, 0.6)',
          display: 'block',
          marginBottom: 8,
        }}
      >
        Update Appointment
      </Font>

      <Font
        type='h2'
        style={{
          display: 'block',
          marginBottom: 24,
        }}
      >
        Confirm Reschedule
      </Font>

      <Button
        sx={{
          mb: 2,
        }}
        startIcon={<ChevronLeft />}
        color='inherit'
        onClick={() => {
          dispatch(setStep(ManageSteps.reschedule));
        }}
      >
        Reschedule
      </Button>

      <Content>
        <Service>
          <Font type='h4' style={{ marginBottom: 16, color: 'rgba(26, 26, 26, 0.6)' }}>
            Before
          </Font>

          <ServiceItem>
            <Person sx={{ mr: 2 }} />

            <Font
              style={{
                textDecoration: 'line-through',
              }}
            >
              {`${booking.client.name}`}
            </Font>
          </ServiceItem>

          <ServiceItem>
            <InsertInvitation sx={{ mr: 2 }} />

            <Font
              style={{
                textDecoration: 'line-through',
              }}
            >
              {moment(booking.start_time).format('ddd D MMMM YYYY, HH:mm')}
            </Font>
          </ServiceItem>

          <ServiceItem>
            <AccessTime sx={{ mr: 2 }} />

            <Font
              style={{
                textDecoration: 'line-through',
              }}
            >
              {moment(booking.end_time).diff(booking.start_time, 'minutes')} minutes
            </Font>
          </ServiceItem>
        </Service>

        <Service>
          <Font type='h4' style={{ marginBottom: 16, color: 'rgba(26, 26, 26, 0.6)' }}>
            Updated Appointment
          </Font>

          <ServiceItem>
            <Person sx={{ mr: 2 }} />
            <Font>{`${booking.client.name}`}</Font>
          </ServiceItem>

          <ServiceItem>
            <InsertInvitation sx={{ mr: 2 }} />
            <Font>{moment(bookingFormUpdate.start_time).format('ddd D MMMM YYYY, HH:mm')}</Font>
          </ServiceItem>

          <ServiceItem>
            <AccessTime sx={{ mr: 2 }} />
            <Font>
              {moment(booking.end_time).diff(booking.start_time, 'minutes')}
              minutes
            </Font>
          </ServiceItem>
        </Service>
      </Content>

      {Object.entries(bookingFormError).map(([key, value]) => (
        <Alert key={key} severity='error'>
          <AlertTitle>{key}</AlertTitle>
          {value}
        </Alert>
      ))}

      <LoadingButton
        variant='contained'
        sx={{ mt: 2 }}
        loading={bookingFormUpdateLoading}
        onClick={() => {
          dispatch(bookingUpdateSubmit());
        }}
      >
        Update Appointment
      </LoadingButton>
    </Container>
  );
}

export default ConfirmReschedule;
