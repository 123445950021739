const colors = [
  '#0052cc',
  '#00a2bf',
  '#00875a',
  '#ff991f',
  '#de350b',
  '#5243aa',
  '#172b4d',
  '#db65b0',
  '#4498e6',
  '#7dc43b',
];

export const avatarGenerator = (letters = 'OP') => {
  const random = Math.floor(Math.random() * colors.length);
  const canvas = window.document.createElement('canvas');
  const context = canvas.getContext('2d');
  const size = 250;
  canvas.width = size;
  canvas.height = size;

  if (!context) return;

  context.font = '500 ' + Math.round(canvas.width / 2) + 'px Arial';
  context.textAlign = 'center';
  context.fillStyle = colors[random];
  context.beginPath();
  context.arc(125, 125, 125, 0, 2 * Math.PI);
  context.fill();
  context.fillStyle = '#ffffff';
  context.fillText(letters, size / 2, size / 1.5);

  // Set image representation in default format (png)
  const dataURL = canvas.toDataURL();

  return dataURL;
};

export const removeSpecialCharacters = (str: string) => {
  return `${str}`.replace(/[^a-zA-Z0-9 ]/g, '');
};
