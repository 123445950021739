import { useDocumentTitle } from 'hooks';
import { PrivateLayout } from 'layout';

// components
import { CalendarConnectionComposition } from 'screens/CalendarConnection';

export const CalendarConnectionScreen = () => {
  useDocumentTitle('Calendar Connections - Gotei');

  return (
    <PrivateLayout>
      <CalendarConnectionComposition />
    </PrivateLayout>
  );
};
