import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN } from 'config';

export const integrationsApi = createApi({
  reducerPath: 'integrationsApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: `${DOMAIN.API}/integrations`,
  }),
  tagTypes: ['googleCalendar'],
  endpoints: (builder) => ({
    googleCalendar: builder.query({
      query: (userId) => {
        return {
          method: 'get',
          url: 'google-calendar/get-by-user',
          params: { user: userId },
        };
      },
      providesTags: ['googleCalendar'],
    }),
    connectGoogleCalendar: builder.mutation({
      query: (code) => {
        return {
          method: 'post',
          url: 'google-calendar/',
          body: { code },
        };
      },
      invalidatesTags: ['googleCalendar'],
    }),
    disconnectGoogleCalendar: builder.mutation({
      query: (calendarId) => {
        return {
          method: 'delete',
          url: `google-calendar/${calendarId}`,
        };
      },
      invalidatesTags: ['googleCalendar'],
    }),
  }),
});

export const {
  useGoogleCalendarQuery,
  useConnectGoogleCalendarMutation,
  useDisconnectGoogleCalendarMutation,
} = integrationsApi;
