import { useEffect } from 'react';

// dependencies
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import styled from 'styled-components';
import { Font, Modal } from 'elements';
import { Add, Delete } from '@mui/icons-material';
import { debounce, useAuthEffect } from 'hooks';
import { useHistory } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import {
  clientsRequest,
  setClientFormAddModalShow,
  clientCreateRequest,
  setClientsDelete,
  clientDeleteRequest,
  setSelected,
  setSearchText,
  filterClients,
} from '..';

// components
import ClientFormAdd from './ClientFormAdd';

import { ReactComponent as SVG } from 'assets/svg/add-client.svg';

const Container = styled.div``;

const Content = styled.div`
  margin-top: 0.5rem;
`;

const AddClientWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const TableSection = styled.div``;

const DeleteSection = styled.div`
  margin-top: 1rem;
`;

const SvgContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 2rem 0;
`;

const AddClientSvg = styled(SVG)`
  width: 30%;
  height: auto;
  max-width: 280px;
`;

const TableCellMobileHidden = styled(TableCell)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

function Clients() {
  const {
    clients,
    clientsLoading,
    clientsAreFetched,
    filteredClients,
    clientFormAddModalShow,
    clientFormLoading,
    clientsDelete,
    selected,
    searchText,
  } = useSelector((state: RootState) => state.clients);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(filterClients());
  }, [dispatch, searchText]);

  useAuthEffect(() => {
    dispatch(clientsRequest());
  }, [dispatch]);

  const searchTextHandler = debounce((event) => {
    dispatch(setSearchText(event.target.value));
  }, 350);

  return (
    <Container>
      <Content>
        {clientsLoading && !clientsAreFetched ? (
          <CircularProgress sx={{ mt: 1 }} size={24} />
        ) : clientsAreFetched && !clients.length ? (
          <SvgContainer>
            <AddClientSvg />

            <Font style={{ marginTop: '2rem' }} type='h4'>
              Looks like you don't have any clients
            </Font>

            <Font>Create your first client.</Font>

            <Button
              sx={{ mt: 3 }}
              startIcon={<Add />}
              onClick={() => {
                dispatch(setClientFormAddModalShow(true));
              }}
              variant='contained'
              color='primary'
            >
              Add Client
            </Button>
          </SvgContainer>
        ) : (
          <>
            <AddClientWrapper>
              <TextField
                sx={{ width: 250, backgroundColor: (theme) => theme.palette.grey[50] }}
                placeholder='Search...'
                variant='outlined'
                size='small'
                onChange={searchTextHandler}
              />

              {!!searchText && (
                <Button
                  color='inherit'
                  sx={{ ml: 1 }}
                  onClick={() => {
                    dispatch(setSearchText(''));
                  }}
                >
                  Clear filters
                </Button>
              )}
            </AddClientWrapper>

            <TableSection>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {selected.length ? (
                        <>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              checked
                              color='primary'
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              onChange={() => {
                                dispatch(setSelected([]));
                              }}
                            />
                          </TableCell>

                          <TableCell colSpan={3} sx={{ p: 'inherit' }}>
                            <Tooltip title='Delete'>
                              <IconButton
                                sx={{ ml: -1.5 }}
                                onClick={() => {
                                  dispatch(setClientsDelete(selected));
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              checked={false}
                              color='primary'
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              onChange={() => {
                                dispatch(setSelected(filteredClients));
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ p: 'inherit' }}>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCellMobileHidden>Phone</TableCellMobileHidden>
                        </>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredClients.map((client) => (
                      <TableRow
                        hover
                        key={client.id}
                        onClick={() => history.push(`/clients/${client.id}`)}
                        sx={{
                          '&:hover': {
                            ' & > td:last-child button': {
                              opacity: 1,
                              pointerEvents: 'all',
                            },
                          },
                        }}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox
                            color='primary'
                            checked={!!selected.find((select) => select.id === client.id)}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            onChange={(event) => {
                              const checked = event.target.checked;

                              if (checked) {
                                dispatch(setSelected([...selected, client]));
                              } else {
                                dispatch(
                                  setSelected(selected.filter((select) => select.id !== client.id)),
                                );
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ p: 'inherit' }}>{client.name}</TableCell>
                        <TableCell>{client.email}</TableCell>
                        <TableCellMobileHidden>
                          <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Box>{client.phone}</Box>

                            <Box>
                              <Tooltip title='Delete'>
                                <IconButton
                                  sx={{ opacity: 0, pointerEvents: 'none' }}
                                  onClick={(event) => {
                                    event.stopPropagation();

                                    dispatch(setClientsDelete([client]));
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Stack>
                        </TableCellMobileHidden>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableSection>
          </>
        )}

        <Modal
          open={!!clientFormAddModalShow}
          title={'Create Client'}
          loading={clientFormLoading}
          onClose={() => dispatch(setClientFormAddModalShow(false))}
          onSubmit={() => {
            dispatch(clientCreateRequest());
          }}
        >
          <ClientFormAdd />
        </Modal>

        <Modal
          open={!!clientsDelete.length}
          title={'Delete Client'}
          loading={clientFormLoading}
          onClose={() => dispatch(setClientsDelete([]))}
          submitText='Delete'
          danger
          onSubmit={() => {
            clientsDelete.forEach((client, index) =>
              dispatch(clientDeleteRequest(client, index + 1, clientsDelete.length)),
            );

            if (clientsDelete.length > 1) {
              dispatch(setSelected([]));
            }
          }}
        >
          <DeleteSection>
            <Font>
              {clientsDelete.length === 1 ? (
                <Font>
                  Are you sure you want to delete <strong>{`${clientsDelete[0]?.name}`}</strong>?
                </Font>
              ) : (
                <>
                  <Font style={{ marginBottom: 8 }}>
                    Are you sure you want to delete <strong>{clientsDelete.length}</strong> clients?
                  </Font>

                  <ul>
                    {clientsDelete.map((client) => (
                      <li key={client.id}>{client.name}</li>
                    ))}
                  </ul>
                </>
              )}
            </Font>
          </DeleteSection>
        </Modal>
      </Content>
    </Container>
  );
}

export default Clients;
