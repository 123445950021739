import React from 'react';

// dependencies
import { Popover as MuiPopover, IconButton, Theme } from '@mui/material';
import styled from 'styled-components';
import { Close } from '@mui/icons-material';

// components

const StyledPopover = styled(MuiPopover)`
  .MuiPaper-root {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.palette.common.white};
    border-radius: 4px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid
    ${({ theme }: { theme: Theme }) => theme.palette.grey[200]};
  padding: 0.5rem 1rem;
`;

const Title = styled.div`
  font-weight: 600;
`;

const Content = styled.div``;

interface AnchorOrigin {
  vertical: 'top' | 'center' | 'bottom';
  horizontal: 'center' | 'left' | 'right';
}

interface TransformOrigin {
  vertical: 'top' | 'center' | 'bottom';
  horizontal: 'center' | 'left' | 'right';
}

interface Props {
  title?: string;
  anchorEl: HTMLElement | null;
  onClose?: () => void;
  children?: React.ReactElement<any, any>;
  anchorOrigin?: AnchorOrigin;
  transformOrigin?: TransformOrigin;
  maxHeight?: number | 'auto';
}
export enum PopoverTheme {
  default = 'default',
  dark = 'dark',
}

const Popover = ({
  title,
  anchorEl,
  onClose,
  children,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  maxHeight = 'auto',
}: Props) => {
  return (
    <StyledPopover
      id='simple-popover'
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      sx={{
        maxHeight,
      }}
    >
      {title && (
        <TitleWrapper>
          <Title>{title}</Title>

          <IconButton onClick={onClose} size='large'>
            <Close />
          </IconButton>
        </TitleWrapper>
      )}

      <Content>{children}</Content>
    </StyledPopover>
  );
};

export default Popover;
