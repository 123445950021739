import { memo, useEffect, useRef } from 'react';

// dependencies
import styled from 'styled-components';
import * as d3 from 'd3';
import { createTooltip } from 'utils';

const Container = styled.div`
  flex: 1;
  position: relative;
`;

const defaultData = [
  {
    category: '0',
    value: 0,
  },
  {
    category: '0.1',
    value: 2,
  },
  {
    category: '0.2',
    value: 4,
  },
  {
    category: '0.4',
    value: 6,
  },
  {
    category: '0.6',
    value: 8,
  },
  {
    category: '0.8',
    value: 10,
  },
  {
    category: '1',
    value: 12,
  },
];

interface YAxis {
  yAxisShow?: boolean;
  yAxisMax?: number;
  yAxisTicks?: number;
  yAxisTickFormat?: (value: d3.NumberValue) => string;
}

interface XAxis {
  xAxisRotateText?: boolean;
  xAxisTwoTicks?: boolean;
  xAxisPadding?: number;
  xAxisMaxBandWidth?: number;
}

interface Props {
  data: Array<any>;
  type?: 'normal' | 'stack';
  stackCategories?: Array<string>;
  xField?: string;
  yField?: string;
  title?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
  xAxisOptions?: XAxis;
  yAxisOptions?: YAxis;
  legend?: 'none' | 'bottom';
  grid?: boolean;
  rectFillCallback?: (d: any) => string | number;
}

const Histogram = memo(
  ({
    data = [],
    xField = 'category',
    yField = 'value',
    xAxisOptions = {},
    yAxisOptions = {},
    grid = false,
  }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const dataIsEmpty = !data?.length;

    const {
      xAxisRotateText = true,
      xAxisTwoTicks = false,
      xAxisPadding = 0,
      xAxisMaxBandWidth = 10000,
    } = xAxisOptions;
    const {
      yAxisShow = true,
      yAxisMax = null,
      yAxisTicks = null,
      yAxisTickFormat = (value) => value.toString(),
    } = yAxisOptions;

    if (dataIsEmpty) data = defaultData;

    useEffect(() => {
      draw(data);
    });

    const draw = (data: Array<any>) => {
      // Remove the existing svg
      d3.select(ref.current).selectAll('svg').remove();
      // Remove the existing tooltip
      d3.select(ref.current).selectAll('.tooltip').remove();

      const getBoundingClientRectZero = {
        width: 0,
        height: 0,
      };

      const svg = d3.select(ref.current).append('svg').attr('width', '100%').attr('height', '100%');

      // Get width and height from svg
      const svgDimensions = svg.node()?.getBoundingClientRect();

      // Main
      const main = svg.append('g');

      // Calculate width and height
      const height = svgDimensions ? svgDimensions.height : 100;
      const width = svgDimensions ? svgDimensions.width : 200;

      // generate dummy y axis to initialize x axis width
      const yAxisDummy = main
        .append('g')
        .style('transform', `translate(${0}, 0)`)
        .call(
          d3
            .axisLeft(
              d3
                .scaleLinear()
                .domain([0, yAxisMax ? yAxisMax : d3.max(data.map((el) => el[yField]))])
                .range([height, 0]),
            )
            .ticks(yAxisTicks)
            .tickFormat(yAxisTickFormat),
        );
      const yAxisDimensionsDummy = yAxisDummy.node()?.getBoundingClientRect();

      const yAxisDummyWidth = yAxisDimensionsDummy ? yAxisDimensionsDummy.width : 200;

      // xScale axis
      const xScale = d3
        .scaleBand()
        .range([0, width - yAxisDummyWidth])
        .domain(
          data.map((d): string => {
            return d[xField];
          }),
        )
        .padding(xAxisPadding);

      // Draw x axis
      const xAxis = main.append('g').call(
        d3
          .axisBottom(xScale)
          .tickValues(
            xAxisTwoTicks
              ? [xScale.domain()[0], xScale.domain()[xScale.domain().length - 1]]
              : xScale.domain(),
          )
          .tickSizeOuter(0),
      );

      if (xAxisRotateText) {
        xAxis
          .selectAll('text')
          .style('text-anchor', 'end')
          .attr('transform', 'translate(0) rotate(-45)');
      }

      // Get X axis dimensions
      const xAxisNode = xAxis.node();
      const xAxisDimensions = xAxisNode
        ? xAxisNode.getBoundingClientRect()
        : getBoundingClientRectZero;

      // center X axis
      xAxis.attr('transform', `translate(${yAxisDummyWidth},${height - xAxisDimensions.height})`);

      // yScale axis
      const yScale = d3
        .scaleLinear()
        .domain([0, yAxisMax ? yAxisMax : d3.max(data.map((el) => el[yField]))])
        .range([height - xAxisDimensions.height - 4, 0]);

      const yAxis = main.append('g');

      if (yAxisShow) yAxis.call(d3.axisLeft(yScale).ticks(yAxisTicks).tickFormat(yAxisTickFormat));

      yAxis.selectAll('path').attr('stroke', 'transparent');

      // Get X axis dimensions
      const yAxisNode = yAxis.node();
      const yAxisDimensions = yAxisNode
        ? yAxisNode.getBoundingClientRect()
        : getBoundingClientRectZero;

      yAxis.attr('transform', `translate(${yAxisDimensions.width},4)`);

      // Remove dummy Y axis
      yAxisDummy.remove();

      // Draw grid
      if (grid) {
        const grid = main
          .append('g')
          .attr('class', 'grid')
          .call(
            d3
              .axisLeft(yScale)
              .tickSize(-width + yAxisDimensions.width)
              .tickFormat(() => ''),
          )
          .style('transform', `translate(${yAxisDimensions.width}px,4px)`);

        grid.selectAll('path').style('stroke-width', 0);

        grid.selectAll('line').style('stroke', '#e0e0e0').style('shape-rendering', 'crispEdges');

        grid.select('line').style('stroke', 'transparent');
      }

      const tooltip = createTooltip({
        selector: ref,
      });

      const rect = main
        .selectAll('myRect')
        .data(data)
        .enter()
        .append('rect')
        .on('mouseover', function (event: MouseEvent, d) {
          d3.select(this).style('opacity', 0.5);

          tooltip
            .html(
              `
                <div style='font-size:12px'>
                  <div>Staff: <strong>${d[xField]}</strong></div>
                  <div>Appointments: <strong>${d[yField]}</strong></div>
                </div>
              `,
            )
            .style('left', event.offsetX + 8 + 'px')
            .style('top', event.offsetY + 15 + 'px')
            .style('opacity', 1);
        })
        .on('mousemove', function (event: MouseEvent, d) {
          tooltip
            .html(
              `
                <div style='font-size:12px'>
                  <div>Staff: <strong>${d[xField]}</strong></div>
                  <div>Appointments: <strong>${d[yField]}</strong></div>
                </div>
              `,
            )
            .style('left', event.offsetX + 8 + 'px')
            .style('top', event.offsetY + 15 + 'px')
            .style('opacity', 1);
        })
        .on('mouseout', function () {
          d3.select(this).style('opacity', 1);

          tooltip.style('opacity', 0);
        })
        .attr('x', (d: any) => {
          const width = Math.min(xScale.bandwidth(), xAxisMaxBandWidth);
          const x = xScale(d[xField]) ?? 0;

          return x + xScale.bandwidth() / 2 - width / 2;
        })
        .attr('width', (d) => Math.min(xScale.bandwidth(), xAxisMaxBandWidth))
        .attr('y', (d: any) => yScale(d.value))
        .attr('height', (d: any) => height - yScale(d[yField]) - xAxisDimensions.height)
        .attr('fill', dataIsEmpty ? 'transparent' : '#1976D2');

      rect.style('transform', `translate(${yAxisDimensions.width}px,0px)`);
    };

    return <Container ref={ref} />;
  },
);

export default Histogram;
