// dependencies
import styled from 'styled-components';
import { useAuthEffect } from 'hooks';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { dailyBookingsRequest } from '.';

// components
import DailyBookings from './components/DailyBookings';
import Activity from './components/Activity';
import StaffAppointments from './components/StaffAppointments';
import StatisticWidgets from './components/StatisticWidgets';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 0 1rem 1rem 0;
`;

const FirstSection = styled.div`
  flex: 1 1 400px;
  display: flex;
  flex-direction: column;
`;

const SecondSection = styled.div`
  flex: 1 1 400px;
  display: flex;
  flex-direction: column;
`;

function DashboardComposition() {
  const { dailyBookingsAreFetched } = useSelector((state: RootState) => state.dashboard);
  const dispatch = useDispatch();

  useAuthEffect(() => {
    if (!dailyBookingsAreFetched) dispatch(dailyBookingsRequest());
  }, [dispatch]);

  return (
    <Container>
      <FirstSection>
        <StatisticWidgets />
        <DailyBookings />
      </FirstSection>

      <SecondSection>
        <StaffAppointments />
        <Activity />
      </SecondSection>
    </Container>
  );
}

export default DashboardComposition;
