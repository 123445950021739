import { useEffect } from 'react';
import { Avatar, Box, Button, Skeleton, Stack } from '@mui/material';
import { Font } from 'elements';
import styled from 'styled-components';
import { useDocumentTitle } from 'hooks';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { setStep, staffRequest, updateBookingForm, setStepsLabelOptional } from '..';

const Container = styled.div``;

const Providers = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  margin-top: 26px;
`;

const Provider = styled.div`
  display: flex;
  align-items: center;
`;

function ProviderForm() {
  const { company, isLoading, staff, staffAreFetched } = useSelector(
    (state: RootState) => state.bookingPage,
  );
  const dispatch = useDispatch();

  useDocumentTitle('Select a Provider - Gotei');

  useEffect(() => {
    dispatch(
      setStepsLabelOptional({
        key: 'Provider',
        value: '',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (company.uuid) dispatch(staffRequest());
  }, [dispatch, company]);

  return (
    <Container>
      <Font type='h2'>Providers</Font>

      <Providers>
        {isLoading && !staffAreFetched ? (
          <Stack spacing={2}>
            <Box flex={1} display={'flex'} alignItems={'center'}>
              <Skeleton variant='circular' width={40} height={40} />
              <Skeleton variant='text' width={70} sx={{ ml: 2 }} />
            </Box>

            <Box flex={1} display={'flex'} alignItems={'center'}>
              <Skeleton variant='circular' width={40} height={40} />
              <Skeleton variant='text' width={70} sx={{ ml: 2 }} />
            </Box>

            <Box flex={1} display={'flex'} alignItems={'center'}>
              <Skeleton variant='circular' width={40} height={40} />
              <Skeleton variant='text' width={70} sx={{ ml: 2 }} />
            </Box>
          </Stack>
        ) : (
          staff.map((staffDetail) => (
            <Button
              key={staffDetail.id}
              fullWidth
              color='inherit'
              sx={{ justifyContent: 'flex-start', ml: -1, minWidth: 250 }}
              onClick={() => {
                dispatch(setStep(2));

                dispatch(
                  setStepsLabelOptional({
                    key: 'Provider',
                    value: staffDetail.name,
                  }),
                );

                dispatch(
                  updateBookingForm({
                    key: 'staff',
                    value: staffDetail.id,
                  }),
                );
              }}
            >
              <Provider>
                <Avatar
                  src={staffDetail.image}
                  sx={{
                    mr: 2,
                  }}
                />

                <Font>{staffDetail.name}</Font>
              </Provider>
            </Button>
          ))
        )}
      </Providers>
    </Container>
  );
}

export default ProviderForm;
