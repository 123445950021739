import { useEffect, useState } from 'react';

// dependencies
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

import privacy from './privacy.md';
import { Alert, AlertTitle, CircularProgress, Stack } from '@mui/material';

export const Privacy = () => {
  const [privacyMarkdown, setPrivacyMarkdown] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);

    axios
      .get(privacy)
      .then((res) => {
        setPrivacyMarkdown(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  return (
    <div>
      {loading && (
        <Stack direction='row' justifyContent='center'>
          <CircularProgress size={28} />
        </Stack>
      )}

      {!loading && error && (
        <Alert severity='error'>
          <AlertTitle>Unable to fetch Privacy Policy</AlertTitle>
          Something went wrong try again later. If this problem continues contact us at{' '}
          <strong>support@gotei.app</strong>
        </Alert>
      )}

      {!loading && !error && <ReactMarkdown>{privacyMarkdown}</ReactMarkdown>}
    </div>
  );
};

export default Privacy;
