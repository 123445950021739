import { useState } from 'react';

import styled from 'styled-components';
import {
  AddOutlined,
  Close,
  Event,
  People,
  PersonOutlined,
  RoomServiceOutlined,
} from '@mui/icons-material';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Stack,
  Tooltip,
  Box,
  IconButton,
  Alert,
} from '@mui/material';
import { Font, Modal, TimePicker } from 'elements';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { BookingFormKeys } from 'model';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { clearEventFormCreate, updateEventFormCreate } from 'store/ui';
import { setClientFormAddModalShow, clientCreateRequest } from 'screens/clients';

// components
import ClientFormAdd from 'screens/clients/components/ClientFormAdd';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

function EventFormCreate() {
  const { businessHours } = useSelector((state: RootState) => state.organization);
  const { eventFormCreate, eventFormError } = useSelector((state: RootState) => state.ui);
  const { clients, staff, services } = useSelector((state: RootState) => state.organization);
  const { clientFormAddModalShow, clientFormLoading } = useSelector(
    (state: RootState) => state.clients,
  );
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedService = services.find((service) => service.id === +eventFormCreate.service);
  const weekday = moment(eventFormCreate.start_time).weekday();
  const businessHour = businessHours.find((hour) => hour.weekday === weekday);
  const staffAssignedToService = staff.filter((staffDetail) =>
    selectedService?.staff.some((el) => el.id === staffDetail.id),
  );
  const staffBookingFormCreate = staff.find(
    (staffDetail) => staffDetail.id === +eventFormCreate.staff,
  );
  const clientBookingFormCreate = clients.find((client) => client.id === +eventFormCreate.client);
  const disabledProviderSelect = !eventFormCreate.service;
  const disabledClientSelect = !eventFormCreate.service;

  return (
    <Form>
      <Stack direction='row' justifyContent='flex-end' m={'-1rem -1rem 0.5rem 0'}>
        <Box>
          <Tooltip title='Close'>
            <IconButton
              onClick={() => {
                dispatch(clearEventFormCreate());
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>

      <Stack direction='row' alignItems='center'>
        <Box
          sx={{
            width: 20,
            height: 20,
            backgroundColor: '#eaeffe',
            borderRadius: 1,
            m: '3px',
          }}
        ></Box>

        <Stack ml={3}>
          <Font type='h3'>
            {staffBookingFormCreate?.name}{' '}
            {clientBookingFormCreate && `and ${clientBookingFormCreate?.name}`}
          </Font>
          <Font>{moment(eventFormCreate.start_time).format('dddd, DD MMMM HH:mm')}</Font>
        </Stack>
      </Stack>

      {eventFormError.non_field_errors && (
        <Alert sx={{ mt: 3 }} severity='error'>
          {eventFormError.non_field_errors}
        </Alert>
      )}

      <Stack direction='row' alignItems='center' mt={3} mb={1.5}>
        <Box>
          <Tooltip title='Service'>
            <RoomServiceOutlined />
          </Tooltip>
        </Box>

        <Stack ml={3} flex={1}>
          <FormControl variant='outlined' fullWidth size='small' error={!!eventFormError.service}>
            <InputLabel htmlFor='outlined-age-native-simple'>Service</InputLabel>

            <Select
              native
              value={eventFormCreate.service}
              onChange={(event: any) => {
                dispatch(
                  updateEventFormCreate({
                    key: BookingFormKeys.service,
                    value: event.currentTarget.value,
                  }),
                );
              }}
              label='Service'
            >
              <option value='' />

              {services.map((service, index) => (
                <option value={service.id} key={index}>
                  {service.name}
                </option>
              ))}
            </Select>

            <FormHelperText>{eventFormError.service}</FormHelperText>
          </FormControl>
        </Stack>
      </Stack>

      <Stack direction='row' alignItems='center' mb={1.5}>
        <Box>
          <Tooltip title='Provider'>
            <PersonOutlined />
          </Tooltip>
        </Box>

        <Stack ml={3} flex={1}>
          <FormControl variant='outlined' fullWidth size='small' error={!!eventFormError.staff}>
            <InputLabel htmlFor='outlined-age-native-simple' disabled={disabledProviderSelect}>
              Provider
            </InputLabel>

            <Select
              native
              value={eventFormCreate.staff}
              disabled={disabledProviderSelect}
              onChange={(event: any) => {
                dispatch(
                  updateEventFormCreate({
                    key: BookingFormKeys.staff,
                    value: event.currentTarget.value,
                  }),
                );
              }}
              label='Provider'
            >
              <option value='' />

              {staffAssignedToService.map((employee, index) => (
                <option value={employee.id} key={index}>
                  {employee.name}
                </option>
              ))}
            </Select>

            <FormHelperText>{eventFormError.staff}</FormHelperText>
          </FormControl>
        </Stack>
      </Stack>

      <Stack direction='row' alignItems='center' mb={1.5}>
        <Box>
          <Tooltip title='Client'>
            <People />
          </Tooltip>
        </Box>

        <Stack direction='row' flex={1} ml={3}>
          <FormControl
            variant='outlined'
            fullWidth
            size='small'
            error={!!eventFormError.client}
            sx={{ mr: 1.5 }}
          >
            <InputLabel htmlFor='outlined-age-native-simple' disabled={disabledClientSelect}>
              Client
            </InputLabel>

            <Select
              native
              value={eventFormCreate.client}
              disabled={disabledClientSelect}
              onChange={(event: any) => {
                dispatch(
                  updateEventFormCreate({
                    key: BookingFormKeys.client,
                    value: event.currentTarget.value,
                  }),
                );
              }}
              label='Client'
            >
              <option value='' />

              {clients.map((client, index) => (
                <option value={client.id} key={index}>
                  {`${client.name}`}
                </option>
              ))}
            </Select>

            <FormHelperText>{eventFormError.client}</FormHelperText>
          </FormControl>

          <Button
            color='primary'
            variant='outlined'
            startIcon={<AddOutlined />}
            sx={{ height: 40, borderRadius: '4px !important' }}
            onClick={() => {
              dispatch(setClientFormAddModalShow(true));
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>

      <Stack direction='row' alignItems='center' mb={1.5}>
        <Box>
          <Tooltip title='Date'>
            <Event />
          </Tooltip>
        </Box>

        <Stack>
          <Stack direction='row' flex={1} ml={3}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DatePicker
                open={isOpen}
                label='Date'
                value={moment(eventFormCreate.start_time)}
                disabled={!eventFormCreate.service}
                onChange={(value) => {
                  if (value) {
                    const dateTime = moment(eventFormCreate.start_time);
                    const date = value.format('YYYY/MM/DD');
                    const time = dateTime.format('HH:mm');

                    dispatch(
                      updateEventFormCreate({
                        key: BookingFormKeys.start_time,
                        value: moment(date + ' ' + time).format(),
                      }),
                    );
                  }
                }}
                onClose={() => {
                  setIsOpen(false);
                }}
                OpenPickerButtonProps={{
                  sx: { display: 'none' },
                }}
                renderInput={(params) => (
                  <TextField
                    size='small'
                    sx={{
                      mr: 1.5,
                    }}
                    {...params}
                    autoComplete='off'
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            <TimePicker
              style={{
                width: 120,
              }}
              disabled={!eventFormCreate.service}
              steps={selectedService?.duration ? +selectedService?.duration : undefined}
              businessHour={businessHour}
              value={moment(eventFormCreate.start_time)}
              onChange={(value) => {
                const dateTime = moment(eventFormCreate.start_time);
                const date = dateTime.format('YYYY/MM/DD');
                const time = value.format('HH:mm');

                dispatch(
                  updateEventFormCreate({
                    key: BookingFormKeys.start_time,
                    value: moment(date + ' ' + time).format(),
                  }),
                );
              }}
            />
          </Stack>

          <FormHelperText sx={{ ml: 3, p: '4px 14px 0 14px' }}>
            {eventFormError.start_time}
          </FormHelperText>
        </Stack>
      </Stack>

      <Modal
        open={!!clientFormAddModalShow}
        title={'Add Client'}
        loading={clientFormLoading}
        onClose={() => dispatch(setClientFormAddModalShow(false))}
        onSubmit={() => {
          dispatch(clientCreateRequest());
        }}
      >
        <ClientFormAdd />
      </Modal>
    </Form>
  );
}
export default EventFormCreate;
