import { call, fork, put, takeLatest, select } from 'redux-saga/effects';
import { logger } from 'utils';
import {
  AuthActionTypes,
  authTokenRefreshRequest,
  setIsAuthenticated,
  setIsAuthenticatedVerified,
  setLogoutLoading,
  setUser,
  selectUser,
  setUserPermission,
  setUserPermissionFetched,
  clearUserPermission,
  setUserPermissionError,
  setLogoutFailed,
  userPermissionRequest,
} from '.';

import { userModuleRpc, staffModuleRpc } from 'services/api';
import { RootState } from 'store/config';
import { Permission, User } from 'model';
import { avatarGenerator } from 'utils';
import { wsNotificationDisconnect } from 'store/ui';

function* authTokenVerify() {
  try {
    logger('Auth Token Verify Request');

    const response: User = yield call(userModuleRpc.tokenVerify.bind(userModuleRpc));

    logger('Auth Token Verify Success');

    yield put(setUser(response));

    if (!response.image) {
      const user = { ...response };
      const match = response.name.match(/\b(\w)/g)?.slice(0, 2);
      const letters = match?.join('').toUpperCase();
      const imageURI = avatarGenerator(letters);

      user.image = imageURI ?? null;

      const response2: User = yield call(
        [userModuleRpc, userModuleRpc.updateUserPartial],
        response,
        user,
      );

      const imageUrl = response2.image?.split('localhost:8000')[1] ?? null;

      yield put(setUser({ ...response2, image: imageUrl }));
    }

    yield put(setIsAuthenticated(true));
    yield put(setIsAuthenticatedVerified(true));
  } catch (error) {
    logger.error('Auth Token Verify Failure');

    yield put(authTokenRefreshRequest());
  }
}

function* authTokenRefresh() {
  logger('Auth Token Refresh Request');

  try {
    const response: User = yield call(userModuleRpc.tokenRefresh.bind(userModuleRpc));

    logger('Auth Token Refresh Success');

    yield put(setUser(response));
    yield put(setIsAuthenticated(true));
    yield put(setIsAuthenticatedVerified(true));
    yield put(userPermissionRequest());
  } catch (error) {
    logger.error('Auth Token Refresh Failure');

    yield call(authLogout);
  }
}

function* authLogout() {
  logger('Auth Logout Request');

  yield put(setLogoutLoading(true));

  try {
    yield call(userModuleRpc.logout.bind(userModuleRpc));

    logger('Auth Logout Success');

    yield put(setLogoutLoading(false));
    yield put(setIsAuthenticated(false));
    yield put(setIsAuthenticatedVerified(true));
    yield put(clearUserPermission());
    yield put(setUserPermissionFetched(false));
    yield put(wsNotificationDisconnect());
  } catch (error: any) {
    logger.error('Auth Logout Failure');

    yield put(setLogoutFailed(true));
    yield put(setLogoutLoading(false));
  }
}

function* getUserPermission() {
  const state: RootState = yield select();
  const user = selectUser(state);

  if (!user.id) {
    return;
  }

  try {
    logger('Auth User Permission Request');

    const response: Permission = yield call(
      staffModuleRpc.getStaffUserPermissions.bind(staffModuleRpc, user.id),
    );
    logger('Auth User Permission Success');

    yield put(setUserPermission(response));
    yield put(setUserPermissionFetched(true));
  } catch (error: any) {
    logger.error('Auth User Permission Failure');

    yield put(setUserPermissionError('Something went wrong with account'));
  }
}

export function* authTokenVerifyRequestWatcher() {
  yield takeLatest(AuthActionTypes.AUTH_TOKEN_VERIFY_REQUEST, authTokenVerify);
}

export function* authTokenRefreshRequestWatcher() {
  yield takeLatest(AuthActionTypes.AUTH_TOKEN_REFRESH_REQUEST, authTokenRefresh);
}

export function* authLogoutRequestWatcher() {
  yield takeLatest(AuthActionTypes.AUTH_LOGOUT_REQUEST, authLogout);
}

export function* userPermissionWatcher() {
  yield takeLatest(AuthActionTypes.USER_PERMISSION_REQUEST, getUserPermission);
}

export const authSagas = [
  fork(authTokenVerifyRequestWatcher),
  fork(authTokenRefreshRequestWatcher),
  fork(authLogoutRequestWatcher),
  fork(userPermissionWatcher),
];
