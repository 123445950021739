import { CSSProperties, useEffect, useState } from 'react';

import { Button, colors, List, ListItem, ButtonProps } from '@mui/material';
import { Font, Popover } from 'elements';
import { timeArrayGenerator } from 'utils';
import moment, { Moment } from 'moment';
import { BusinessHour } from 'model';

interface Props extends Omit<ButtonProps, 'value' | 'onChange' | 'slot'> {
  steps?: number;
  value?: Moment;
  error?: boolean;
  style?: CSSProperties;
  businessHour?: BusinessHour;
  includeLastStep?: boolean;
  onChange?: (value: Moment) => void;
}

function TimePicker({
  steps = 10,
  value = moment(),
  onChange,
  style = {},
  error = false,
  businessHour,
  includeLastStep = false,
  ...rest
}: Props) {
  const [popoverEl, setPopoverEl] = useState<HTMLButtonElement | null>(null);
  const [timeArr, setTimeArr] = useState<Array<string>>([]);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(24);

  useEffect(() => {
    setTimeArr(timeArrayGenerator(steps, startTime * 60, endTime * 60));
  }, [steps, startTime, endTime]);

  useEffect(() => {
    if (businessHour) {
      const startTimeHour = moment(businessHour?.start_time, 'HH:mm').hours();
      const startTimeMinutes = moment(businessHour?.start_time, 'HH:mm').minutes();
      const endTimeHour = moment(businessHour?.end_time, 'HH:mm').hours();
      const endTimeMinutes = moment(businessHour?.end_time, 'HH:mm').minutes();

      setStartTime(startTimeHour + startTimeMinutes / 60);
      setEndTime(endTimeHour + (endTimeMinutes + (includeLastStep ? steps : 0)) / 60);
    }
  }, [businessHour, includeLastStep, steps]);

  return (
    <>
      <Button
        {...rest}
        variant='outlined'
        sx={{
          borderColor: error ? colors.red[700] : colors.grey[400],
        }}
        style={{
          ...style,
        }}
        color={error ? 'error' : 'inherit'}
        onClick={(event) => setPopoverEl(event.currentTarget)}
      >
        {value.format('HH:mm')}
      </Button>

      <Popover anchorEl={popoverEl} maxHeight={350} onClose={() => setPopoverEl(null)}>
        <List>
          {timeArr.map((time, index) => {
            return (
              <ListItem
                button
                key={index}
                selected={time === value.format('HH:mm')}
                onClick={() => {
                  if (onChange) {
                    onChange(moment(time, 'HH:mm'));
                  }

                  setPopoverEl(null);
                }}
              >
                <Font>{moment(time, 'HH:mm').format('HH:mm')}</Font>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
}

export default TimePicker;
