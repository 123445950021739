import { useEffect } from 'react';

import { Avatar, Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { Font } from 'elements';
import styled from 'styled-components';
import { useDocumentTitle } from 'hooks';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { setStep, servicesRequest, updateBookingForm, setStepsLabelOptional } from '..';

import image from 'assets/images/calendar-cancel.png';
import placeholder from 'assets/images/placeholder.png';

const Container = styled.div`
  flex: 1;
`;

const Services = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
`;

const Service = styled.div`
  display: flex;
  align-items: center;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 2rem 0;
`;

const Img = styled.img``;

interface Props {
  companyUrl: string;
}

function ServiceForm({ companyUrl }: Props) {
  const { company, services, isLoading, servicesAreFetched } = useSelector(
    (state: RootState) => state.bookingPage,
  );
  const dispatch = useDispatch();

  useDocumentTitle('Select a Service - Gotei');

  useEffect(() => {
    dispatch(
      setStepsLabelOptional({
        key: 'Service',
        value: '',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (company.url_component === companyUrl && !servicesAreFetched) {
      dispatch(servicesRequest());
    }
  }, [dispatch, company, companyUrl, servicesAreFetched]);

  return (
    <Container>
      <Font type='h2'>Services</Font>

      <Services>
        {isLoading ? (
          <Stack spacing={2}>
            <Box flex={1} display={'flex'} alignItems={'center'}>
              <Skeleton variant='circular' width={40} height={40} />
              <Skeleton variant='text' width={70} sx={{ ml: 2 }} />
            </Box>

            <Box flex={1} display={'flex'} alignItems={'center'}>
              <Skeleton variant='circular' width={40} height={40} />
              <Skeleton variant='text' width={70} sx={{ ml: 2 }} />
            </Box>

            <Box flex={1} display={'flex'} alignItems={'center'}>
              <Skeleton variant='circular' width={40} height={40} />
              <Skeleton variant='text' width={70} sx={{ ml: 2 }} />
            </Box>
          </Stack>
        ) : servicesAreFetched && !services.length ? (
          <ImageContainer>
            <Img src={image} />

            <Font style={{ marginTop: '2rem' }} type='h4'>
              No services with open slots right now
            </Font>

            <Font>Services with bookable slots will appear when available.</Font>
          </ImageContainer>
        ) : (
          services.map((service) => (
            <Button
              fullWidth
              color='inherit'
              key={service.id}
              sx={{ justifyContent: 'flex-start', ml: -1, minWidth: 250 }}
              onClick={() => {
                dispatch(setStep(1));

                dispatch(
                  setStepsLabelOptional({
                    key: 'Service',
                    value: service.name,
                  }),
                );

                dispatch(
                  updateBookingForm({
                    key: 'service',
                    value: service.id,
                  }),
                );
              }}
            >
              <Service>
                <Avatar src={service.image} sx={{ mr: 2 }}>
                  <Avatar src={placeholder} sx={{ width: 55, height: 55 }} />
                </Avatar>

                <Stack textAlign='left' direction='column'>
                  <Typography textAlign='left'>{service.name}</Typography>
                  <Stack direction='row'>
                    <Typography variant='caption'>{service.duration} mins</Typography>
                  </Stack>
                </Stack>
              </Service>
            </Button>
          ))
        )}
      </Services>
    </Container>
  );
}

export default ServiceForm;
