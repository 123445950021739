import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookingType, NotificationType } from 'model';
import { RootState } from 'store/config';

interface State {
  dailyBookings: BookingType[];
  dailyBookingsLoading: boolean;
  dailyBookingsAreFetched: boolean;
  dailyBookingsSelected: Array<BookingType>;
  lastDailyBookings: BookingType[];
  weeklyAppointments: BookingType[];
  lastWeeklyAppointments: BookingType[];
  activity: Array<NotificationType>;
  activityLoading: boolean;
  activityIsFetched: boolean;
}

const initialState: State = {
  dailyBookings: [],
  dailyBookingsLoading: false,
  dailyBookingsAreFetched: false,
  dailyBookingsSelected: [],
  lastDailyBookings: [],
  weeklyAppointments: [],
  lastWeeklyAppointments: [],
  activity: [],
  activityLoading: false,
  activityIsFetched: false,
};

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDailyBookings: (state, action: PayloadAction<Array<BookingType>>) => {
      return {
        ...state,
        dailyBookings: action.payload,
      };
    },
    setDailyBookingsLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        dailyBookingsLoading: action.payload,
      };
    },
    setDailyBookingsAreFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        dailyBookingsAreFetched: action.payload,
      };
    },
    setDailyBookingsSelected: (state, action: PayloadAction<Array<BookingType>>) => {
      return {
        ...state,
        dailyBookingsSelected: action.payload,
      };
    },
    setLastDailyBookings: (state, action: PayloadAction<Array<BookingType>>) => {
      return {
        ...state,
        lastDailyBookings: action.payload,
      };
    },
    setWeeklyAppointments: (state, action: PayloadAction<BookingType[]>) => {
      return {
        ...state,
        weeklyAppointments: action.payload,
      };
    },
    setLastWeeklyAppointments: (state, action: PayloadAction<BookingType[]>) => {
      return {
        ...state,
        lastWeeklyAppointments: action.payload,
      };
    },
    setActivity: (state, action: PayloadAction<Array<NotificationType>>) => {
      return {
        ...state,
        activity: action.payload,
      };
    },
    setActivityLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        activityLoading: action.payload,
      };
    },
    setActivityIsFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        activityIsFetched: action.payload,
      };
    },
  },
});

export const {
  setDailyBookings,
  setDailyBookingsLoading,
  setDailyBookingsAreFetched,
  setDailyBookingsSelected,
  setLastDailyBookings,
  setWeeklyAppointments,
  setLastWeeklyAppointments,
  setActivity,
  setActivityLoading,
  setActivityIsFetched,
} = DashboardSlice.actions;

export enum DashboardActionTypes {
  DAILY_BOOKINGS_REQUEST = 'dashboard/DAILY_BOOKINGS_REQUEST',
  ACTIVITY_REQUEST = 'dashboard/ACTIVITY_REQUEST',
}

export const dailyBookingsRequest = () => {
  return {
    type: DashboardActionTypes.DAILY_BOOKINGS_REQUEST,
  };
};

export const activityRequest = () => {
  return {
    type: DashboardActionTypes.ACTIVITY_REQUEST,
  };
};

export const selectDashboard = (state: RootState) => {
  return state.dashboard;
};

export const selectDailyBookings = createSelector(
  [selectDashboard],
  (value) => value.dailyBookings,
);

export const selectDailyBookingAreFetched = createSelector(
  [selectDashboard],
  (value) => value.dailyBookingsAreFetched,
);

export const dashboardReducer = DashboardSlice.reducer;
