import { Font, Popper } from 'elements';
import styled from 'styled-components';

// components
import Company from './components/Company';
import { Box, Button, Divider, IconButton, Stack } from '@mui/material';
import { Check, Edit, MoreVertTwoTone } from '@mui/icons-material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

// redux
import { RootState } from 'store/config';
import { useDispatch, useSelector } from 'react-redux';
import { clearCompanyFormEdit, companyUpdateRequest, setCompanyFormEDit } from '.';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem;
  }
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

const Content = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const CompanyComposition = () => {
  const { company } = useSelector((state: RootState) => state.organization);
  const { companyFormEdit } = useSelector((state: RootState) => state.company);
  const [popperEl, setPopperEl] = useState<any>(null);
  const dispatch = useDispatch();

  return (
    <Container>
      <PageHeader>
        <Stack>
          <Font type='h1'>Company </Font>

          <Font>
            Company's configuration page, you can edit your company information, set up your
            business hours and change your logo.
          </Font>
        </Stack>

        <Box
          display={{
            xs: 'flex',
            sm: 'none',
          }}
          justifyContent='flex-end'
        >
          {companyFormEdit.uuid ? (
            <>
              <Button
                color='inherit'
                sx={{
                  mr: 1,
                }}
                onClick={() => {
                  dispatch(clearCompanyFormEdit());
                }}
              >
                Cancel
              </Button>

              <LoadingButton
                startIcon={<Check />}
                variant='contained'
                color='primary'
                onClick={() => {
                  dispatch(companyUpdateRequest());
                }}
              >
                Save
              </LoadingButton>
            </>
          ) : (
            <IconButton
              onClick={(event) => {
                setPopperEl(event.currentTarget);
              }}
            >
              <MoreVertTwoTone />
            </IconButton>
          )}

          <Popper open={!!popperEl} anchorEl={popperEl} onClose={() => setPopperEl(null)}>
            <Stack p={1}>
              <Box>
                <Button
                  fullWidth
                  color='inherit'
                  startIcon={<Edit />}
                  size='large'
                  onClick={() => {
                    dispatch(
                      setCompanyFormEDit({
                        ...company,
                      }),
                    );

                    setPopperEl(null);
                  }}
                >
                  Edit
                </Button>
              </Box>
            </Stack>
          </Popper>
        </Box>
      </PageHeader>

      <Divider />

      <Content>
        <Company />
      </Content>
    </Container>
  );
};

export default CompanyComposition;
