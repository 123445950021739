import { FieldsErrors } from 'model';

export const getError = (data: any) => {
  const keys: any = Object.keys(data);

  if (typeof data === 'object' && keys[0]) {
    return `${data[keys[0]]}`;
  } else if (typeof data === 'string') {
    return data;
  } else {
    return 'Something went wrong';
  }
};

interface ErrorObj {
  [key: string]: any;
}

export const getErrorEntry = (errObj: ErrorObj) => {
  const entry = Object.entries(errObj)[0];

  if (!entry) {
    throw new Error('Get error entry invalid argument.');
  }

  const [key, value]: [key: any, value: any] = entry;

  return {
    key,
    value,
  };
};

export const getFieldsError = (data: any): FieldsErrors | undefined => {
  const keys: any = Object.keys(data);
  const key = keys[0];
  let value: string = '';

  if (Array.isArray(data[keys[0]])) {
    value = data[keys[0]][0];
  }

  if (typeof data[keys[0]] === 'object') {
    const valueKey = Object.keys(data[keys[0]])[0];
    value = data[keys[0]][valueKey];
  }

  if (keys[0]) {
    return {
      key,
      value,
    };
  }
};

export const getErrorFields = (error: any): FieldsErrors => {
  /**
   * @ERROR_FIELDS
   * This is the most updated function in order to get all
   * the error fields. Try to use this one as much as possible
   */
  if (Array.isArray(error)) {
    return Object.entries(error)
      .map(([key, value]) => ({ [key]: String(value) }))
      .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
  } else if (typeof error === 'object') {
    return Object.entries(error)
      .map(([key, value]) => ({ [key]: String(value) }))
      .reduce((objA, objB) => ({ ...objA, ...objB }), {});
  } else if (typeof error === 'string') {
    return { error: error.slice(0, 250) };
  } else {
    return { error: 'Something went wrong!' };
  }
};
