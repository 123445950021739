import { useEffect, useState } from 'react';

import { Routes } from 'routes';
import MuiThemeProvider from './mui/MuiThemeProvider';

import { Button, IconButton, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import Slide, { SlideProps } from '@mui/material/Slide';

// redux
import { closeSnackbarRequest, undoSnackbarRequest } from 'store/ui';
import { Close } from '@mui/icons-material';

type TransitionProps = Omit<SlideProps, 'direction'>;

const App = () => {
  const { snackbar, snackbarSuccessAction } = useSelector((state: RootState) => state.ui);
  const [transition, setTransition] = useState<React.ComponentType<TransitionProps> | undefined>(
    undefined,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setTransition(() => TransitionLeft);
  }, []);

  const TransitionLeft = (props: SlideProps) => {
    return <Slide {...props} direction='right' />;
  };

  const action = (
    <>
      {snackbarSuccessAction.type && (
        <Button
          color='inherit'
          size='small'
          onClick={() => {
            dispatch(undoSnackbarRequest());
          }}
        >
          Undo
        </Button>
      )}

      <IconButton
        size='small'
        color='inherit'
        onClick={() => {
          dispatch(closeSnackbarRequest());
        }}
      >
        <Close fontSize='small' />
      </IconButton>
    </>
  );

  return (
    <MuiThemeProvider>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        TransitionComponent={transition}
        key={transition?.name}
        action={action}
        sx={{
          '.MuiPaper-root': {
            minWidth: 0,
          },
        }}
      />

      <Routes />
    </MuiThemeProvider>
  );
};

export default App;
