import { CheckLoginSuccessFlow } from 'hoc';
import { useDocumentTitle } from 'hooks';

// components
import LoginComposition from './LoginComposition';

const LoginScreen = () => {
  useDocumentTitle('Log in - Gotei');

  return (
    <CheckLoginSuccessFlow>
      <LoginComposition />
    </CheckLoginSuccessFlow>
  );
};

export default LoginScreen;
