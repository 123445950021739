import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import {
  LoginFormKeys,
  LoginUserForm,
  FieldsErrors,
  FormFieldPayload,
} from 'model';
import { RootState } from 'store/config';

interface SliceState {
  loading: boolean;
  loginServerError?: string;
  loginForm: LoginUserForm;
  loginFormError: FieldsErrors;
}

const initialState: SliceState = {
  loading: false,
  loginServerError: '',
  loginForm: {
    username: '',
    password: '',
  },
  loginFormError: {},
};

const LoginSlice = createSlice({
  initialState,
  name: 'login',
  reducers: {
    setLoginLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    updateLoginForm: (
      state,
      action: PayloadAction<FormFieldPayload<LoginFormKeys>>,
    ) => {
      return {
        ...state,
        loginForm: {
          ...state.loginForm,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearLoginForm: (state) => {
      return {
        ...state,
        loginForm: {
          username: '',
          password: '',
        },
      };
    },
    setLoginFormError: (
      state,
      action: PayloadAction<FormFieldPayload<LoginFormKeys>>,
    ) => {
      return {
        ...state,
        loginFormError: {
          ...state.loginFormError,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearLoginFormError: (state) => {
      return {
        ...state,
        loginFormError: {},
      };
    },
    setLoginServerError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loginServerError: action.payload,
      };
    },
    clearServerError: (state) => {
      return {
        ...state,
        loginServerError: undefined,
      };
    },
  },
});

export const {
  setLoginLoading,
  updateLoginForm,
  clearLoginForm,
  setLoginFormError,
  clearLoginFormError,
  setLoginServerError,
  clearServerError,
} = LoginSlice.actions;

export enum LoginActionTypes {
  LOGIN_FORM_SUBMIT = 'login/formSubmit',
  LOGIN_SUCCESS = 'login/success',
}

export const loginSubmit = () => {
  return {
    type: LoginActionTypes.LOGIN_FORM_SUBMIT,
  };
};

export const selectLogin = (state: RootState) => {
  return state.login;
};

export const selectLoginForm = createSelector(
  [selectLogin],
  (value) => value.loginForm,
);

export const selectLoginFormError = createSelector(
  [selectLogin],
  (value) => value.loginFormError,
);

export const loginReducer = LoginSlice.reducer;
