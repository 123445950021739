import { call, fork, takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { calendarModuleRpc, clientsModuleRpc } from 'services/api';
import { logger, formErrorIsEmpty } from 'utils';
import { AppointmentByClientParams, BookingType, Client } from 'model';
import { RootState } from 'store/config';

import {
  ClientActionTypes,
  setClient,
  setClientLoading,
  selectClientFormError,
  setClientFormLoading,
  selectClientFormUpdate,
  clearClientFormError,
  clearClientFormUpdate,
  setClientFormError,
  selectClientFormDelete,
  setClientFormDeleteSuccess,
  selectClientDetail,
  clearClientFormDelete,
  setAppointments,
  setAppointmentsLoading,
  setAppointmentsAreFetched,
} from '.';

function* getClient(action: PayloadAction<number>) {
  try {
    logger('Client Request');

    yield put(setClientLoading(true));

    const response: Client = yield call(
      [clientsModuleRpc, clientsModuleRpc.getClient],
      action.payload,
    );

    logger('Client Success');

    yield put(setClient(response));
    yield put(setClientLoading(false));
  } catch (error: any) {
    logger('Client Failure');

    yield put(setClientLoading(false));
  }
}

function* deleteClient() {
  const state: RootState = yield select();
  const clientFormDelete = selectClientFormDelete(state);

  try {
    logger('Client delete request');

    yield call(clientsModuleRpc.deleteClient.bind(clientsModuleRpc, clientFormDelete.id));

    logger('Client delete success');

    yield put(setClientFormDeleteSuccess(true));
    yield put(clearClientFormDelete());
  } catch (error: any) {
    logger.error('Client delete failure');

    yield put(setClientFormError(error));
    yield put(setClientFormLoading(false));
  }
}

function* updateClient() {
  yield call(validateClientFormEdit);
  const state: RootState = yield select();
  const clientFormUpdate = selectClientFormUpdate(state);
  const clientFormError = selectClientFormError(state);

  if (formErrorIsEmpty(clientFormError)) return;

  try {
    yield put(setClientFormLoading(true));

    logger('Client edit request');

    const response: Client = yield call(
      clientsModuleRpc.updateClient.bind(clientsModuleRpc, clientFormUpdate),
    );

    logger('Client update success');

    yield put(setClient(response));
    yield put(setClientFormLoading(false));
    yield put(clearClientFormUpdate());
  } catch (error: any) {
    logger.error('Client update failure');

    yield put(setClientFormError(error));
    yield put(setClientFormLoading(false));
  }
}

function* validateClientFormEdit() {
  yield put(clearClientFormError());

  const state: RootState = yield select();
  const clientFormUpdate = selectClientFormUpdate(state);

  if (!clientFormUpdate.name) {
    yield put(
      setClientFormError({
        key: 'name',
        value: 'Name is required.',
      }),
    );
  }

  if (!clientFormUpdate.email) {
    yield put(
      setClientFormError({
        key: 'email',
        value: 'Email is required.',
      }),
    );
  }
}

function* getClientAppointments() {
  const state: RootState = yield select();
  const client = selectClientDetail(state);

  try {
    logger('Client appointments request');

    yield put(setAppointmentsLoading(true));

    const params: AppointmentByClientParams = {
      client: client.id,
    };

    const response: BookingType[] = yield call(
      [calendarModuleRpc, calendarModuleRpc.getAppointmentByClient],
      params,
    );

    logger('Client appointments success');

    yield put(setAppointments(response));
    yield put(setAppointmentsAreFetched(true));
    yield put(setAppointmentsLoading(false));
  } catch (error: any) {
    logger.error('Client appointments failure');

    yield put(setAppointmentsLoading(false));
  }
}

function* getClientWatcher() {
  yield takeEvery(ClientActionTypes.CLIENT_REQUEST, getClient);
}

function* clientDeleteWatcher() {
  yield takeEvery(ClientActionTypes.CLIENT_DELETE_REQUEST, deleteClient);
}

function* clientEditWatcher() {
  yield takeLatest(ClientActionTypes.CLIENT_UPDATE_REQUEST, updateClient);
}

function* clientAppointmentsWatcher() {
  yield takeLatest(ClientActionTypes.CLIENT_APPOINTMENTS_REQUEST, getClientAppointments);
}

export const clientSagas = [
  fork(getClientWatcher),
  fork(clientDeleteWatcher),
  fork(clientEditWatcher),
  fork(clientAppointmentsWatcher),
];
