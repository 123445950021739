import { useState } from 'react';

// dependencies
import { Font, Popper } from 'elements';
import styled from 'styled-components';
import {
  Check,
  ChevronLeft,
  Delete,
  Edit,
  KeyboardBackspaceRounded,
  MoreVertTwoTone,
} from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';

// components
import StaffDetail from './components/StaffDetail';

// redux
import { RootState } from 'store/config';
import { useDispatch, useSelector } from 'react-redux';
import {
  setStaffDetailEdit,
  clearStaffDetailEdit,
  setStaffDetailDelete,
  staffDetailUpdateRequest,
} from '.';
import { LoadingButton } from '@mui/lab';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem;
  }
`;

const Content = styled.div`
  margin-top: 1rem;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const MobileScreenShow = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
  }
`;

const MdScreenShow = styled.div`
  display: none;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
  }
`;

function StaffDetailComposition() {
  const { staffDetail, staffDetailEditLoading, staffDetailEdit } = useSelector(
    (state: RootState) => state.staffDetail,
  );
  const [popperEl, setPopperEl] = useState<any>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Container>
      <PageHeader>
        <MobileScreenShow>
          <Box flex={1}>
            <IconButton onClick={() => history.goBack()}>
              <KeyboardBackspaceRounded sx={{ fontSize: 30 }} />
            </IconButton>
          </Box>

          <Box flex={1.5} textAlign='center'>
            <Font type='h1'>Staff Detail</Font>
          </Box>

          <Stack flex={1} direction='row' justifyContent='flex-end'>
            {staffDetailEdit.id ? (
              <>
                <Button
                  color='inherit'
                  sx={{
                    mr: 1,
                  }}
                  onClick={() => dispatch(clearStaffDetailEdit())}
                >
                  Cancel
                </Button>

                <LoadingButton
                  color='primary'
                  variant='contained'
                  startIcon={<Check />}
                  loading={staffDetailEditLoading}
                  onClick={() => dispatch(staffDetailUpdateRequest())}
                >
                  Save
                </LoadingButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={(event) => {
                    setPopperEl(event.currentTarget);
                  }}
                >
                  <MoreVertTwoTone />
                </IconButton>

                <Popper open={!!popperEl} anchorEl={popperEl} onClose={() => setPopperEl(null)}>
                  <Stack p={1} spacing={1} divider={<Divider />}>
                    <Box>
                      <Button
                        fullWidth
                        color='inherit'
                        size='large'
                        startIcon={<Edit />}
                        onClick={() => {
                          dispatch(setStaffDetailEdit(staffDetail));

                          setPopperEl(null);
                        }}
                      >
                        Edit
                      </Button>
                    </Box>

                    <Button
                      fullWidth
                      color='error'
                      size='large'
                      startIcon={<Delete />}
                      disabled={staffDetail.user?.is_company_admin}
                      onClick={() => {
                        dispatch(setStaffDetailDelete(staffDetail));

                        setPopperEl(null);
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                </Popper>
              </>
            )}
          </Stack>
        </MobileScreenShow>

        <MdScreenShow>
          <Font type='h1'>Staff Detail</Font>

          <Font>
            Staff detailed page, here you can edit staff information or delete them. Set up staff
            configurations like working hours, break time and assign or unassign services from this
            staff.
          </Font>

          <Box mt={2} ml={-1}>
            <Button color='inherit' startIcon={<ChevronLeft />} onClick={() => history.goBack()}>
              Back
            </Button>
          </Box>
        </MdScreenShow>
      </PageHeader>

      <Divider />

      <Content>
        <StaffDetail />
      </Content>
    </Container>
  );
}

export default StaffDetailComposition;
