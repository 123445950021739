import { useState } from 'react';

// dependencies
import styled from 'styled-components';
import { Badge, IconButton, Box, Stack, Avatar, Button, Divider } from '@mui/material';
import { LogoutOutlined, Notifications } from '@mui/icons-material';
import { Font, Popper, Logo } from 'elements';
import { usePrivateLayout } from 'contexts/usePrivateLayout';

// redux
import { authLogoutRequest } from 'store/auth';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { setIsOpenDrawer } from 'store/ui';

import { ReactComponent as AlignRight } from 'assets/svg/align-right.svg';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  height: 65px;
`;

const LogoWrapper = styled.div`
  display: flex;
  padding-left: 1.5rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding-left: 0.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 1rem;
  }
`;

const Toolbar = styled.div`
  padding-right: 1.5rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding-right: 0.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-right: 0.5rem;
  }
`;

const NavBar = () => {
  const { user, logoutLoading } = useSelector((state: RootState) => state.auth);
  const { notifications, isOpenDrawer } = useSelector((state: RootState) => state.ui);
  const [accountEl, setAccountEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const { setNotificationAnchor } = usePrivateLayout();

  return (
    <Container>
      <LogoWrapper>
        <Logo link='/dashboard' size='large' />
      </LogoWrapper>

      <Toolbar>
        <Box
          display={{
            sm: 'block',
            lg: 'none',
          }}
        >
          <IconButton onClick={() => dispatch(setIsOpenDrawer(!isOpenDrawer))}>
            <Badge
              variant='dot'
              invisible={!notifications.filter((notification) => !notification.is_read).length}
              color='error'
            >
              <AlignRight />
            </Badge>
          </IconButton>
        </Box>

        <Box
          display={{
            xs: 'none',
            sm: 'none',
            lg: 'block',
          }}
        >
          <IconButton
            color='inherit'
            sx={{ mr: 0.7 }}
            onClick={(event) => setNotificationAnchor(event)}
          >
            <Badge
              badgeContent={notifications.filter((notification) => !notification.is_read).length}
              color='error'
            >
              <Notifications />
            </Badge>
          </IconButton>

          <IconButton
            color='inherit'
            disabled={logoutLoading}
            onClick={(event) => {
              setAccountEl(event.currentTarget);
            }}
          >
            <Avatar sx={{ width: 24, height: 24 }} src={user.image || ''} />
          </IconButton>
        </Box>

        <Popper open={!!accountEl} anchorEl={accountEl} onClose={() => setAccountEl(null)}>
          <Stack p={'5px'} minWidth={150}>
            <Stack direction='row' alignItems='center' spacing={2} p={1}>
              <Avatar sx={{ width: 45, height: 45 }} src={user.image || ''} />

              <Stack p={1}>
                <Font type='h4'>{user.name}</Font>
                <Font>{user.email}</Font>
              </Stack>
            </Stack>

            <Divider sx={{ m: 1 }} />

            <Box m={1}>
              <Button
                fullWidth
                color='inherit'
                sx={{ justifyContent: 'flex-start' }}
                startIcon={<LogoutOutlined sx={{ p: '0 7px' }} />}
                onClick={() => {
                  dispatch(authLogoutRequest());
                }}
              >
                Logout
              </Button>
            </Box>
          </Stack>
        </Popper>
      </Toolbar>
    </Container>
  );
};
export default NavBar;
