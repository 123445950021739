// dependencies
import styled from 'styled-components';
import { Font } from 'elements';
import moment from 'moment-timezone';
import { Divider } from '@mui/material';
import { useDocumentTitle } from 'hooks';

// redux
import { AccessTime, InsertInvitation, Person } from '@mui/icons-material';
import { RootState } from 'store/config';
import { useSelector } from 'react-redux';

import calendarCancel from 'assets/images/calendar-cancel.png';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
  }
`;

const Header = styled.div`
  text-align: center;
`;

const Service = styled.div`
  flex: 1;
  margin-left: 24px;
`;

const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const Img = styled.img``;

function BookingCancelled() {
  const { booking } = useSelector((state: RootState) => state.manage);

  useDocumentTitle('Appointment Cancelled - Gotei');

  return (
    <Container>
      <Content>
        <Image>
          <Img src={calendarCancel} />
        </Image>

        <Header>
          <Font
            type='h2'
            style={{
              display: 'block',
              marginBottom: 16,
            }}
          >
            Appointment Cancelled
          </Font>

          <Font
            style={{
              color: 'rgba(26, 26, 26, 0.6)',
              display: 'block',
            }}
          >
            Your meeting with {booking.staff.name} has been canceled.
          </Font>
        </Header>

        <Divider sx={{ m: 3 }} />

        <Service>
          <Font type='h4' style={{ marginBottom: 16, color: 'rgba(26, 26, 26, 0.6)' }}>
            Appointment Overview
          </Font>

          <ServiceItem>
            <Person sx={{ mr: 2 }} />

            <Font
              style={{
                textDecoration: 'line-through',
              }}
            >
              {`${booking.client.name}`}
            </Font>
          </ServiceItem>

          <ServiceItem>
            <InsertInvitation sx={{ mr: 2 }} />

            <Font
              style={{
                textDecoration: 'line-through',
              }}
            >
              {moment(booking.start_time).format('ddd D MMMM YYYY, HH:mm')}
            </Font>
          </ServiceItem>

          <ServiceItem>
            <AccessTime sx={{ mr: 2 }} />

            <Font
              style={{
                textDecoration: 'line-through',
              }}
            >
              {moment(booking.end_time).diff(booking.start_time, 'minutes')} minutes
            </Font>
          </ServiceItem>
        </Service>

        <Divider sx={{ margin: '8px 24px 0 24px' }} />
      </Content>
    </Container>
  );
}

export default BookingCancelled;
