// dependencies
import {
  Button,
  Skeleton,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  Box,
  Stack,
} from '@mui/material';
import { Font } from 'elements';
import styled from 'styled-components';
import moment from 'moment-timezone';
import WithPermission from 'hoc/WithPermission';
import { BookingType } from 'model';
import { usePrivateLayout } from 'contexts/usePrivateLayout';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { setEventPreview } from 'store/ui';
import { setDailyBookingsSelected } from '..';

import { ReactComponent as SVG } from 'assets/svg/not-found.svg';

const SvgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 2rem 0;
`;

const NotFoundSvg = styled(SVG)`
  width: 100%;
  height: auto;
  max-width: 280px;
`;

const Time = styled(Font)`
  margin-right: 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-right: 1rem;
  }
`;

const EventBadge = styled.div`
  min-width: 20px;
  min-height: 20px;
  background-color: #eaeffe;
  border-radius: 4px;
  margin-right: 1rem;
`;

const Duration = styled.div`
  flex: 1;
  text-align: end;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

function DailyBookings() {
  const { dailyBookings, dailyBookingsLoading, dailyBookingsSelected } = useSelector(
    (state: RootState) => state.dashboard,
  );
  const { staff } = useSelector((state: RootState) => state.organization);
  const dispatch = useDispatch();
  const { setAppointmentAnchor } = usePrivateLayout();

  return (
    <Card
      sx={{
        m: '1rem 1px 1px 1rem',
      }}
    >
      <CardHeader
        title='Today appointments'
        action={
          <WithPermission permission='company_receptionist'>
            <FormControl fullWidth>
              <Select
                defaultValue='none'
                variant='standard'
                disableUnderline
                onChange={(event) => {
                  if (event.target.value === 'All') {
                    const dailyBookingsCopy: Array<BookingType> = [...dailyBookings];
                    dispatch(setDailyBookingsSelected(dailyBookingsCopy));
                  } else {
                    const dailyBookingsCopy: Array<BookingType> = dailyBookings.filter(
                      (booking: any) => Number(event.target.value) === booking.staff.id,
                    );
                    dispatch(setDailyBookingsSelected(dailyBookingsCopy));
                  }
                }}
              >
                <MenuItem value='none'>All</MenuItem>

                {staff.map((staffDetail, index) => (
                  <MenuItem value={staffDetail.id} key={index}>
                    {staffDetail.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </WithPermission>
        }
      />

      <CardContent
        sx={{
          flex: '1 0 0',
          minHeight: 300,
          overflow: 'auto',
        }}
      >
        {dailyBookingsLoading ? (
          [1, 2, 3, 4, 5].map((_, index) => (
            <Stack
              key={index}
              minHeight={50}
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Stack flex={3} direction='row' alignItems='center'>
                <Stack flex={4} direction='row' alignItems='center'>
                  <EventBadge />

                  <Stack flex={1}>
                    <Skeleton variant='text' width={40} height={20} />
                  </Stack>

                  <Stack flex={1} mr={2}>
                    <Skeleton variant='text' width={'100%'} height={20} />
                  </Stack>
                </Stack>

                <Box flex={1} display='flex' justifyContent='flex-end'>
                  <Skeleton variant='text' width={40} height={20} />
                </Box>
              </Stack>

              <Box flex={1} display='flex' justifyContent='flex-end'>
                <Skeleton variant='text' width={40} height={20} />
              </Box>
            </Stack>
          ))
        ) : !dailyBookingsSelected.length ? (
          <SvgContainer>
            <NotFoundSvg />
            <Font style={{ marginTop: '2rem' }} type='h4'>
              Look like today is very quiet
            </Font>
            <Font>Your first appointment is just a click away.</Font>
          </SvgContainer>
        ) : (
          dailyBookingsSelected.map((data, index) => (
            <Stack
              key={index}
              minHeight={50}
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Stack flex={3} direction='row' alignItems='center'>
                <Stack flex={4} direction='row' alignItems='center'>
                  <EventBadge />

                  <Time type='h5'>{moment(data.start_time).format('HH:mm')}</Time>

                  <Stack mr={2}>
                    <Font>{`${data.staff.name} ${data.service.name} with ${data.client.name}`}</Font>
                  </Stack>
                </Stack>

                <Duration>
                  <Font>{data.service.duration} min</Font>
                </Duration>
              </Stack>

              <Box flex={1} textAlign='end'>
                <Button
                  color='inherit'
                  variant='outlined'
                  onClick={(event) => {
                    dispatch(setEventPreview(data));
                    setAppointmentAnchor(event);
                  }}
                >
                  Details
                </Button>
              </Box>
            </Stack>
          ))
        )}
      </CardContent>
    </Card>
  );
}

export default DailyBookings;
