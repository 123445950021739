// dependencies
import styled from 'styled-components';
import { Theme } from '@mui/material';
import { useDocumentTitle } from 'hooks';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
`;

const InfoSection = styled.div`
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  margin-left: 0.6rem;
  color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
`;

const Strong = styled.strong`
  color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
`;

const NotFound = () => {
  useDocumentTitle('Page not found - Gotei');

  return (
    <Container>
      <Content>
        <InfoSection>
          <Paragraph>
            <Strong>404</Strong>. Page Not Found.
          </Paragraph>

          <Paragraph>The requested url was not found on this server.</Paragraph>
        </InfoSection>
      </Content>
    </Container>
  );
};

export default NotFound;
