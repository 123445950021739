import { Close, CloudUploadOutlined } from '@mui/icons-material';
import { FormHelperText, IconButton, Stack, Theme } from '@mui/material';
import { Font } from 'elements';
import React, { useState } from 'react';

import styled from 'styled-components';

const Container = styled.div``;

const DropZone = styled.div`
  flex: 1;
  border: 2px dashed #e1e1e1;
  border-radius: 4px;
  stroke-dasharray: 16;
  display: block;
  padding: 1rem;
  min-height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  display: none;
`;

const Text = styled(Font)``;

const Browse = styled.label`
  color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
  font-weight: 600;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const FileUploadPreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FileUploadPreviewName = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.palette.grey[600]};
`;

const marginValues = {
  none: 0,
  dense: '8px 0 4px 0',
  normal: '16px 0 8px 0',
};

type Accept = '.pdf' | '.txt' | '.doc' | '.docx' | 'image/*';

interface Props {
  accept?: Accept[];
  margin?: 'none' | 'normal' | 'dense';
  showTypes?: boolean;
  showUploaded?: boolean;
  htmlFor?: string;
  style?: React.CSSProperties;
  onFileUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFileDelete?: () => void;
}

function FileUpload({
  margin = 'none',
  accept = [],
  showTypes = true,
  showUploaded = false,
  htmlFor = 'file-upload',
  style = {},
  onFileUpload = () => {},
  onFileDelete = () => {},
}: Props) {
  const [file, setFile] = useState<File | undefined>(undefined);

  return (
    <Container style={style}>
      <DropZone
        style={{
          margin: marginValues[margin],
        }}
      >
        <Input
          accept={accept.join(', ')}
          // accept='image/*'
          id={htmlFor}
          type='file'
          onChange={(event) => {
            const file = event.target.files?.[0];

            setFile(file);
            onFileUpload(event);
          }}
        />

        <Stack>
          <Stack mb={1} alignItems='center'>
            <CloudUploadOutlined
              fontSize='large'
              sx={{ color: (theme) => theme.palette.grey[400] }}
            />

            {showTypes && !!accept.length && (
              <FormHelperText sx={{ textAlign: 'center' }}>{accept.join(' ')}</FormHelperText>
            )}
          </Stack>

          <Text>
            Drop your file here or <Browse htmlFor={htmlFor}>browse</Browse>
          </Text>
        </Stack>
      </DropZone>

      {!!file?.name && showUploaded && (
        <FileUploadPreview>
          <FileUploadPreviewName>{file.name}</FileUploadPreviewName>

          <IconButton
            size='small'
            onClick={() => {
              setFile(undefined);
              onFileDelete();
            }}
          >
            <Close fontSize='small' />
          </IconButton>
        </FileUploadPreview>
      )}
    </Container>
  );
}

export default FileUpload;
