import { createSlice, PayloadAction, Action, createSelector } from '@reduxjs/toolkit';
import {
  Snackbar,
  NotificationType,
  BookingType,
  BookingCreateType,
  FieldsErrors,
  FormFieldPayload,
  BookingUpdateType,
} from 'model';
import { RootState } from 'store/config';

interface State {
  isOpenDrawer: boolean;
  months: Array<number>;
  weekdays: Array<number>;
  snackbar: Snackbar;
  snackbarSuccessAction: PayloadAction | Action;
  snackbarFailureAction: PayloadAction | Action;
  snackbarActionUndo: boolean;
  notifications: Array<NotificationType>;
  notificationWsOpen: boolean;
  notificationsAreFetched: boolean;
  eventPreview: BookingType;
  eventFormLoading: boolean;
  eventFormError: FieldsErrors;
  eventFormCreate: BookingCreateType;
  eventFormUpdate: BookingUpdateType;
  eventFormDropUpdate: BookingUpdateType;
  eventDeleteModal: boolean;
}

const initialState: State = {
  isOpenDrawer: false,
  months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  weekdays: [1, 2, 3, 4, 5, 6, 0],
  snackbar: {
    open: false,
    message: '',
  },
  snackbarSuccessAction: {
    type: '',
    payload: undefined,
  },
  snackbarFailureAction: {
    type: '',
    payload: undefined,
  },
  snackbarActionUndo: false,
  notifications: [],
  notificationWsOpen: false,
  notificationsAreFetched: false,
  eventPreview: {
    id: 0,
    uuid: '',
    service: {
      company: '',
      created: '',
      description: '',
      duration: '',
      id: 0,
      name: '',
      staff: [],
      updated: '',
      image: '',
      is_meeting: false,
      is_active: true,
    },
    client: {
      company: '',
      id: 0,
      name: '',
      email: '',
      phone: null,
    },
    start_time: '',
    end_time: '',
    created: '',
    updated: '',
    staff: {
      company: '',
      created: '',
      description: '',
      id: 0,
      name: '',
      updated: '',
      user: null,
      image: '',
    },
    company: '',
    is_canceled: false,
  },
  eventFormLoading: false,
  eventFormError: {},
  eventFormCreate: {
    client: 0,
    service: 0,
    staff: 0,
    company: '',
    start_time: '',
  },
  eventFormUpdate: {
    id: 0,
    uuid: '',
    service: 0,
    client: 0,
    start_time: '',
    end_time: '',
    created: '',
    updated: '',
    staff: 0,
    company: '',
    is_canceled: false,
  },
  eventFormDropUpdate: {
    id: 0,
    uuid: '',
    service: 0,
    client: 0,
    start_time: '',
    end_time: '',
    created: '',
    updated: '',
    staff: 0,
    company: '',
    is_canceled: false,
  },
  eventDeleteModal: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsOpenDrawer: (state, action: PayloadAction<boolean>) => {
      state.isOpenDrawer = action.payload;
    },
    openSnackbar: (state, action: PayloadAction<Omit<Snackbar, 'open'>>) => {
      return {
        ...state,
        snackbar: {
          open: true,
          ...action.payload,
        },
      };
    },
    closeSnackbar: (state) => {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
        },
      };
    },
    clearSnackbar: (state) => {
      return {
        ...state,
        snackbar: {
          open: false,
          message: '',
        },
      };
    },
    setSnackbarSuccessAction: (state, action: PayloadAction<PayloadAction | Action>) => {
      return {
        ...state,
        snackbarSuccessAction: action.payload,
      };
    },
    clearSnackbarSuccessAction: (state) => {
      return {
        ...state,
        snackbarSuccessAction: {
          type: '',
          payload: undefined,
        },
      };
    },
    setSnackbarFailureAction: (state, action: PayloadAction<PayloadAction | Action>) => {
      return {
        ...state,
        snackbarFailureAction: action.payload,
      };
    },
    clearSnackbarFailureAction: (state) => {
      return {
        ...state,
        snackbarFailureAction: {
          type: '',
          payload: undefined,
        },
      };
    },
    setSnackbarActionUndo: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        snackbarActionUndo: action.payload,
      };
    },
    setNotifications: (state, action: PayloadAction<Array<NotificationType>>) => {
      return {
        ...state,
        notifications: action.payload,
      };
    },
    updateNotifications: (state, action: PayloadAction<NotificationType>) => {
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    },
    updateNotificationsReadStatus: (state, action: PayloadAction<NotificationType>) => {
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification.id === action.payload.id) {
            return action.payload;
          } else {
            return notification;
          }
        }),
      };
    },
    setNotificationWsOpen: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        notificationWsOpen: action.payload,
      };
    },
    setNotificationAreFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        notificationsAreFetched: action.payload,
      };
    },
    setEventPreview: (state, action: PayloadAction<BookingType>) => {
      return {
        ...state,
        eventPreview: action.payload,
      };
    },
    setEventFormLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        eventFormLoading: action.payload,
      };
    },
    setEventFormError: (state, action: PayloadAction<FieldsErrors>) => {
      return {
        ...state,
        eventFormError: {
          ...state.eventFormError,
          ...action.payload,
        },
      };
    },
    clearEventFormError: (state) => {
      return {
        ...state,
        eventFormError: {},
      };
    },
    updateEventFormCreate: (
      state,
      action: PayloadAction<FormFieldPayload<keyof BookingCreateType>>,
    ) => {
      return {
        ...state,
        eventFormCreate: {
          ...state.eventFormCreate,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearEventFormCreate: (state) => {
      return {
        ...state,
        eventFormCreate: {
          client: 0,
          service: 0,
          staff: 0,
          company: '',
          start_time: '',
        },
      };
    },
    setEventFormUpdate: (state, action: PayloadAction<BookingUpdateType>) => {
      return {
        ...state,
        eventFormUpdate: action.payload,
      };
    },
    updateEventFormUpdate: (
      state,
      action: PayloadAction<FormFieldPayload<keyof BookingUpdateType>>,
    ) => {
      return {
        ...state,
        eventFormUpdate: {
          ...state.eventFormUpdate,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearEventFormUpdate: (state) => {
      return {
        ...state,
        eventFormUpdate: {
          id: 0,
          uuid: '',
          service: 0,
          client: 0,
          start_time: '',
          end_time: '',
          created: '',
          updated: '',
          staff: 0,
          company: '',
          is_canceled: false,
        },
      };
    },
    setEventFormDropUpdate: (state, action: PayloadAction<BookingUpdateType>) => {
      return {
        ...state,
        eventFormDropUpdate: action.payload,
      };
    },
    clearEventFormDropUpdate: (state) => {
      return {
        ...state,
        eventFormDropUpdate: {
          id: 0,
          uuid: '',
          service: 0,
          client: 0,
          start_time: '',
          end_time: '',
          created: '',
          updated: '',
          staff: 0,
          company: '',
          is_canceled: false,
        },
      };
    },
    setEventDeleteModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        eventDeleteModal: action.payload,
      };
    },
  },
});

export const {
  setIsOpenDrawer,
  openSnackbar,
  closeSnackbar,
  clearSnackbar,
  setSnackbarSuccessAction,
  clearSnackbarSuccessAction,
  setSnackbarFailureAction,
  clearSnackbarFailureAction,
  setSnackbarActionUndo,
  setNotifications,
  updateNotifications,
  setNotificationWsOpen,
  updateNotificationsReadStatus,
  setNotificationAreFetched,
  setEventPreview,
  setEventFormLoading,
  setEventFormError,
  clearEventFormError,
  updateEventFormCreate,
  clearEventFormCreate,
  setEventFormUpdate,
  updateEventFormUpdate,
  clearEventFormUpdate,
  setEventFormDropUpdate,
  clearEventFormDropUpdate,
  setEventDeleteModal,
} = uiSlice.actions;

export enum UiActionTypes {
  OPEN_SNACKBAR_REQUEST = 'ui/OPEN_SNACKBAR_REQUEST',
  CLOSE_SNACKBAR_REQUEST = 'ui/CLOSE_SNACKBAR_REQUEST',
  UNDO_SNACKBAR_REQUEST = 'ui/UNDO_SNACKBAR_REQUEST',
  WS_NOTIFICATION_CONNECT = 'ui/WS_NOTIFICATION_CONNECT',
  WS_NOTIFICATION_DISCONNECT = 'ui/WS_NOTIFICATION_DISCONNECT',
  EVENT_CREATE_SUBMIT = 'calendar/EVENT_CREATE_SUBMIT',
  EVENT_UPDATE_SUBMIT = 'calendar/EVENT_UPDATE_SUBMIT',
  EVENT_DELETE_REQUEST = 'calendar/EVENT_DELETE_REQUEST',
}

export const openSnackbarRequest = (
  message: string,
  onSuccessAction?: PayloadAction | Action,
  onFailureAction?: PayloadAction | Action,
) => {
  return {
    type: UiActionTypes.OPEN_SNACKBAR_REQUEST,
    payload: { message, onSuccessAction, onFailureAction },
  };
};

export const closeSnackbarRequest = () => {
  return {
    type: UiActionTypes.CLOSE_SNACKBAR_REQUEST,
  };
};

export const undoSnackbarRequest = () => {
  return {
    type: UiActionTypes.UNDO_SNACKBAR_REQUEST,
  };
};

export const wsNotificationConnect = () => {
  return {
    type: UiActionTypes.WS_NOTIFICATION_CONNECT,
  };
};

export const wsNotificationDisconnect = () => {
  return {
    type: UiActionTypes.WS_NOTIFICATION_DISCONNECT,
  };
};

export const eventCreateSubmit = () => {
  return {
    type: UiActionTypes.EVENT_CREATE_SUBMIT,
  };
};

export const eventUpdateSubmit = () => {
  return {
    type: UiActionTypes.EVENT_UPDATE_SUBMIT,
  };
};

export const eventDeleteRequest = () => {
  return {
    type: UiActionTypes.EVENT_DELETE_REQUEST,
  };
};

export const selectUi = (state: RootState) => state.ui;

export const selectUiSnackbarSuccessAction = createSelector(
  [selectUi],
  (value) => value.snackbarSuccessAction,
);

export const selectUiSnackbarFailureAction = createSelector(
  [selectUi],
  (value) => value.snackbarFailureAction,
);

export const selectUiSnackbarActionUndo = createSelector(
  [selectUi],
  (value) => value.snackbarActionUndo,
);

export const selectNotifications = createSelector([selectUi], (value) => value.notifications);

export const selectEventFormError = createSelector([selectUi], (value) => value.eventFormError);

export const selectEventFormCreate = createSelector([selectUi], (value) => value.eventFormCreate);

export const selectEventFormUpdate = createSelector([selectUi], (value) => value.eventFormUpdate);

export const uiReducer = uiSlice.reducer;
