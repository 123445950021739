export function fileToBase64(file: File) {
  return new Promise<string>(function (resolve, reject) {
    const reader = new FileReader();
    reader.onload = function () {
      // we add on base64 the file name in order to take it later when we pare it on file creation
      resolve(reader.result + `||${file.name}`);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function base64ToFile(url = 'default', name?: string) {
  const [fileURL, fileName] = url.split('||'); // we separate file url from file name
  const arr = fileURL.split(',');
  const mime = arr[0]?.match(/:(.*?);/)?.[1];
  const finalName = name ? name : fileName ? fileName : 'untitled';

  return fetch(fileURL)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], finalName, { type: mime });
    });
}

export const urlToBase64 = async (imageUrl: string) => {
  return await fetch(imageUrl)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
