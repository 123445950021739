import { Box, Button, Divider, IconButton, Stack } from '@mui/material';
import { Font } from 'elements';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ChevronLeft, KeyboardBackspaceRounded } from '@mui/icons-material';

// components
import { GoogleMeetIntegration } from 'screens/GoogleMeetIntegration/components';

// images

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 1.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem;
  }
`;

const Content = styled.div`
  display: flex;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

const XsScreenShow = styled.div`
  flex: 1;
  display: none;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
  }
`;

const MdScreenShow = styled.div`
  display: none;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
  }
`;

export const GoogleMeetIntegrationComposition = () => {
  const history = useHistory();

  return (
    <Container>
      <PageHeader>
        <XsScreenShow>
          <Box flex={1}>
            <IconButton onClick={() => history.push('/integrations')}>
              <KeyboardBackspaceRounded sx={{ fontSize: 30 }} />
            </IconButton>
          </Box>

          <Box flex={3} textAlign='center'>
            <Font type='h1'>Google meet integration</Font>
          </Box>

          <Box flex={1} />
        </XsScreenShow>

        <MdScreenShow>
          <Stack>
            <Font type='h1'>Google meet integration</Font>

            <Font>
              Include Google Meet details in your Gotei events by connecting your google calendar.
            </Font>
          </Stack>

          <Box mt={2} ml={-1}>
            <Button
              color='inherit'
              startIcon={<ChevronLeft />}
              onClick={() => history.push('/integrations')}
            >
              Back
            </Button>
          </Box>
        </MdScreenShow>
      </PageHeader>

      <Divider />

      <Content>
        <GoogleMeetIntegration />
      </Content>
    </Container>
  );
};
