import styled from 'styled-components';

import personImg from 'assets/images/person-thumb-up.png';
import { Font } from 'elements';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1rem;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  max-width: 1000px;
  margin-bottom: 120px;
  border-radius: 10px;
  height: 400px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: auto;
    padding: 1rem;
    margin-bottom: 80px;
  }
`;

const PersonSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const PersonImage = styled.img`
  position: absolute;
  height: 125%;
  bottom: 0;
`;

const RegisterSection = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 45px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 0;
  }
`;

const RegisterTitle = styled(Font)`
  font-size: 37px;
  font-weight: 700;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 28px;
  }
`;

const RegisterDescription = styled(Font)`
  font-size: 18px;
  color: ${({ theme }) => theme.palette.common.white};
`;

const ButtonWhite = styled(Button)`
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
    opacity: 0.9;
  }
`;

function Register() {
  const history = useHistory();

  return (
    <Container>
      <Content>
        <PersonSection>
          <PersonImage src={personImg} />
        </PersonSection>

        <RegisterSection>
          <RegisterTitle>Get your free account</RegisterTitle>

          <RegisterDescription>
            What are you waiting for, get started now and manage your appointments easier than ever.
          </RegisterDescription>

          <Box mt={5}>
            <ButtonWhite variant='contained' size='large' onClick={() => history.push('/signup')}>
              Sign up
            </ButtonWhite>
          </Box>
        </RegisterSection>
      </Content>
    </Container>
  );
}

export default Register;
