import { Chip, FormHelperText, FormLabel, InputAdornment, TextField } from '@mui/material';
import styled from 'styled-components';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { updateServiceFormAdd } from '..';
import { RootState } from 'store/config';
import { Numbers } from '@mui/icons-material';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ServiceDetailSection = styled.div`
  margin-top: 1.5rem;
  display: flex;
`;

const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const ChipWrapper = styled.div`
  margin-left: -5px;
`;

function ServiceFormAdd() {
  const { serviceFormAdd, serviceFormAddError } = useSelector((state: RootState) => state.services);
  const { staff } = useSelector((state: RootState) => state.organization);
  const dispatch = useDispatch();

  return (
    <Container>
      <Header>
        <TextField
          type='text'
          label='Service name'
          fullWidth
          sx={{ mr: 1.5 }}
          value={serviceFormAdd.name}
          error={!!serviceFormAddError.name}
          helperText={serviceFormAddError.name}
          onChange={(event) => {
            dispatch(
              updateServiceFormAdd({
                key: 'name',
                value: event.currentTarget.value,
              }),
            );
          }}
        />

        <TextField
          type='number'
          label='Duration'
          fullWidth
          sx={{ ml: 1.5 }}
          value={serviceFormAdd.duration}
          error={!!serviceFormAddError.duration}
          helperText={serviceFormAddError.duration ?? 'Provide duration in minutes'}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Numbers />
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            dispatch(
              updateServiceFormAdd({
                key: 'duration',
                value: event.currentTarget.value,
              }),
            );
          }}
        />
      </Header>

      <ServiceDetailSection>
        <div style={{ flex: 1 }}>
          <DetailsSection>
            <TextField
              type='text'
              size='small'
              multiline
              rows={4}
              label='Description'
              value={serviceFormAdd.description}
              error={!!serviceFormAddError.description}
              helperText={serviceFormAddError.description ?? 'Optional'}
              onChange={(event) => {
                dispatch(
                  updateServiceFormAdd({
                    key: 'description',
                    value: event.currentTarget.value,
                  }),
                );
              }}
            />
          </DetailsSection>

          <DetailsSection>
            {/* <Font type='h5'>Add or Remove users *</Font> */}
            <FormLabel sx={{ mb: 1.4 }}>Staff</FormLabel>

            <ChipWrapper>
              {staff.map((item) => {
                if (serviceFormAdd.staff.some((id) => id === item.id)) {
                  return (
                    <Chip
                      color='primary'
                      label={item.name}
                      key={item.id}
                      onDelete={() => {
                        const staffCopy = [...serviceFormAdd.staff];
                        const updatedStaff = staffCopy.filter((id) => id !== item.id);

                        dispatch(
                          updateServiceFormAdd({
                            key: 'staff',
                            value: updatedStaff,
                          }),
                        );
                      }}
                    />
                  );
                } else {
                  return (
                    <Chip
                      label={item.name}
                      key={item.id}
                      onClick={() => {
                        const staffCopy = [...serviceFormAdd.staff];
                        staffCopy.push(item.id);

                        dispatch(
                          updateServiceFormAdd({
                            key: 'staff',
                            value: staffCopy,
                          }),
                        );
                      }}
                    />
                  );
                }
              })}
            </ChipWrapper>

            <FormHelperText error={!!serviceFormAddError.staff}>
              {serviceFormAddError.staff
                ? serviceFormAddError.staff
                : 'You need to choose at least one user'}
            </FormHelperText>
          </DetailsSection>
        </div>
      </ServiceDetailSection>
    </Container>
  );
}

export default ServiceFormAdd;
