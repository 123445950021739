// dependencies
import styled from 'styled-components';
import { Font } from 'elements';
import moment from 'moment-timezone';
import { Avatar, Button } from '@mui/material';
import { AccessTime, InsertInvitation, Person } from '@mui/icons-material';
import { ManageSteps } from 'model';
import { LoadingButton } from '@mui/lab';
import { useDocumentTitle } from 'hooks';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/config';
import { setStep, bookingDeleteRequest } from '..';

import image from 'assets/images/placeholder.png';

const Container = styled.div`
  flex: 1;
`;

const Header = styled.div`
  padding: 1.5rem;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 4px;
  display: flex;
  margin-bottom: 2rem;
`;

const HeaderDetail = styled.div`
  margin-left: 1rem;
`;

const HeaderStaff = styled.div``;

const Service = styled.div``;

const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
  }
`;

function BookingOverview() {
  const { booking, deleteLoading } = useSelector((state: RootState) => state.manage);
  const dispatch = useDispatch();

  useDocumentTitle('Overview Appointment - Gotei');

  return (
    <Container>
      <Font
        style={{
          color: 'rgba(26, 26, 26, 0.6)',
          display: 'block',
          marginBottom: 8,
        }}
      >
        Overview Appointment
      </Font>

      <Font
        type='h2'
        style={{
          display: 'block',
          marginBottom: 32,
        }}
      >
        {moment(booking.start_time).format('D MMMM YYYY, HH:mm')}
      </Font>

      <Header>
        <HeaderStaff>
          <Avatar src={booking.service.image} sx={{ width: 55, height: 55 }}>
            <Avatar src={image} sx={{ width: 55, height: 55 }} />
          </Avatar>
        </HeaderStaff>

        <HeaderDetail>
          <Font type='h4'>
            {booking.service.duration} Minutes {booking.service.name}
          </Font>

          <Font>{booking.staff.name}</Font>
        </HeaderDetail>
      </Header>

      <Service>
        <Font type='h4' style={{ marginBottom: 16, color: 'rgba(26, 26, 26, 0.6)' }}>
          Appointment Details
        </Font>

        <ServiceItem>
          <Person sx={{ mr: 2 }} />
          <Font>{`${booking.client.name}`}</Font>
        </ServiceItem>

        <ServiceItem>
          <InsertInvitation sx={{ mr: 2 }} />
          <Font>{moment(booking.start_time).format('ddd D MMMM YYYY, HH:mm')}</Font>
        </ServiceItem>

        <ServiceItem>
          <AccessTime sx={{ mr: 2 }} />
          <Font>{moment(booking.end_time).diff(booking.start_time, 'minutes')} minutes</Font>
        </ServiceItem>
      </Service>

      <ButtonWrapper>
        <LoadingButton
          color='error'
          sx={{ mr: 2 }}
          loading={deleteLoading}
          onClick={() => {
            dispatch(bookingDeleteRequest());
          }}
        >
          Cancel Appointment
        </LoadingButton>
        <Button
          variant='contained'
          onClick={() => {
            dispatch(setStep(ManageSteps.reschedule));
          }}
        >
          Reschedule Appointment
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

export default BookingOverview;
