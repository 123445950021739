import BaseRpc from './BaseRpc';
import axios, { AxiosRequestConfig } from 'axios';

import { User, UserFormCreate, UserLoginRequest } from 'model';
import { base64ToFile, formDataGenerator, generateString } from 'utils';

axios.defaults.withCredentials = true; // this is required to be able receive the cookies tokens
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

export class UserModuleRpc extends BaseRpc {
  private getImageName() {
    return `${generateString()}.jpg`;
  }

  public async login(
    { username, password }: UserLoginRequest,
    config: AxiosRequestConfig = {},
  ): Promise<any> {
    const url = `${this.baseUrl}/auth/login/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const response = await axios.post<any>(url, { email: username, password }, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async signup(userFormCreate: UserFormCreate, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/accounts/user/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const response = await axios.post<any>(url, userFormCreate, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async resetPassword(email: string, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/accounts/reset_password/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const response = await axios.post<any>(url, { email }, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async confirmResetPassword(password: string, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/accounts/confirm_reset_password/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const response = await axios.put<any>(url, { password }, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async updateUserPartial(
    user: User,
    userPartial: Partial<User>,
    config: AxiosRequestConfig = {},
  ) {
    const url = `${this.baseUrl}/accounts/user/${user.id}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const image = userPartial.image
        ? await base64ToFile(userPartial.image, this.getImageName())
        : null;
      const formData = formDataGenerator({ ...userPartial, image });

      const response = await axios.patch<any>(url, formData, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async signupWithCompany(data: UserFormCreate, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/accounts/user/with_company/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const response = await axios.post<any>(url, data, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async tokenVerify(config: AxiosRequestConfig = {}): Promise<any> {
    const url = `${this.baseUrl}/auth/verify/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const response = await axios.post<any>(url, {}, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async tokenRefresh(config: AxiosRequestConfig = {}): Promise<any> {
    const url = `${this.baseUrl}/auth/refresh/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const response = await axios.post<any>(url, {}, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async logout(config: AxiosRequestConfig = {}): Promise<any> {
    const url = `${this.baseUrl}/auth/logout/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const response = await axios.post<any>(url, {}, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async userDetail(id: number, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/accounts/user/${id}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const response = await axios.get<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }

  public async userDelete(id: number, config: AxiosRequestConfig = {}) {
    const url = `${this.baseUrl}/accounts/user/${id}/`;

    if (config.headers == null) {
      config.headers = {
        'Content-Type': 'application/json',
      };
    }

    try {
      const response = await axios.delete<any>(url, config);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw Object.assign(new Error('Something went wrong'), { code: 402 });
      }
    }
  }
}

export default UserModuleRpc;
