import UserModuleRpc from './UserModuleRpc';
import CalendarModuleRpc from './CalendarModuleRpc';
import CompanyModuleRpc from './CompanyModuleRpc';
import StaffModuleRcp from './StaffModuleRpc';
import ServiceModuleRpc from './ServiceModuleRpc';
import ClientsModuleRpc from './ClientsModuleRpc';
import PublicModuleRpc from './PublicModuleRpc';

import { DOMAIN } from 'config';

export const userModuleRpc: UserModuleRpc = new UserModuleRpc(DOMAIN.API);
export const calendarModuleRpc: CalendarModuleRpc = new CalendarModuleRpc(DOMAIN.API);
export const companyModuleRpc: CompanyModuleRpc = new CompanyModuleRpc(DOMAIN.API);
export const staffModuleRpc: StaffModuleRcp = new StaffModuleRcp(DOMAIN.API);
export const serviceModuleRpc: ServiceModuleRpc = new ServiceModuleRpc(DOMAIN.API);
export const clientsModuleRpc: ClientsModuleRpc = new ClientsModuleRpc(DOMAIN.API);
export const publicModuleRpc: PublicModuleRpc = new PublicModuleRpc(DOMAIN.API);
