import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { BookingType, Staff } from 'model';
import moment from 'moment-timezone';
import { View } from 'react-big-calendar';
import { RootState } from 'store/config';

interface SliceState {
  date: string;
  calendarView: View;
  bookingLoading: boolean;
  bookings: Array<BookingType>;
  bookingsAreFetched: boolean;
  staff: Array<Staff>;
  staffSelected: Array<Staff>;
}

const initialState: SliceState = {
  date: moment().format(),
  calendarView: 'day',
  bookingLoading: false,
  bookings: [],
  bookingsAreFetched: false,
  staff: [],
  staffSelected: [],
};

const CalendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setDate: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        date: action.payload,
      };
    },
    setCalendarView: (state, action: PayloadAction<View>) => {
      return {
        ...state,
        calendarView: action.payload,
      };
    },
    setBookingLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        bookingLoading: action.payload,
      };
    },
    setBookings: (state, action: PayloadAction<Array<BookingType>>) => {
      return {
        ...state,
        bookings: action.payload,
      };
    },
    setBookingsAreFetched: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        bookingsAreFetched: action.payload,
      };
    },
    setStaff: (state, action: PayloadAction<Array<Staff>>) => {
      return {
        ...state,
        staff: action.payload,
      };
    },
    setStaffSelected: (state, action: PayloadAction<Array<Staff>>) => {
      return {
        ...state,
        staffSelected: action.payload,
      };
    },
  },
});

export const {
  setDate,
  setCalendarView,
  setBookingLoading,
  setBookingsAreFetched,
  setBookings,
  setStaff,
  setStaffSelected,
} = CalendarSlice.actions;

export enum CalendarActionTypes {
  BOOKINGS_REQUEST = 'calendar/BOOKINGS_REQUEST',
  STAFF_REQUEST = 'staff/STAFF_REQUEST',
}

export const bookingsRequest = () => {
  return {
    type: CalendarActionTypes.BOOKINGS_REQUEST,
  };
};

export const staffRequest = () => {
  return {
    type: CalendarActionTypes.STAFF_REQUEST,
  };
};

export const selectCalendar = (state: RootState) => {
  return state.calendar;
};

export const selectCalendarBookings = createSelector([selectCalendar], (value) => value.bookings);

export const selectCalendarView = createSelector([selectCalendar], (value) => value.calendarView);

export const selectCalendarDate = createSelector([selectCalendar], (value) => value.date);

export const selectStaffSelected = createSelector([selectCalendar], (value) => value.staffSelected);

export const calendarReducer = CalendarSlice.reducer;
