import React from 'react';

import { usePermission } from 'hooks';
import { Permission } from 'model';

interface Props {
  children: React.ReactNode;
  permission: Permission[0];
}

function WithPermission({ children, permission }: Props) {
  const isAllowed = usePermission(permission);

  if (isAllowed) {
    return <>{children}</>;
  } else {
    return null;
  }
}

export default WithPermission;
